import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LookingForGender, OwnGender } from "src/app/enums/gender";
import Pending from "src/app/enums/pending";
import { MeData } from "src/app/interfaces/MeData";
import { LocationInfoRegisterStep } from "src/app/interfaces/RegisterSteps";
import { ApiService } from "src/app/services/api.service";
import { AuthService } from "src/app/services/auth.service";
import { DeviceInfoService } from "src/app/services/device-info.service";
import { LocationService } from "src/app/services/location.service";

type RegistrationStepResponse = {
    pending: Pending[];
    me?: MeData;
    message?: string;
};

@Component({
    selector: "app-set-profile",
    templateUrl: "./set-profile.component.html",
})
export class SetProfileComponent implements AfterViewInit {
    public currentInputValid = false;
    public pendingArray: Pending[] = [];
    public me?: MeData;
    /**
     * The input values
     */
    public firstname = "";
    public gender?: OwnGender;
    public lookingFor?: LookingForGender;

    private locationInfo?: LocationInfoRegisterStep;

    public tags: string[] = [];
    public day = "";
    public month = "";
    public year = "";
    public dob = "";
    public validationDob = {
        day: false,
        month: false,
        year: false,
    };

    public imageUploaded = {};
    public photoUploading = false;
    public error = false;
    public loading = false;
    public preStep = false;
    public minOnePhotoUploaded = false;
    public showSentEmailScreen = false;
    public loadingWithoutTitle = false;
    public errorEmailVerification = "";
    /** State is used to prevent double clicks. */
    public blockForwardButton = false;

    public manuallySelectLocation = false;
    public locationError = "";

    /**
     * The number of uploaded photos
     */
    public numberUploadedPhotos = 0;

    // TODO: replace step with enum
    /**
     * The current step
     */
    public step: number;

    /**
     * The number of total steps
     */
    private stepCount = 4;
    public errorMessage = "";
    private verifyToken = "";

    @ViewChild("mainContent") private mainElement: ElementRef;

    private onTouchScroll?: (e: Event) => void;

    showLegaLinksModal = false;
    constructor(
        private locationService: LocationService,
        private api: ApiService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private changeDetectorRef: ChangeDetectorRef,
        private authService: AuthService,
        private deviceInfoService: DeviceInfoService
    ) {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.verifyToken = params["verifyToken"];
            this.loading = false;
            this.loadingWithoutTitle = false;
            this.beginOnboarding();
        });
    }

    ngAfterViewInit(): void {
        this.initAutocomplete();

        let keyboardOpened = false;

        this.onTouchScroll = () => {
            const availableScrollY =
                this.mainElement.nativeElement.scrollHeight - (window.visualViewport?.height ?? 0);

            if (window.scrollY > availableScrollY && keyboardOpened) {
                window.scrollTo({
                    top: availableScrollY,
                    behavior: "smooth",
                });
            }
        };
        window.addEventListener("touchend", this.onTouchScroll, {
            passive: false,
        });

        const allInputFields = document.querySelectorAll("input");

        allInputFields.forEach((inputField) => {
            inputField.addEventListener("focus", () => {
                // keyboard opened
                if (this.deviceInfoService.isUsingMobileSafari()) {
                    this.mainElement.nativeElement.style.height = "fit-content";

                    if (this.deviceInfoService.hasHomeButton()) {
                        if (this.step === 1) {
                            // digit keyboard is opened
                            this.mainElement.nativeElement.style.minHeight = "calc(100% - 190px)"; // 190px is the height of the digit keyboard on iPhone 6
                        }
                    } else {
                        if (this.step === 1) {
                            // digit keyboard is opened
                            this.mainElement.nativeElement.style.minHeight = "calc(100% - 235px)"; // 235 is the height of the digit keyboard on iPhone 15 Pro Max
                        }
                    }

                    keyboardOpened = true;
                }
            });
            inputField.addEventListener("blur", () => {
                // keyboard closed
                if (!this.deviceInfoService.isUsingMobileSafari()) return;

                setTimeout(() => {
                    // check if input really lost focus
                    if (document.activeElement && document.activeElement.tagName === "INPUT") {
                        return;
                    }

                    this.mainElement.nativeElement.style.height = "100%";
                    this.mainElement.nativeElement.style.minHeight = "calc(100%)";
                    keyboardOpened = false;
                });
            });
        });
    }

    beginOnboarding() {
        this.api.registerStep({ verifyToken: this.verifyToken }).subscribe({
            next: (response) => {
                this.pendingArray = response.pending;
                this.me = response.me;
                this.loadingWithoutTitle = false;
                if (!this.pendingArray.includes(Pending.completeProfile)) {
                    localStorage.setItem("register_completed", "TRUE");
                    this.authService.navigateToDiscoverPageAfterLoginRegister();
                }
                this.redirectToStep(this.pendingArray);
                this.setMe(this.me);

                this.currentInputValid = this.setCurrentInputValidValue(this.step);
            },
            error: (response) => {
                this.pendingArray = response.error.pending;
                this.me = response.error.me;
                this.setMe(this.me);
                this.currentInputValid = this.setCurrentInputValidValue(this.step);
            },
        });
    }

    setMe(me: any) {
        this.redirectToStep(this.pendingArray);
        this.firstname = me.name ? me.name : "";
        const meDob = new Date(Date.parse(me.dob));
        this.day = me.dob ? String(meDob.getDate()) : "";
        this.month = me.dob ? String(meDob.getMonth() + 1) : "";
        this.year = me.dob ? String(meDob.getFullYear()) : "";
        this.setDob();
        this.gender = me.gender ? me.gender : "";
        this.lookingFor = me.lookingFor ? me.lookingFor.sex : "";
        this.tags = me.tags ? me.tags : [];
    }

    redirectToStep(pendingArray: Pending[]) {
        if (!pendingArray.includes(Pending.completeProfile)) {
            localStorage.setItem("register_completed", "TRUE");
            localStorage.setItem("initialPending", JSON.stringify(pendingArray));
            this.router.navigate(["register-successfull"]);
        }
        
        if (pendingArray.includes(Pending.completeAge)) {
            this.step = 1;
            this.preStep = true;
        } else if (pendingArray.includes(Pending.completeGender)) {
            this.step = 2;
        } else if (pendingArray.includes(Pending.completeLookingFor)) {
            this.step = 3;
        } else if (pendingArray.includes(Pending.completeLocation)) {
            this.step = 4;
        }
        this.loadingWithoutTitle = false;
    }

    goToHome() {
        this.authService.navigateToDiscoverPageAfterLoginRegister();
    }

    /**
     * Switches to the previous step
     */
    public previousStep(): void {
        this.errorMessage = "";
        this.photoUploading = false;
        this.step = Math.max(1, this.step - 1); 
        this.currentInputValid = this.setCurrentInputValidValue(this.step);
        this.manuallySelectLocation = false;
    }

    /**
     * Switches to the next step
     */
    public nextStep(event: Event): void {
        if (this.blockForwardButton) return;
        this.blockForwardButton = true;

        this.errorMessage = "";
        if (this.currentInputValid) {
            this.postUpdatesForStep(this.step, (response, success) => {
                if (success) {
                    if (!response.pending.includes(Pending.completeProfile)) {
                        localStorage.setItem("register_completed", "TRUE");
                        localStorage.setItem("initialPending", JSON.stringify(response.pending));
                        this.router.navigate(["register-successfull"]);
                    } else {
                        this.step = Math.min(this.stepCount, this.step + 1);
                        this.currentInputValid = this.setCurrentInputValidValue(this.step);
                    }
                    if (this.step === 4) {
                        // reset location text input
                        const input = document.getElementById(
                            "autocomplete-input"
                        ) as HTMLInputElement;
                        if (input) input.value = "";
                    }
                }
                if (!success && this.step == 7) {
                    this.loading = true;
                }
                this.blockForwardButton = false;
            });
        } else {
            event.preventDefault();
            this.blockForwardButton = false;
            if (this.step == 4 && this.manuallySelectLocation) {
                this.locationError = "Bitte wähle einen Addresse aus der Liste aus.";
            }
        }
    }

    /**
     * Gets the percentage of the step progress
     * @returns The percentage
     */
    public currentStepProgress(): number {
        return Math.max(0, Math.min(100, Math.ceil((this.step / this.stepCount) * 100)));
    }

    private postUpdatesForStep(
        step: number,
        handler: (response: RegistrationStepResponse, success: boolean) => void
    ): void {
        if (step === 1) {
            this.api.registerStep({ dob: this.dob }).subscribe({
                next: (response) => {
                    handler(response, true);
                },
                error: (response) => {
                    handler(response, false);
                },
            });
        }
        if (step === 2) {
            if (!this.gender) {
                console.error("Failed to set gender.");
                return;
            }
            this.api.registerStep({ gender: this.gender }).subscribe({
                next: (response) => {
                    handler(response, true);
                },
                error: (response) => {
                    handler(response, false);
                },
            });
        }
        if (step === 3) {
            if (!this.lookingFor) {
                console.error("Failed to set looking for gender.");
                return;
            }
            this.api.registerStep({ lookingFor: this.lookingFor }).subscribe({
                next: (response) => {
                    handler(response, true);
                },
                error: (response) => {
                    handler(response, false);
                },
            });
        }
        if (step === 4) {
            if (!this.locationInfo) {
                console.error("LocationInfo is not set.");
                return;
            }
            this.api.registerStep(this.locationInfo).subscribe({
                next: (response) => {
                    handler(response, true);
                },
                error: (response) => {
                    handler(response, false);
                },
            });
        }
    }

    public retrieveLocation(): void {
        if (this.blockForwardButton) return;
        this.blockForwardButton = true;

        let locationServiceResponse = false;

        this.locationService.getPosition().then(
            (response) => {
                locationServiceResponse = true;

                this.locationInfo = response;
                this.postUpdatesForStep(this.step, (response, success) => {

                    if (success) {
                        this.currentInputValid = false;
                        this.blockForwardButton = false;
                        this.redirectToStep(response.pending);
                    }

                    // if (success && response.pending.includes(Pending.completeTags)) {
                    //     this.step = 6;
                    //     // this.currentInputValid = this.setCurrentInputValidValue(
                    //     //     this.step
                    //     // );
                    //     this.currentInputValid = false;
                    // }
                    
                });
            },
            () => {
                locationServiceResponse = true;

                this.manuallySelectLocation = true;

                this.blockForwardButton = false;
            }
        );
        setTimeout(() => {
            // if after 1000ms the location service didn't give a response, allow user to manually select location
            if (!locationServiceResponse) {
                this.manuallySelectLocation = true;
                this.blockForwardButton = false;
            }
        }, 2000);
    }

    getName(value: string) {
        this.firstname = value;
        const trimmedValue = value.trim();
        if (/[^a-zA-Z0-9@ ]/.test(trimmedValue)) {
            this.errorMessage = "Bitte verwenden Sie keine Sonderzeichen.";
            this.currentInputValid = false;
        } else if (/[\d@]/.test(trimmedValue)) {
            this.errorMessage = "Gewählter Name ist nicht erlaubt.";
            this.currentInputValid = false;
        } else {
            this.currentInputValid = true;
            this.errorMessage = "";
        }
    }

    toggleSupport() {
        window.open("mailto:support@amolino.de");
    }

    setGender(value: string) {
        if (value === "MALE") {
            this.gender = OwnGender.MALE;
        } else if (value === "FEMALE") {
            this.gender = OwnGender.FEMALE;
        } else if (value === "DIVERS") {
            this.gender = OwnGender.DIVERS;
        }
        if (this.gender) {
            this.currentInputValid = true;
        }
    }

    setLookingFor(value: string) {
        if (value === "MALE") {
            this.lookingFor = LookingForGender.MALE;
        } else if (value === "FEMALE") {
            this.lookingFor = LookingForGender.FEMALE;
        } else if (value === "ALL") {
            this.lookingFor = LookingForGender.ALL;
        }
        if (this.lookingFor) {
            this.currentInputValid = true;
        }
    }

    setDob() {
        this.validationDob.day = this.validateDob("day");
        this.validationDob.month = this.validateDob("month");
        this.validationDob.year = this.validateDob("year");
        if (this.validationDob.day && this.validationDob.month && this.validationDob.year) {
            const dayValue =
                Number(this.day) <= 9 && Number(this.day) >= 0 ? "0" + this.day : this.day;
            const monthValue =
                Number(this.month) <= 9 && Number(this.month) >= 0 ? "0" + this.month : this.month;
            this.dob = this.year + "-" + monthValue + "-" + dayValue;
            const date = new Date(Number(this.year), Number(monthValue) - 1, Number(dayValue));
            if (this.calculateAge(date) < 18) {
                this.currentInputValid = false;
                this.errorMessage = "Du musst mindestens 18 Jahre alt sein.";
            } else {
                this.currentInputValid = true;
                this.errorMessage = "";
            }
        } else {
            this.currentInputValid = false;
            this.errorMessage = "";
        }
    }

    calculateAge(DOB: Date) {
        const today = new Date();
        const birthDate = DOB;
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    validateDob(value: string): boolean {
        const pattern = /^[0-9]+$/;
        if (value === "day")
            return pattern.test(this.day) && Number(this.day) > 0 && Number(this.day) < 32;
        else if (value === "month")
            return pattern.test(this.month) && Number(this.month) > 0 && Number(this.month) < 13;
        else if (value === "year" && this.year.length)
            return pattern.test(this.year) && this.year.length === 4;
        else return false;
    }

    getTags(value: string[]) {
        this.tags = value;
        if (this.tags.length === 5) {
            this.currentInputValid = true;
        } else {
            this.currentInputValid = false;
        }
    }

    imageUploadPromise(): Promise<RegistrationStepResponse> {
        return new Promise((resolve, reject) => {
            this.photoUploading = true;
            this.loading = true;
            let iteration = 0;

            //
            // We save the response from the last succesful image upload request. In case at least one upload is successful,
            // the minOnePhotoUploaded gets set to true and we continue with the profile. We save the response to give the
            // pending array in the response to the handler of the promise
            let successfulResponse: RegistrationStepResponse | null;

            for (const key in this.imageUploaded) {
                const file = this.imageUploaded[key];
                const imageFormData = new FormData();
                imageFormData.append("image", file, file.name);
                if (key === "1") {
                    imageFormData.append("profileImage", "true");
                } else {
                    imageFormData.append("profileImage", "false");
                }
                this.api.images(imageFormData).subscribe({
                    next: (response) => {
                        iteration += 1;
                        this.minOnePhotoUploaded = true;
                        successfulResponse = response;
                        if (iteration === this.numberUploadedPhotos) {
                            resolve(successfulResponse);
                        }
                    },
                    error: () => {
                        iteration += 1;
                        if (iteration === this.numberUploadedPhotos) {
                            reject(successfulResponse);
                        }
                    },
                });
            }
        });
    }

    /**
     * Get all selected images
     * @param value The selected file data from child component
     */
    getImages(value: { file: File; id: number }) {
        this.imageUploaded[value.id] = value.file;
        this.numberUploadedPhotos = Object.keys(this.imageUploaded).length;
        if (this.numberUploadedPhotos > 0) {
            this.currentInputValid = true;
        }
    }

    setCurrentInputValidValue(step: number): boolean {
        if (step === 1 && this.firstname) return true;
        else if (step === 2 && this.dob) return true;
        else if (step === 3 && this.gender) return true;
        else if (step === 4 && this.lookingFor) return true;
        else if (step === 5) return false;
        else if (step === 6 && this.tags.length === 5) return true;
        else if (step === 7 && this.numberUploadedPhotos > 0) return true;
        else return false;
    }

    initAutocomplete() {
        const autocompleteElement = document.getElementById("autocomplete-input");
        if (!autocompleteElement) {
            console.warn("Failed to setup Google Maps Autocomplete. Input element not found.");
            return;
        }

        this.locationService.initGoogleMapsAutocomplete(autocompleteElement, (place) => {
            if (!place.geometry) {
                this.locationError = "Bitte wähle ein Stadt aus der Liste aus.";
                this.currentInputValid = false;
                return;
            }
            this.locationError = "";

            const lat = place.geometry.location.lat();
            const long = place.geometry.location.lng();

            this.locationInfo = { lat, long };

            this.currentInputValid = true;

            // update ui because the normal change detection somehow is not triggered
            this.changeDetectorRef.detectChanges();
        });
    }
}
