import { animate, keyframes, state, style, transition, trigger } from "@angular/animations";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import { ApiService } from "src/app/services/api.service";
import { AppState } from "src/app/store/app.state";
import { updateMeData } from "src/app/store/me-data-store";

@Component({
    selector: "app-login-bonus-page",
    templateUrl: "./login-bonus-page.component.html",
    animations: [
        trigger("bounce", [
            state(
                "show",
                style({
                    transform: "translateY(0)",
                })
            ),
            state(
                "hide",
                style({
                    transform: "translateY(100%)",
                })
            ),
            transition("hide => show", [
                animate(
                    "0.8s ease-in",
                    keyframes([
                        style({ transform: "translateY(-100%)" }),
                        style({ transform: "translateY(0)" }),
                        style({ transform: "translateY(-150px)" }),
                        style({ transform: "translateY(0)" }),
                        style({ transform: "translateY(-50px)" }),
                        style({ transform: "translateY(0)" }),
                    ])
                ),
            ]),
        ]),
    ],
})
export class LoginBonusPageComponent implements OnInit {
    public bonuses!: any[];
    public bonusLevel!: number;
    public icons: any[] = [];

    public loading = true;

    @Output() closeEvent: EventEmitter<void> = new EventEmitter();

    constructor(private api: ApiService, private store: Store<AppState>) {}

    ngOnInit(): void {
        this.api
            .me()
            .loginBonus()
            .subscribe({
                next: (response) => {
                    this.store.dispatch(updateMeData(response.me));
                    this.loading = false;

                    this.bonuses = response.loginBonus;
                    this.bonusLevel = response.me.progressiveBonusKeys.step;

                    for (let i = 0; i < this.bonusLevel; i++) {
                        if (i === this.bonusLevel - 1) {
                            this.icons.push({ lastIcon: true });
                        } else {
                            this.icons.push({ lastIcon: false });
                        }
                    }
                },
                error: () => {
                    this.closeEvent.emit();
                },
            });
    }

    claimBonus() {
        this.closeEvent.emit();
    }
}
