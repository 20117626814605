<div
    class="md:h-[{{ desktopHeight }}px] h-full rounded-{{
        borderRadius
    }} md:rounded-lg flex flex-col bg-{{ backgroundColor }} pt-{{
        topPadding
    }}  md:w-[{{ desktopWidth }}px] p-{{ padding }} {{ classProps }}"
>
    <app-close
        *ngIf="!overlay"
        [closeColor]="closeColor === 'white' ? 'white' : 'gray'"
        (click)="emitCloseClicked()"
    ></app-close>
    <div class="pt-{{ sectionTopPaddingLarge }} flex justify-center">
        <div class="relative">
            <div class="{{ imageWrapperProps }} relative">
                <img class="{{ imageProps }}" src="{{ image }}" />
            </div>
            <div class="p-1 absolute -bottom-1 right-1 rounded-full">
                <img
                    *ngIf="smallIcon"
                    class="h-11 w-11"
                    src="{{ smallIcon }}"
                    alt="lock"
                />
            </div>
        </div>
    </div>
    <h1 class="heading-1 pt-{{ sectionTopPaddingSmall }} text-center">
        {{ h1 }}
    </h1>
    <h1 *ngIf="h1b" class="heading-1 -mt-6 text-center">{{ h1b }}</h1>
    <div class="flex flex-col justify-between h-full">
        <div
            *ngIf="showSubtitle"
            class="flex justify-center pt-{{
                sectionTopPaddingLarge
            }} text-center {{ subtitleProps }}"
            [ngClass]="[
                startPageModal
                    ? ' text-[0.8rem] leading-6 font-normal'
                    : 'flow-text-logged-in'
            ]"
        >
            <ng-content select="[subtitle]"></ng-content>
        </div>
        <div class="pt-{{ sectionTopPaddingLarge }}">
            <ng-content select="[content]"></ng-content>
        </div>
        <div
            *ngIf="disclaimer"
            class="text-[13px] pt-{{ sectionTopPaddingLarge }} h-full"
        >
            <p class="content text-center">{{ disclaimer }}</p>
        </div>
        <div
            *ngIf="keys"
            class="text-[13px] pt-{{ sectionTopPaddingLarge }} h-full"
        >
            <p class="content text-center">Diese Funktion kostet:</p>
            <div class="flex items-center justify-center space-x-2 mt-2">
                <img
                    class="h-4 w-4"
                    src="../../../../assets/images/key_circle.png"
                    alt="sale_green_outline"
                />
                <p class="bold text-xs text-brand-title">
                    {{ keys }}x Schlüssel
                </p>
            </div>
        </div>
        <div
            *ngIf="customFooter"
            [ngClass]="{
                'grid justify-items-center w-full': true,
                'border-t border-brand-body/20': showSeparationLine
            }"
            class="gap-y-1 my-8 py-8"
        >
            <div class="text-brand-title text-base font-semibold">
                {{ footer }}
            </div>
            <a
                href="{{ linkLink }}"
                class="{{ linkClass }}"
                (click)="emitFooterLinkClicked($event)"
                >{{ footerLinkText }}</a
            >
        </div>
        <app-support-footer
            *ngIf="supportFooter"
            class="w-full"
        ></app-support-footer>
    </div>
</div>
