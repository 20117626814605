import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { DateStringMethodsService } from "src/app/services/date-string-methods.service";

import { animate, state, style, transition, trigger } from "@angular/animations";
import { ActivatedRoute } from "@angular/router";
import { LikedByProfile } from "src/app/interfaces/ProfileData";
import { NotificationService } from "src/app/services/notification.service";
@Component({
    selector: "app-likes-list",
    templateUrl: "./likes-list.component.html",
    animations: [
        trigger("simpleFadeAnimation", [
            state("in", style({ opacity: 1 })),
            transition(":enter", [style({ opacity: 0 }), animate(300)]),
            transition(":leave", animate(300, style({ opacity: 0 }))),
        ]),
    ],
})
export class LikesListComponent implements OnInit {
    /**
     * All member that likes me
     */
    public users: LikedByProfile[] = [];
    public newLikes: LikedByProfile[] = [];
    public seenLikes: LikedByProfile[] = [];
    public notification: any;

    /**
     * Opens the unlock profile modal for this user directly.
     */
    public preselectedUserId?: string;

    /**
     * True: show limit reached screen
     */
    public displayLimitReached = false;

    constructor(
        private api: ApiService,
        private dateService: DateStringMethodsService,
        private notificationService: NotificationService,
        private route: ActivatedRoute
    ) {
        this.notificationService.newMessage.subscribe((message: string) => {
            this.notification = JSON.parse(message);
            if (this.notification.type === "NewLikeTemplate") {
                this.getLikedByUsers();
            }
        });
        this.route.queryParams.subscribe((params) => {
            if (params["selectUser"]) {
                this.preselectedUserId = params["selectUser"];
            }
        });
    }

    ngOnInit(): void {
        this.getLikedByUsers();
    }

    public getLikedByUsers() {
        this.api
            .me()
            .likes()
            .subscribe((response) => {
                this.newLikes = [];
                this.seenLikes = [];
                this.users = response.likedBy;
                this.users.map((value) => {
                    if (this.isNew(value.likedAt)) {
                        this.newLikes.push(value);
                    } else {
                        this.seenLikes.push(value);
                    }
                });
            });
    }

    private isNew(value: string): boolean {
        if (this.dateService.isGreaterThanDayBeforeYesterday(value)) return true;
        return false;
    }
}
