import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BuyLikesAction } from "../enums/shop";
import { MeDataUpdate } from "../interfaces/MeData";
import {
    BuyLikesResponse,
    LikesResponse,
    LoginBonusResponse,
    MatchesResponse,
    MembersMeData,
    StandardApiResponse,
} from "../interfaces/api-responses";
import { ApiService } from "./api.service";

@Injectable({ providedIn: "root" })
export class MemberMeService {
    constructor(private api: ApiService) {}

    /**
     * Gets the authenticated users profile
     * @returns The observable
     */
    public get(): Observable<MembersMeData> {
        return this.api.get("members/me");
    }

    /**
     * Updates the user
     * @param body The post body
     * @returns The observable
     */
    public update(body: MeDataUpdate): Observable<StandardApiResponse> {
        return this.api.post("members/me", body);
    }

    /**
     * Trigger the account delete flow
     * @returns The observable
     */
    public delete(): Observable<any> {
        return this.api.delete("members/me");
    }

    /**
     * Gets the list of all matches
     * @returns The observable
     */
    public matches(): Observable<MatchesResponse> {
        return this.api.get("members/me/matches");
    }

    /**
     * Gets the list of all received likes
     * @returns The observable
     */
    public likes(): Observable<LikesResponse> {
        return this.api.get("members/me/likes");
    }

    /**
     * Buys likes
     * @param action The possible actions (BUY_LIKES)
     * @returns The observable
     */
    public buyLikes(action: BuyLikesAction): Observable<BuyLikesResponse> {
        return this.api.post("members/me/likes", { action });
    }

    /**
     * Gets the login bonus state
     * @returns The observable
     */
    public loginBonus(): Observable<LoginBonusResponse> {
        return this.api.get("members/me/loginBonus");
    }

    /**
     * Updates an existing image
     * @param imageId The members image id
     * @param image The new image image
     * @returns The observable
     */
    public image(imageId: string, data: FormData): Observable<any> {
        return this.api.post(`members/me/images/${imageId}`, data);
    }

    /**
     * Deletes an image of the member
     * @param imageId The image id
     * @returns The observable
     */
    public deleteImage(imageId: string): Observable<any> {
        return this.api.delete(`members/me/images/${imageId}`);
    }

    /**
     * Initiates a reset password flow
     * @param password The new password
     * @param confirmPassword The confirmed password
     * @returns The observable
     */
    public resetPassword(password: string, confirmPassword: string): Observable<any> {
        return this.api.post("members/me/resetPassword", {
            password,
            confirmPassword,
        });
    }

    /**
     * Initiates a reset email flow
     * @param email The new email
     * @returns The observable
     */
    public resetEmail(email: string): Observable<any> {
        return this.api.post("members/me/resetEmail", { email });
    }

    /**
     * Verifies the new email
     * @param verifyToken The verification token for the reset email flow
     * @returns The observable
     */
    public verifyEmail(verifyToken: string): Observable<any> {
        return this.api.post("members/me/verifyEmail", { verifyToken });
    }

    /**
     * Initiates a forgot password flow for unauthorised user
     * @param email The use email
     * @returns The obserbable
     */
    public forgotPassword(email: any): Observable<any> {
        return this.api.post("members/me/forgotPassword", email, false);
    }

    public forgotPasswordVerify(data: any): Observable<any> {
        return this.api.post("members/me/forgotPasswordVerify", data, false);
    }

    /**
     * Deletes member from  my likes list
     * @param memberId The member id of member who likes me
     * @returns  The observeable
     */
    public deleteLike(memberId: string): Observable<any> {
        return this.api.delete(`members/me/likes/${memberId}`);
    }

    /*
    Seemingly not being used
    */
    /**
     * Uploads a new image to the user
     * @param type The type of the image (PUBLIC, PRIVATE, EXCLUSIVE, INTIMATE)
     * @param profileImage Whether the image is the profile image or not
     * @param image The image data
     * @returns The observable
     */
    public images(type: string, profileImage: boolean, image: string): Observable<any> {
        return this.api.post("members/me/images", {
            type,
            profileImage,
            image,
        });
    }
}
