export enum OwnGender {
    MALE = "MALE",
    FEMALE = "FEMALE",
    DIVERS = "DIVERS",
}
export enum LookingForGender {
    MALE = "MALE",
    FEMALE = "FEMALE",
    ALL = "ALL",
}
