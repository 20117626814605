import { Observable } from "rxjs";
import { MatchResponse, MessagesResponse } from "../interfaces/api-responses";
import { ApiService } from "./api.service";

export class MatchesService {
    constructor(private api: ApiService) {}

    /**
     * Gets the match for a given match id
     * @param matchId The match id
     * @returns The observable
     */
    public find(matchId: string): Observable<MatchResponse> {
        return this.api.get(`matches/${matchId}`);
    }

    /**
     * Removes the given match
     * @param matchId The match id
     * @returns The observable
     */
    public delete(matchId: string): Observable<any> {
        return this.api.delete(`matches/${matchId}`);
    }

    /**
     * Sends a new message to a match
     * @param matchId The match id
     * @param message The message
     * @returns The observable
     */
    public message(matchId: string, message: string): Observable<any> {
        return this.api.post(`matches/${matchId}`, { message });
    }

    /**
     * Sends a new message to a match
     * @param matchId The match id
     * @param message The message
     * @returns The observable
     */
    public messages(matchId: string, message: string): Observable<any> {
        return this.api.post(`matches/${matchId}/messages`, { message });
    }

    public getMessages(matchId: string, pageIndex: number): Observable<MessagesResponse> {
        return this.api.get(`matches/${matchId}/messages?page=${pageIndex}`);
    }
}
