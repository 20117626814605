<div *ngIf="profileInfos">
    <div class="relative overflow-hidden">
        <!-- Global Fade -->
        <div
            class="absolute inset-0 z-10 bg-cover bg-center"
            style="
                background-image: url('../../../../assets/images/entdecken_foto_fade.png');
            "
        ></div>

        <!-- Baclground Blur Image -->
        <img
            class="absolute inset-0 bg-cover bg-center h-full w-full scale-125 blur-sm"
            src="{{ profileInfos.image | addImageBaseUrl }}"
        />

        <!-- Profile Image -->
        <div class="relative">
            <img
                class="absolute bottom-0 z-20"
                src="../../../../assets/images/entdecken_foto_fade.png"
            />
            <div class="w-full flex items-center justify-center">
                <img
                    class="w-3/4"
                    src="{{ profileInfos.image | addImageBaseUrl }}"
                />
            </div>
        </div>

        <!-- User Info -->
        <div
            class="absolute z-20 bottom-12 text-white flex items-end justify-center h-full w-full"
            style="height: 50vh"
        >
            <div class="flex flex-col items-center justify-center">
                <p class="text-3xl font-bold mb-4">
                    {{ profileInfos.username }}, {{ profileInfos.dob | dateToAge }}
                </p>
                <p class="text-lg font-medium">Hol Dir Dein Match auf</p>
                <img
                    class="w-32"
                    src="../../../assets/images/amolino_logo_340x115.png"
                    alt="amolino_logo"
                />
            </div>
        </div>
    </div>

    <div class="relative flex justify-center mb-10 z-40">
        <div
            class="absolute w-16 h-16 p-2 -translate-y-8 md:-translate-y-9 rounded-full md:w-20 md:h-20 flex flex-col items-center justify-center bg-white"
        >
            <img
                class="h-full w-full"
                src="../../../assets/images/heart_green_public_profile.png"
                alt="heart-icon"
            />
        </div>
    </div>
    <div class="flex flex-col items-center space-y-6 w-full">
        <h1 class="text-2xl font-bold text-center">Worauf wartest du?</h1>
        <p class="text-brand-body text-center leading-6 w-80 text-sm">
            Du möchtest
            <span class="font-semibold"
                >{{ profileInfos.username }} ({{
                    profileInfos.dob | dateToAge
                }})</span
            >
            kennenlernen? Dann erstelle jetzt in wenigen Schritten unverbindlich
            ein Konto bei <a class="link" href="amolino.de">amolino.de</a>.
        </p>
        <div class="w-[360px]">
            <a
                [routerLink]="['/']"
                [queryParams]="{ open: 'register' }"
                class="bg-brand-magenta font-bold py-3 text-white text-lg font-medium cursor-pointer w-full inline-block rounded-full text-center"
            >
                Kostenlos anmelden
            </a>
        </div>
    </div>
    <div class="px-6 mt-24">
        <div class="text-base text-brand-title space-y-6 list-none my-6 pb-12">
            <div
                class="hidden md:block border-t border-gray-200 translate-y-16"
            ></div>
            <div class="flex flex-col space-y-8 md:justify-between text-lg">
                <div class="space-y-8">
                    <h1 class="text-xl font-bold uppercase">Rechtliches</h1>
                    <ul class="space-y-2 text-brand-body">
                        <li>
                            <a href="/imprint" class="cursor-pointer"
                                >Impressum</a
                            >
                        </li>
                        <li><a href="/terms" class="cursor-pointer">AGB</a></li>
                        <li>
                            <a href="/data-protection" class="cursor-pointer"
                                >Datenschutzrichtlinie</a
                            >
                        </li>
                        <li>
                            <a
                                href="/right-of-withdrawal"
                                class="cursor-pointer"
                                >Widerrufsbelehrungen</a
                            >
                        </li>
                    </ul>
                </div>
                <div class="space-y-8">
                    <h1 class="text-xl font-bold uppercase">Nützliches</h1>
                    <ul class="space-y-2 text-brand-body">
                        <li>
                            <a href="/help" class="cursor-pointer">Kontakt</a>
                        </li>
                        <li><a href="/help" class="cursor-pointer">FAQ</a></li>
                        <li>
                            <a href="javascript: Cookiebot.show();"
                                >Cookie-Einstellungen</a
                            >
                        </li>
                    </ul>
                </div>
                <div class="space-y-8 text-brand-body">
                    <h1 class="text-xl font-bold uppercase">Social Media</h1>
                    <div class="flex flex-nowrap space-x-6">
                        <a
                            class="cursor-pointer"
                            href="https://www.instagram.com/amolino.de/"
                            ><img
                                class="h-8"
                                src="../../../../assets/images/instagram.png"
                                alt="instagram icon"
                        /></a>
                        <a
                            class="cursor-pointer"
                            href="https://www.facebook.com/amolino.de/"
                            ><img
                                class="h-8"
                                src="../../../../assets/images/facebook.png"
                                alt="facebook icon"
                        /></a>
                    </div>
                </div>
                <div class="space-y-8">
                    <h1 class="text-xl font-bold uppercase">Downloads</h1>
                    <ul class="space-y-2 text-brand-body">
                        <li>
                            <a
                                href="https://play.google.com/store/apps/details?id=de.amolino.app31664"
                                >Android App (Coming Soon)</a
                            >
                        </li>
                        <li>
                            <a href="/" class="cursor-pointer"
                                >Apple App (Coming Soon)</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
            <div class="border-t border-gray-200"></div>
            <div class="md:text-center text-lg text-brand-body">
                &copy; <span><a class="link">amolino.de</a></span> ist eine
                Marke der MultiLabel GmbH mit Sitz in Hamburg: Hohe Bleichen 12,
                20354 Hamburg
            </div>
        </div>
    </div>
</div>
