import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PickerModule } from "@ctrl/ngx-emoji-mart";
import { EmojiModule } from "@ctrl/ngx-emoji-mart/ngx-emoji";
import { UserAuthRoutingModule } from "../user-auth/user-auth-routing.module";
import { BodyTextComponent } from "./components/body-text/body-text.component";
import { ChatItemComponent } from "./components/chat-item/chat-item.component";
import { CloseGrayIconComponent } from "./components/close-gray-icon/close-gray-icon.component";
import { CloseWhiteIconComponent } from "./components/close-white-icon/close-white-icon.component";
import { CloseComponent } from "./components/close/close.component";
import { CreditNotifyComponent } from "./components/credit-notify/credit-notify.component";
import { EmailSentComponent } from "./components/email-sent/email-sent.component";
import { ErrorInsufficientBalanceComponent } from "./components/error-insufficient-balance/error-insufficient-balance.component";
import { FormGenericInputComponent } from "./components/form/generic-input/generic-input.component";
import { InputBoxComponent } from "./components/form/input-box/input-box.component";
import { FormInputComponent } from "./components/form/input/input.component";
import { FormRadioButtonComponent } from "./components/form/radio-button/radio-button.component";
import { SubmitButtonComponent } from "./components/form/submit-button/submit-button.component";
import { HeartDividerComponent } from "./components/heart-divider/heart-divider.component";
import { HeartsBgComponent } from "./components/hearts-bg/hearts-bg.component";
import { AvailableSoonComponent } from "./components/home-page/available-soon/available-soon.component";
import { FairChanceComponent } from "./components/home-page/fair-chance/fair-chance.component";
import { SectionLayoutBackgroundComponent } from "./components/home-page/section-layout-background/section-layout-background.component";
import { SectionLayoutComponent } from "./components/home-page/section-layout/section-layout.component";
import { InputFieldComponent } from "./components/input-field/input-field.component";
import { InstantMatchButtonComponent } from "./components/instant-match-button/instant-match-button.component";
import { InstantMatchFormModalComponent } from "./components/instant-match-form-modal/instant-match-form-modal.component";
import { InsufficientCreditsComponent } from "./components/insufficient-credits/insufficient-credits.component";
import { LikeButtonComponent } from "./components/like-button/like-button.component";
import { LinkButtonComponent } from "./components/link-button/link-button.component";
import { LinkComponent } from "./components/link/link.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { LockedPhotoOverlayComponent } from "./components/locked-photo-overlay/locked-photo-overlay.component";
import { MatchComponent } from "./components/match/match.component";
import { ModalComponent } from "./components/modal/modal.component";
import { NotifyComponent } from "./components/notify/notify.component";
import { OfferItemComponent } from "./components/offer-item/offer-item.component";
import { OverlayComponent } from "./components/overlay/overlay.component";
import { PhotoCropperComponent } from "./components/photo-cropper/photo-cropper.component";
import { PolicyItemComponent } from "./components/policy-item/policy-item.component";
import { RegisterWithItemComponent } from "./components/register-with-item/register-with-item.component";
import { DateFieldComponent } from "./components/registration-steps/date-field/date-field.component";
import { FooterComponent } from "./components/registration-steps/footer/footer.component";
import { ImageUploadComponent } from "./components/registration-steps/image-upload/image-upload.component";
import { InterestItemComponent } from "./components/registration-steps/interest-item/interest-item.component";
import { InterestsComponent } from "./components/registration-steps/interests/interests.component";
import { QuestionComponent } from "./components/registration-steps/question/question.component";
import { RejectButtonComponent } from "./components/reject-button/reject-button.component";
import { SavedSuccessfullyTextComponent } from "./components/saved-successfully-text/saved-successfully-text.component";
import { SetProfileModalComponent } from "./components/set-profile-modal/set-profile-modal.component";
import { ShrinkButtonComponent } from "./components/shrink-button/shrink-button.component";
import { SimpleFadeInOutModalComponent } from "./components/simple-fade-in-out-modal/simple-fade-in-out-modal.component";
import { SlideButtonComponent } from "./components/slide-button/slide-button.component";
import { SliderComponent } from "./components/slider/slider.component";
import { StepComponent } from "./components/step/step.component";
import { SupportFooterComponent } from "./components/support-footer/support-footer.component";
import { TagComponent } from "./components/tag/tag.component";
import { TutorialComponent } from "./components/tutorial/tutorial.component";
import { ImageSliderComponent } from "./image-slider/image-slider.component";
import { NgxImageSliderDirective } from "./image-slider/ngx-image-slider.directive";
import { LoginBonusPageComponent } from "./pages/login-bonus-page/login-bonus-page.component";
import { AddImageBaseUrlPipe } from "./pipes/add-image-base-url.pipe";
import { CustomizeDistancePipe } from "./pipes/customize-distance.pipe";
import { DateToAgePipe } from "./pipes/date-to-age.pipe";
import { DateToChatTimePipe } from "./pipes/date-to-chat-time.pipe";
import { DateToLikedAtTimePipe } from "./pipes/date-to-liked-at-time.pipe";
import { FloatToEuroPipe } from "./pipes/float-to-euro.pipe";

@NgModule({
    declarations: [
        LinkButtonComponent,
        InputBoxComponent,
        LinkComponent,
        HeartDividerComponent,
        AvailableSoonComponent,
        SectionLayoutComponent,
        FairChanceComponent,
        FooterComponent,
        StepComponent,
        QuestionComponent,
        DateFieldComponent,
        InterestsComponent,
        InterestItemComponent,
        SubmitButtonComponent,
        ChatItemComponent,
        FormGenericInputComponent,
        FormInputComponent,
        FormRadioButtonComponent,
        InputFieldComponent,
        ImageUploadComponent,
        PolicyItemComponent,
        PhotoCropperComponent,
        CloseGrayIconComponent,
        RegisterWithItemComponent,
        SupportFooterComponent,
        SliderComponent,
        ImageSliderComponent,
        NgxImageSliderDirective,
        SectionLayoutBackgroundComponent,
        CloseWhiteIconComponent,
        ShrinkButtonComponent,
        CloseComponent,
        NotifyComponent,
        DateToAgePipe,
        HeartsBgComponent,
        ModalComponent,
        OverlayComponent,
        FloatToEuroPipe,
        InstantMatchButtonComponent,
        LikeButtonComponent,
        InstantMatchFormModalComponent,
        RejectButtonComponent,
        CustomizeDistancePipe,
        AddImageBaseUrlPipe,
        DateToChatTimePipe,
        DateToLikedAtTimePipe,
        SlideButtonComponent,
        SavedSuccessfullyTextComponent,
        BodyTextComponent,
        TagComponent,
        ErrorInsufficientBalanceComponent,
        OverlayComponent,
        LoaderComponent,
        SetProfileModalComponent,
        OfferItemComponent,
        InsufficientCreditsComponent,
        TutorialComponent,
        LockedPhotoOverlayComponent,
        SimpleFadeInOutModalComponent,
        EmailSentComponent,
        MatchComponent,
        CreditNotifyComponent,

        /* pages */
        LoginBonusPageComponent,
    ],
    imports: [
        CommonModule,
        UserAuthRoutingModule,
        FormsModule,
        NgxSliderModule,
        PickerModule,
        EmojiModule,
        ReactiveFormsModule,
    ],
    exports: [
        LinkButtonComponent,
        InputBoxComponent,
        LinkComponent,
        ShrinkButtonComponent,
        HeartDividerComponent,
        AvailableSoonComponent,
        SectionLayoutComponent,
        SectionLayoutBackgroundComponent,
        FairChanceComponent,
        StepComponent,
        QuestionComponent,
        DateFieldComponent,
        InterestsComponent,
        SubmitButtonComponent,
        ChatItemComponent,
        FormGenericInputComponent,
        FormInputComponent,
        FormRadioButtonComponent,
        InputFieldComponent,
        ImageUploadComponent,
        PolicyItemComponent,
        PhotoCropperComponent,
        CloseGrayIconComponent,
        CloseWhiteIconComponent,
        RegisterWithItemComponent,
        SupportFooterComponent,
        SliderComponent,
        ImageSliderComponent,
        CloseComponent,
        NotifyComponent,
        DateToAgePipe,
        HeartsBgComponent,
        ModalComponent,
        OverlayComponent,
        FloatToEuroPipe,
        InstantMatchButtonComponent,
        LikeButtonComponent,
        InstantMatchFormModalComponent,
        RejectButtonComponent,
        CustomizeDistancePipe,
        AddImageBaseUrlPipe,
        DateToChatTimePipe,
        DateToLikedAtTimePipe,
        SlideButtonComponent,
        SavedSuccessfullyTextComponent,
        BodyTextComponent,
        TagComponent,
        ErrorInsufficientBalanceComponent,
        LoaderComponent,
        SetProfileModalComponent,
        OfferItemComponent,
        InsufficientCreditsComponent,
        TutorialComponent,
        SimpleFadeInOutModalComponent,
        EmailSentComponent,
        MatchComponent,
        CreditNotifyComponent,

        /* pages */
        LoginBonusPageComponent,
    ],
})
export class SharedModule {}
