<app-main-layout>
    <div
        class="bg-white h-screen text-brand-title cursor-pointer"
        [routerLink]="'/'"
    >
        <div
            class="flex flex-col items-center px-6 mx-auto pt-8 max-w-sm h-full"
        >
            <img
                class="my-6"
                src="../../../assets/images/checkmark-circle.png"
                alt="login-icon"
                height="35px"
                width="35px"
            />
            <h1 class="text-3xl font-semibold">Konto pausiert</h1>
            <p
                class="my-11 text-center text-[13px] max-w-[300px] leading-6 text-brand-body font-medium"
            >
                Du hast dein Konto pausiert. Dein Profil ist nun für niemanden
                mehr sichtbar. Durch einen erneuten Login kannst du die
                Pausierung jederzeit wieder aufheben.
            </p>
        </div>
    </div>
</app-main-layout>
