<div class="group flex items-center justify-center">
    <div
        (click)="navigateToCheckout($event)"
        class="flex cursor-pointer items-center justify-center w-full duration-300"
    >
        <div
            class="w-full relative bg-white ring-4 ring-white ring-opacity-30 rounded-xl"
        >
            <div class="p-4 flex justify-between font-medium">
                <div>
                    <!-- Title -->
                    <h1 class="text-xl font-bold">{{ title }}</h1>
                    <div
                        [class]="
                            (product.offer ? 'absolute' : '') +
                            ' flex items-center space-x-1 text-xs md:text-[10px]'
                        "
                    >
                        <span
                            *ngIf="!product.offer"
                            class="text-brand-cyan font-semibold"
                            >{{ isCoins ? "Bonus" : "Menge" }}:</span
                        >
                        <img
                            *ngIf="!product.offer"
                            class="h-[10px]"
                            src="../../../../assets/images/{{ image }}"
                        />

                        <img
                            *ngIf="product.offer"
                            class="h-[10px]"
                            src="../../../../assets/images/red_clock.png"
                        />
                        <span
                            *ngIf="product.offer"
                            class="text-brand-cyan font-semibold"
                        >
                            <span *ngIf="expiryText; else alternativeExpiryText"
                                >Nur noch
                                <span class="link">{{ expiryText }}</span>
                            </span>
                            <ng-template #alternativeExpiryText
                                >Nur noch für kurze Zeit</ng-template
                            >
                        </span>
                    </div>
                </div>
                <div
                    class="text-xs md:text-[10px] grid justify-items-end gap-y-2"
                >
                    <!-- Price -->
                    <div class="flex items-center space-x-2">
                        <p
                            class="text-brand-title font-semibold text-[11px] md:text-[10px]"
                            *ngIf="isCoins"
                        >
                            {{ pricePerCoin | floatToEuro }} / Credit
                        </p>
                        <p
                            [class]="
                                'text-base text-center font-bold px-2 rounded-full ' +
                                (product.offer
                                    ? 'bg-brand-green/40 text-brand-title'
                                    : isCoins
                                      ? 'bg-brand-magenta text-white'
                                      : 'bg-brand-body/10 text-brand-title')
                            "
                        >
                            {{ product.cost / 100 | floatToEuro }}
                        </p>
                    </div>
                    <div
                        *ngIf="
                            product.offer || (isCoins && savePercentageCoin > 0)
                        "
                        class="space-y-2 flex flex-col"
                    >
                        <div class="flex items-center space-x-2">
                            <div
                                *ngIf="product.bonusText"
                                class="flex items-center space-x-1"
                            >
                                <img
                                    class="h-2"
                                    src="../../../assets/images/checkmark_magenta.png"
                                />
                                <h1
                                    class="text-[11px] md:text-[10px] font-bold"
                                >
                                    {{ product.bonusText }}
                                </h1>
                            </div>
                            <h2
                                [class]="
                                    (product.offer
                                        ? 'bg-brand-magenta/20 text-brand-magenta'
                                        : 'bg-brand-green/40') +
                                    ' font-bold text-[11px] md:text-[10px] px-2 rounded-full'
                                "
                            >
                                {{ product.offer ? "" : "Spare" }}
                                {{
                                    isCoins
                                        ? savePercentageCoin
                                        : savePercentageKey
                                }}
                                % {{ product.offer ? "Rabatt" : "" }}
                            </h2>
                        </div>
                    </div>
                    <div
                        *ngIf="
                            calculateSavePercentage(
                                product.cost / 100 / product.qtyCoins
                            ) <= 0 && !product.offer
                        "
                    >
                        <p class="text-brand-body/50">Kein Bonus!</p>
                    </div>
                </div>
            </div>
            <img
                *ngIf="product.offer"
                class="w-full h-5 object-fill rounded-b-xl"
                src="../../../../assets/images/offer_footer.png"
                alt="footer"
            />
        </div>
    </div>
</div>
