import { Component, Input } from "@angular/core";

@Component({
    selector: "app-profile-settings-item",
    templateUrl: "./profile-settings-item.component.html",
})
export class ProfileSettingsItemComponent {
    @Input() icon!: string;
    @Input() info!: string;
    @Input() title!: string;
    @Input() path!: string;
}
