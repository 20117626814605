<div class="text-base text-brand-title space-y-6 list-none my-6 pb-12">
    <div class="hidden md:block border-t border-gray-200 translate-y-16"></div>
    <div
        class="flex flex-col space-y-8 lg:justify-between lg:space-y-0 lg:flex-row text-lg"
    >
        <div class="space-y-8">
            <h1 class="text-xl font-bold uppercase">Rechtliches</h1>
            <ul class="space-y-2 text-brand-body">
                <li><a href="/imprint" class="cursor-pointer">Impressum</a></li>
                <li><a href="/terms" class="cursor-pointer">AGB</a></li>
                <li>
                    <a href="/data-protection" class="cursor-pointer"
                        >Datenschutzrichtlinie</a
                    >
                </li>
                <li>
                    <a href="/right-of-withdrawal" class="cursor-pointer"
                        >Widerrufsbelehrungen</a
                    >
                </li>
            </ul>
        </div>
        <div class="space-y-8">
            <h1 class="text-xl font-bold uppercase">Nützliches</h1>
            <ul class="space-y-2 text-brand-body">
                <li><a href="/help" class="cursor-pointer">Kontakt</a></li>
                <li><a href="/help" class="cursor-pointer">FAQ</a></li>
                <li>
                    <a href="javascript: Cookiebot.show();"
                        >Cookie-Einstellungen</a
                    >
                </li>
            </ul>
        </div>
        <div class="space-y-8 text-brand-body">
            <h1 class="text-xl font-bold uppercase">Social Media</h1>
            <div class="flex flex-nowrap space-x-6">
                <a
                    class="cursor-pointer"
                    href="https://www.instagram.com/amolino.de/"
                    ><img
                        class="h-8"
                        src="../../../../assets/images/instagram.png"
                        alt="instagram icon"
                /></a>
                <a
                    class="cursor-pointer"
                    href="https://www.facebook.com/amolino.de/"
                    ><img
                        class="h-8"
                        src="../../../../assets/images/facebook.png"
                        alt="facebook icon"
                /></a>
            </div>
        </div>
        <div class="space-y-8">
            <h1 class="text-xl font-bold uppercase">Downloads</h1>
            <ul class="space-y-2 text-brand-body">
                <li>
                    <a
                        href="https://play.google.com/store/apps/details?id=de.amolino.app31664"
                        >Android App</a
                    >
                </li>
                <li>
                    <a href="/" class="cursor-pointer"
                        >Apple App (Coming Soon)</a
                    >
                </li>
            </ul>
        </div>
    </div>
    <div class="border-t border-gray-200"></div>
    <div class="md:text-center text-lg text-brand-body">
        &copy; <span><a class="link">amolino.de</a></span> ist eine Marke der
        MultiLabel GmbH mit Sitz in Hamburg: Hohe Bleichen 12, 20354 Hamburg
    </div>
</div>
