import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class DeviceInfoService {
    isUsingMobileSafari() {
        const ua = window.navigator.userAgent;
        const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        const webkit = !!ua.match(/WebKit/i);
        const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
        return iOSSafari;
    }

    hasHomeButton() {
        if (window.screen.height <= 700) {
            return true;
        } else {
            return false;
        }
    }
}
