<div *ngIf="isAuthenticated !== null">
    <app-main-layout
        *ngIf="isAuthenticated"
        [showProfile]="true"
        [leftMenuText]="'Datenschutz Richtlinie'"
    >
        <div class="px-4 pt-11 md:pt-0">
            <app-data-protection-content></app-data-protection-content>
        </div>
    </app-main-layout>
    <app-public-wrapper-layout *ngIf="!isAuthenticated" [type]="'cyan'">
        <div class="md:py-8 flex flex-col md:px-12 space-y-4">
            <a class="link" href="/">Zurück</a>
            <app-data-protection-content></app-data-protection-content>
        </div>
    </app-public-wrapper-layout>
</div>
