import { DOCUMENT } from "@angular/common";
import { AfterViewInit, Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import Cropper from "cropperjs";
import { Observable } from "rxjs";
import { ApiService } from "src/app/services/api.service";
import { MembersMeStoreService } from "src/app/services/members-me-store.service";

@Component({
    selector: "app-crop-photo",
    templateUrl: "./crop-photo.component.html",
})
// TODO: this component is only used for the /my-profile route, it should be removed and the /my-profile route should use the PhotoCropperComponent
export class CropPhotoComponent implements AfterViewInit {
    @Input() id: number;
    @Input() profileText = false;
    public image: any;

    public success = false;
    public loading = false;

    imageUploaded: any;
    minContainerHeight = 400;
    @Input() file: any;
    @Input() selectedfile: File;
    private cropper!: Cropper;
    @Output() closeMatch: EventEmitter<string> = new EventEmitter();
    state$: Observable<object>;
    public imageURL: string;
    public imageId: string;
    imageType: any;
    constructor(
        private api: ApiService,
        private membersMeStore: MembersMeStoreService,
        public router: Router,
        @Inject(DOCUMENT) private document: Document
    ) {}

    ngAfterViewInit(): void {
        const imageLS = localStorage.getItem("image");
        this.image = imageLS ? JSON.parse(imageLS) : undefined;
        this.imageURL = this.image.url;
        this.imageId = this.image.id;
        this.imageType = this.image.type;

        if (!this.imageURL) {
            document.location.href = "my-photos";
        }
        this.image = new Image();
        this.image.onload = () => {
            console.log("Image loaded!");
        };
        this.image.setAttribute("class", "block");
        this.image.id = "image";
        this.image.src = this.imageURL;
        const imageContainer = document.querySelector("#img-container");
        this.image.className = "block max-w-full";

        if (!imageContainer) {
            console.error("No image container for cropper found.");
            return;
        }

        imageContainer.appendChild(this.image);

        if (window.innerWidth > 768) {
            this.minContainerHeight = 400;
        } else {
            this.minContainerHeight = 400;
        }

        this.cropper = new Cropper(this.image, {
            viewMode: 3,
            dragMode: "move",
            cropBoxMovable: true,
            cropBoxResizable: true,
            checkOrientation: false,
            minContainerHeight: this.minContainerHeight,
            aspectRatio: 9 / 16,
            minCropBoxHeight: 100,
            crop: () => {
                const canvas = this.cropper.getCroppedCanvas();
                this.imageURL = canvas.toDataURL("image/jpeg");
            },
        });
    }

    dataURLtoFile(dataurl, filename) {
        const arr = dataurl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    saveEditedImage() {
        this.file = this.dataURLtoFile(this.imageURL, "upload.jpeg");
        const imageFormData = new FormData();
        imageFormData.append("image", this.file);

        imageFormData.append("profileImage", "false");
        imageFormData.append("type", "PUBLIC");

        if (this.imageId) {
            this.success = false;
            this.loading = true;
            this.api
                .me()
                .image(this.imageId, imageFormData)
                // imageId: string, type: string , profileImage: boolean, image: any
                .subscribe({
                    next: () => {
                        this.loading = false;
                        this.success = true;
                        localStorage.removeItem("image");
                        this.membersMeStore.removeMembersMeFromStore();
                        this.document.location.href = "my-photos";
                    },
                    error: (e) => {
                        console.error("Failed to save edited image", e);
                    },
                });
        } else {
            console.error("No imageId provided.");
        }
    }
}
