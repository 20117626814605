import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "app-image-upload",
    templateUrl: "./image-upload.component.html",
})
export class ImageUploadComponent {
    @Input() id: number;
    @Input() profileText = false;
    @Input() height!: string;
    @Output() Upload: EventEmitter<any> = new EventEmitter();
    public image: any;
    public selectedFile!: File;
    public toBeCropped?: string;

    onFileChanged(event: any) {
        this.selectedFile! = event.target.files[0];
        const fileReader = new FileReader();
        fileReader.readAsDataURL(this.selectedFile);
        fileReader.addEventListener("load", () => {
            this.toBeCropped = fileReader.result as string;
        });
    }

    onCroppedImage(file: File) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.addEventListener("load", () => {
            this.toBeCropped = undefined;
            this.image = fileReader.result;
            this.Upload.emit({ file: file, id: this.id });
        });
    }
}
