<a routerLink="{{ path }}" class="group">
    <div class="flex justify-between items-center">
        <div class="flex space-x-3 items-start">
            <div class="mt-1">
                <img
                    class="h-5"
                    src="../../../../assets/images/{{ icon }}.png"
                    alt="{{ icon }}"
                />
            </div>
            <div>
                <p
                    class="text-md text-brand-title font-semibold group-hover:underline"
                >
                    {{ title }}
                </p>
                <p class="text-xs">{{ info }}</p>
            </div>
        </div>
        <img
            class="h-3"
            src="../../../../assets/images/blue_arrow.png"
            alt="blue_arrow"
        />
    </div>
</a>
