<app-main-layout [showProfile]="true" [leftMenuText]="'Dein Profiltext'">
    <div
        class="text-brand-body text-[13px] px-4 pb-8 pt-11 sm:pt-0 space-y-8 flex flex-col justify-between"
    >
        <!-- <app-saved-successfully-text *ngIf="updateSuccessful"></app-saved-successfully-text> -->
        <div class="leading-4">
            <h2 class="text-brand-magenta heading-2 mb-8 mt-6">Über dich</h2>
            <div class="space-y-6 pb-12">
                <div class="grid space-y-4">
                    <div class="leading-6 pb-8 border-b border-brand/10">
                        Das Veröffentlichen privater Daten wie beispielsweise
                        Wohnadressen, Telefonnummern oder E-Mail Adressen sowie
                        jegliche Art von externen Links ist nicht gestattet.
                    </div>
                </div>
                <form [formGroup]="form" (ngSubmit)="submitForm()">
                    <div
                        class="flex text-brand-title items-center justify-between"
                    >
                        <label class="label">Dein Text*</label>
                        <p class="text-[10px]">{{ length }}/480 Zeichen</p>
                    </div>
                    <app-input-field [error]="!form.valid">
                        <div class="w-full">
                            <textarea
                                rows="7"
                                class="w-full focus:outline-none resize-none input placeholder:input-placeholder text-brand-title"
                                placeholder="Stelle Dich den anderen Personen vor indem Du etwas über Dich erzählst…"
                                formControlName="description"
                            ></textarea>
                            <div class="pb-4"></div>
                        </div>
                        <p
                            error
                            *ngIf="
                                description.touched &&
                                description.hasError('required')
                            "
                        >
                            Bitte füllen Sie dieses Feld aus.
                        </p>
                        <p
                            error
                            *ngIf="
                                description.touched &&
                                description.hasError('maxlength')
                            "
                        >
                            Die Beschreibung darf maximal 480 Zeichen lang sein.
                        </p>
                    </app-input-field>
                    <app-link-button
                        [success]="success"
                        [loading]="loading"
                        [saveButton]="true"
                        (userClicked)="submitForm()"
                        [theme]="'purple'"
                        >Speichern</app-link-button
                    >

                    <!-- <app-link-button [theme]="'purple'" (userClicked)="submitForm()">Speichern</app-link-button> -->
                </form>
            </div>
        </div>
    </div>
</app-main-layout>
