import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "dateToAge",
})
export class DateToAgePipe implements PipeTransform {
    transform(value: string | Date): unknown {
        const today = new Date();
        const date = new Date(value);
        // @ts-ignore
        const difference = today - date;
        const age = Math.floor(difference / 31557600000);

        return isNaN(age) ? "-" : age;
    }
}
