<div
    class="h-full w-full absolute top-0 left-0 bottom-0 z-30 md:flex md:items-center md:justify-center md:bg-gray-400/80"
>
    <app-modal
        [startPageModal]="true"
        (footerLink)="closePopup()"
        [desktopHeight]="'800'"
        [desktopWidth]="'400'"
        [topPadding]="'6'"
        [image]="'../../../../assets/images/auth/einloggen_icon.png'"
        [h1]="'Einloggen'"
        (closeMatch)="closePopup()"
        [topPadding]="'6'"
        [showSubtitle]="false"
        [image]="'../../../../assets/images/checkmark-circle.png'"
        [h1]="'Konto in Löschung'"
    >
        <div content>
            <div
                class="flex flex-col items-center space-between h-full px-4 text-center mb-24"
            >
                <div
                    class="flex w-full flex-col items-center space-between h-full space-y-2"
                >
                    <p>
                        Wir bestätigen hiermit den Eingang Deines
                        Löschungsauftrags. Dein Konto wird automatisch zum
                        <strong>{{ readableDate }}</strong>
                        gelöscht, sofern Du Dich nicht vorher wieder einloggst.
                    </p>
                    <br />
                    <p>
                        Dennoch bedanken wir uns für Deine Nutzung unseres
                        Dienstes. Wir bedauern, dass Du uns verlassen möchtest.
                        Wenn Du unsere Dienste in Zukunft wieder nutzen
                        möchtest, bist du jederzeit herzlich willkommen.
                    </p>
                    <br />

                    <p class="font-bold">Mit freundlichen Grüßen</p>
                    <p class="font-bold">
                        Dein Team von
                        <span><a href="amolino.de">amolino.de</a></span>
                    </p>
                </div>
            </div>
        </div>
    </app-modal>
</div>
