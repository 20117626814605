import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { PaypalTransactionDetails } from "../public/payments/checkout-paypal/checkout-paypal.component";
import { AuthData, AuthProvider } from "./api.service";

declare const executeWhenAppReady: (func: () => void) => void;
declare const getAppPlatform: () => string;
declare const openCustomTab: (url: string, extras: AuthCustomTabData | PaypalCustomTabData) => void;
declare const isInCustomTab: () => boolean;
declare const closeCustomTab: (
    data:
        | PaypalCustomTabResponse
        | AuthErrorCustomTabResponse
        | AuthLoginDataCustomTabResponse
        | AuthRegisterDataCustomTabResponse
) => void;
declare const getCustomTabData: () => any;
declare const makeInAppPurchase: (
    productId: string,
    consumable: boolean,
    userIdentifier: string
) => Promise<any>;
declare const requestNotificationPermissions: (openSettingsIfNecessary: boolean) => Promise<any>;
declare const oneSignalLogin: (externalID: string) => Promise<any>;
declare const notificationPermissionsGranted: () => Promise<any>;
declare const getNotificationToken: () => Promise<any>;

export enum WebToAppOperation {
    PAYPAL,
    AUTH,
}
/**
 * is used to pass data to the custom tab
 */
export interface CustomTabData {
    operation: WebToAppOperation;
}

export enum AuthType {
    LOGIN,
    REGISTER,
}
/**
 * This data is used to pass to the register component.
 */
export interface AuthRegisterDataCustomTabResponse {
    authType: AuthType;
    authData: AuthData;
    authProvider: AuthProvider;
}
export type AuthCustomTabResponse =
    | AuthRegisterDataCustomTabResponse
    | AuthLoginDataCustomTabResponse
    | AuthErrorCustomTabResponse;
/**
 * This data is used to pass to the login component.
 */
export interface AuthLoginDataCustomTabResponse {
    authType: AuthType;
    authData: AuthData;
}
export interface AuthErrorCustomTabResponse {
    error: boolean;
    message: string;
}
export interface AuthCustomTabData extends CustomTabData {
    tryingToRegister: boolean;
    tryingToLogin: boolean;
}

export interface PaypalCustomTabData extends CustomTabData {
    paypalTransactionDetails: PaypalTransactionDetails;
    // Why is an accessToken needed? Because the custom tab is opened in a new tab, where the user is not logged in but to call the shop endpoint an accessToken is needed.
    accessToken: string;
}
export interface PaypalCustomTabResponse {
    purchaseSuccessful: boolean;
    description?: any; // TODO: figure out what this means
}

export enum AppPlatform {
    android,
    ios,
    unknown,
}

@Injectable({
    providedIn: "root",
})
export class WebToAppService {
    /**
     * isInCustomTab() returns true if the app is opened in a custom tab. However sometimes that doesn't work properly anymore after navigating to a different site.
     * This flag can be set before navigating to a different site to remember that the app was opened in a custom tab.
     */
    private flaggedAsCustomTab = false;

    addOnAppPausedListener = (func: () => void) => document.addEventListener("appPaused", func);

    removeOnAppPausedListener = (func: () => void) =>
        document.removeEventListener("appPaused", func);

    addOnAppResumedListener = (func: () => void) => document.addEventListener("appResumed", func);

    removeOnAppResumedListener = (func: () => void) =>
        document.removeEventListener("appResumed", func);

    isUsingApp = () => {
        return getAppPlatform() !== null;
    };

    getAppPlatform = (): AppPlatform => {
        const platform = getAppPlatform();

        if (platform === "android") {
            return AppPlatform.android;
        } else if (platform === "ios") {
            return AppPlatform.ios;
        } else {
            return AppPlatform.unknown;
        }
    };

    flagAsCustomTab = () => (this.flaggedAsCustomTab = true);
    openedInCustomTab = () => {
        if (this.flaggedAsCustomTab) {
            return true;
        }

        return isInCustomTab();
    };

    getCustomTabData = (): AuthCustomTabData | PaypalCustomTabData | void => {
        return getCustomTabData();
    };

    closeCustomTab = closeCustomTab;

    openedInCustomTabLoginFlow = (loginData: AuthLoginDataCustomTabResponse) => {
        closeCustomTab(loginData);
    };

    openedInCustomTabRegisterFlow = (registerData: AuthRegisterDataCustomTabResponse) => {
        closeCustomTab(registerData);
    };

    payWithPaypalUsingCustomTab = (
        paypalTransactionDetails: PaypalTransactionDetails,
        accessToken: string
    ): Promise<PaypalCustomTabResponse> => {
        return new Promise((resolve, reject) => {
            openCustomTab(environment.webToApp.paypalURL, {
                operation: WebToAppOperation.PAYPAL,
                paypalTransactionDetails,
                accessToken,
            });
            document.addEventListener("customTabClosed", function (e: any) {
                if ("reason" in e.detail) {
                    reject("Something went wrong with the Custom Tabs: " + e.detail.reason);
                } else {
                    const data = e.detail;

                    resolve(data);
                }
            });
        });
    };

    /**
     *
     * @returns Promise<any> The data from the custom tab login/register
     */
    authUsingCustomTab = (link, customTabData: AuthCustomTabData): Promise<any> => {
        return new Promise((resolve, reject) => {
            openCustomTab(link, customTabData);
            document.addEventListener("customTabClosed", function (e: any) {
                if ("reason" in e.detail) {
                    // native custom tab error
                    reject("Something went wrong with the Custom Tabs: " + e.detail.reason);
                } else {
                    const data = e.detail as AuthCustomTabResponse;

                    if ("error" in data) {
                        const error = data as AuthErrorCustomTabResponse;
                        reject("Error in custom tab: Message: " + error.message);
                    }

                    resolve(data);
                }
            });
        });
    };

    registerUsingCustomTab = async (): Promise<
        AuthRegisterDataCustomTabResponse | AuthLoginDataCustomTabResponse
    > => {
        const data = await this.authUsingCustomTab(environment.webToApp.registerURL, {
            operation: WebToAppOperation.AUTH,
            tryingToLogin: false,
            tryingToRegister: true,
        });
        return data;
    };

    loginUsingCustomTab = async (): Promise<
        AuthLoginDataCustomTabResponse | AuthRegisterDataCustomTabResponse
    > => {
        const data = await this.authUsingCustomTab(environment.webToApp.loginURL, {
            operation: WebToAppOperation.AUTH,
            tryingToLogin: true,
            tryingToRegister: false,
        });
        return data;
    };

    makeInAppPurchase = async (
        productId: string,
        consumable: boolean,
        userId: string
    ): Promise<any> => {
        try {
            const response = await makeInAppPurchase(productId, consumable, userId);
            console.log("InAppPurchase response:", response); // TODO: document response
        } catch (e) {
            console.error("Failed to make In App Purchase. More info:", e);
            throw e;
        }
    };

    logHTTPErrorResponse(error: HttpErrorResponse) {
        if (!this.isUsingApp()) return;

        if (error.status === 0) {
            console.error(`Client-Side API error for endpoint ${error.url}:`, error);
        } else {
            console.error(`Server-Side API error for endpoint ${error.url}:`, error);
        }
        try {
            console.error("Error Status:", error.status);
            console.error("Error Message:", error.message);
            console.error("More details on error:", JSON.stringify(error.error));
        } catch (e) {
            console.error("Failed to parse error message.");
        }
    }

    oneSinalLogin(externalId: string): Promise<void> {
        return new Promise((resolve) => {
            executeWhenAppReady(() => {
                try {
                    oneSignalLogin(externalId);
                    resolve();
                } catch (e) {
                    console.warn("WebToApp failed to login with OneSignal. More info:", e);
                    throw e;
                }
            });
        });
    }

    async getNotificationToken(): Promise<string> {
        try {
            const response = await getNotificationToken();
            const token = response.token;
            return token;
        } catch (e) {
            console.error("Failed to get notification token. More info:", e);

            throw e;
        }
    }

    async requestNotificationPermissions() {
        try {
            await requestNotificationPermissions(false);
        } catch (e) {
            console.error("Failed to request notification permissions in webToApp. More info: ", e);

            throw e;
        }
    }

    async notificationPermissionsGranted() {
        const result = await notificationPermissionsGranted();
        console.log(result);
    }
}
