import { EventEmitter, Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { environment } from "src/environments/environment";
import { TokenHandlingService } from "./token-handling.service";
import { WebToAppService } from "./web-to-app.service";

@Injectable({
    providedIn: "root",
})
export class NotificationService {
    private wssURL: string;
    private socket: WebSocket | null = null;
    private initing = false;
    public newMessage = new EventEmitter<string>();

    private webToAppListenersAdded = false;

    constructor(
        private tokenHandling: TokenHandlingService,
        private webToAppService: WebToAppService
    ) {}

    async init(id: string) {
        if (this.initing) {
            return;
        }
        this.initing = true;
        const oldToken = this.getLastTokenUsedInWebsocket();
        let newToken;
        try {
            newToken = await this.getToken();
        } catch (error) {
            console.error("NotificationService: Could not get token", error);
        }

        if (newToken !== null && (oldToken !== newToken || this.socket === null)) {
            this.wssURL = `${environment.websocket.notifications.url}/?token=${newToken}`;
            this.setLastTokenUsedInWebsocket(newToken);

            if (this.socket && this.socket.readyState === WebSocket.OPEN) {
                this.socket.close();
            }

            this.socket = new WebSocket(this.wssURL);

            this.socket.onopen = () => {
                this.initing = false;
            };

            this.socket.onmessage = (event) => {
                this.newMessage.emit(event.data);
            };

            this.socket.onclose = async (event) => {
                this.initing = false;

                //
                // The custom code 3999 means that the user has been logged out.
                // In this case we don't want to reconnect.
                if (event.code !== 3999) {
                    await this.init(id);
                }
            };

            this.socket.onerror = (error) => {
                console.error(`NotificationService socket error:`, error);
            };
        }

        document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "visible") {
                this.onResume(id);
            } else {
                this.onPause();
            }
        });
        if (!this.webToAppListenersAdded) {
            this.webToAppService.addOnAppPausedListener(() => this.onPause());
            this.webToAppService.addOnAppResumedListener(() => this.onResume(id));
            this.webToAppListenersAdded = true;
        }
    }

    private onPause() {
        this.closeConnection();
    }

    private onResume(id: string) {
        this.init(id);
    }

    /**
     * Closes the websocket connection
     */
    public closeConnection(): void {
        if (this.socket) {
            this.socket.close(3999, "logout");
            this.socket = null;
        }
    }

    // Function to get a valid token
    private async getToken(): Promise<string> {
        const token = await lastValueFrom(this.tokenHandling.getValidAuthenticationToken());

        if (token && token.accessToken) {
            return token.accessToken;
        } else {
            throw new Error("No valid token found");
        }
    }

    private setLastTokenUsedInWebsocket(token: string) {
        localStorage.setItem("lastTokenUsedInWebsocket", token);
    }

    private getLastTokenUsedInWebsocket(): string | null {
        return localStorage.getItem("lastTokenUsedInWebsocket");
    }
}
