import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "app-tag",
    templateUrl: "./tag.component.html",
})
export class TagComponent implements OnInit {
    @Input() profileImage = false;
    @Input() theme = "purple";
    selectedTheme = "";
    @Input() textSize = "8";
    ngOnInit(): void {
        this.setTheme();
    }

    setTheme() {
        const instantMatch = "bg-gradient-to-r from-brand-cyan to-brand-magenta text-[11px]";
        const newMatch = "bg-brand-magenta text-[11px]";
        const profileImage = "bg-brand-magenta text-[10px]";
        const restrictedImage = "pl-5 bg-brand-cyan text-[10px]";
        const chatNewMatch = "bg-brand-magenta text-[10px]";
        const chatInstantMatch = "bg-gradient-to-r from-brand-cyan to-brand-magenta text-[10px]";
        const chatDate = "bg-brand-title text-[10px]";

        if (this.theme === "newMatch") {
            this.selectedTheme = newMatch;
        }
        if (this.theme === "instantMatch") {
            this.selectedTheme = instantMatch;
        }
        if (this.theme === "chatNewMatch") {
            this.selectedTheme = chatNewMatch;
        }
        if (this.theme === "chatInstantMatch") {
            this.selectedTheme = chatInstantMatch;
        }
        if (this.theme === "chatDate") {
            this.selectedTheme = chatDate;
        }
        if (this.theme === "profileImage") {
            this.selectedTheme = profileImage;
        }
        if (this.theme === "restrictedImage") {
            this.selectedTheme = restrictedImage;
        }
    }
}
