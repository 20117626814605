import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";

@Component({
    selector: "app-terms-and-conditions",
    templateUrl: "./terms-and-conditions.component.html",
})
export class TermsAndConditionsComponent implements OnInit {
    public isAuthenticated?: boolean;
    constructor(private authService: AuthService) {}

    ngOnInit(): void {
        if (
            this.authService.isAuthenticated &&
            localStorage.getItem("register_completed") === "TRUE"
        ) {
            this.isAuthenticated = true;
        } else this.isAuthenticated = false;
    }
}
