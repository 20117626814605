import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { FacebookLoginProvider, SocialAuthService, SocialUser } from "angularx-social-login";
import { ApiService, AuthData } from "src/app/services/api.service";

import { animate, state, style, transition, trigger } from "@angular/animations";
import Pending from "src/app/enums/pending";
import { AuthResponse } from "src/app/interfaces/api-responses";
import { AuthService } from "src/app/services/auth.service";
import {
    AuthLoginDataCustomTabResponse,
    AuthType,
    WebToAppService,
} from "src/app/services/web-to-app.service";
import { environment } from "src/environments/environment";
declare const AppleID: any;

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    animations: [
        trigger("simpleFadeAnimation", [
            state("in", style({ opacity: 1 })),
            transition(":enter", [style({ opacity: 0 }), animate(300)]),
            transition(":leave", animate(300, style({ opacity: 0 }))),
        ]),
    ],
})
export class LoginComponent implements OnInit {
    form: FormGroup = new FormGroup({
        email: new FormControl(null, [Validators.required, Validators.email]),
        password: new FormControl(null, [Validators.required]),
    });

    errorEmail!: boolean;
    loginIssues = false;
    loginStep = 1;
    emailSent = false;
    errorMessage: string;
    passwordInput: string;
    passwordInputType = "password";
    public isClickInProgress = false;

    user: SocialUser;
    loggedIn: boolean;
    public googleButtonLoaded = false;

    @Input() loginData!: AuthLoginDataCustomTabResponse;
    @Output() hidePopup: EventEmitter<string> = new EventEmitter();
    @Output() loading: EventEmitter<boolean> = new EventEmitter();
    @Output() toRegister: EventEmitter<string> = new EventEmitter();

    constructor(
        private api: ApiService,
        private router: Router,
        private socialAuthService: SocialAuthService,
        private webToAppService: WebToAppService,
        private authService: AuthService
    ) {}

    getInputValue(event: Event) {
        const inputElement = event.target as HTMLInputElement;
        this.passwordInput = inputElement.value;
    }

    togglePasswordVisibility() {
        this.passwordInputType = this.passwordInputType === "password" ? "text" : "password";
    }

    toggleLoginIssues() {
        if (this.loginStep === 1) {
            window.open("mailto:support@amolino.de");
        } else {
            this.loginIssues = !this.loginIssues;
        }
    }

    signinWithFacebook(): void {
        try {
            this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
            this.socialAuthService.authState.subscribe((user) => {
                this.handleAuthentication({
                    authProvider: "FACEBOOK",
                    token: user.authToken,
                });
            });
        } catch (error) {
            console.error("Error while signing in with Facebook", error);
        }
    }

    emitLoading(loading: boolean) {
        this.loading.emit(loading);
    }

    ngOnInit(): void {
        if (this.loginData) {
            this.handleAuthentication(this.loginData.authData);
        }

        this.form.get("email")?.valueChanges.subscribe(() => {
            this.errorEmail = this.email.hasError("required") || this.email.hasError("email");
        });

        this.socialAuthService.authState.subscribe((user) => {
            this.user = user;
            this.loggedIn = user != null;
        });

        setTimeout(() => {
            this.api.setupThirdPartyButtons((token) => {
                this.handleAuthentication({ authProvider: "GOOGLE", token });
            }, false);
            this.googleButtonLoaded = true;
        }, 500);
    }

    /**
     * Handles an authentication request
     * @param postdata The post data
     */
    private handleAuthentication(postdata: AuthData): void {
        this.emitLoading(true);

        if (this.webToAppService.openedInCustomTab() && postdata.authProvider !== "LOCAL") {
            this.webToAppService.openedInCustomTabLoginFlow({
                authData: postdata,
                authType: AuthType.LOGIN,
            });
            return;
        }

        this.api.auth(postdata).subscribe({
            next: (response) => {
                if (this.webToAppService.openedInCustomTab()) {
                    this.webToAppService.openedInCustomTabLoginFlow({
                        authData: postdata,
                        authType: AuthType.LOGIN,
                    });
                } else {
                    this.loginFlow(response);
                }
            },
            error: () => {
                this.errorMessage = "Einloggen Fehlgeschlagen.";
                this.emitLoading(false);
            },
        });
    }

    /**
     * this function basically triggers all the UI changes that are done after a user login
     * it does not handle the actual login process
     */
    private loginFlow(response: AuthResponse) {
        //
        // Cache the "pending" array in the local storage
        localStorage.setItem("initialPending", JSON.stringify(response.pending));

        if (response.accessToken) {
            localStorage.setItem("register_completed", "FALSE");
        }
        //
        // If the "completeProfile" flag is not set, finish up the sign up process
        if (!response.pending.includes(Pending.completeProfile)) {
            localStorage.setItem("register_completed", "TRUE");
            // TODO: probably remove this
            localStorage.setItem(
                "amolino/updatedBy",
                JSON.stringify(response.me.progressiveBonusKeys.updatedBy)
            );

            this.authService.navigateToDiscoverPageAfterLoginRegister();
        } else {
            if (response.pending.includes(Pending.completeEmail)) {
                this.emitLoading(false);
                this.openEmailSentScreen();
            } else {
                this.router.navigate(["/register/step"]);
            }
        }
    }

    login(): void {
        this.isClickInProgress = true;

        const data = Object.assign({}, { authProvider: "LOCAL" }, this.form.value);
        this.handleAuthentication(data);

        this.isClickInProgress = false;
    }

    get email(): FormControl {
        return this.form.get("email") as FormControl;
    }

    get password(): FormControl {
        return this.form.get("password") as FormControl;
    }

    /**
     * Emit event to hide the popup window for desktop view
     */
    public closePopup() {
        this.hidePopup.emit();
    }

    close() {
        if (this.webToAppService.openedInCustomTab()) {
            this.webToAppService.closeCustomTab({
                error: true,
                message: "Login form closed manually by user.",
            });
            return;
        }

        !this.loginIssues && !this.emailSent
            ? this.closePopup()
            : this.emailSent
            ? this.closeEmailSentScreen()
            : "";
    }

    /**
     * Emit event to go to the register popup window for desktop view
     */
    public goToRegister() {
        this.toRegister.emit();
    }

    /**
     * Close the email sent screen
     */
    public closeEmailSentScreen() {
        this.emailSent = false;
    }

    /**
     * Opens the email sent screen
     */
    public openEmailSentScreen(): void {
        this.emailSent = true;
    }

    async signInWithApple() {
        try {
            AppleID.auth.init({
                clientId: environment.apple.auth.clientId,
                scope: "name email",
                redirectURI: environment.apple.auth.redirectUri,
                state: "init",
                nonce: "test",
                usePopup: true, //or false defaults to false
            });
            const data = await AppleID.auth.signIn();

            this.handleAuthentication({
                authProvider: "APPLE",
                token: data.authorization.id_token,
            });
        } catch (error) {
            console.error("Error while signing in with Apple", error);
        }
    }

    public googleLoginClicked() {
        if (this.googleButtonLoaded) {
            this.emitLoading(true);
        }
    }
}
