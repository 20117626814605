import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: "app-locked-photo-overlay",
    templateUrl: "./locked-photo-overlay.component.html",
})
export class LockedPhotoOverlayComponent implements OnInit {
    @Input() type: string;

    @Output() forwardToUnlockPhotoModal: EventEmitter<any> = new EventEmitter();

    public icon: string;

    ngOnInit(): void {
        this.selectIcon();
    }

    private selectIcon() {
        switch (this.type) {
            case "INTIMATE":
                this.icon = "unlock_photo_intimate";
                break;
            case "EXCLUSIVE":
                this.icon = "unlock_photo_exclusive";
                break;
            case "PRIVATE":
                this.icon = "unlock_photo_private";
                break;
            case "SPECIAL":
                this.icon = "unlock_photo_special";
                break;
        }
    }

    goForward() {
        this.forwardToUnlockPhotoModal.emit();
    }
}
