import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { PaypalService } from "src/app/services/paypal.service";
import { Product } from "src/app/services/shop.service";
import { TokenHandlingService } from "src/app/services/token-handling.service";
import {
    PaypalCustomTabData,
    WebToAppOperation,
    WebToAppService,
} from "src/app/services/web-to-app.service";

/**
 * All the information needed by the Paypal api to create the transaction.
 */
export interface PaypalTransactionDetails {
    clientSecret: string; // TODO: find out what this represents
    product: Product;
}

@Component({
    selector: "app-checkout-paypal",
    templateUrl: "./checkout-paypal.component.html",
})
export class CheckoutPaypalComponent implements OnInit {
    public paypalTransactionDetails!: PaypalTransactionDetails;

    private product?: Product;

    @ViewChild("paypalRef", { static: true }) private paypalRef: ElementRef;

    constructor(
        private api: ApiService,
        private router: Router,
        private webToAppService: WebToAppService,
        private paypalService: PaypalService,
        private tokenHandlingService: TokenHandlingService
    ) {
        // gather paypalTransactionDetails either via customTabData (web-to-app) or router state
        if (this.webToAppService.openedInCustomTab()) {
            try {
                const customTabData = this.webToAppService.getCustomTabData();
                if (customTabData && customTabData.operation === WebToAppOperation.PAYPAL) {
                    const customTabPaypalData = customTabData as PaypalCustomTabData;
                    this.tokenHandlingService.setAuthenticationToken(
                        customTabPaypalData.accessToken
                    );
                    this.paypalTransactionDetails = customTabPaypalData.paypalTransactionDetails;
                } else {
                    throw new Error("CustomTabData is not paypal data.");
                }
            } catch (e) {
                console.warn(
                    "Could not get CustomTabPaypalData from web-to-app service. Closing the custom tab.",
                    e
                );
                this.webToAppService.closeCustomTab({
                    purchaseSuccessful: false,
                });
            }
        } else if (this.router.getCurrentNavigation()?.extras.state) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const state = this.router.getCurrentNavigation()?.extras.state as any;

            if (state && Object.prototype.hasOwnProperty.call(state, "paypalTransactionDetails")) {
                this.paypalTransactionDetails = state.paypalTransactionDetails;
            }
            if (state && Object.prototype.hasOwnProperty.call(state, "product")) {
                this.product = state.product;
            }
        }
    }

    ngOnInit(): void {
        if (!this.paypalTransactionDetails) {
            this.router.navigate(["/user/shop"]);
        }
        this.paypalService.executeWhenPaypalIsReady(() => {
            this.initPayPalCheckout(this.paypalTransactionDetails.clientSecret, this.router);
        });
    }

    initPayPalCheckout(clientSecret: string, router: any) {
        this.paypalService.paypal
            .Buttons({
                style: {
                    layout: "horizontal",
                    size: "50px",
                    color: "blue",
                    shape: "pill",
                    tagline: false,
                },
                createOrder: () => clientSecret,
                onApprove: (data, actions) => {
                    // Capture the payment when the user approves the transaction
                    return actions.order.capture().then((response) => {
                        const description = response.purchase_units.map((res) => res.reference_id);

                        this.api
                            .shop()
                            .verifyPaypal(response.id)
                            .subscribe({
                                next: () => {
                                    // close custom tab
                                    if (this.webToAppService.openedInCustomTab()) {
                                        this.webToAppService.closeCustomTab({
                                            description,
                                            purchaseSuccessful: true,
                                        });
                                    } else {
                                        router.navigate(["/purchase-successful"], {
                                            state: {
                                                description,
                                                product: this.product,
                                            },
                                        });
                                    }
                                },
                                error: () => {
                                    if (this.webToAppService.openedInCustomTab()) {
                                        this.webToAppService.closeCustomTab({
                                            purchaseSuccessful: false,
                                        });
                                    } else {
                                        router.navigate(["/purchase-unsuccessful"]);
                                    }
                                },
                            });
                    });
                },
                onError: () => {
                    if (this.webToAppService.openedInCustomTab()) {
                        this.webToAppService.closeCustomTab({
                            purchaseSuccessful: false,
                        });
                    } else {
                        router.navigate(["/purchase-unsuccessful"]);
                    }
                },
            })
            .render(this.paypalRef.nativeElement);
    }

    public cancelPaymentProcess(event: MouseEvent): void {
        event.preventDefault();
        if (this.webToAppService.openedInCustomTab()) {
            this.webToAppService.closeCustomTab({
                purchaseSuccessful: false,
            });
        } else {
            this.router.navigate(["/checkout"], {
                state: {
                    product: this.paypalTransactionDetails.product,
                },
            });
        }
    }
}
