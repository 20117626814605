import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Product } from "src/app/services/shop.service";

@Component({
    selector: "app-offer-item",
    templateUrl: "./offer-item.component.html",
})
export class OfferItemComponent {
    @Input() public product: Product;
    @Input() public index = 0;

    constructor(private router: Router) {}

    public get isCoins(): boolean {
        return this.product.qtyKeys === 0;
    }

    public get isKeys(): boolean {
        return this.product.qtyCoins === 0;
    }

    public get title(): string {
        return this.isCoins
            ? `${this.product.qtyCoins} Credits`
            : `${this.product.qtyKeys} Schlüssel`;
    }

    public get image(): string {
        return `${this.isKeys ? "keyoffer" : "creditoffer"}_${
            this.isKeys ? this.index + 1 : this.product.bonusLevel
        }.png`;
    }

    public get pricePerCoin(): number {
        return this.turncate(this.product.cost / 100 / this.product.qtyCoins);
    }

    public get pricePerKey(): number {
        return this.turncate(this.product.cost / 100 / this.product.qtyKeys);
    }

    public get savePercentageCoin(): number {
        const regular = 0.99;
        return Number(this.turncate((regular - this.pricePerCoin) * 100).toFixed(0));
    }

    public get savePercentageKey(): number {
        const regular = 0.99;
        return Number(this.turncate((regular - this.pricePerKey) * 100).toFixed(0));
    }

    public get expiryText(): string {
        if (!this.product.offer || !this.product.expiredAt) {
            return "";
        }

        const now = new Date();
        const expiry = new Date(this.product.expiredAt);
        let remainingSeconds = new Date(expiry.getTime() - now.getTime()).getTime() / 1000;

        // const remainingDays = Math.floor(remainingSeconds / 86400);
        // remainingSeconds -= remainingDays * 86400;

        const remainingHours = Math.floor(remainingSeconds / 3600);
        remainingSeconds -= remainingHours * 3600;

        const remainingMinutes = Math.floor(remainingSeconds / 60);
        remainingSeconds -= Math.floor(remainingMinutes * 60);

        return [
            {
                value: remainingHours,
                content: `${remainingHours} Std`,
            },
            {
                value: remainingMinutes,
                content: `${remainingMinutes} Min`,
            },
        ]
            .filter((set) => set.value > 0)
            .map((set) => set.content)
            .join(" ");
    }

    /**
     * To get only 2 fraction digits
     * @param value decimal value with long fraction digits
     * @returns The Number
     */
    public turncate(value: number): number {
        let num = value.toString(); //If it's not already a String
        num = num.slice(0, num.indexOf(".") + 3); //With 3 exposing the hundredths place
        return Number(num);
    }

    calculateSavePercentage(value: number): number {
        const price = 0.99;
        value = this.turncate(value);
        const result = this.turncate((price - value) * 100).toFixed(0);
        return Number(result);
    }

    public navigateToCheckout(event: MouseEvent): void {
        event.preventDefault();
        this.router.navigate(["/checkout"], {
            state: {
                product: this.product,
            },
        });
    }
}
