import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ApiService } from "src/app/services/api.service";

@Component({
    selector: "app-password-reset",
    templateUrl: "./password-reset.component.html",
})
export class PasswordResetComponent {
    @Output() hidePopup: EventEmitter<string> = new EventEmitter();
    @Input() showResetPassword = false;
    @Input() emailSent = false;
    public isClickInProgress = false;

    form: FormGroup = new FormGroup({
        email: new FormControl(null, [Validators.required, Validators.email]),
    });

    constructor(private api: ApiService) {}

    toggleResetPassword() {
        this.showResetPassword = !this.showResetPassword;
    }

    closeEmailSent() {
        this.showResetPassword = true;
        this.emailSent = false;
    }

    public requestEmail() {
        this.isClickInProgress = true;

        if (this.form.valid) {
            this.api
                .me()
                .forgotPassword(this.form.value)
                .subscribe({
                    next: () => {
                        this.showResetPassword = false;
                        this.emailSent = true;
                    },
                });
        }
        this.isClickInProgress = false;
    }

    get email(): FormControl {
        return this.form.get("email") as FormControl;
    }

    /**
     * Emit event to hide the popup window for desktop view
     */
    public closePopup() {
        this.hidePopup.emit();
    }
}
