import { Component, EventEmitter, Input, Output } from "@angular/core";

export interface Tutorial {
    heading: string;
    description: string;
    image: number;
    buttonText: string;
}

@Component({
    selector: "app-tutorial",
    templateUrl: "./tutorial.component.html",
})
export class TutorialComponent {
    index = 0;

    @Input() tutorials: Tutorial[];
    @Input() continueText = "Weiter";

    @Output() closeTutorial: EventEmitter<string> = new EventEmitter();

    nextTutorial() {
        if (this.index + 1 == this.tutorials.length) {
            this.close();
            return;
        }
        this.index++;
    }

    public close(): void {
        this.closeTutorial.emit();
    }
}
