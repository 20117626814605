import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ApiService } from "src/app/services/api.service";
import { MembersMeStoreService } from "src/app/services/members-me-store.service";

import { animate, state, style, transition, trigger } from "@angular/animations";
@Component({
    selector: "app-change-email",
    templateUrl: "./change-email.component.html",
    animations: [
        trigger("simpleFadeAnimation", [
            state("in", style({ opacity: 1 })),
            transition(":enter", [style({ opacity: 0 }), animate(300)]),
            transition(":leave", animate(300, style({ opacity: 0 }))),
        ]),
    ],
})
export class ChangeEmailComponent implements OnInit {
    form: UntypedFormGroup = new UntypedFormGroup({
        email: new UntypedFormControl(null, [Validators.required, Validators.email]),
    });

    public requestError: string | null = null;
    public requestSuccess = false;
    public showEmailSent = false;

    toggleEmailSent() {
        this.showEmailSent = !this.showEmailSent;
    }

    constructor(private api: ApiService, private membersMeStore: MembersMeStoreService) {}

    ngOnInit(): void {
        this.membersMeStore.getMembersMeFromStore().subscribe((response) => {
            this.email.setValue(response.email);
        });
    }

    public changeEmail(): void {
        if (this.email.invalid) {
            return;
        }
        this.api
            .me()
            .resetEmail(this.email.value)
            .subscribe({
                next: () => {
                    this.form.clearValidators();
                    this.requestError = null;
                    this.requestSuccess = true;
                    this.showEmailSent = true;
                    this.membersMeStore.updateMembersMeInStore("email", this.email.value);
                },
                error: (response) => {
                    this.requestError = response.message;
                },
            });
    }

    get email(): UntypedFormControl {
        return this.form.get("email") as UntypedFormControl;
    }

    public resendEmail(event: MouseEvent): void {
        event.preventDefault();

        this.changeEmail();
    }
}
