import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class GoogleMapsService {
    private maps?;

    private callbacks: ((maps) => void)[] = [];

    executeWhenGoogleMapsIsReady = (callback: (maps) => void) => {
        if (this.maps) {
            callback(this.maps);
        } else {
            this.callbacks.push(callback);
        }
    };

    loadScript = () => {
        const googleMapsScript = document.createElement("script");
        googleMapsScript.setAttribute(
            "src",
            `https://maps.googleapis.com/maps/api/js?key=AIzaSyCoe1ypvHdJuZxvQReP_a0w-u409JJwKqg&libraries=places&language=en`
        );
        googleMapsScript.setAttribute("async", "async");
        googleMapsScript.setAttribute("defer", "defer");
        document.head.appendChild(googleMapsScript);
        googleMapsScript.addEventListener("load", () => {
            try {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                this.maps = (window as any).google.maps;
                this.callbacks.forEach((callback) => callback(this.maps));
            } catch (e) {
                console.error("Failed to load Google Maps. More info:", e);
            }
        });
    };
}
