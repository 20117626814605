import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "app-account-deleted",
    templateUrl: "./account-deleted.component.html",
})
export class AccountDeletedComponent {
    // public date: string;
    @Input() date?: string;
    @Output() hidePopup: EventEmitter<string> = new EventEmitter();

    public get readableDate(): string {
        if (!this.date) return "";

        const date = new Date(this.date);
        return `${date.getDate().toString().padStart(2, "0")}.${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}.${date.getFullYear().toString().substring(2)}`;
    }

    public closePopup() {
        this.hidePopup.emit();
    }
}
