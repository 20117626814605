<div
    class="h-full w-full absolute top-0 left-0 z-30 md:flex md:items-center md:justify-center md:bg-gray-400/80"
>
    <app-modal
        [startPageModal]="true"
        *ngIf="!emailSent"
        (closeMatch)="closePopup()"
        [desktopHeight]="'700'"
        [customFooter]="true"
        [desktopWidth]="'400'"
        [topPadding]="'4'"
        [footer]="'Du hast schon ein Konto?'"
        [footerLinkText]="'Zum Login'"
        [image]="'../../../../assets/images/konto_erstellen_icon.png'"
        [h1]="'Konto erstellen'"
        (clickFooter)="goToLogin()"
    >
        <div subtitle class="px-4">
            Durch die Anmeldung mit einem unserer Dienste stimmst Du unseren
            <a [routerLink]="'/terms'" class="link">AGB</a>
            zu. Informationen darüber, wie wir Deine Daten handhaben, findest Du
            in unserer
            <a routerLink="data-protection" class="link">Datenschutzerklärung</a
            >.
        </div>
        <div content class="px-4">
            <form
                [formGroup]="form"
                (ngSubmit)="register()"
                class="space-y-4"
                *ngIf="showForm"
            >
                <app-input-box
                    class="mb-4"
                    [label]="'E-Mail'"
                    [icon]="'auth/at-symbol.png'"
                    [error]="errorEmail"
                >
                    <input
                        class="w-full focus:outline-none input text-brand-title placeholder:input-placeholder"
                        placeholder="Wie lautet Deine Email"
                        formControlName="email"
                        (input)="updateEmail()"
                    />

                    <p
                        error
                        *ngIf="email.touched && email.hasError('required')"
                    >
                        Dieses Feld muss ausgefüllt sein
                    </p>
                    <p error *ngIf="email.touched && email.hasError('email')">
                        Bitte gib eine gültige E-Mail Adresse ein
                    </p>
                </app-input-box>
                <app-input-box
                    [label]="'Passwort'"
                    [error]="errorPassword"
                    (eyeClicked)="togglePasswordVisibility()"
                >
                    <input
                        class="w-full focus:outline-none input text-brand-title placeholder:input-placeholder"
                        type="{{ passwordInputType }}"
                        placeholder="Wähle ein Passwort"
                        formControlName="password"
                    />
                    <p
                        error
                        *ngIf="
                            password.touched && password.hasError('required')
                        "
                    >
                        Dieses Feld muss ausgefüllt sein
                    </p>
                    <p
                        error
                        *ngIf="
                            password.touched &&
                            (password.hasError('pattern') ||
                                password.hasError('minlength'))
                        "
                    >
                        Nutze mindestens 8 Zeichen, einen Großbuchstaben, eine
                        Zahl und ein Sonderzeichen
                    </p>
                </app-input-box>
                <div class="flex text-xs mt-3 space-x-1">
                    <img
                        class="h-4 w-4"
                        src="../../../assets/images/info.png"
                        alt="sale_green_outline"
                    />
                    <p>
                        Nutze mindestens 8 Zeichen, einen Großbuchstaben, eine
                        Zahl und ein Sonderzeichen
                    </p>
                </div>
                <p
                    *ngIf="errorMessage"
                    class="text-brand-danger text-center mt-2"
                >
                    {{ errorMessage }}
                </p>
                <div class="mt-8 py-2 w-full">
                    <app-link-button
                        [loggedIn]="false"
                        [isDisabled]="isClickInProgress"
                        *ngIf="form.valid"
                        (userClicked)="register()"
                        [theme]="'purple'"
                        >Konto erstellen</app-link-button
                    >
                    <app-link-button
                        [loggedIn]="false"
                        *ngIf="!form.valid"
                        [theme]="'disable'"
                        >Konto erstellen</app-link-button
                    >
                </div>
                <input type="submit" hidden />
            </form>
            <div class="flex flex-col items-center w-full space-y-4">
                <div *ngIf="!showForm" class="grid self-stretch space-y-2">
                    <div>
                        <div
                            id="signInWithGoogleReg"
                            class="{{
                                googleButtonLoaded ? '' : 'cursor-not-allowed'
                            }}"
                        >
                            <div
                                class="h-12 !border-2 !border-brand-title text-brand-title rounded-full w-full px-4 py-2 flex items-center justify-center relative font-bold text-sm cursor-not-allowed"
                            >
                                <img
                                    class="absolute h-5 left-4"
                                    src="../../../assets/images/auth/google_icon.png"
                                />
                                Anmelden mit Google
                            </div>
                        </div>
                        <template id="signInWithGoogleButton">
                            <div
                                class="h-12 !border-2 !border-brand-title text-brand-title rounded-full w-full px-4 py-2 flex items-center justify-center relative font-bold text-sm cursor-pointer"
                            >
                                <img
                                    class="absolute h-5 left-4"
                                    src="../../../assets/images/auth/google_icon.png"
                                />
                                Anmelden mit Google
                            </div>
                        </template>
                    </div>

                    <app-register-with-item
                        (click)="signinWithFacebook()"
                        [title]="'Anmelden mit Facebook'"
                        [icon]="'auth/fb_icon.png'"
                    >
                    </app-register-with-item>
                    <app-register-with-item
                        (click)="signInWithApple()"
                        [title]="'Anmelden mit Apple'"
                        [icon]="'auth/apple_icon.png'"
                    >
                    </app-register-with-item>
                </div>

                <a
                    class="modal-link"
                    *ngIf="!showForm"
                    (click)="showForm = true"
                    >Mit E-Mail anmelden</a
                >
            </div>
        </div>
    </app-modal>
    <app-modal
        [startPageModal]="true"
        *ngIf="emailSent"
        (closeMatch)="closeEmailSentScreen()"
        [desktopHeight]="'800'"
        [supportFooter]="true"
        [topPadding]="'6'"
        [image]="'../../../../assets/images/checkmark-circle.png'"
        [desktopWidth]="'400'"
        [h1]="'E-Mail gesendet'"
    >
        <div subtitle class="text-center">
            Eine Bestätigungsmail wurde an Deine E-Mail-Adresse gesendet. Bitte
            überprüfe auch Deinen Posteingang sowie Deinen Spam- bzw.
            Junk-Ordner. Es kann bis zu 5 Minuten dauern, bis die E-Mail bei Dir
            eintrifft.
        </div>
    </app-modal>
</div>
