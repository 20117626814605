<!-- TODO: remove dynamic functions from app-main-layout -->
<app-main-layout
    (navigatedBack)="navigatedBack()"
    [showProfile]="true"
    leftMenuText="Kasse"
    [separationLine]="true"
    [bottomPadding]="'6'"
>
    <script src="https://js.stripe.com/v3/"></script>
    <div
        class="bg-white relative bg-cover bg-no-repeat h-full overflow-y-scroll no-scrollbar pt-20 pb-8 sm:pt-0"
    >
        <!-- Product -->
        <div class="px-4">
            <div
                *ngIf="paymentIntent === null"
                class="py-4 bg-white flex justify-between text-base items-center sticky top-0 border-b-2 border-opacity-10 border-brand-body"
            >
                <div class="font-medium leading-5 flex flex-col space-y-2">
                    <p
                        class="text-brand-magenta font-bold text-[0.9rem] text-right"
                    >
                        Deine Bestellung:
                    </p>
                    <div class="">
                        <img
                            class="inline-flex h-[10px]"
                            src="../../../../assets/images/checkmark_green.png"
                        />
                        <span
                            class="text-brand-title ml-1 font-bold text-[0.9rem]"
                            >{{
                                product.qtyCoins
                                    ? product.qtyCoins + " Credits"
                                    : product.qtyKeys + " Schlüssel"
                            }}</span
                        >
                    </div>
                </div>
                <div class="flex flex-col items-end leading-5 pt-2">
                    <p class="text-brand-body text-[11px]">Inkl. MwSt.</p>
                    <h1 class="font-bold text-[0.9rem] text-right">
                        {{ product.cost / 100 | floatToEuro }}
                    </h1>
                </div>
            </div>
        </div>
        <!-- Payment methods -->
        <div *ngIf="paymentIntent === null" class="px-4">
            <p class="text-xs text-brand-title mt-6 mb-4 leading-6">
                <span>
                    <h2 class="heading-2 text-brand-title pb-2">
                        Zahlungsmöglichkeiten
                    </h2>
                </span>

                Durch den Kauf bestätige ich, dass ich die
                <a class="underline" routerLink="/terms">AGB</a> und
                <a class="underline" routerLink="/right-of-withdrawal"
                    >Widerrufsbelehrungen</a
                >
                des Anbieters gelesen habe und mit dem Absenden meiner
                Bestellung mein Einverständnis dazu erklärt habe.
            </p>

            <h3 class="heading-3 text-brand-title">Wähle einen Anbieter</h3>

            <div class="text-white text-sm mb-4 leading-5 font-semibold">
                Wähle einen Anbieter
            </div>
            <div class="grid gap-y-2">
                <a (click)="continueWithTransfer($event)">
                    <app-payment-methods-item
                        [bonus]="'10%'"
                        [recommended]="true"
                        [src]="'vorkasse'"
                        [title]="'Überweisung'"
                    ></app-payment-methods-item>
                </a>

                <a *ngIf="showApplePay" (click)="makePayment(applePayRequest)">
                    <app-payment-methods-item
                        [bonus]="'5%'"
                        [src]="'applepay'"
                        [title]="'Apple Pay'"
                    ></app-payment-methods-item>
                </a>
                <a
                    *ngIf="showGooglePay"
                    (click)="makePayment(googlePayRequest)"
                >
                    <app-payment-methods-item
                        [bonus]="'5%'"
                        [src]="'gpay'"
                        [title]="'Google Pay'"
                    ></app-payment-methods-item>
                </a>

                <a href (click)="continueWithStripe($event, 'klarna')">
                    <app-payment-methods-item
                        [bonus]="'5%'"
                        [src]="'klarna'"
                        [title]="'Klarna'"
                    ></app-payment-methods-item>
                </a>
                <a href (click)="continueWithStripe($event, 'card')">
                    <app-payment-methods-item
                        [bonus]="'5%'"
                        [src]="'creditcard'"
                        [title]="'Kreditkarte'"
                    ></app-payment-methods-item>
                </a>
                <!-- <a href (click)="continueWithStripe($event, 'sofort')">
                    <app-payment-methods-item
                        [bonus]="'5%'"
                        [src]="'sofort'"
                        [title]="'Sofort'"
                    ></app-payment-methods-item>
                </a> -->
                <a (click)="continueWithPayPal($event)">
                    <app-payment-methods-item
                        [src]="'paypal'"
                        [title]="'PayPal'"
                    ></app-payment-methods-item>
                </a>
            </div>
        </div>

        <!-- Payment information -->
        <div *ngIf="paymentIntent !== null" class="p-4">
            <form id="payment-form">
                <div id="payment-element">
                    <!--Stripe.js injects the Payment Element-->
                </div>
                <button id="submit">
                    <div class="spinner hidden" id="spinner"></div>
                    <span id="button-text">Pay now</span>
                </button>
                <div id="payment-message" class="hidden"></div>
            </form>
        </div>
    </div>
</app-main-layout>
