import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, Input, OnInit } from "@angular/core";
import { ApiService } from "src/app/services/api.service";
@Component({
    selector: "app-message-item",
    templateUrl: "./message-item.component.html",
    animations: [
        trigger("simpleFadeAnimation", [
            state("in", style({ opacity: 1 })),
            transition(":enter", [style({ opacity: 0 }), animate(300)]),
            transition(":leave", animate(300, style({ opacity: 0 }))),
        ]),
    ],
})
export class MessageItemComponent implements OnInit {
    @Input() me!: any;
    @Input() message!: any;
    @Input() requestReadReceiptPrice: any;
    public sendMessageFailed!: boolean;
    public isClickInProgress = false; // initialize with false
    public sender!: boolean;
    public date: Date;
    createdAt: Date;
    createdAtDay: string;
    readReceipt: string;
    readAtDay: string;
    createdAtTime: string;
    readAtTime: string;
    readAt: Date;
    public dateFormatted: string;
    public time: Date;
    public keys: number;
    public strTime = "aaa";
    public today: Date;
    public timeFormatted: string;
    public showRequestReadConfirmation = false;
    public sentByMe = false;
    public readAtPurchased = false;
    constructor(private api: ApiService) {}
    toggleRequestReadConfirmation() {
        if (this.keys >= 2) {
            this.showRequestReadConfirmation = !this.showRequestReadConfirmation;
        }
    }

    requestReadConfirmation() {
        this.isClickInProgress = true; // set isClickInProgress to true

        this.api
            .message()
            .message(this.message._id)
            .subscribe({
                next: (response) => {
                    this.readAtPurchased = true;
                    this.showRequestReadConfirmation = !this.showRequestReadConfirmation;

                    this.message.readAt = response.message.readAt;
                    this.message.readReceipt = true;
                    this.readAt = new Date(Date.parse(this.message.readAt));

                    // creating read Receipt
                    this.readReceipt = this.getReadReceipt(this.readAt);
                },
                error: (e) => {
                    console.error("Error while requesting read confirmation", e);
                },
            });

        this.isClickInProgress = false; // reset isClickInProgress to false once the operation completes
    }

    /*
    Adds a '0' infront of single digits. Useful for Months and Days, e.g. 9.9.1999 ->09.09.1999
    */
    isToday(date) {
        const today = new Date();
        if (today.toDateString() === date.toDateString()) {
            return true;
        }

        return false;
    }

    getReadReceipt(readAt: Date) {
        if (readAt === null) {
            return "Diese Nachricht wurde noch nicht gelesen.";
        }
        if (this.isToday(readAt)) {
            return `Diese Nachricht wurde Heute um ${this.parseTime(readAt)} gelesen`;
        }
        return `Diese Nachricht wurde am ${this.parseDate(readAt)} um ${this.parseTime(
            readAt
        )} gelesen`;
    }

    padTo2Digits(num) {
        return num.toString().padStart(2, "0");
    }

    parseDate(date: Date) {
        if (this.isToday(date)) {
            return "Heute";
        }

        return (
            this.padTo2Digits(date.getDay()) +
            "." +
            this.padTo2Digits(date.getMonth() + 1) +
            "." +
            date.getFullYear()
        );
    }

    parseTime(date: Date) {
        return this.padTo2Digits(date.getHours()) + ":" + this.padTo2Digits(date.getMinutes());
    }

    ngOnInit(): void {
        this.keys = this.me.keys;
        if (this.message.sender === this.me._id) {
            this.sentByMe = true;
        } else {
            this.sentByMe = false;
        }

        // retreiving created at and readat time and Day
        this.createdAt = new Date(Date.parse(this.message.createdAt));
        this.readAt = new Date(Date.parse(this.message.readAt));
        this.createdAtTime = this.parseTime(this.createdAt);
        this.createdAtDay = this.parseDate(this.createdAt);

        // creating read Receipt
        this.readReceipt = this.getReadReceipt(this.readAt);
    }
}
