export const environment = {
    production: false,
    ttQueryString: "ttclid",
    onesignal: {
        appId: "3db055fb-40f4-4665-ae9c-dc9011998a44",
    },
    webToApp: {
        registerURL: "https://test.amolino.de/?open=register",
        loginURL: "https://test.amolino.de/?open=login",
        paypalURL: "https://test.amolino.de/checkout-paypal",
    },
    url: {
        auth: "https://auth-test.amolino.de",
        api: "https://testapi.amolino.de/api/v1",
        image: "https://images-v2.amolino.de",
    },
    stripe: {
        returnUrl: "https://test.amolino.de/user/shop",
        publicKey:
            "pk_test_51LNYIhJAuwKdRnpdX48VoV4kf5F8cU7Ch9edcpHjwoY47ZG3qevp6zpsCDoKmNY5p0RZ5PjzUUCcJg9C7Y2GXDHf0056o0s45T",
    },
    paypal: {
        clientId:
            "ATYEUF4VZi83jaE8MAw9CoJai2Sjg6KqUoA9jxZ6f3TqC_zPmyuMU-brOuQAsSIsd-Dsah3aVZYFg2Um",
    },
    google: {
        auth: {
            clientId:
                "806773032759-ud4gb1hpf2rg1117g3prd318mhrmkqme.apps.googleusercontent.com",
        },
    },
    apple: {
        auth: {
            clientId: "de.amolino.test.sid",
            redirectUri: "https://test.amolino.de/apple-callback",
        },
    },
    facebook: {
        auth: {
            clientId: "613186417289466",
        },
    },
    websocket: {
        notifications: {
            url: "wss://notifications-test.amolino.de",
        },
    },
};
