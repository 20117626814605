import { Observable } from "rxjs";
import {
    MemberInteractionResponse,
    MembersResponse,
    OtherMemberData,
    StandardApiResponse,
} from "../interfaces/api-responses";
import { ApiService } from "./api.service";

export class MembersService {
    constructor(private api: ApiService) {}

    /**
     * Gets the items that the authenticated user can buy
     * @returns The observable
     */
    public get(): Observable<MembersResponse> {
        return this.api.get("members");
    }

    /**
     * Gets the profile of a given member
     * @param memberId The member id
     * @returns The observable
     */
    public find(memberId: string): Observable<OtherMemberData> {
        return this.api.get(`members/${memberId}`);
    }

    public searchByUsername(username: string) {
        return this.api.get(`members/search?username=${username}`);
    }

    /**
     * Interacts with a given member
     * @param memberId The member id
     * @param interaction The interaction type
     * @param message The message (if interaction type is instant match)
     * @returns The Observable
     */
    public interact(
        memberId: string,
        interaction: string,
        message: string | null = null
    ): Observable<MemberInteractionResponse> {
        return this.api.post(`members/${memberId}`, { interaction, message });
    }

    /**
     * Attempts to access an image of a given member
     * @param memberId The member id
     * @param imageId The image id
     * @param type The type of the image
     * @returns The observable
     */
    public image(memberId: string, imageId: string, type: string): Observable<StandardApiResponse> {
        return this.api.post(`members/${memberId}/images`, { imageId, type });
    }
}
