import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
})
export class HeaderComponent {
    @Output() loginClicked: EventEmitter<string> = new EventEmitter();
    @Output() registerClicked: EventEmitter<string> = new EventEmitter();

    @Input() usingMobileDevice: boolean;

    clickLogin() {
        this.loginClicked.emit();
    }

    clickRegister() {
        this.registerClicked.emit();
    }

    scrollToSection(target: any) {
        const element = document.getElementById(target);
        if (element) element.scrollIntoView({ behavior: "smooth" });
    }
}
