import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "dateToChatTime",
})
export class DateToChatTimePipe implements PipeTransform {
    transform(value: string): string {
        const date = new Date(value);
        let result: string;
        if (this.isToday(value)) {
            result = this.getTime(value);
        } else if (this.isYesterday(value)) {
            result = "gestern";
        } else {
            result = this.getFullDate(date);
        }

        return result;
    }

    getFullDate(date: any): string {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return (
            (day < 10 ? "0" + String(day) : String(day)) +
            "." +
            (month < 10 ? "0" + String(month) : String(month)) +
            "." +
            String(year)
        );
    }

    isYesterday(value: any): boolean {
        const yesterday = new Date();
        const date = new Date(value);
        yesterday.setDate(yesterday.getDate() - 1);
        if (yesterday.toDateString() === date.toDateString()) {
            return true;
        }
        return false;
    }

    isToday(value: any): boolean {
        const today = new Date();
        const date = new Date(value);
        if (today.toDateString() === date.toDateString()) {
            return true;
        }
        return false;
    }

    getTime(date: any): string {
        const data = new Date(date);
        let hrs = String(data.getHours());
        let mins = String(data.getMinutes());
        if (Number(hrs) <= 9) hrs = "0" + hrs;
        if (Number(mins) < 10) mins = "0" + mins;
        const postTime = hrs + ":" + mins;
        return postTime;
    }
}
