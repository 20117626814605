<app-main-layout [showProfile]="true" [leftMenuText]="'Cookie-Richtlinie'">
    <div class="px-4 pb-8 pt-11 sm:pt-0 text-brand-body">
        <div class="space-y-6 pt-6 max-w-sm">
            <h2 class="heading-2 text-brand-magenta">Cookie-Richtlinie</h2>

            <p>
                Die Multilabel GmbH setzt auf ihren Webseiten verschiedene
                Cookies ein. Cookies sind kleine Dateien mit
                Konfigurationsinformationen, die auf Ihrem Endgerät gespeichert
                werden. Cookies lassen sich grundsätzlich in drei Kategorien
                unterteilen.
            </p>

            <p>
                Für die Funktionalität der Webseite sind sog. Funktionscookies
                unerlässlich. Die Verarbeitung der Funktionscookies ist
                notwendig, um Ihnen einen Besuch der Webseite zu ermöglichen
                (vgl. Art. 6 Abs. 1 lit. b DSGVO).
                <br />
                Den Komfort eines Webseitenbesuchs erhöhen sog. Komfortcookies,
                die z. B. Ihre Spracheinstellungen speichern. Die
                Rechtsgrundlage für die Komfortcookies ist ein berechtigtes
                Interesse (Art. 6 Abs. 1 lit. f DSGVO). Das berechtigte
                Interesse liegt in der Bereitstellung eines gewissen Komforts
                bei Ihren Besuchen auf der Webseite. Sie können der
                Datenverarbeitung jederzeit mit Wirkung für die Zukunft
                widersprechen. Nähere Informationen dazu finden Sie in der
                Datenschutzerklärung.
                <br />
                Um ein pseudonymisiertes Nutzungsprofil zu erstellen werden sog.
                Trackingcookies verwendet. Trackingcookies werden nur gesetzt,
                wenn eine Einwilligung durch den Webseitenbesucher dafür
                vorliegt (Art. 6 Abs. 1 lit. a DSGVO). Die Einwilligung wird
                über den sog. Cookie-Banner abgegeben, der aktiv angeklickt
                werden muss.
                <br />
                Weitere Informationen zu den eingesetzten Trackingtools und wie
                Sie Ihre Einwilligung widerrufen können, finden Sie in der
                Datenschutzerklärung
                <br />
                Innerhalb der beschriebenen Kategorien werden verschiedene
                Cookie-Arten eingesetzt. Nachfolgend sind die gebräuchlichsten
                Arten von Cookies zu Ihrem Verständnis erklärt:
            </p>

            <p>
                <strong class="font-semibold"> 1. Sitzungs-Cookies </strong>
                <br />
                Während Sie auf einer Webseite aktiv sind, wird temporär ein
                Sitzungs-Cookie im Speicher Ihres Computers abgelegt, in dem
                eine Sitzungskennung gespeichert wird, um z.B. bei jedem
                Seitenwechsel zu verhindern, dass Sie sich erneut anmelden
                müssen. Sitzungs-Cookies werden bei der Abmeldung gelöscht oder
                verlieren ihre Gültigkeit, sobald ihre Sitzung automatisch
                abgelaufen ist.
            </p>

            <p>
                <strong class="font-semibold">
                    2. Permanente oder Protokoll-Cookies
                </strong>
                <br />
                Ein permanenter oder Protokoll-Cookie speichert eine Datei über
                den im Ablaufdatum vorgesehenen Zeitraum auf Ihrem Computer.
                Durch diese Cookies erinnern sich Webseiten bei Ihrem nächsten
                Besuch an Ihre Informationen und Einstellungen. Das führt zu
                einem schnelleren und bequemeren Zugriff, da Sie z.B. nicht
                erneut ihre Spracheinstellung für unser Portal vornehmen müssen.
                Mit Verstreichen des Ablaufdatums wird der Cookie automatisch
                gelöscht, wenn Sie die Webseite besuchen, die diesen erzeugt
                hat.
            </p>
            <p>
                <strong class="font-semibold">
                    3. Drittanbieter-Cookies
                </strong>
                <br />
                Drittanbieter-Cookies stammen von anderen Anbietern als dem
                Betreiber der Webseite. Sie können beispielsweise zum Sammeln
                von Informationen für Werbung, benutzerdefinierte Inhalte und
                Web-Statistiken verwendet werden
            </p>
            <p>
                <strong class="font-semibold"> 4. Flash-Cookies </strong>
                <br />
                Flash-Cookies werden als Datenelemente von Webseiten auf Ihrem
                Computer gespeichert, wenn diese mit Adobe Flash betrieben
                werden. Flash-Cookies haben kein Zeitlimit.
            </p>

            <p>Auf dieser Webseite werden die folgenden Cookies verwendet:</p>
            <!-- <div class="gap-y-6 grid">
                <app-policy-item title="VWD6_EINSIGHTEN_PRIVACY_MODAL _VIEWED"></app-policy-item>
                <app-policy-item title="VWD6_EINSIGHTEN_PRIVACY_BANNER _VIEWED"></app-policy-item>
                <app-policy-item title="VWD6_EINSIGHTEN_PRIVACY_&lt;category name&gt;"></app-policy-item>
                <app-policy-item title=" UZ_TI_dc_value"></app-policy-item>
                <app-policy-item title="awsalb"></app-policy-item>
            </div> -->
        </div>
    </div>
</app-main-layout>
