import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class DateStringMethodsService {
    public getFullDate(date: Date): string {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return (
            (day < 10 ? "0" + String(day) : String(day)) +
            "." +
            (month < 10 ? "0" + String(month) : String(month)) +
            "." +
            String(year)
        );
    }

    public isYesterday(value: string | number | Date): boolean {
        const yesterday = new Date();
        const date = new Date(value);
        yesterday.setDate(yesterday.getDate() - 1);
        if (yesterday.toDateString() === date.toDateString()) {
            return true;
        }
        return false;
    }

    public isToday(value: string | number | Date): boolean {
        const today = new Date();
        const date = new Date(value);
        if (today.toDateString() === date.toDateString()) {
            return true;
        }
        return false;
    }

    public getTime(date: string | Date | number): string {
        const data = new Date(date);
        let hrs = String(data.getHours());
        let mins = String(data.getMinutes());
        if (Number(hrs) <= 9) hrs = "0" + hrs;
        if (Number(mins) < 10) mins = "0" + mins;
        const postTime = hrs + ":" + mins;
        return postTime;
    }

    public calculateAgeDifference(myDob: string, partnerDob: string): number {
        const today = new Date();
        const myDate = new Date(myDob);
        const partnerDate = new Date(partnerDob);
        const myAge = Math.floor((+today - +myDate) / 31557600000);
        const partnerAge = Math.floor((+today - +partnerDate) / 31557600000);
        return Math.abs(myAge - partnerAge);
    }

    public isGreaterThanDayBeforeYesterday(value: string): boolean {
        const dayBeforeYesterday = new Date();
        const date = new Date(value);
        dayBeforeYesterday.setDate(dayBeforeYesterday.getDate() - 2);
        if (+date > +dayBeforeYesterday) {
            return true;
        }
        return false;
    }
}
