<div>
    <app-image-slider
        *ngIf="openImageSlider"
        (closeLightBox)="closeImageLightBox()"
        [startIndex]="startImageIndexForSlider"
        [images]="member?.gallery"
        [member]="member"
        (unlockPhotoModal)="showPhotoUnlockModal($event)"
    >
    </app-image-slider>
    <div *ngIf="!showInsufficientCreditsError">
        <div
            *ngIf="
                member &&
                !interaktionLimitReached &&
                !showSolveMatch &&
                !openImageSlider
            "
            class="absolute h-full w-full no-scrollbar sm:pt-0 {{
                hideFullPage ? 'overflow-y-hidden' : 'overflow-y-scroll'
            }}"
        >
            <app-others-profile-header
                [commonTagsText]="commonTagsText"
                [member]="member"
                (performedInstantMatch)="performedInstantMatch()"
                (displayStatusMatchForm)="matchFormStatus($event)"
                [showButton]="{
                    like: showLikeButton,
                    match: showInstantMatchButton
                }"
                (insufficientCredits)="showInsufficientCreditsError = true"
                (limitReached)="interaktionLimitReached = true"
                (likeMember)="likedMember()"
                (profileImageClicked)="openImageLightBox()"
            ></app-others-profile-header>
            <div *ngIf="!hideFullPage" class="text-brand-body px-6">
                <div class="text-brand-body z-10">
                    <div>
                        <h2 class="text-brand-magenta mb-8 mt-8 heading-2">
                            Interessen
                        </h2>
                        <app-user-interests
                            [commonTags]="commonTags"
                            [tags]="member?.tags"
                        >
                        </app-user-interests>
                    </div>
                    <!-- Divider -->
                    <div
                        class="border-b border-brand-body my-8 opacity-25"
                    ></div>
                    <div>
                        <h2 class="text-brand-magenta mb-8 heading-2">
                            Über {{ member?.username ?? '-' }}
                        </h2>
                        <p class="mb-4">{{ member?.profileText }}</p>
                    </div>
                    <!-- Divider -->
                    <div
                        class="border-b border-brand-body my-12 opacity-30"
                    ></div>
                    <div>
                        <h2 class="text-brand-magenta mb-8 heading-2">
                            Galerie
                        </h2>
                        <div class="grid grid-cols-4 gap-1">
                            <div
                                class=""
                                *ngFor="
                                    let item of member?.gallery;
                                    let i = index
                                "
                            >
                                <app-image
                                    (click)="handleStartImageIndexValue(i)"
                                    [imageItem]="item"
                                ></app-image>
                            </div>
                        </div>
                    </div>
                    <!-- Divider -->
                    <div
                        class="border-b border-brand-body mt-10 mb-6 opacity-25"
                    ></div>
                    <div
                        class="pb-4 font-semibold flex items-center space-x-2 justify-center my-4 md:pb-4"
                    >
                        <div class="flex items-center space-x-2">
                            <img
                                class="h-[15px] w-[15px]"
                                src="../../../../assets/images/alert_white_filled.png"
                                alt="alert_white_filled"
                            />
                            <a
                                href="mailto:support@amolino.de?subject=Profil #{{
                                    memberId
                                }} &body=Hallo,%0D%0A ich möchte den/die User:in aus folgenden Gründen melden:"
                                class="hover:underline"
                                >Dieses Profil melden</a
                            >
                        </div>
                        <div
                            *ngIf="visitedFrom === 'chat'"
                            class="flex items-center space-x-2"
                        >
                            <img
                                class="h-[15px] w-[15px]"
                                src="../../../../assets/images/unmatch_red.png"
                                alt="alert_white_filled"
                            />
                            <div
                                (click)="showSolveMatch = true"
                                class="hover:underline cursor-pointer"
                            >
                                Match Lösen
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- DISCOVER LIMIT SCREENS IF ERROR 402 PAYMENT REQUIRED -->

        <div class="pt-11 sm:pt-0" *ngIf="interaktionLimitReached">
            <app-discover-limit
                (manuallyClosed)="interaktionLimitReached = false"
                (boughtNewLikes)="interaktionLimitReached = false"
            >
            </app-discover-limit>
        </div>

        <div
            @simpleFadeAnimation
            *ngIf="showSolveMatch"
            class="bg-white z-20 text-brand-body absolute inset-0 top-[43px] md:top-0"
        >
            <app-modal
                [topPadding]="'6'"
                (closeMatch)="showSolveMatch = false"
                smallIcon="../../../../assets/images/unmatch_modal.png"
                image="{{ member?.image | addImageBaseUrl }}"
                [imageWrapperProps]="'h-40 w-40 rounded-full overflow-hidden'"
                [imageProps]="'h-40 w-40 rounded-full object-cover'"
                [disclaimer]="
                    'Diese Aktion kann nicht rückgängig gemacht werden.'
                "
                [h1]="'Bist du sicher?'"
            >
                <div subtitle>
                    Wenn Du das Match auflöst, wird der Chat entfernt und Du
                    hast keinen Zugriff mehr auf das Profil der Nutzerin oder
                    des Nutzers.
                </div>
                <div content>
                    <div class="w-full">
                        <app-link-button
                            (click)="deleteMatch(matchId)"
                            [isDisabled]="isClickInProgress"
                            [theme]="'pink'"
                            >Match lösen</app-link-button
                        >
                    </div>
                </div>
            </app-modal>
        </div>

        <!-- SOLVE MATCH MODAL -->
        <!-- <div class="bg-white text-brand-body h-full fixed md:static top-0 z-10" *ngIf="showSolveMatch">
            <div class="flex flex-col items-center text-[13px] space-y-8 leading-6 px-6 pt-6 mx-auto max-w-sm">
                <div class="grid relative">
                    <div class="p-1">
                        <img class="h-36 w-36 rounded-full object-cover " src="{{ member?.image | addImageBaseUrl}}" alt="user image">
                    </div>
                    <div class="p-1 absolute -bottom-1 right-1 rounded-full">
                        <img class="h-10 w-10" src="../../../../assets/images/unmatch_modal.png" alt="unmatch_modal">
                    </div>
                </div>
                <p class="text-center font-semibold text-brand-body">
                    Diese Aktion ist nicht mehr umkehrbar.
                </p>
            </div>
        </div> -->

        <!-- UNLOCK FOTO MODALS -->
        <app-photo-type-item
            (insufficientCredits)="showInsufficientCreditsError = true"
            *ngIf="intimate"
            (unlockCompleted)="photoUnlockCompleted($event)"
            (closePhotoModal)="intimate = false; hideFullPage = false"
            [dataForUnlock]="photoToUnlock"
            [type]="'intimate'"
            [icon]="'unlock_photo_intimate'"
            [price]="fees?.unlockIntimate"
            [info]="
                'Wenn Du ein Foto dieses Typs freischaltest, könnte es möglicherweise freizügige oder erotische Inhalte enthalten. Bist Du sicher, dass Du dieses Foto freischalten möchtest?'
            "
        >
        </app-photo-type-item>

        <app-photo-type-item
            (insufficientCredits)="showInsufficientCreditsError = true"
            *ngIf="exclusive"
            (unlockCompleted)="photoUnlockCompleted($event)"
            (closePhotoModal)="exclusive = false; hideFullPage = false"
            [dataForUnlock]="photoToUnlock"
            [type]="'exclusive'"
            [icon]="'unlock_photo_exclusive'"
            [price]="fees?.unlockExclusive"
            [info]="
                'Bei Fotos dieses Typs handelt es sich oft um sehr intime Momente der Person, die nicht für die Öffentlichkeit bestimmt sind. Möchtest Du dieses Foto freischalten?'
            "
        >
        </app-photo-type-item>

        <app-photo-type-item
            (insufficientCredits)="showInsufficientCreditsError = true"
            *ngIf="private"
            (unlockCompleted)="photoUnlockCompleted($event)"
            (closePhotoModal)="private = false; hideFullPage = false"
            [dataForUnlock]="photoToUnlock"
            [type]="'private'"
            [icon]="'unlock_photo_private'"
            [price]="fees?.unlockPrivate"
            [info]="
                'Es handelt sich hierbei um ein privates Foto, das möglicherweise einen persönlichen Moment des Nutzers oder der Nutzerin zeigt. Möchtest Du das Foto freischalten?'
            "
        >
        </app-photo-type-item>

        <app-photo-type-item
            (insufficientCredits)="showInsufficientCreditsError = true"
            *ngIf="special"
            (unlockCompleted)="photoUnlockCompleted($event)"
            (closePhotoModal)="special = false; hideFullPage = false"
            [dataForUnlock]="photoToUnlock"
            [type]="'special'"
            [icon]="'unlock_photo_special'"
            [price]="fees?.unlockSpecial"
            [info]="
                'Es handelt sich hierbei um ein privates Foto, das möglicherweise einen sehr persönlichen Moment des Nutzers oder der Nutzerin zeigt. Möchtest Du das Foto freischalten?'
            "
        >
        </app-photo-type-item>
    </div>
    <div @simpleFadeAnimation *ngIf="showInsufficientCreditsError">
        <app-insufficient-credits
            (closeForm)="closeInsufficientCreditsError()"
        ></app-insufficient-credits>
    </div>
</div>
