<app-main-layout
    [showProfile]="true"
    [leftMenuText]="'Dein Konto'"
    [rightMenuText]="'Ausloggen'"
    [rightMenuIcon]="'logout.png'"
>
    <div
        class="h-full w-full overflow-x-hidden flex flex-col no-scrollbar pt-11 sm:pt-0"
    >
        <!-- Profile Image -->
        <div class="flex justify-center w-full relative">
            <div
                class="bg-gradient-to-tr from-brand-cyan to-brand-magenta h-[190px] w-[400px] rounded-b-full scale-150 -translate-y-8"
            ></div>
            <div class="absolute top-12">
                <div class="relative ring-[10px] ring-white/10 rounded-full">
                    <div
                        style="
                            overflow: hidden;
                            height: 180px;
                            width: 180px;
                            border-radius: 50%;
                            position: relative;
                        "
                    >
                        <img
                            src="{{ image }}"
                            alt="profile_picture"
                            style="
                                position: absolute;
                                top: -40%;
                                min-width: 100%;
                                min-height: 100%;
                                object-fit: cover;
                                object-position: center;
                            "
                        />
                    </div>
                    <div id="wrapper" class="">
                        <input
                            id="camera-upload"
                            capture="camera"
                            class="hidden"
                            type="file"
                            name="img"
                            accept="image/jpeg,image/jpg,image/heic,image/heif"
                            (change)="compressImage($event)"
                        />
                        <label for="camera-upload" id="camera-upload-input">
                            <img
                                class="w-11 absolute -bottom-1 -right-2 cursor-pointer"
                                src="../../../../assets/images/profile_camera.png"
                                alt=" take_photo"
                            />
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex-grow flex flex-col mt-12">
            <!-- Information -->
            <div class="grid justify-items-center">
                <h1 class="text-3xl font-bold">
                    {{ me?.username ?? "-" | titlecase }},
                    {{ me?.dob?.toString() ?? "-" | dateToAge }}
                </h1>
                <p class="text-sm mb-8">{{ me?.email ?? "-" }}</p>
                <div
                    class="flex items-center justify-between w-full my-4 px-4 md:px-8"
                >
                    <div
                        class="flex space-x-3 items-center text-brand-title font-semibold text-base"
                    >
                        <div class="flex items-center">
                            <img
                                src="../../../../../assets/images/credits.png"
                                alt="rhombus-icon"
                                width="25px"
                                height="25px"
                            />
                            <p>
                                {{
                                    me?.coins?.toString()?.padStart(3, "0") ??
                                        "-"
                                }}
                            </p>
                        </div>
                        <div class="flex items-center">
                            <img
                                src="../../../../../assets/images/keys.png"
                                alt="key-icon"
                                width="25px"
                                height="25px"
                            />
                            <p>
                                {{
                                    me?.keys?.toString()?.padStart(3, "0") ??
                                        "-"
                                }}
                            </p>
                        </div>
                    </div>
                    <div class="flex items-center space-x-4">
                        <a
                            routerLink="/user/shop"
                            class="hover:underline text-sm font-bold"
                            >Zum Shop</a
                        >
                        <img
                            class="h-3"
                            src="../../../../assets/images/blue_arrow.png"
                            alt="blue_arrow"
                        />
                    </div>
                </div>
            </div>

            <!-- Pages -->
            <div
                class="bg-brand-body bg-opacity-10 px-8 py-12 md:px-8 divide-y divide-brand-body divide-opacity-20 [&>*]:py-6"
                style="box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.1)"
            >
                <div class="!pt-0">
                    <app-profile-settings-item
                        title="Fotos bearbeiten"
                        icon="photos_darkgreen"
                        info="Fotos hochladen, bearbeiten und entfernen"
                        path="/my-photos"
                    ></app-profile-settings-item>
                </div>
                <div>
                    <app-profile-settings-item
                        title="Profil bearbeiten"
                        icon="profile"
                        info="Profilangaben und Informationen bearbeiten"
                        path="/edit-profile"
                    >
                    </app-profile-settings-item>
                </div>
                <div>
                    <app-profile-settings-item
                        title="Kontoeinstellungen"
                        icon="settings"
                        info="Generelle Einstellungen zu Deinem Konto"
                        path="/account-settings"
                    >
                    </app-profile-settings-item>
                </div>
                <div class="!pb-0">
                    <app-profile-settings-item
                        title="Hilfebereich"
                        icon="headphones"
                        info="Unsere FAQ's sowie Kontaktmöglichkeiten"
                        path="/help"
                    ></app-profile-settings-item>
                </div>
            </div>
        </div>
    </div>
</app-main-layout>
