<!-- <div class="flex space-x-2 flex-grow-o overflow-hidden">
    <div class="grid relative min-w-fit">
      <p *ngIf="match.new" class="absolute right-0 bg-green-300 rounded-full p-1 border-[3px] border-white"></p>
    </div>
      </div>
    </div>
  </div>
   -->
<div
    class="flex justify-between cursor-pointer items-center"
    [routerLink]="['/chat', match._id]"
>
    <a
        class="flex space-x-4 md:space-x-2 items-center cursor-pointer overflow-hidden w-full"
    >
        <div class="grid relative">
            <div
                class="w-14 h-14 md:h-[50px] md:w-[50px] rounded-full overflow-hidden"
            >
                <img
                    class="object-cover h-14 w-16 md:h-[50px] md:w-[50px]"
                    src="{{ match.partner.image | addImageBaseUrl }}"
                    alt="user_photo"
                    style="border-radius: 50%"
                />
            </div>
            <!-- <div class="h-[50px] w-[50px] bg-gray-400 rounded-full opacity-80 "></div> -->
        </div>
        <div class="leading-normal w-full">
            <div class="flex items-center justify-between">
                <h2 class="text-brand-title font-bold text-[0.9rem]">
                    {{ match.partner.username ?? '-' }},
                    {{ match.partner.dob | dateToAge }}
                </h2>
                <div class="relative text-sm">
                    <p>
                        {{
                            (match.lastMessage
                                ? match.lastMessage.createdAt
                                : match.createdAt
                            ) | dateToChatTime
                        }}
                    </p>
                </div>
            </div>
            <div
                *ngIf="!newInstantMatch"
                class="whitespace-nowrap overflow-hidden flex items-center overflow-ellipsis content !max-w-[200px] !profile-subtitle"
            >
                <span
                    *ngIf="lastMessageFromRecipient && !read && !newMatch"
                    class="bg-brand-magenta rounded-full p-1 border-[3px] border-white"
                ></span>
                <img
                    *ngIf="lastMessageSentByMe"
                    class="inline pr-1"
                    src="../../../../assets/images/send_icon.png"
                    alt="chat_send"
                    style="height: 10px"
                />
                <span>
                    <p
                        class="!profile-subtitle"
                        [ngClass]="[read ? '!font-normal' : '!font-bold']"
                    >
                        {{ lastMessage }}
                    </p></span
                >
            </div>
            <div *ngIf="newMatch" class="flex items-center space-x-1">
                <div class="!max-w-[100px]">
                    <app-tag [theme]="'newMatch'">Neues match</app-tag>
                </div>
                <p
                    class="{{
                        match.new
                            ? '!profile-subtitle font-bold'
                            : '!profile-subtitle !font-normal'
                    }}"
                >
                    Jetzt schreiben...
                </p>
            </div>
            <div *ngIf="newInstantMatch" class="flex items-center space-x-2">
                <div class="!max-w-[100px]">
                    <app-tag [theme]="'instantMatch'">Sofort-Match</app-tag>
                </div>
                <p
                    class="{{
                        match.new
                            ? '!profile-subtitle font-bold'
                            : '!profile-subtitle !font-normal'
                    }}"
                >
                    Jetzt antworten
                </p>
            </div>
        </div>
    </a>
    <!-- <div class="flex justify-start"><p class="text-xs ml-4">{{ (match.lastMessage?match.lastMessage.createdAt : match.createdAt) | dateToChatTime }}</p></div> -->
</div>
