<div *ngIf="toBeCropped">
    <app-photo-cropper
        [imageToBeCropped]="toBeCropped"
        (croppedImage)="onCroppedImage($event)"
    ></app-photo-cropper>
</div>
<div *ngIf="image" class="relative h-full w-full">
    <img
        class="object-cover rounded-sm overflow-hidden h-full w-full"
        src="{{ image }}"
        alt="user_image"
    />
    <span
        *ngIf="profileText"
        class="bg-brand-magenta opacity-100 text-white text-xs px-4 rounded-full absolute top-2 right-2"
    >
        Profilbild</span
    >
    <input
        class="absolute top-0 h-full w-full bg-purple-400 appearance-none opacity-0"
        type="file"
        name="img"
        accept="image/jpeg,image/jpg,image/heic,image/heif"
        (change)="onFileChanged($event)"
    />
</div>
<div *ngIf="!image" class="h-full w-full relative">
    <div class="bg-white opacity-40 h-full w-full"></div>
    <div class="absolute flex flex-col h-full w-full top-1 px-1 opacity-100">
        <span
            *ngIf="profileText"
            class="bg-brand-magenta opacity-100 text-white text-xs px-4 rounded-full self-end"
        >
            Profilbild</span
        >
        <div class="flex items-center justify-center h-full w-full relative">
            <img
                class="h-12 w-12 bg-white rounded-full"
                src="../../../../../assets/images/auth/add_button.png"
                alt="add_button"
            />
            <input
                class="absolute h-14 w-14 opacity-0 bg-purple-400 appearance-none"
                type="file"
                name="img"
                accept="image/jpeg,image/jpg,image/heic,image/heif"
                (change)="onFileChanged($event)"
            />
        </div>
    </div>
</div>
