<div class="w-full h-full fixed bg-white z-50 top-0 left-0 overflow-scroll">
    <div
        class="m-4 text-white space-y-6 flex flex-col justify-between m-4 pt-11 sm:pt-0"
    >
        <div id="wrapper" class="!space-y-6">
            <div class="image-cropper">
                <div
                    id="img-container"
                    class="max-w-[300px] !h-full my-0 mx-auto"
                >
                    <div class="absolute w-full z-40"></div>
                </div>
            </div>

            <div class="flow-text">
                Wähle den gewünschten Bildausschnitt aus. Nutze dazu das
                Werkzeug zum Verschieben und Zoomen, um das Bild perfekt
                auszurichten.
            </div>
            <div>
                <app-link-button
                    [saveButton]="true"
                    (userClicked)="save()"
                    [theme]="'cyan'"
                    >Speichern</app-link-button
                >
            </div>
        </div>
    </div>
</div>
