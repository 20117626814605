import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { FacebookLoginProvider, SocialAuthService, SocialUser } from "angularx-social-login";
import { Observer } from "rxjs";
import Pending from "src/app/enums/pending";
import { AuthResponse } from "src/app/interfaces/api-responses";
import { ApiService, ThirdPartyAuthData } from "src/app/services/api.service";
import { AuthService } from "src/app/services/auth.service";
import {
    AuthRegisterDataCustomTabResponse,
    AuthType,
    WebToAppService,
} from "src/app/services/web-to-app.service";
import { environment } from "src/environments/environment";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const AppleID: any;

@Component({
    selector: "app-register",
    templateUrl: "./register.component.html",
})
export class RegisterComponent implements OnInit {
    emailSent = false;
    form: UntypedFormGroup = new UntypedFormGroup({
        email: new UntypedFormControl(null, [Validators.required, Validators.email]),
        password: new UntypedFormControl(null, [
            Validators.required,
            Validators.minLength(8),
            Validators.pattern(/([A-Z])/), // atleast one capital letter
            Validators.pattern(/[-+_!@#$%^&*.,?]/), // atleast one symbol
        ]),
    });

    passwordInputType = "password";
    togglePasswordVisibility() {
        this.passwordInputType = this.passwordInputType === "password" ? "text" : "password";
    }

    errorEmail!: boolean;
    public isClickInProgress = false;
    errorPassword!: boolean;
    errorMessage!: string;
    user: SocialUser;
    loggedIn: boolean;
    showForm = false;

    @Input() tiktokClickId?: string;

    /**
     * If the registerData property is set, the actual registration process is skipped. Instead only the UI changes are triggered using the data from the registerData property.
     */
    @Input() registerData!: AuthRegisterDataCustomTabResponse;
    /**
     * Emits an event that shows the loading animation on the parent component.
     */
    @Output() loading: EventEmitter<boolean> = new EventEmitter();

    @Output() hidePopup: EventEmitter<string> = new EventEmitter();
    @Output() toLogin: EventEmitter<string> = new EventEmitter();

    public googleButtonLoaded = false;

    constructor(
        private api: ApiService,
        private router: Router,
        private changeDetector: ChangeDetectorRef,
        private socialAuthService: SocialAuthService,
        private webToAppService: WebToAppService,
        private authService: AuthService
    ) {}

    signinWithFacebook(): void {
        this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
        this.socialAuthService.authState.subscribe((user) => {
            const authData: ThirdPartyAuthData = {
                authProvider: "FACEBOOK",
                token: user.authToken,
                tt_ref: this.tiktokClickId,
            };
            if (this.webToAppService.openedInCustomTab()) {
                this.webToAppService.openedInCustomTabRegisterFlow({
                    authData,
                    authProvider: "FACEBOOK",
                    authType: AuthType.REGISTER,
                });
            }
            this.api.auth(authData).subscribe(this.registerationHandlers(true));
            this.emailSent = false;
        });
    }

    ngOnInit(): void {
        if (this.registerData) {
            this.loading.emit(true);

            this.api
                .auth(this.registerData.authData)
                .subscribe(
                    this.registerationHandlers(this.registerData.authData.authProvider !== "LOCAL")
                );

            if (this.registerData.authProvider === "LOCAL") {
                this.isClickInProgress = false;
            } else {
                this.emailSent = false;
            }
        }

        this.form.get("email")?.valueChanges.subscribe(() => {
            this.errorMessage = "";
            this.errorEmail = this.email.hasError("required") || this.email.hasError("email");
        });

        this.form.get("password")?.valueChanges.subscribe(() => {
            this.errorMessage = "";
            this.errorPassword =
                this.password.hasError("required") ||
                this.password.hasError("minlength") ||
                this.password.hasError("pattern");
        });
        this.socialAuthService.authState.subscribe((user) => {
            this.user = user;
            this.loggedIn = user != null;
        });

        setTimeout(() => {
            this.api.setupThirdPartyButtons((token) => {
                const authData: ThirdPartyAuthData = {
                    authProvider: "GOOGLE",
                    token,
                    tt_ref: this.tiktokClickId,
                };
                if (this.webToAppService.openedInCustomTab()) {
                    this.webToAppService.openedInCustomTabRegisterFlow({
                        authData,
                        authProvider: "GOOGLE",
                        authType: AuthType.REGISTER,
                    });
                }
                this.api.auth(authData).subscribe(this.registerationHandlers(true));
                this.emailSent = false;
            }, true);
            this.googleButtonLoaded = true;
        }, 500);
    }

    private registerationHandlers(
        skipVerification = false,
        additionalNextHandler?: (response: AuthResponse) => void
    ): Partial<Observer<any>> {
        return {
            next: (response: AuthResponse) => {
                this.registrationFlow(response, skipVerification, additionalNextHandler);
            },
            error: (response) => {
                this.errorMessage = response.error.message;
            },
        };
    }

    /**
     * this function basically triggers all the UI changes that are done after a user registers
     * it does not handle the actual registration process
     * @param response
     * @param skipVerification
     * @param additionalNextHandler
     */
    private registrationFlow(
        response: AuthResponse,
        skipVerification = false,
        additionalNextHandler?: (response: AuthResponse) => void
    ) {
        if (additionalNextHandler) {
            additionalNextHandler(response);
        }

        if (response.accessToken) {
            localStorage.setItem("register_completed", "FALSE");
        }
        if (!skipVerification) {
            this.loading.emit(false);
            this.openEmailSentScreen();
            this.changeDetector.detectChanges();
        }
        if (skipVerification) {
            //is only relevant if user register through 3rd provider
            if (!response.pending.includes(Pending.completeProfile)) {
                localStorage.setItem("register_completed", "TRUE");
                this.authService.navigateToDiscoverPageAfterLoginRegister();
            } else {
                this.router.navigate(["/register/step"]);
            }
        }
    }

    updateEmail() {
        this.form.patchValue({
            email: this.email.value.charAt(0).toLowerCase() + this.email.value.slice(1),
        });
    }

    register() {
        this.isClickInProgress = true;
        this.errorMessage = "";
        this.loading.emit(true);

        const authData = Object.assign(
            {},
            {
                authProvider: "LOCAL",
                register: true,
                tt_ref: this.tiktokClickId,
            },
            this.form.value
        );
        this.api.auth(authData).subscribe(
            this.registerationHandlers(undefined, () => {
                if (this.webToAppService.openedInCustomTab()) {
                    // pretend that user used login function
                    this.webToAppService.openedInCustomTabLoginFlow({
                        authData: { ...authData, register: false },
                        authType: AuthType.LOGIN,
                    });
                }
            })
        );
        this.isClickInProgress = false;
    }

    get email(): UntypedFormControl {
        return this.form.get("email") as UntypedFormControl;
    }

    get password(): UntypedFormControl {
        return this.form.get("password") as UntypedFormControl;
    }

    /**
     * Emit event to hide the popup window for desktop view
     */
    public closePopup() {
        if (this.webToAppService.openedInCustomTab()) {
            this.webToAppService.closeCustomTab({
                error: true,
                message: "Register form closed manually by user.",
            });
            return;
        }

        this.hidePopup.emit();
    }

    /**
     * Emit event to go to the login popup window for desktop view
     */
    public goToLogin() {
        this.toLogin.emit();
    }

    public closeEmailSentScreen() {
        this.emailSent = false;
    }

    /**
     * Opens the email sent screen
     */
    public openEmailSentScreen(): void {
        this.emailSent = true;
    }

    async signInWithApple() {
        try {
            AppleID.auth.init({
                clientId: environment.apple.auth.clientId,
                scope: "name email",
                redirectURI: environment.apple.auth.redirectUri,
                state: "init",
                nonce: "test",
                usePopup: true, //or false defaults to false
            });
            const data = await AppleID.auth.signIn();
            const authData: ThirdPartyAuthData = {
                authProvider: "APPLE",
                token: data.authorization.id_token,
                tt_ref: this.tiktokClickId,
            };
            if (this.webToAppService.openedInCustomTab()) {
                this.webToAppService.openedInCustomTabRegisterFlow({
                    authData,
                    authProvider: "APPLE",
                    authType: AuthType.REGISTER,
                });
            }
            this.api.auth(authData).subscribe(this.registerationHandlers(true));
            this.emailSent = false;
        } catch (error) {
            console.error("Failed to register with Apple", error);
        }
    }
}
