import { Component } from "@angular/core";
import { ContactEmailService } from "src/app/services/contact-email.service";

@Component({
    selector: "app-help-content",
    templateUrl: "./help-content.component.html",
})
export class HelpContentComponent {
    faqList = [
        {
            question: "Was sind Credits?",
            answer: "Credits sind eine Art Währung auf amolino.de und werden benötigt, um mit anderen Nutzern und Nutzerinnen chatten zu können.",
        },
        {
            question: "Wie kann ich Credits Kaufen?",
            answer: 'Du kannst unsere Credits im unserem Shop erwerben, indem Du auf den Reiter "Shop" klickst und das gewünschte Credit-Paket auswählst. Jede Zahlung bei uns ist eine Einmalzahlung - Wir buchen also ohne Deine Zustimmung zu keinem Zeitpunkt etwas ab.',
        },
        {
            question: "Wofür sind Schlüssel?",
            answer: "Schlüssel sind auf Amolino.de eine Art Währung, mit der Du exklusive Inhalte von anderen Nutzerinnen und Nutzern freischalten kannst. Dazu gehören unter anderem Profile, Matches und Fotos.",
        },
        {
            question: "Wie kann ich Schlüssel kaufen?",
            answer: 'Du kannst unsere Schlüssel im unserem Shop erwerben, indem Du auf den Reiter "Shop" klickst und das gewünschte Schlüssel-Paket auswählst. Jede Zahlung bei uns ist eine Einmalzahlung - Wir buchen also ohne Deine Zustimmung zu keinem Zeitpunkt etwas ab.',
        },
        {
            question: "Bekomme ich Schlüssel / Credits auch gratis?",
            answer: "Ja, alle Nutzerinnen und Nutzer auf amolino.de erhalten einen Login-Bonus. Dieser Bonus beinhaltet kostenlose Schlüssel und Credits, die bei der Nutzung der Plattform eingesetzt werden können.",
        },
        {
            question: "Wie funktioniert der Login-Bonus?",
            answer: "Der Login-Bonus ist ein Treueprogramm für unsere Nutzerinnen und Nutzer auf amolino.de. Durch tägliches Einloggen erhältst Du gratis Schlüssel und Credits. Es gibt insgesamt 7 Bonusstufen, die gestaffelt sind und Dir besondere Vorteile bieten. Um die nächste Stufe zu erreichen, musst Du Dich einfach jeden Tag mindestens einmal einloggen. Bei Erreichen der 7. Stufe erhältst Du den höchsten Bonus, danach startet der Bonus wieder bei Stufe 1.",
        },
        {
            question: "Welche Zahlungsarten gibt es?",
            answer: "Wir bieten folgende Zahlungsmöglichkeiten an:  Vorkasse / Überweisung, PayPal, Google Pay, Apple Pay, Klarna, Giropay und Kreditkarte.",
        },
        {
            question: "Warum erhalte ich mit PayPal / Vorkasse  einen Bonus?",
            answer: "Bei diesen Zahlungsanbietern fallen die niedrigsten Transaktionsgebühren an. Diese geringere Belastung geben wir an Dich weiter und schütten deshalb höhere Credits / Schlüssel Beträge aus.",
        },
        {
            question: "Wie funktioniert die Vorkasse-Zahlung?",
            answer: "Bei der Vorkasse-Zahlung handelt es sich um eine klassische Überweisung, die Du selbst von Deinem Online-Banking oder bei Deiner Hausbank an uns tätigst. Bitte beachte, dass bei dieser Zahlungsart die Credits/Schlüssel nicht sofort ausgeschüttet werden. Erst wenn der von Dir überwiesene Betrag auf unserem Bankkonto eingegangen ist, schütten wir die Credits/Schlüssel an Dich aus. In der Regel dauert dies 1-2 Werktage.",
        },
        {
            question: "Ich habe einen falschen Verwendungszweck angegeben",
            answer:
                'Falls Du bei der Vorkasse-Zahlung einen falschen Verwendungszweck angegeben hast, solltest Du umgehend unseren Kundenservice kontaktieren. Du erreichst uns unter <a class="underline text-brand-cyan" href="' +
                this.contactEmailService.getContactEmailHref("Allgemeiner Support") +
                '">support@amolino.de.</a>',
        },
        {
            question: "Warum sind einige Fotos nur bei einer Freischaltung zu sehen?",
            answer: 'Durch das Freischalten von Fotos können Nutzerinnen und Nutzer ihr ernsthaftes Interesse untermauern und anderen Nutzern zeigen, dass sie an einem Kontakt interessiert sind. Gleichzeitig ermöglicht das gezielte Freischalten von sensiblen Inhalten, wie beispielsweise Fotos, nur bestimmten Nutzern deren Betrachtung, um die Privatsphäre und Sicherheit zu gewährleisten. Abhängig von der Intimität des dargestellten Inhalts auf den Fotos, können Nutzerinnen und Nutzer selbst entscheiden, auf welcher Stufe das Foto gesperrt wird. Hierfür stehen drei Auswahlmöglichkeiten zur Verfügung: "Private" (private Inhalte), "Exclusive" (intimere Inhalte) und "Intimate" (sehr intime Inhalte).',
        },
        {
            question: "Wie kann ich eigene Fotos sperren?",
            answer: 'Nachdem Du ein Foto hochgeladen hast, hast Du die Möglichkeit, es mit einem der drei Sperrstufen ("Private", "Exclusive" und "Intimate") zu schützen. Dabei müssen sensible und intime Inhalte stets gesperrt werden. Du kannst auch bereits hochgeladene Fotos in Deiner Galerie bearbeiten, indem Du einfach auf das entsprechende Foto klickst.',
        },
        {
            question: "Warum muss ich Inhalte freischalten?",
            answer: "Das amolino-Prinzip beruht darauf, die anderen Nutzerinnen und Nutzer zu schützen. Daher sind bestimmte Inhalte, wie beispielsweise Fotos mit sensiblen Inhalten, nicht für jeden öffentlich zugänglich. Außerdem ist es uns wichtig, insbesondere weibliche Nutzerinnen vor einer Flut von Nachrichten zu schützen. Durch das Freischalten von Inhalten können Nutzer ihr ernstes Interesse untermauern und anderen Nutzern zeigen, dass sie wirklich an einem Kontakt interessiert sind. Gleichzeitig können sensible Inhalte, wie z.B. Fotos, gezielter und nur für bestimmte Nutzer sichtbar gemacht werden, um die Privatsphäre und Sicherheit zu gewährleisten.",
        },
        {
            question: "Wie melde ich ein Profil?",
            answer: 'Auf der Profilseite einer Nutzerin oder eines Nutzers findest Du am unteren Ende die Option "Dieses Profil melden". Wenn Du darauf klickst, öffnet sich Dein E-Mail Programm und Du kannst uns genau mitteilen, warum Du eine Nutzerin oder einen Nutzer melden möchtest.',
        },
        {
            question: "Was ist ein Match?",
            answer: 'Ein Match ist der Zustand, den Du und ein anderer Nutzer oder eine andere Nutzerin habt, wenn Ihr Euch unter "Entdecken" gegenseitig mit einem "Like" markiert habt. Durch das Match könnt Ihr gegenseitig Eure Profile aufrufen und miteinander chatten.',
        },
        {
            question: "Wie bekomme ich ein Match?",
            answer: 'Um So Mehr Nutzerinnen und Nutzer Du mit einem Like unter dem Menüpunkt "Entdecken" markierst, umso höher ist die Wahrscheinlichkeit, dass jemand dabei ist, der Dich auch mag. Natürlich solltest Du nicht wahllos Likes verteilen, sondern nur an Nutzerinnen und Nutzer, die Dir auch wirkliche gefallen.',
        },
        {
            question: "Was ist ein Sofort-Match?",
            answer: 'Die "Sofort-Match" Funktion ist ein kostenpflichtiges Feature, bei dem Du sofort ein Match mit einer Nutzerin oder einem Nutzer Deiner Wahl herstellen kannst, ohne dass diese Person zustimmen muss. Zusätzlich musst Du bei diesem Feature direkt eine erste Nachricht mitsenden. Bitte beachte, dass es keine Garantie dafür gibt, dass die Nutzerin oder der Nutzer auf Deine Nachricht antwortet oder das Match aufrechterhält.',
        },
        {
            question: "Wie kann ich ein Match auflösen?",
            answer: 'Auf der Profilseite einer Nutzerin oder eines Nutzers findest Du am unteren Ende die Option "Match auflösen". Wenn Du darauf klickst, wird das bestehende Match zwischen Dir und der Person aufgehoben. Bitte beachte, dass dabei auch die Chats zwischen euch gelöscht werden und Du keinen Zugriff mehr auf die Profilansicht der Nutzerin oder des Nutzers hast',
        },
        {
            question: "Ich kann mich nicht mehr einloggen, was kann ich tun?",
            answer:
                'Wenn Du Dich nicht mehr einloggen kannst, hast Du die Möglichkeit, unser Passwort-Zurücksetzen-Feature zu nutzen. Klicke dafür auf "Einloggen", dann auf "Einloggen mit E-Mail" und anschließend auf "Passwort zurücksetzen". Wenn Du jedoch Probleme beim Einloggen mit einem Drittanbieter wie Apple, Facebook oder G-Mail angemeldet hast, solltest Du stattdessen unseren Kundenservice unter <a class="underline text-brand-cyan" href="' +
                this.contactEmailService.getContactEmailHref("Allgemeiner Support") +
                '">support@amolino.de.</a> kontaktieren.',
        },
        {
            question: "Wie lösche ich meine Konto?",
            answer: 'Klicke oben rechts in der Menüleiste auf Dein eigenes Profilbild und anschließend auf "Kontoeinstellungen". Scrolle nun ganz nach unten. Dort findest Du den Link "Konto löschen".',
        },
        {
            question: "Wie kann ich mein Konto wieder aktivieren?",
            answer: "Wenn Du Dein Konto gelöscht hast, ist es für andere Nutzerinnen und Nutzer nicht mehr sichtbar, bleibt aber noch bis zu 180 Tage in unserem System gespeichert. Wenn Du Dein Konto innerhalb dieser Zeit wieder nutzen möchtest, kannst Du es einfach durch erneutes Einloggen reaktivieren. Bitte beachte jedoch, dass dadurch der Löschvorgang abgebrochen wird.",
        },
        // add more question-answer pairs here
    ];

    constructor(public contactEmailService: ContactEmailService) {}

    toTop() {
        const topElement = document.getElementById("top");
        if (topElement) topElement.scrollIntoView({ behavior: "smooth" });
    }
}
