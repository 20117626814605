<div [class]="class" class="h-full w-full flex flex-col">
    <!-- Question -->
    <h1
        class="text-[1.7rem] font-bold mb-7 md:mb-5 text-white"
        [class]="centered ? 'text-center' : ''"
    >
        {{ title }}
    </h1>
    <!-- Content -->
    <div class="flex-grow">
        <ng-content></ng-content>
    </div>
</div>
