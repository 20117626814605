import { ActionReducer, ActionReducerMap, MetaReducer } from "@ngrx/store";
import { localStorageSync } from "ngrx-store-localstorage";
import { AppState } from "./app.state";
import { meDataReducer } from "./me-data-store";
import { membersMeReducer } from "./members-me.store";

const localStorageSyncConfig = {
    keys: ["meData", "membersMe"],
    storageKeySerializer: (key) => `ngrx_store_${key}`,
    rehydrate: true,
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync(localStorageSyncConfig)(reducer);
}
export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

export const reducers: ActionReducerMap<AppState> = {
    meData: meDataReducer,
    membersMe: membersMeReducer,
    //other reducer
};
