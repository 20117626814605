import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BuyLikesAction } from "src/app/enums/shop";
import { ApiService } from "src/app/services/api.service";

@Component({
    selector: "app-discover-limit",
    templateUrl: "./discover-limit.component.html",
    animations: [
        trigger("simpleFadeAnimation", [
            state("in", style({ opacity: 1 })),
            transition(":enter", [style({ opacity: 0 }), animate(300)]),
            transition(":leave", animate(300, style({ opacity: 0 }))),
        ]),
    ],
})
export class DiscoverLimitComponent {
    @Input() fee: number;

    @Output() manuallyClosed: EventEmitter<void> = new EventEmitter();
    @Output() boughtNewLikes: EventEmitter<void> = new EventEmitter();

    public showAlternativeKeyShop = false;

    constructor(private api: ApiService) {}

    public close() {
        this.manuallyClosed.emit();
    }

    public buyMore() {
        this.api
            .me()
            .buyLikes(BuyLikesAction.BUY_LIKES)
            .subscribe({
                next: () => {
                    this.boughtNewLikes.emit();
                },
                error: () => {
                    this.showAlternativeKeyShop = true;
                },
            });
    }
}
