import {
    AnimationEvent,
    animate,
    keyframes,
    state,
    style,
    transition,
    trigger,
} from "@angular/animations";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-match",
    templateUrl: "./match.component.html",

    animations: [
        trigger("bounce", [
            state(
                "show",
                style({
                    transform: "translateY(0)",
                })
            ),
            state(
                "hide",
                style({
                    transform: "translateY(100%)",
                })
            ),
            transition("void => *", [
                // transition('hide => show', [
                animate(
                    "0.8s ease-in",
                    keyframes([
                        style({ transform: "translateY(400px)" }),
                        style({ transform: "translateY(0)" }),
                        style({ transform: "translateY(150px)" }),
                        style({ transform: "translateY(0)" }),
                        style({ transform: "translateY(50px)" }),
                        style({ transform: "translateY(0)" }),
                        // style({position: 'sticky'}),
                    ])
                ),
            ]),
            transition("* => void", [
                animate(
                    "0.8s ease-out",
                    keyframes([
                        // style({position: 'absolute'}),
                        style({ transform: "translateY(100%)" }),
                        style({ transform: "translateY(150px)" }),
                        style({ transform: "translateY(100%)" }),
                        style({ transform: "translateY(400px)" }),
                        style({ transform: "translateY(100%)" }),
                    ])
                ),
            ]),
            transition("hide => show", animate("1000ms ease-in")),
        ]),
    ],
})
export class MatchComponent implements OnInit {
    @Input() public position!: string;
    @Output() closeMatch: EventEmitter<string> = new EventEmitter();
    @Output() closeAnimationEnd: EventEmitter<void> = new EventEmitter();
    @Input() src?: string;
    @Input() h1?: string;
    @Input() h2?: string;
    @Input() color?: string;
    @Input() info?: string;
    @Input() bg?: string;
    @Input() buttonTheme?: string = "purple";
    public value = "";
    @Input() show = true;
    @Input() matchData: any;
    public imageBaseUrl: string;

    get stateName() {
        return this.show ? "show" : "hide";
    }

    ngOnInit(): void {
        this.imageBaseUrl = environment.url.image;
    }

    onAnimationEvent(event: AnimationEvent) {
        if (event.phaseName === "done" && event.toState === "void" && this.closeAnimationEnd) {
            this.closeAnimationEnd.emit();
        }
    }

    public closeView(): void {
        this.show = !this.show;
        this.closeMatch.emit();
    }
}
