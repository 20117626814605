import { Component, Input } from "@angular/core";

@Component({
    selector: "app-menu-item",
    templateUrl: "./menu-item.component.html",
})
export class MenuItemComponent {
    @Input() activePath = false;
    @Input() displayNotification = false;
    @Input() link!: string;
}
