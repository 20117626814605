<div
    [ngClass]="background"
    class="bg-gradient-to-t text-white bg-cover bg-no-repeat h-full w-full fixed md:absolute top-0 z-10"
>
    <div class="h-full flex flex-col items-center p-6 overflow-hidden">
        <div (click)="closeModal()" class="self-end">
            <app-close [closeColor]="'white'"></app-close>
        </div>
        <img
            class="h-14 w-14 my-8"
            src="../../../../assets/images/{{ icon }}.png"
            alt="{{ icon }}"
        />
        <p class="heading-1">
            <strong class="">Fototyp:</strong><span> {{ type }}</span>
        </p>
        <div class="mt-10 flow-text-logged-in">
            <p class="text-center">{{ info }}</p>
            <app-error-insufficient-balance
                *ngIf="error"
            ></app-error-insufficient-balance>
        </div>
        <div class="my-16 w-full">
            <app-link-button
                [isDisabled]="isClickInProgress"
                [theme]="
                    type !== 'intimate' && type !== 'special'
                        ? 'purple'
                        : 'cyan'
                "
                (click)="unlockPhoto()"
            >
                Foto Freischalten
            </app-link-button>
        </div>
        <div class="grid justify-items-center">
            <p>Die Freischaltung kostet:</p>
            <div class="text-xs flex items-center space-x-2 mt-2">
                <img
                    class="h-4 w-4"
                    src="../../../../assets/images/price_white.png"
                    alt="price_white"
                />
                <p class="font-semibold">{{ price }}x Schlüssel</p>
            </div>
        </div>
    </div>
</div>
