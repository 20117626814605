import { Pipe, PipeTransform } from "@angular/core";
import { DateStringMethodsService } from "src/app/services/date-string-methods.service";

@Pipe({
    name: "dateToLikedAtTime",
})
export class DateToLikedAtTimePipe implements PipeTransform {
    constructor(private dateStringMethods: DateStringMethodsService) {}
    transform(value: string): string {
        const date = new Date(value);
        let result: string;
        if (this.dateStringMethods.isToday(value)) {
            result = "von heute";
        } else if (this.dateStringMethods.isYesterday(value)) {
            result = "von gestern";
        } else {
            result =
                "vom " +
                this.dateStringMethods.getFullDate(date) +
                " um " +
                this.dateStringMethods.getTime(date);
        }

        return result;
    }
}
