<div
    class="flex items-center justify-between space-x-2 pr-4 cursor-pointer"
    (click)="clickedItem()"
>
    <p
        class="{{
            selected ? 'text-brand-cyan font-bold' : ' '
        }} hover:bg-brand-body hover:bg-opacity-5 font-semibold rounded-full py-1 pl-4 w-full select-none"
    >
        {{ title }}
    </p>
    <img
        *ngIf="selected"
        class="h-3 w-3"
        src="../../../../assets/images/checkmark_magenta.png"
        alt="checkmark_magenta"
    />
</div>
