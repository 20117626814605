<div
    class="h-full w-full md:rounded-lg flex flex-col  {{ classProps }}"
    [ngClass]="[theme === 'dark' ? 'bg-brand-title' : 'bg-white']"
>
    <div class="pt-8 flex justify-center">
        <div *ngIf="image" class="relative">
            <div class="{{ imageWrapperProps }} relative">
                <img class="{{ imageProps }}" src="{{ image }}" />
            </div>
        </div>
    </div>
    <h1
        class="heading-1 pt-4 text-center"
        [ngClass]="[theme === 'dark' ? 'text-white' : 'text-brand-title']"
    >
        {{ h1 }}
    </h1>
    <div
        class="flex flex-col justify-between h-full w-full"
        [ngClass]="[
            theme === 'dark'
                ? 'bg-brand-title text-white'
                : 'bg-white text-brand-body'
        ]"
    >
        <div
            class="flex justify-center pt-8 text-center"
            [ngClass]="[
                startPageModal
                    ? ' text-[0.8rem] leading-6 font-normal'
                    : 'flow-text-logged-in'
            ]"
        >
            <ng-content select="[subtitle]"></ng-content>
        </div>
        <div class="pt-8">
            <ng-content select="[content]"></ng-content>
        </div>
        <div
            *ngIf="customFooter"
            class="grid justify-items-center w-full gap-y-1 my-8 py-8"
        >
            <a
                class="modal-link cursor-pointer"
                [ngClass]="[
                    theme === 'dark' ? '!text-white' : '!text-brand-body'
                ]"
                (click)="emitFooterClicked()"
                >{{ footerLinkText }}</a
            >
        </div>
    </div>
</div>
