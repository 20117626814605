<div class="h-full w-full grid items-center bg-white/20 select-none">
    <div class="w-full flex flex-col items-center px-4">
        <img
            class="h-14 w-14"
            src="../../../../assets/images/{{ icon }}.png"
            alt="{{ icon }}"
        />
        <p class="heading-1 mt-6 mb-10 text-white">
            <strong class="">Fototyp:</strong
            ><span class="font-normal"> {{ type.toLowerCase() }}</span>
        </p>
        <div class="w-full">
            <app-link-button
                [theme]="type.toLowerCase() !== 'intimate' ? 'purple' : 'cyan'"
                (click)="goForward()"
            >
                Freischalten
            </app-link-button>
        </div>
    </div>
</div>
