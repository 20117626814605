<app-modal
    [topPadding]="'4'"
    (closeMatch)="hideModal()"
    class="absolute md:top-0 z-50"
    [h1]="'Nicht genug Schlüssel'"
    [image]="'../../../../assets/images/emoji.png'"
    [imageProps]="'h-24'"
>
    <div subtitle>
        Leider hast Du nicht genügend Schlüssel, um das gewünschte Feature zu
        nutzen. Du kannst weitere Schlüssel in unserem Shop erwerben.
    </div>
    <div content>
        <div *ngFor="let offer of offers" class="mb-4">
            <app-offer-item [product]="offer"></app-offer-item>
        </div>
        <div class="pb-6">
            <app-link-button (userClicked)="goToShop()" [theme]="'purple'"
                >Zum Shop</app-link-button
            >
        </div>
    </div>
</app-modal>
