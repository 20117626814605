<div
    class="h-full w-full absolute top-0 left-0 z-30 md:flex md:items-center md:justify-center bg-white md:bg-gray-400/80"
>
    <div
        class="h-full w-full md:h-[650px] md:w-[400px] bg-white md:rounded-xl py-4 px-6"
    >
        <form
            [formGroup]="form"
            (ngSubmit)="resetPassword()"
            class="h-full flex flex-col items-center justify-evenly text-brand-body"
        >
            <h1 class="text-3xl font-semibold text-brand-title">
                Passwort ändern
            </h1>
            <p class="leading-6 text-center text-sm">
                Das Passwort muss mindestens 8 Zeichen, einen Großbuchstaben,
                eine Zahl und ein Sonderzeichen enthalten.
            </p>
            <div class="w-full">
                <app-input-box
                    [label]="'Passwort'"
                    class="mb-3"
                    (eyeClicked)="showPassword = !showPassword"
                    [error]="!password.valid"
                >
                    <input
                        class="w-full focus:outline-none placeholder:text-xs placeholder:opacity-50"
                        placeholder="Neues Passwort eingeben"
                        type="{{ showPassword ? 'text' : 'password' }}"
                        formControlName="password"
                    />
                    <p
                        error
                        *ngIf="
                            password.touched && password.hasError('required')
                        "
                    >
                        Bitte füllen Sie dieses Feld aus.
                    </p>
                    <p
                        error
                        *ngIf="password.touched && password.hasError('pattern')"
                    >
                        Das Passwort muss mindestens 8 Zeichen, einen
                        Großbuchstaben, eine Zahl und ein Sonderzeichen
                        enthalten.
                    </p>
                </app-input-box>

                <app-input-box
                    [label]="'Passwort wiederholen'"
                    (eyeClicked)="showRepeatPassword = !showRepeatPassword"
                    [error]="!confirmPassword.valid"
                >
                    <input
                        class="w-full focus:outline-none placeholder:text-xs placeholder:opacity-50"
                        type="{{ showRepeatPassword ? 'text' : 'password' }}"
                        placeholder="Neues Passwort wiederholen"
                        formControlName="confirmPassword"
                    />
                    <p
                        error
                        *ngIf="
                            confirmPassword.touched &&
                            confirmPassword.hasError('required')
                        "
                    >
                        Bitte füllen Sie dieses Feld aus.
                    </p>
                    <p
                        error
                        *ngIf="
                            confirmPassword.touched &&
                            confirmPassword.hasError('pattern')
                        "
                    >
                        Das Passwort muss mindestens 8 Zeichen, einen
                        Großbuchstaben, eine Zahl und ein Sonderzeichen
                        enthalten.
                    </p>
                    <p error *ngIf="matchError">
                        Das gewählte Passwort stimmt nicht überein.
                    </p>
                </app-input-box>
            </div>
            <p
                class="text-center text-sm font-semibold text-brand-danger"
                *ngIf="error"
            >
                {{ error }}
            </p>
            <button
                *ngIf="!success"
                [disabled]="!form.valid"
                class="{{
                    !form.valid
                        ? 'bg-brand-body opacity-30'
                        : 'bg-brand-magenta'
                }} py-2 text-center rounded-full w-80 ring-4 ring-white ring-opacity-40 hover:ring-opacity-0 text-white text-lg font-semibold"
            >
                Passwort ändern
            </button>
            <p *ngIf="success" class="text-brand-green text-sm text-center">
                Dein Passwort wurde erfolgreich gespeichert
            </p>
            <a
                *ngIf="success"
                class="link font-medium"
                [routerLink]="'/'"
                [queryParams]="{ open: 'login' }"
                >Jetzt einloggen</a
            >
            <app-support-footer
                class="w-full sticky bottom-0"
            ></app-support-footer>
        </form>
    </div>
</div>
