<div [@fadeIn] class="absolute w-full bottom-0 z-10">
    <div
        class="bg-cover -mb-5 pb-6"
        style="
            background-image: url(&quot;../../../../assets/images/entdecken_foto_fade.png&quot;);
        "
    >
        <h1 class="text-2xl font-bold drop-shadow-md text-white px-4">
            {{ member?.username ?? "-" }}, {{ member?.dob ?? "-" | dateToAge }}
        </h1>
    </div>
    <app-modal
        classProps="rounded-t-2xl"
        [sectionTopPaddingLarge]="'2'"
        (closeMatch)="hideModal()"
        [sectionTopPaddingLarge]="'1'"
        [desktopHeight]="'200'"
        [topPadding]="'6'"
        [image]="'../../../../assets/images/instantmatch_mdoal.png'"
        [keys]="fees?.instantMatch"
        [h1]="'Sofort-Match'"
    >
        <div subtitle>
            Du möchtest sofort in Kontakt mit
            <strong>{{ member?.username ?? "-" }}</strong> treten und eine Nachricht
            schreiben? Dann stelle jetzt ohne Wartezeit ein Match her.
            <!-- <app-error-insufficient-balance *ngIf="showInsufficientCreditsError"></app-error-insufficient-balance> -->
        </div>
        <div content>
            <form
                [formGroup]="form"
                (ngSubmit)="
                    submitInstantMatchMessage(member?._id, 'INSTANTMATCH')
                "
                class="w-full"
            >
                <div class="flex text-brand-title items-center justify-between">
                    <label class="label">Deine Nachricht</label>
                    <p class="text-xs">
                        {{ message.value?.length ?? 0 }}/160 Zeichen
                    </p>
                </div>
                <app-input-field [error]="!form.valid">
                    <textarea
                        rows="2"
                        class="w-full input placeholder:input-placeholder focus:outline-none resize-none text-brand-title"
                        placeholder="Schreibe etwas nettes"
                        formControlName="message"
                    ></textarea>
                    <p
                        error
                        *ngIf="message.touched && message.hasError('required')"
                    >
                        Die Eingabe ist erforderlich
                    </p>
                    <p
                        error
                        *ngIf="message.touched && message.hasError('maxlength')"
                    >
                        Maximal 160 Zeichen
                    </p>
                </app-input-field>
                <div class="w-full">
                    <app-link-button
                        [loggedIn]="false"
                        *ngIf="form.valid"
                        (userClicked)="
                            submitInstantMatchMessage(
                                member?._id,
                                'INSTANTMATCH'
                            )
                        "
                        [theme]="'purple'"
                        >Match herstellen</app-link-button
                    >
                    <app-link-button
                        [loggedIn]="false"
                        *ngIf="!form.valid"
                        [theme]="'disable'"
                        >Match herstellen</app-link-button
                    >
                </div>
                <input type="submit" hidden />
            </form>
        </div>
    </app-modal>
</div>
