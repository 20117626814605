<div
    class=""
    *ngIf="newUpload; else imageContent"
    class="flex flex-col space-y-4 items-center justify-center rounded-md !text-[9px]"
    id="dash"
></div>
<ng-template #imageContent>
    <div class="relative" *ngIf="image.image; else emptyContent">
        <div class="overflow-hidden h-52">
            <img
                id="img-{{ image.id }}"
                (click)="UploadPhoto()"
                class="cursor-pointer object-cover align-middle min-w-full min-h-full"
                src="{{ imgSrc | addImageBaseUrl }}"
            />
        </div>
        <div
            *ngIf="image.profileImage"
            class="absolute bottom-0 w-full text-center bg-brand-magenta py-1 !text-white"
        >
            <div class="text-xs">Profilbild</div>
        </div>
        <div
            *ngIf="!image.profileImage && image.type !== 'PUBLIC'"
            class="absolute top-1 right-1"
        >
            <div>
                <img
                    class="h-8 self-start"
                    src="../../../../assets/images/lock_gallery.png"
                    alt="unlock_photo"
                />
            </div>
        </div>
    </div>
</ng-template>
<ng-template #emptyContent>
    <div
        class="rounded-md h-1/5 border-[1pt] border-dashed border-brand-body/40"
    ></div>
</ng-template>
