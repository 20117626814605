<div *ngIf="isAuthenticated !== null">
    <app-main-layout
        *ngIf="isAuthenticated"
        [showProfile]="true"
        [leftMenuText]="'AGB'"
    >
        <div class="px-4 pt-16 md:pt-0">
            <app-terms-content></app-terms-content>
        </div>
    </app-main-layout>
    <app-public-wrapper-layout
        [verticalPadding]="'0'"
        [horizontalPadding]="'0'"
        *ngIf="!isAuthenticated"
        [type]="'cyan'"
    >
        <!-- <a class="link pb-4" href="/">Zurück</a> -->
        <app-public-profile-content></app-public-profile-content>
    </app-public-wrapper-layout>
</div>
