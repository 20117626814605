import { Component, Input } from "@angular/core";

@Component({
    selector: "app-user-interests",
    templateUrl: "./user-interests.component.html",
})
export class UserInterestsComponent {
    @Input() public commonTags: any;
    @Input() public tags: any;
}
