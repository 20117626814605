import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from "@angular/core";

@Component({
    selector: "app-link-button",
    templateUrl: "./link-button.component.html",
})
export class LinkButtonComponent implements OnInit, OnChanges {
    @Input() theme:
        | "white"
        | "purple"
        | "red"
        | "green"
        | "hero"
        | "pink"
        | "cyan"
        | "danger"
        | "disable"
        | "shrink"
        | "login" = "purple";

    @Input() text = "";
    @Input() path!: string;
    @Input() name!: string;
    @Input() buttonWidth = "full";
    @Input() classProps!: string;
    @Input() saveText = "Speichern";
    @Input() savedText = "Gespeichert";
    selectedTheme = "";
    @Input() loggedIn = true;
    @Input() isDisabled = false;
    @Input() saveButton = false;
    @Input() error = false;
    @Input() errorMessage = "error";
    @Input() loading = false;

    @Output() public userClicked: EventEmitter<MouseEvent> = new EventEmitter();

    ngOnInit(): void {
        if (this.saveButton && this.text === "") {
            this.text = this.saveText;
        }

        this.setTheme();
    }

    @Input() success = false;

    ngOnChanges(changes: SimpleChanges) {
        if (changes["isDisabled"]) {
            this.isDisabled = changes["isDisabled"].currentValue;

            this.setTheme();
        }
        if (changes["loading"]) {
            this.loading = changes["loading"].currentValue;
        }
        if (changes["success"]) {
            this.success = changes["success"].currentValue;

            if (this.success) {
                this.text = this.savedText;
                this.loading = false;

                this.success = true;
                this.selectedTheme = "brand-green";

                setTimeout(() => {
                    this.success = false;
                    this.text = this.saveText;
                    this.selectedTheme = this.theme;
                    this.setTheme();
                }, 2000);
            }
        }
        if (changes["error"]) {
            this.error = changes["error"].currentValue;
            if (this.error) {
                this.text = this.saveText;
            }
        }
    }

    setTheme() {
        const whiteTheme = "text-brand-cyan";
        const redTheme = "red-500";
        const purpleTheme = "brand-magenta";
        const shrinkTheme = "brand-magenta";
        const greenTheme = "brand-green";
        const heroTheme = "brand-magenta";
        const pinkTheme = "brand-danger";
        const cyanTheme = "brand-cyan";
        const brandDangerTheme = "brand-danger";
        const disabledTheme = "brand-body bg-opacity-20";
        if (this.isDisabled) {
            this.selectedTheme = disabledTheme;
        } else {
            if (this.theme === "white") {
                this.selectedTheme = whiteTheme;
            } else if (this.theme === "purple") {
                this.selectedTheme = purpleTheme;
            } else if (this.theme === "red") {
                this.selectedTheme = redTheme;
            } else if (this.theme === "green") {
                this.selectedTheme = greenTheme;
            } else if (this.theme === "hero") {
                this.selectedTheme = heroTheme;
            } else if (this.theme === "pink") {
                this.selectedTheme = pinkTheme;
            } else if (this.theme === "cyan") {
                this.selectedTheme = cyanTheme;
            } else if (this.theme === "danger") {
                this.selectedTheme = brandDangerTheme;
            } else if (this.theme === "disable") {
                this.selectedTheme = disabledTheme;
            } else if (this.theme === "shrink") {
                this.selectedTheme = shrinkTheme;
            } else if (this.theme === "login") {
                this.selectedTheme = "white";
            }
        }
    }

    public buttonClicked(event: MouseEvent): void {
        if (!this.isDisabled) {
            this.userClicked.emit(event);
        }
    }
}
