<div class="mb-4">
    <div class="flex flex-col items-center">
        <label class="label leading-5 mb-4 text-center">{{ label }}</label>
        <div
            [ngClass]="
                'flex flex-row space-x-4 mb-3 pt-2 text-sm font-semibold ' +
                classprop
            "
        >
            <ng-content></ng-content>
        </div>
    </div>
    <div
        [ngClass]="error ? 'border-b-green-500' : 'border-b-white'"
        class="border-b-2"
    ></div>
    <div class="text-red-500">
        <ng-content select="[error]"></ng-content>
    </div>
</div>
