<div *ngIf="isAuthenticated !== undefined">
    <app-main-layout
        *ngIf="isAuthenticated"
        [showProfile]="true"
        [leftMenuText]="'Hilfe / Kontakt'"
    >
        <div class="px-4 pb-6 pt-16 md:pt-6">
            <app-imprint-content [id]="id"></app-imprint-content>
        </div>
    </app-main-layout>
    <app-public-wrapper-layout *ngIf="!isAuthenticated" [type]="'cyan'">
        <div class="md:py-8 flex flex-col md:px-12 space-y-4">
            <a class="link" href="/">Zurück</a>

            <app-imprint-content></app-imprint-content>
        </div>
    </app-public-wrapper-layout>
</div>
