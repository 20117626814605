import { Component, Input } from "@angular/core";

@Component({
    selector: "app-loader",
    templateUrl: "./loader.component.html",
})
export class LoaderComponent {
    @Input() h1: string;
    @Input() theme = "cyan";
}
