<app-main-layout [showProfile]="true" [leftMenuText]="'Username ändern'">
    <div class="pt-11 sm:pt-0">
        <form [formGroup]="form">
            <div class="absolute -top-7 right-4 font-medium text-white text-sm">
                <button
                    [disabled]="!form.valid"
                    class="flex space-x-2 items-center {{
                        !form.valid ? 'opacity-40' : ' '
                    }}"
                >
                    <p class="underline">Email-anfordern</p>
                    <!-- TODO: rplace the correct icon -->
                    <img
                        class="h-3"
                        src="../../../../assets/images/save_icon.png"
                        alt="save_icon"
                    />
                </button>
            </div>
            <div class="px-4 text-brand-body text-[13px]">
                <h2 class="my-10 text-brand-magenta heading-2">
                    Neuen Usernamen festlegen
                </h2>
                <p class="leading-6 mb-10 mr-14">
                    Gib bitte den Nicknamen ein den du zukünftig verwenden möchtest. Du kannst den Nicknamen nach der Änderung nicht mehr Ändern.
                </p>
                <app-input-box
                    [label]="'Nickname'"
                    [icon]="'auth/at-symbol.png'"
                    class="mb-3"
                    [error]="!username.valid"
                >
                    <input
                        class="w-full focus:outline-none text-base placeholder:opacity-50"
                        type="text"
                        formControlName="username"
                    />
                    <p
                        error
                        *ngIf="username.touched && username.hasError('required')"
                    >
                        Bitte geben Sie einen gültigen Usernamen ein.
                    </p>
                    <p error *ngIf="username.touched && username.hasError('username')">
                        Bitte geben Sie einen gültigen Usernamen ein.
                    </p>
                </app-input-box>
                <p class="text-brand-danger" *ngIf="requestError !== null">
                    {{ requestError }}
                </p>
                <app-link-button
                    [isDisabled]="isDisabled"
                    [success]="requestSuccess"
                    [saveButton]="true"
                    (userClicked)="changeUsername()"
                    [theme]="'purple'"
                    [loading]="loading"
            ></app-link-button>
            </div>
        </form>
    </div>
</app-main-layout>
