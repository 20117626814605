import { Component, EventEmitter, Input, Output } from "@angular/core";
@Component({
    selector: "app-modal",
    templateUrl: "./modal.component.html",
})
export class ModalComponent {
    @Input() theme!: string;
    @Input() linkLink: string;
    @Input() subtitleProps: string;
    @Input() topPadding = "16";
    @Input() backgroundColor = "white";
    @Input() closeColor = "gray";
    @Input() borderRadius = "none";
    @Input() showSubtitle = true;
    @Output() closeMatch: EventEmitter<string> = new EventEmitter();
    @Output() clickFooter: EventEmitter<string> = new EventEmitter();
    @Output() footerLink: EventEmitter<string> = new EventEmitter();
    @Input() supportFooter = false;
    @Input() customFooter = false;
    @Input() event: string;
    @Input() sectionTopPaddingLarge = "8";
    @Input() linkClass = "modal-link";
    @Input() sectionTopPaddingSmall = "4";
    @Input() desktopHeight = "600";
    @Input() desktopWidth = "300";
    @Input() show = true;
    @Input() overlay = false;
    @Input() showSeparationLine = true;
    @Input() startPageModal = false;
    @Input() h1: string;
    @Input() h1b: string;
    @Input() padding = "6";
    public showState = "visible"; // add a new property to keep track of the animation state

    @Input() image: string;
    @Input() imageWrapperProps: string;
    @Input() imageProps = "w-12";
    @Input() disclaimer: string;
    @Input() smallIcon: string;
    @Input() body: string;
    @Input() footer: string;
    @Input() footerLinkText: string;
    @Input() link: string;
    @Input() subtitle: string;
    @Input() keys: number;
    @Input() fontSize = "xs";
    @Input() classProps!: string;

    public emitFooterClicked(): void {
        this.clickFooter.emit();
    }

    public emitCloseClicked(): void {
        this.closeMatch.emit();
    }

    public emitFooterLinkClicked(event: Event): void {
        event.preventDefault();
        this.footerLink.emit();
    }
}
