<div class="px-6 pb-8 pt-28 sm:pt-8 absolute flex flex-col h-full w-full">
    <h2 class="text-brand-magenta heading-2">Chats</h2>
    <!-- Summary -->
    <div class="flex items-center space-x-2 text-xs">
        <div
            *ngIf="noOfUnreadMessages !== 0"
            class="h-2 w-2 bg-brand-magenta rounded-full inline-flex"
        ></div>
        <span class="text-[0.8rem] leading-4 font-medium"
            >{{ noOfUnreadMessages }} ungelesene Nachrichte(n),
            {{ noOfNewMatches }} neue Matches</span
        >
    </div>
    <div class="mt-8 overflow-scroll no-scrollbar">
        <div *ngFor="let match of matches" class="mb-3">
            <app-chat-item
                *ngIf="match !== null"
                [match]="match"
                [myId]="myId"
            ></app-chat-item>
        </div>
    </div>
</div>
