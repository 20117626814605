import { Component, Input } from "@angular/core";

@Component({
    selector: "app-login-option",
    templateUrl: "./login-option.component.html",
})
export class LoginOptionComponent {
    @Input() public image = "";
    @Input() public loginOptionName = "";
}
