import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class PaypalService {
    public paypal?;

    private callbacks: (() => void)[] = [];

    executeWhenPaypalIsReady = (callback: () => void) => {
        if (this.paypal) {
            callback();
        } else {
            this.callbacks.push(callback);
        }
    };

    loadScript = () => {
        const paypalScript = document.createElement("script");
        paypalScript.setAttribute(
            "src",
            `https://www.paypal.com/sdk/js?client-id=${environment.paypal.clientId}&currency=EUR`
        );
        document.head.appendChild(paypalScript);
        paypalScript.addEventListener("load", () => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.paypal = (window as any).paypal;
            this.callbacks.forEach((callback) => callback());
        });
    };
}
