<div class="flex space-x-4 items-start">
    <img
        class="h-7 w-7"
        src="../../../../assets/images/welcome_{{ count }}.png"
        alt="step_count"
    />
    <div>
        <div class="text-sm font-medium">
            <ng-content></ng-content>
        </div>
    </div>
</div>
