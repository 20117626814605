import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "src/app/services/api.service";

@Component({
    selector: "app-reset-password",
    templateUrl: "./reset-password.component.html",
})
export class ResetPasswordComponent {
    form: FormGroup = new FormGroup({
        password: new FormControl(null, [
            Validators.required,
            Validators.minLength(8),
            Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/),
        ]),
        confirmPassword: new FormControl(null, [
            Validators.required,
            Validators.minLength(8),
            Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/),
        ]),
    });

    public showPassword = false;
    public showRepeatPassword = false;
    public success = false;
    public matchError = false;
    public error: string;
    private email: string;
    private passwordToken: string;

    constructor(
        private api: ApiService,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {
        this.activatedRoute.queryParams.subscribe((params) => {
            if (!params["email"] || !params["passwordToken"]) {
                this.router.navigate(["/"]);
            } else {
                this.email = params["email"];
                this.passwordToken = params["passwordToken"];
            }
        });
    }

    public resetPassword(): void {
        if (this.password.value !== this.confirmPassword.value) {
            this.matchError = true;
        } else {
            this.matchError = false;
            const data = Object.assign(
                {},
                { email: this.email, passwordToken: this.passwordToken },
                this.form.value
            );
            this.api
                .me()
                .forgotPasswordVerify(data)
                .subscribe({
                    next: () => {
                        this.form.reset();
                        this.success = true;
                    },
                    error: () => {
                        this.error = "Etwas stimmt nicht. Versuchen Sie es später noch einmal!";
                    },
                });
        }
    }

    get password(): FormControl {
        return this.form.get("password") as FormControl;
    }

    get confirmPassword(): FormControl {
        return this.form.get("confirmPassword") as FormControl;
    }
}
