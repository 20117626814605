import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import Cropper from "cropperjs";

@Component({
    selector: "app-photo-cropper",
    templateUrl: "./photo-cropper.component.html",
})
export class PhotoCropperComponent implements OnInit {
    private minContainerHeight = 400;
    private cropper!: Cropper;
    private currentCroppedImageURL!: string;

    @Input() id: number;
    /**
     * src of the image to be cropped
     */
    @Input() imageToBeCropped: string;
    @Output() croppedImage = new EventEmitter<File>();

    ngOnInit(): void {
        const image = new Image();
        image.src = this.imageToBeCropped as string;
        image.id = "image";
        image.classList.add("block");

        const imageContainer = document.querySelector("#img-container");
        if (imageContainer) imageContainer.appendChild(image);

        this.cropper = new Cropper(image, {
            viewMode: 3,
            dragMode: "move",
            cropBoxMovable: true,
            cropBoxResizable: true,
            checkOrientation: false,
            minContainerHeight: this.minContainerHeight,
            minContainerWidth: 100,
            aspectRatio: 9 / 16,
            minCropBoxHeight: 100,
            crop: () => {
                const canvas = this.cropper.getCroppedCanvas();
                const imageURL = canvas.toDataURL("image/jpeg");
                this.currentCroppedImageURL = imageURL;
            },
        });
    }

    save() {
        this.croppedImage.emit(this.dataURLtoFile(this.currentCroppedImageURL, "upload.jpeg"));
    }

    dataURLtoFile(dataurl, filename) {
        const arr = dataurl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }
}
