import { createAction, createReducer, createSelector, on } from "@ngrx/store";
import { MeData } from "../interfaces/MeData";
import { AppState } from "./app.state";

export const updateMeData = createAction("[Me Data] updateMeData", (data: MeData) => ({
    data,
}));
//Action to update only keys and coins inside medata
export const updateKeysAndCoins = createAction(
    "[Me Data] updateKeysAndCoins",
    (keys: number, coins: number) => ({ keys, coins })
);
export const deleteMeData = createAction("[Me Data] deleteMeData");
export const initialMeData = null;

export const meDataReducer = createReducer(
    initialMeData,
    /* @ts-ignore */ // TODO: properly type this, so that we don't need to ignore this
    on(updateMeData, (state, { data }) => {
        const normalizedData = normalize(data);
        // only update the store if the data has changed
        if (normalizedData && JSON.stringify(state) !== JSON.stringify(normalizedData)) {
            return normalizedData;
        }
        return state;
    }),
    on(deleteMeData, () => {
        return initialMeData;
    }),
    on(updateKeysAndCoins, (state, { keys, coins }) => ({
        /* @ts-ignore */ // TODO: properly type this, so that we don't need to ignore this
        ...state,
        /* @ts-ignore */ // TODO: properly type this, so that we don't need to ignore this
        keys: state.keys + keys,
        /* @ts-ignore */ // TODO: properly type this, so that we don't need to ignore this
        coins: state.coins + coins,
    }))
);

/**
 * Avoid unnessesay updates of the store data, by:
 * - Formating the dob property to be a date, because it is a string sometimes
 * - Sometimes the tags come as array of strings ["abc", "def", "ghi"], sometimes as array of objects
 * [{_id: "abc"}, {_id: "def"}, {_id: "ghi"}]. This always returns the fist version
 */
function normalize(data: { dob?: Date | string; tags?: (string | { _id: string })[] }) {
    if (!(data && (data.dob || data.tags))) {
        return data;
    }
    const copyData = { ...data };
    try {
        if (data.dob) {
            copyData.dob = new Date(data.dob);
        }
        if (data.tags) {
            copyData.tags = data.tags.map((tag) => {
                if (typeof tag === "string") {
                    return tag;
                }
                return tag._id;
            });
        }
    } catch (e) {
        // on error return the original data
        return data;
    }
    return copyData;
}

/*
The first arrow function takes the entire state object as an argument and
selects the meData property from it. The selected value is then passed as an
argument to the second arrow function, which simply returns the value as is.
This second arrow function is called the "projection function" and its job is
to transform the input value into the desired output value.*/
export const getMeData = createSelector(
    (state: AppState) => state.meData,
    (meData): MeData | null => meData
);
