import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class UpdateMemberStackService {
    private subject = new Subject<any>();

    /**
     * Set success after interact successfully with member
     */
    interactWithMember() {
        this.subject.next("success");
    }

    /**
     * Send event after interact with Member
     * @returns The observeable
     */
    onInteractWithMember(): Observable<any> {
        return this.subject.asObservable();
    }
}
