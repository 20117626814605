<app-main-layout>
    <div
        class="h-full bg-brand-cyan flex flex-col justify-evenly items-center px-4 text-white text-sm pt-20 pb-8 sm:pt-0"
    >
        <div class="flex flex-col space-y-9 w-full items-center">
            <img
                class="h-40 w-40"
                src="../../../../assets/images/vorkasse_check_small.png"
                alt="payment_declined"
            />
            <div class="flex flex-col items-center">
                <p class="text-[1.7rem] font-bold my-2">Kauf erfolgreich</p>
                <p class="text-[0.8rem] leading-6 font-normal">
                    Transaktionnummer: {{ transactionNumber }}
                </p>
            </div>
            <div
                *ngIf="product"
                class="border-b border-t border-white/80 text-white/80 w-full px-4 py-6 space-y-2"
            >
                <div class="flex items-center justify-between">
                    <p class="font-medium">Ausschüttung</p>
                    <p class="text-white font-bold">
                        {{
                            product.qtyCoins > 0
                                ? product.qtyCoins
                                : product.qtyKeys
                        }}
                        {{ product.qtyCoins > 0 ? "Credits" : "Schlüssel" }}
                    </p>
                </div>
                <div class="flex items-center justify-between">
                    <p class="font-medium">Zahlungsbetrag</p>
                    <p class="text-white font-bold">
                        {{ product.cost / 100 | floatToEuro }}
                    </p>
                </div>
            </div>
        </div>
        <a [routerLink]="'/user/discover'" class="underline font-bold"
            >Zurück</a
        >
    </div>
</app-main-layout>
