import { Component, OnInit } from "@angular/core";
import Tag from "src/app/interfaces/Tag";
import { ApiService } from "src/app/services/api.service";
import { MembersMeStoreService } from "src/app/services/members-me-store.service";

@Component({
    selector: "app-edit-my-interests",
    templateUrl: "./edit-my-interests.component.html",
})
export class EditMyInterestsComponent implements OnInit {
    public availableTags: Tag[] = [];
    public selectedTags: string[] = [];
    public disabled = false;

    public success = false;
    public updateSuccessful = false;
    public isDisabled = false;
    public loading = false;

    constructor(private api: ApiService, private membersMeStore: MembersMeStoreService) {}

    ngOnInit(): void {
        this.api.tags().subscribe((response) => {
            this.availableTags = response.availableTags;
            response.me.tags.forEach((element) => {
                this.selectedTags.push(element._id);
            });
        });
    }

    public addToList(tag: Tag): void {
        if (this.selectedTags.includes(tag._id)) {
            // If the tag is already included, remove it
            this.selectedTags.splice(this.selectedTags.indexOf(tag._id), 1);
        } else {
            // Otherwise, add it to the selectedTags array
            this.selectedTags.push(tag._id);
        }

        if (this.selectedTags.length < 5 || this.selectedTags.length > 10) {
            this.disabled = true;
        } else {
            this.disabled = false;
        }
    }

    public saveChanges(): void {
        this.success = false;
        this.loading = true;

        this.api
            .me()
            .update({ tags: this.selectedTags })
            .subscribe(() => {
                this.success = true;
                this.loading = false;

                this.updateSuccessful = true;
                this.membersMeStore.removeMembersMeFromStore();
            });
    }
}
