import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PickerModule } from "@ctrl/ngx-emoji-mart";
import { EmojiModule } from "@ctrl/ngx-emoji-mart/ngx-emoji";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { LayoutModule } from "../layout/layout.module";
import { SharedModule } from "../shared/shared.module";
import { UserAuthModule } from "../user-auth/user-auth.module";
import { AboutYouComponent } from "./about-you/about-you.component";
import { AccountSettingsComponent } from "./account-settings/account-settings/account-settings.component";
import { AsFooterItemComponent } from "./account-settings/as-footer-item/as-footer-item.component";
import { ChangeEmailComponent } from "./account-settings/change-email/change-email.component";
import { ChangeUsernameComponent } from "./account-settings/change-username/change-username.component";
import { ChangePasswordComponent } from "./account-settings/change-password/change-password.component";
import { EditMyInterestsComponent } from "./account-settings/edit-my-interests/edit-my-interests.component";
import { MyInterestsItemComponent } from "./account-settings/my-interests-item/my-interests-item.component";
import { AccountPausedComponent } from "./account/account-paused/account-paused.component";
import { AppleCallbackComponent } from "./apple-callback/apple-callback.component";
import { ChatListComponent } from "./chats/chat-list/chat-list.component";
import { ChatComponent } from "./chats/chat/chat.component";
import { MessageItemComponent } from "./chats/message-item/message-item.component";
import { ProfileChatComponent } from "./chats/profile-chat/profile-chat.component";
import { CookiePolicyComponent } from "./cookie-policy/cookie-policy.component";
import { DataProtectionContentComponent } from "./data-protections/data-protection-content/data-protection-content.component";
import { DataProtectionComponent } from "./data-protections/data-protection/data-protection.component";
import { CardComponent } from "./discover/card/card.component";
import { DiscoverEmptyStackComponent } from "./discover/discover-empty-stack/discover-empty-stack.component";
import { DiscoverLimitComponent } from "./discover/discover-limit/discover-limit.component";
import { InsufficientBalanceForUnlockProfileComponent } from "./discover/insufficient-balance-for-unlock-profile/insufficient-balance-for-unlock-profile.component";
import { StartMainComponent } from "./discover/start-page/start-page.component";
import { ResetPasswordComponent } from "./forgot-password/reset-password/reset-password.component";
import { FaqItemComponent } from "./help/faq-item/faq-item.component";
import { HelpContentComponent } from "./help/help-content/help-content.component";
import { HelpHomeComponent } from "./help/help-home/help-home.component";
import { ImprintContentComponent } from "./imprints/imprint-content/imprint-content.component";
import { ImprintTestComponent } from "./imprints/imprint-test/imprint-test.component";
import { ImprintComponent } from "./imprints/imprint/imprint.component";
import { LandingComponent } from "./landing/landing.component";
import { LegalLinksComponent } from "./legal-links/legal-links.component";
import { ImageComponent } from "./like-you/image/image.component";
import { LikeItemComponent } from "./like-you/like-item/like-item.component";
import { LikesListComponent } from "./like-you/likes-list/likes-list.component";
import { UserInterestsComponent } from "./like-you/user-interests/user-interests.component";
import { MainPageLayoutComponent } from "./main-page-layout/main-page-layout.component";
import { NotFoundPageComponent } from "./not-found-page/not-found-page.component";
import { OthersProfileHeaderComponent } from "./others-profile/others-profile-header/others-profile-header.component";
import { VisitProfileComponent } from "./others-profile/visit-profile/visit-profile.component";
import { CheckoutPaypalComponent } from "./payments/checkout-paypal/checkout-paypal.component";
import { CheckoutStripeComponent } from "./payments/checkout-stripe/checkout-stripe.component";
import { CheckoutComponent } from "./payments/checkout/checkout.component";
import { PaymentMethodsItemComponent } from "./payments/payment-methods-item/payment-methods-item.component";
import { PrepaymentOrderComponent } from "./payments/prepayment-order/prepayment-order.component";
import { PurchaseSuccessfulComponent } from "./payments/purchase-successful/purchase-successful.component";
import { PurchaseUnsuccessfulComponent } from "./payments/purchase-unsuccessful/purchase-unsuccessful.component";
import { PhotoTypeItemComponent } from "./photo-types/photo-type-item/photo-type-item.component";
import { CropPhotoComponent } from "./photos-settings/crop-photo/crop-photo.component";
import { DeletePhotoComponent } from "./photos-settings/delete-photo/delete-photo.component";
import { EditPhotoComponent } from "./photos-settings/edit-photo/edit-photo.component";
import { ImageTypeSelectComponent } from "./photos-settings/image-type-select/image-type-select.component";
import { MyPhotosComponent } from "./photos-settings/my-photos/my-photos.component";
import { PhotoContainerComponent } from "./photos-settings/photo-container/photo-container.component";
import { EditProfileSectionComponent } from "./profile/edit-profile-section/edit-profile-section.component";
import { EditProfileComponent } from "./profile/edit-profile/edit-profile.component";
import { MyProfileComponent } from "./profile/my-profile/my-profile.component";
import { ProfileSettingsItemComponent } from "./profile/profile-settings-item/profile-settings-item.component";
import { PublicProfileContentComponent } from "./public-profile/public-profile-content/public-profile-content.component";
import { PublicProfileComponent } from "./public-profile/public-profile.component";
import { PublicRoutingModule } from "./public-routing.module";
import { PublicTermsComponent } from "./public-terms/public-terms";
import { LocationInfoComponent } from "./register/location-info/location-info.component";
import { RightOfWithdrawalComponent } from "./rights/right-of-withdrawal/right-of-withdrawal.component";
import { RightsContentComponent } from "./rights/rights-content/rights-content.component";
import { SetProfileComponent } from "./set-profile/set-profile.component";
import { ProductPriceItemComponent } from "./shops/product-price-item/product-price-item.component";
import { ShopComponent } from "./shops/shop/shop.component";
import { TermsAndConditionsComponent } from "./terms-and-conditions/terms-and-conditions.component";
import { TermsContentComponent } from "./terms/terms-content/terms-content.component";
import { SearchComponent } from './search/search.component';
@NgModule({
    declarations: [
        LandingComponent,
        SetProfileComponent,
        LocationInfoComponent,
        StartMainComponent,
        ChatListComponent,
        ChatComponent,
        LikesListComponent,
        LikeItemComponent,
        ImageComponent,
        UserInterestsComponent,
        EditProfileComponent,
        EditProfileSectionComponent,
        ChangePasswordComponent,
        ChangeUsernameComponent,
        ResetPasswordComponent,
        MyProfileComponent,
        ProfileSettingsItemComponent,
        CookiePolicyComponent,
        TermsAndConditionsComponent,
        ImprintComponent,
        ChangeEmailComponent,
        EditMyInterestsComponent,
        MyInterestsItemComponent,
        PhotoTypeItemComponent,
        VisitProfileComponent,
        HelpHomeComponent,
        FaqItemComponent,
        InsufficientBalanceForUnlockProfileComponent,
        DiscoverLimitComponent,
        DiscoverEmptyStackComponent,
        ShopComponent,
        ProductPriceItemComponent,
        CheckoutComponent,
        CheckoutStripeComponent,
        PaymentMethodsItemComponent,
        PurchaseUnsuccessfulComponent,
        PurchaseSuccessfulComponent,
        PrepaymentOrderComponent,
        AccountSettingsComponent,
        AsFooterItemComponent,
        MessageItemComponent,
        PhotoContainerComponent,
        MyPhotosComponent,
        NotFoundPageComponent,
        PublicProfileComponent,
        AboutYouComponent,
        DeletePhotoComponent,
        MainPageLayoutComponent,
        AccountPausedComponent,
        EditPhotoComponent,
        DataProtectionComponent,
        RightOfWithdrawalComponent,
        PublicTermsComponent,
        ImprintContentComponent,
        HelpContentComponent,
        DataProtectionContentComponent,
        TermsContentComponent,
        RightsContentComponent,
        PublicProfileContentComponent,
        LegalLinksComponent,
        CropPhotoComponent,
        ImageTypeSelectComponent,
        OthersProfileHeaderComponent,
        CheckoutPaypalComponent,
        ImprintTestComponent,
        AppleCallbackComponent,
        ProfileChatComponent,
        CardComponent,
        SearchComponent,
    ],
    imports: [
        CommonModule,
        PublicRoutingModule,
        LayoutModule,
        SharedModule,
        ReactiveFormsModule,
        UserAuthModule,
        FormsModule,
        PickerModule,
        EmojiModule,
        InfiniteScrollModule,
    ],
})
export class PublicModule {}
