import { Attribute, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: "app-input-box",
    templateUrl: "./input-box.component.html",
})
export class InputBoxComponent implements OnInit {
    @Attribute("class") classList: string;
    @Input() label!: string;
    @Input() icon = "auth/show_password.png";
    @Input() error!: boolean;
    @Input() inputValue!: string;
    input!: string;

    @Output() eyeClicked: EventEmitter<string> = new EventEmitter<string>();
    constructor(@Attribute("class") classList: string) {
        this.classList = classList;
    }

    ngOnInit(): void {
        this.error = true;
    }

    public clickIcon(): void {
        if (this.label === "Passwort" || this.label === "Passwort wiederholen") {
            if (this.icon === "auth/show_password.png") {
                this.icon = "auth/hide_password.png";
                this.eyeClicked.emit();
            } else {
                this.icon = "auth/show_password.png";
                this.eyeClicked.emit();
            }
        }
    }
}
