import { Injectable } from "@angular/core";
import { Observable, map, of, tap } from "rxjs";
import { Fees } from "../interfaces/Fees";
import { FeesResponse } from "../interfaces/api-responses";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: "root",
})
export class FeesService {
    private fees: Fees;

    constructor(private api: ApiService) {}

    getFees(): Observable<Fees> {
        if (this.fees) {
            return of(this.fees);
        } else {
            return this.api.fees().pipe(
                tap((response: FeesResponse) => {
                    this.fees = response.fees;
                }),
                map((response: FeesResponse) => response.fees)
            );
        }
    }
}
