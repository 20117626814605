import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-checkout-stripe",
    templateUrl: "./checkout-stripe.component.html",
})
export class CheckoutStripeComponent implements OnInit {
    public secret!: string;

    public product!: any;

    public stripe: any;

    public elements: any;
    public error = false;
    public success = false;
    public loading = false;

    public paymentIntent?: any;

    constructor(private api: ApiService, private router: Router) {
        if (this.router.getCurrentNavigation()?.extras.state) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const state = this.router.getCurrentNavigation()?.extras.state as any;
            if (
                state &&
                Object.prototype.hasOwnProperty.call(state, "secret") &&
                Object.prototype.hasOwnProperty.call(state, "product")
            ) {
                this.secret = state.secret;
                this.product = state.product;
            }
        }

        // @ts-ignore
        this.stripe = Stripe(environment.stripe.publicKey);
        this.elements = null;
    }

    private async setPaymentIntent() {
        this.paymentIntent = await this.stripe.retrievePaymentIntent(this.secret);
    }

    navigatedBack() {
        this.api.shop().cancelStripe(this.paymentIntent.paymentIntent.id).subscribe();
    }

    cancelOrder() {
        this.api.shop().cancelStripe(this.paymentIntent.paymentIntent.id).subscribe();
        this.router.navigate(["/user/shop"]);
    }

    async confirmPayment() {
        this.error = false;

        this.loading = true;
        const { error } = await this.stripe.confirmPayment({
            elements: this.elements,
            confirmParams: {
                return_url: environment.stripe.returnUrl,
            },
        });

        if (error.type === "card_error" || error.type === "validation_error") {
            this.error = true;
            this.loading = false;
            console.error(
                "An error occured while trying to confirm stripe payment:",
                error.message
            );
            return Promise.reject(error);
        } else {
            this.error = false;
            this.loading = false;
            console.error("An unexpected error occurred while trying to confirm stripe payment.");
            return Promise.reject(new Error("Unexpected error"));
        }
    }

    ngOnInit(): void {
        if (!this.product || !this.secret) {
            this.router.navigate(["/user/shop"]);
        }

        setTimeout(() => {
            this.initialize(this.secret);
            this.setPaymentIntent();
        }, 100);
    }

    public initialize(secret: string) {
        this.elements = this.stripe.elements({
            appearance: {
                theme: "stripe",
            },
            clientSecret: secret,
        });

        const paymentElement = this.elements.create("payment");
        paymentElement.mount("#payment-element");
    }

    public showMessage(messageText: string) {
        const messageContainer = document.querySelector("#payment-message");
        if (messageContainer === null) {
            console.warn("Failed to show message because #payment-message element was not found.");
            return;
        }

        messageContainer.classList.remove("hidden");
        messageContainer.textContent = messageText;

        setTimeout(function () {
            messageContainer.classList.add("hidden");
            messageContainer.textContent = "";
        }, 4000);
    }
}
