<app-mobile-wrapper-layout [type]="'cyanToMagenta'">
    <app-hearts-bg *ngIf="!showTutorial" type="registerSuccessful">
        <div
            *ngIf="!showTutorial"
            class="flex flex-col items-center mt-12 px-8 text-white h-5/6 w-full justify-evenly"
        >
            <img
                class="h-12 w-12 items-center"
                src="../../../assets/images/welcome_icon.png"
            />

            <div class="grid space-y-6 w-full">
                <h1 class="text-[1.7rem] text-white font-bold text-center">
                    Super, Du hast es geschafft!
                </h1>
                <p class="text-center flow-text-logged-in">
                    Schön, dass Du auf amolino bist! Du kannst gleich loslegen
                    und neue Kontakte entdecken - beachte aber bitte Folgendes:
                </p>
                <app-step [count]="1"
                    >Auf amolino achten wir auf Freundlichkeit - melde
                    unfreundliche Nutzer und sei selbst freundlich zu
                    anderen</app-step
                >
                <app-step [count]="2"
                    >Auf amolino gilt: Geduld ist oft der Schlüssel - viele
                    Nutzer möchten mit der ersten Nachricht lieber noch keine
                    Telefonnummern tauschen</app-step
                >
                <app-step [count]="3"
                    >Sicherheit und Vertrauen haben auf amolino höchste
                    Priorität - wähle für ein erstes Date immer einen
                    öffentlichen Ort</app-step
                >
                <app-link-button
                    [theme]="'cyan'"
                    (userClicked)="showTutorial = !showTutorial"
                    >Weiter
                </app-link-button>
            </div>
        </div>
    </app-hearts-bg>

    <app-tutorial
        *ngIf="showTutorial"
        (closeTutorial)="goToDiscover()"
        [tutorials]="tutorials"
    ></app-tutorial>
</app-mobile-wrapper-layout>
