import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { Product } from "src/app/services/shop.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-shop",
    templateUrl: "./shop.component.html",
})
export class ShopComponent implements OnInit {
    public offers: Product[] = [];
    public keys: Product[] = [];
    public credits: Product[] = [];

    public message: any = null;

    public pageProcessing = false;

    constructor(
        private api: ApiService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe((params) => {
            if (params["payment_intent"] && params["payment_intent_client_secret"]) {
                this.pageProcessing = true;
            }
        });
        this.api
            .shop()
            .get()
            .subscribe((response) => {
                response.products.forEach((product) => {
                    if (product.offer) {
                        this.offers.push(product);
                    } else if (product.qtyCoins > 0) {
                        this.credits.push(product);
                    } else if (product.qtyKeys > 0) {
                        this.keys.push(product);
                    }
                });
            });

        this.checkStatus();
    }

    public async checkStatus() {
        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        // @ts-ignore
        const client = Stripe(environment.stripe.publicKey);
        const { paymentIntent } = await client.retrievePaymentIntent(clientSecret);

        switch (paymentIntent.status) {
            case "succeeded":
                this.router.navigate(["/purchase-successful"], {
                    state: {
                        description: paymentIntent.description,
                    },
                });
                break;
            case "processing":
                this.showMessage("Your payment is processing.");
                break;
            case "requires_payment_method":
                this.router.navigate(["/purchase-unsuccessful"], {
                    state: {
                        payment: "unsucessful",
                    },
                });
                break;
            default:
                this.showMessage("Something went wrong.");
                break;
        }
    }

    public showMessage(messageText: string) {
        this.message = messageText;
    }
}
