import { animate, state, style, transition, trigger } from "@angular/animations";
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { LikedByProfile } from "src/app/interfaces/ProfileData";
import { ApiService } from "src/app/services/api.service";
import { FeesService } from "src/app/services/fees.service";
import { MatchDataService } from "src/app/services/match-data.service";
@Component({
    selector: "app-like-item",
    templateUrl: "./like-item.component.html",
    animations: [
        trigger("fadeIn", [
            state(
                "void",
                style({
                    transform: "translateY(100%)",
                    opacity: 0,
                })
            ),
            state(
                "show",
                style({
                    opacity: 1,
                    transform: "translateY(0)",
                })
            ),
            transition("* <=> *", animate(500)),
        ]),
        trigger("popOverState", [
            state(
                "hide",
                style({
                    transform: "translateY(-100%)",
                })
            ),
            state(
                "show",
                style({
                    transform: "translateY(0)",
                })
            ),
            transition("void => show", animate("600ms ease-out")),
            transition("show => hide", animate("600ms ease-out")),
            transition("hide => show", animate("1000ms ease-in")),
        ]),
    ],
})
export class LikeItemComponent implements OnInit, AfterViewInit {
    @Input() user: LikedByProfile;
    @Input() preselected: boolean;

    @Output() refreshList: EventEmitter<string> = new EventEmitter();
    @Output() profileUnlockClicked: EventEmitter<string> = new EventEmitter();
    @Output() limitReached: EventEmitter<string> = new EventEmitter();

    heart = "inactive";
    keyhole = "like_page_keyhole";
    delete = "delete_like";

    public isClickInProgress = false;
    member: any;
    /**
     * Match Infos if match is created
     */
    public matchInfo = {
        myImage: "",
        partnerImage: "",
        partnerId: "",
        matchId: "",
        type: "",
    };

    /**
     * True: show insufficient  credits error while unlock profile view
     */
    insufficientCreditsForProfileUnlock = false;

    /**
     * True: open profile unlock modal
     */
    showUnlockProfileModal = false;

    //fees
    public fees: any;

    constructor(
        private api: ApiService,
        private feesService: FeesService,
        private matchDataService: MatchDataService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.feesService.getFees().subscribe((response) => (this.fees = response));
    }

    ngAfterViewInit() {
        if (this.preselected) {
            this.clickOnProfile();
        }
    }

    /**
     * Interact with the other member
     * @param memberId The member id
     * @param interaction Interaction type
     */
    postInteract(memberId: string, interaction: string, locked: boolean) {
        if (!locked) {
            this.api
                .members()
                .interact(memberId, interaction)
                .subscribe({
                    next: (response) => {
                        if (interaction === "LIKE" && response.matchId) {
                            this.matchInfo.myImage = response.me.image;
                            this.matchInfo.partnerImage = this.user.image;
                            this.matchInfo.partnerId = this.user._id;
                            this.matchInfo.matchId = response.matchId;
                            const matchData = this.matchInfo;
                            matchData.type = "normal";
                            this.matchDataService.changeMatchData(matchData);
                            setTimeout(() => {
                                this.refreshList.emit();
                            }, 5000);
                        }
                    },
                    error: (response) => {
                        if (response.status === 402) this.limitReached.emit();
                    },
                });
        }
    }

    /**
     * Delete member who likes you
     * @param memberId The member id who likes you
     */
    public deleteLike(memberId: string) {
        this.api
            .me()
            .deleteLike(memberId)
            .subscribe({
                next: () => {
                    this.refreshList.emit();
                },
                error: (e) => {
                    console.warn("Failed to delete like:", e);
                },
            });
    }

    /**
     * Unlock the profile
     * @param memberId Member id
     * @param interaction Ineraction type is BUY_ACCESS
     */
    public profileUnlock(memberId: string, interaction: string) {
        this.isClickInProgress = true;
        this.api
            .members()
            .interact(memberId, interaction)
            .subscribe({
                next: () => {
                    this.showUnlockProfileModal = false;
                    this.refreshList.emit();
                },
                error: (response) => {
                    this.insufficientCreditsForProfileUnlock = true;
                    console.warn("Failed to unlock profile:", response);
                },
            });
        this.isClickInProgress = false;
    }

    public clickOnProfile() {
        console.log(this.user._id);
        if (this.user.locked) {
            this.toggleUnlockProfileModal(this.user._id ? true : false);
        } else {
            this.router.navigate(["/profile/", "like", this.user._id]);
        }
    }

    /**
     * Hide and show the unlockprofilemodal view
     */
    toggleUnlockProfileModal(value: boolean, event?) {
        if (value) {
            this.showUnlockProfileModal = !this.showUnlockProfileModal;
            this.insufficientCreditsForProfileUnlock = false;
        } else {
            if (event) event.preventDefault();
        }
    }

    /**
     * Hide and show the instant match active view
     */
}
