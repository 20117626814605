<div class="w-full cursor-pointer -space-y-[32px] text-white">
    <div
        class="relative z-10 pointer-events-none flex items-center text-[20px] justify-center w-[300px] px-18"
    >
        <ng-content></ng-content>
    </div>
    <div
        [ngClass]="selectedTheme"
        class="relative z-0 rounded-3xl bg-brand-magenta transition-scale duration-300 hover:scale-95 hover:bg-[#8e2f65] w-[300px] h-12 py-2 font-semibold"
        [routerLink]="path"
    ></div>
</div>
