<div class="relative">
    <div class="overflow-hidden rounded-md">
        <img
            class=" {{
                imageItem.locked ? 'blur-[5px]' : ''
            }} w-full h-28 object-cover rounded-md"
            src="{{ imageItem.image | addImageBaseUrl }}"
            alt="user_collection"
        />
    </div>
    <div
        *ngIf="imageItem?.locked"
        class="absolute top-0 h-full w-full rounded-sm flex items-center justify-center"
    >
        <img
            class="cursor-pointer w-9"
            src="../../../../assets/images/unlock_photo_galery.png"
            alt="unlock_photo_galery"
        />
    </div>
</div>

<!-- *ngIf="lockIcon" -->
