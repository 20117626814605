import { Injectable } from "@angular/core";
import { LocationInfoRegisterStep } from "../interfaces/RegisterSteps";
import { GoogleMapsService } from "./google-maps.service";

@Injectable({
    providedIn: "root",
})
export class LocationService {
    constructor(private googleMapsService: GoogleMapsService) {}

    getPosition(): Promise<LocationInfoRegisterStep> {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                (res) => {
                    resolve({
                        long: res.coords.longitude,
                        lat: res.coords.latitude,
                    });
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    initGoogleMapsAutocomplete(autocompleteInput: HTMLElement, placeChanged: (place: any) => void) {
        this.googleMapsService.executeWhenGoogleMapsIsReady((maps) => {
            // Create the autocomplete object, restricting the search predictions to all cities in germany
            const options = {
                componentRestrictions: { country: "de" },
                fields: ["geometry"],
                types: ["(cities)"],
            };
            const autocomplete = new maps.places.Autocomplete(autocompleteInput, options);
            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();

                placeChanged(place);
            });
        });
    }
}
