<div
    class="absolute top-0 right-0 z-10 h-full w-full overflow-x-hidden no-scrollbar touch-none"
>
    <!-- Main Image -->
    <div
        #imageSwiper
        class="flex h-full"
        appNgxImageSlider
        (mouseDrag)="mouseDrag($event)"
        (goToImage)="goToImage($event)"
    >
        <div
            id="image_{{ item._id }}"
            class="relative overflow-hidden w-full"
            *ngFor="let item of images; let i = index"
        >
            <div class="relative h-full w-full">
                <img
                    id="{{ i }}"
                    src="{{ item.image | addImageBaseUrl }}"
                    class="h-full w-full object-cover"
                    alt="profile_images"
                />
            </div>

            <div *ngIf="item?.locked" class="absolute top-0 h-full w-full">
                <app-locked-photo-overlay
                    [type]="item.type"
                    (forwardToUnlockPhotoModal)="
                        unlockPhotoModal.emit({
                            imageId: item._id,
                            type: item.type,
                            memberId: member._id
                        })
                    "
                >
                </app-locked-photo-overlay>
            </div>
        </div>
    </div>
    <div class="absolute top-6 right-6 z-10">
        <app-close (click)="close()" [closeColor]="'white'"></app-close>
    </div>
    <div class="absolute bottom-6 w-full">
        <div class="w-fit mx-auto flex flex-row space-x-1">
            <div *ngFor="let item of images; let i = index">
                <div
                    *ngIf="i < noOfImages"
                    [ngClass]="{
                        'opacity-100': i === currentIndex % noOfImages,
                        'opacity-50': i !== currentIndex % noOfImages
                    }"
                    class="h-3 w-3 rounded-full bg-white"
                ></div>
            </div>
        </div>
    </div>
</div>
