<app-form-generic-input [class]="class" [info]="info" [checked]="checked">
    <!-- Button -->
    <div class="flex items-center space-x-5 mb-3 cursor-pointer">
        <div class="flex items-center relative">
            <img
                class="h-4 w-4"
                src="../../../../../assets/images/auth/select_inactive.png"
                alt="select_inactive"
            />
            <img
                class="h-[16px] w-[16px] absolute left-0 top-0 inset-1 {{
                    checked ? ' ' : 'opacity-0'
                }}"
                src="../../../../../assets/images/auth/select_active.png"
                alt="select_active"
            />
            <input
                class="absolute left-0 top-0 opacity-0"
                (input)="change($event)"
                [id]="id"
                type="radio"
                [name]="name"
                [value]="value"
            />
        </div>

        <label [for]="id" class="text-[1em] leading-5">{{ label }}</label>
    </div>
</app-form-generic-input>
