import { Component, OnInit } from "@angular/core";
import { MembersMeStoreService } from "src/app/services/members-me-store.service";

@Component({
    selector: "app-edit-profile",
    templateUrl: "./edit-profile.component.html",
})
export class EditProfileComponent implements OnInit {
    public me: any;

    constructor(private membersMeStore: MembersMeStoreService) {}

    ngOnInit(): void {
        this.membersMeStore.getMembersMeFromStore().subscribe((response) => (this.me = response));
    }

    public getTags(): string {
        return (this.me?.tags ?? []).map((tag) => tag.de).join(", ");
    }

    public getGender(): string {
        if (!this.me) return "-";

        return {
            MALE: "Männlich",
            FEMALE: "Weiblich",
            DIVERS: "Divers",
        }[this.me.gender];
    }

    public getLookingFor(): string {
        if (!this.me) return "-";

        return {
            MALE: "Männer",
            FEMALE: "Frauen",
            ALL: "Frauen, Männer",
        }[this.me.lookingFor.sex];
    }

    public getDateOfBirth(): string {
        if (!this.me) return "-";

        const date = new Date(this.me.dob);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");

        return `${day}.${month}.${year}`;
    }

    public getAge(): string {
        if (!this.me) return "-";

        const difference = new Date().getTime() - new Date(this.me.dob).getTime();
        return Math.floor(difference / (1000 * 60 * 60 * 24 * 365)).toString();
    }
}
