<div class="flex flex-wrap font-medium">
    <div *ngFor="let tag of tags; let i = index">
        <div
            class="border-2 text-center {{
                commonTags.includes(tag._id)
                    ? 'bg-brand-cyan text-white'
                    : 'text-brand-cyan'
            }} border-brand-cyan rounded-full px-4 py-[2px] mr-1 mb-2 font-bold"
        >
            {{ tag.de }}
        </div>
    </div>
</div>
