import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { Product } from "src/app/services/shop.service";
import { AppPlatform, WebToAppService } from "src/app/services/web-to-app.service";

@Component({
    selector: "app-product-price-item",
    templateUrl: "./product-price-item.component.html",
})
export class ProductPriceItemComponent {
    @Input() public product: Product;
    @Input() public index = 0;

    constructor(
        private router: Router,
        private webToAppService: WebToAppService,
        private api: ApiService
    ) {}

    public get isCoins(): boolean {
        return this.product.qtyKeys === 0;
    }

    public get isKeys(): boolean {
        return this.product.qtyCoins === 0;
    }

    public get isCoinsAndKeys(): boolean {
        return this.product.qtyCoins > 0 && this.product.qtyKeys > 0;
    }

    public get title(): string {
        if (this.isCoinsAndKeys) {
            return `${this.product.qtyKeys} Schlüssel & ${this.product.qtyCoins} Credits`;
        } else {
            return this.isCoins
                ? `${this.product.qtyCoins} Credits`
                : `${this.product.qtyKeys} Schlüssel`;
        }
    }

    public get image(): string {
        return `${this.isKeys ? "keyoffer" : "creditoffer"}_${
            this.isKeys ? this.index + 1 : this.product.bonusLevel
        }.png`;
    }

    public get pricePerCoin(): number {
        return this.turncate(this.product.cost / 100 / this.product.qtyCoins);
    }

    public get pricePerKey(): number {
        return this.turncate(this.product.cost / 100 / this.product.qtyKeys);
    }

    public get savePercentageCoin(): number {
        const regular = 0.99;
        return Number(this.turncate((regular - this.pricePerCoin) * 100).toFixed(0));
    }

    public get savePercentageKey(): number {
        const regular = 0.99;
        return Number(this.turncate((regular - this.pricePerKey) * 100).toFixed(0));
    }

    public get expiryText(): string {
        if (!this.product.offer || !this.product.expiredAt) {
            return "";
        }

        const now = new Date();
        const expiry = new Date(this.product.expiredAt);
        let remainingSeconds = new Date(expiry.getTime() - now.getTime()).getTime() / 1000;

        const remainingDays = Math.floor(remainingSeconds / 86400);
        remainingSeconds -= remainingDays * 86400;

        const remainingHours = Math.floor(remainingSeconds / 3600);
        remainingSeconds -= remainingHours * 3600;

        const remainingMinutes = Math.floor(remainingSeconds / 60);
        remainingSeconds -= Math.floor(remainingMinutes * 60);

        return [
            remainingDays > 0
                ? {
                      value: remainingDays,
                      content: `${remainingDays} Tage`,
                  }
                : {},
            {
                value: remainingHours,
                content: `${remainingHours} Std`,
            },
            remainingDays == 0
                ? {
                      value: remainingMinutes,
                      content: `${remainingMinutes} Min`,
                  }
                : {},
        ]
            .filter((set) => set)
            .filter((set) => {
                if (set.value) return set.value > 0;

                return true; // TODO: replace this line for something more appropriate
            })
            .map((set) => set.content)
            .join(" ");
    }

    /**
     * To get only 2 fraction digits
     * @param value decimal value with long fraction digits
     * @returns The Number
     */
    public turncate(value: number): number {
        let num = value.toString(); //If it's not already a String
        num = num.slice(0, num.indexOf(".") + 3); //With 3 exposing the hundredths place
        return Number(num);
    }

    calculateSavePercentage(value: number): number {
        const price = 0.99;
        value = this.turncate(value);
        const result = this.turncate((price - value) * 100).toFixed(0);
        return Number(result);
    }

    public navigateToCheckout(event: MouseEvent): void {
        event.preventDefault();

        // TODO: add loading animation
        if (this.webToAppService.getAppPlatform() === AppPlatform.ios) {
            this.api
                .me()
                .get()
                .subscribe(async (response) => {
                    const user_id = response.me._id;

                    console.log(
                        `Trying to make in app purchase with product id ${this.product._id} and user id ${user_id}`
                    );
                    try {
                        await this.webToAppService.makeInAppPurchase(
                            this.product._id,
                            true,
                            user_id
                        );
                        this.router.navigate(["/purchase-successful"], {
                            state: {
                                product: this.product,
                            },
                        });
                    } catch (e) {
                        this.router.navigate(["/purchase-unsuccessful"]);
                    }

                    // TODO: stop loading animation
                });
            return;
        }

        this.router.navigate(["/checkout"], {
            state: {
                product: this.product,
            },
        });
    }
}
