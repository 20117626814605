import { Component } from "@angular/core";
import { Meta } from "@angular/platform-browser";
import airbridge from "airbridge-web-sdk-loader";
import { environment } from "src/environments/environment";
import { AuthService } from "./services/auth.service";
import { GoogleMapsService } from "./services/google-maps.service";
import { OneSignalService } from "./services/onesignal.service";
import { PaypalService } from "./services/paypal.service";
// import { ExternalScriptService } from "./services/script.service";
import { WebToAppService } from "./services/web-to-app.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.css"],
})
export class AppComponent {
    title = "fe-amolino";

    constructor(
        private metaService: Meta,
        private paypalService: PaypalService,
        private googleMapsService: GoogleMapsService,
        private oneSignalService: OneSignalService,
        // private extScriptService: ExternalScriptService,
        private webToAppService: WebToAppService,
        private authService: AuthService
    ) {
        this.metaService.addTag({ name: "referrer", content: "no-referrer" });

        console.log(window.navigator.userAgent);

        if (!this.webToAppService.isUsingApp() && !this.webToAppService.openedInCustomTab()) {
            // this.extScriptService.loadUsercentrics();
        }

        // if no appilix user
        if (
            window.navigator.userAgent !==
            "Mozilla/5.0 (Linux; Android 10; SM-A750F) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/95.0.4638.50 Mobile Safari/537.36"
        ) {
            // this.extScriptService.loadUsercentrics();
        }

        airbridge.init({
            app: "amolino",
            webToken: "3e4d4648179c4ec6965af6b751b7c6a4",
        });

        this.paypalService.loadScript();
        this.googleMapsService.loadScript();
        this.oneSignalService.loadScript();

        /* @ts-ignore */
        window.OneSignalDeferred = window.OneSignalDeferred || [];
        /* @ts-ignore */
        OneSignalDeferred.push(function (OneSignal) {
            OneSignal.init({
                appId: environment.onesignal.appId,
            });
        });

        if (this.authService.isAuthenticated) {
            this.oneSignalService.setExternalUserIdToUserId();
        }
    }
}
