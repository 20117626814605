import { Component, EventEmitter, Output } from "@angular/core";

@Component({
    selector: "app-credit-notify",
    templateUrl: "./credit-notify.component.html",
})
export class CreditNotifyComponent {
    @Output() closeNotify: EventEmitter<string> = new EventEmitter();

    onClose() {
        this.closeNotify.emit();
    }
}
