<div class="space-y-6 flex-col">
    <h2 class="heading-magenta">Impressum</h2>
    <p>
        amolino.de ist eine Marke der Multilabel GmbH mit Sitz in Hamburg.
    </p>
    <p>
        <strong class="font-semibold">Anschrift</strong>
        <br>
        Multilabel GmbH
        <br>
        Hohe Bleichen 12
        <br>
        20354 Hamburg
    </p>
    <p>
        <strong class="font-semibold">Geschäftsführer</strong>
        <br>
        Martin Heurer
        <br>
        HRB 176119 Amtsgericht Hamburg
        <br>
        USt-ID: DE354577477
        <br>
        HRB 31175
    </p>
    <p>
        <strong class="font-semibold">Nutzeranfragen</strong>
        <br>
        Du bist amolino-Nutzer und hast ein Anliegen? Hier helfen wir dir gern:
        <br>
        Support: <a routerLink="/" class="underline text-brand-cyan">support@amolino.de</a>
        <br>
        Jugendschutz: <a routerLink="/" class="underline text-brand-cyan">jugendschutz@amolino.de</a>
        <br>
        Datenschutz: <a routerLink="/" class="underline text-brand-cyan">datenschutz@amolino.de</a>
        <br>
    </p>
    <p>
        <strong class="font-semibold">Behördenanfragen</strong>
        <br>
        Als Behörde verwenden Sie bitte diese E-Mail-Adresse: <a routerLink="/"
            class="underline text-brand-cyan">behoerden@amolino.de</a>
    </p>
    <p>
        <strong class="font-semibold">Streitbeilegung</strong>
        <br>
        Die EU-Kommission stellt eine Plattform zur Online-Streitbeilegung (OS-Plattform) bereit.
        Diese ist hier zu finden: '<a routerLink="/"
            class="underline text-brand-cyan">www.ec.europa.eu/consumers/odr.</a> Die
        Multilabel GmbH ist zur Teilnahme an einem Streitbeilegungsverfahren vor einer
        Verbraucherschlichtungsstelle weder verpflichtet noch bereit.
    </p>
    <p>
        <strong class="font-semibold">Geschäftliche Anfraagen</strong>
        <br>
        Sie haben ein geschäftliches Anliegen? Bitte nutzen Sie dazu die folgende E-Mail-Adresse: <a routerLink="/"
            class="underline text-brand-cyan">business@amolino.de</a>
    </p>
</div>
