import { Options } from "@angular-slider/ngx-slider";
import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "app-slider",
    templateUrl: "./slider.component.html",
})
export class SliderComponent {
    @Input() public minValue = 18;
    @Input() public maxValue = 27;

    options: Options = {
        floor: 18,
        ceil: 99,
    };

    @Output() public valueChanged: EventEmitter<{ min: number; max: number }> = new EventEmitter();

    public sliderValueChanged(): void {
        this.valueChanged.emit({ min: this.minValue, max: this.maxValue });
    }
}
