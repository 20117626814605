import { Component, Input } from "@angular/core";

@Component({
    selector: "app-input-field",
    templateUrl: "./input-field.component.html",
})
export class InputFieldComponent {
    @Input() label!: string;
    @Input() error!: boolean;
    @Input() classprop!: string;
}
