import { Component, Input } from "@angular/core";

@Component({
    selector: "app-as-footer-item",
    templateUrl: "./as-footer-item.component.html",
})
export class AsFooterItemComponent {
    @Input() title!: string;
    @Input() arrowColor = "blue";
    @Input() linkPath!: string;
    @Input() isTargetBlank = false;
}
