<div id="top" class="space-y-6 py-6 flex flex-col">
    <h2 class="heading-magenta">Du benötigst Hilfe?</h2>
    <p>
        <strong class="font-semibold">Nutzeranfragen:</strong>
        <br>
        Du bist bereits Nutzer bei Amolino oder möchtest Dich anmelden? Bei Fragen und Anliegen steht dir unser Kundenservice
        gerne zur Verfügung. Schau gerne vorab in unseren FAQ nach, ob deine Frage bereits beantwortet wurde, bevor du uns
        kontaktierst.
    </p>

    <p>
        Kundenservice: <a href="{{ contactEmailService.getContactEmailHref('Allgemeiner Support') }}" class="underline text-brand-cyan">support@amolino.de</a>
        <br>
        (Montag bis Freitag von 09:00 bis 18:00 Uhr)
    </p>
    <p class="font-semibold">
        FAQ
    </p>
    <div class="space-y-2 flex flex-col">
        <app-faq-item *ngFor="let item of faqList" [title]="item.question">
            <p class="leading-5" [innerHTML]="item.answer"></p>
        </app-faq-item>
    </div>
    <div class="flex items-center justify-center">
        <a class="cursor-pointer text-brand-cyan underline" (click)="toTop()">Nach Oben</a>
    </div>
</div>
