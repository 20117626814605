<div
    *ngIf="!displayLimitReached"
    class="absolute flex flex-col h-full w-full pb-8 pt-[88px] sm:pt-2"
>
    <div class="overflow-scroll no-scrollbar">
        <div *ngIf="newLikes.length > 0">
            <h2 class="pt-6 mb-4 px-4 heading-2 text-brand-magenta">
                Neueste Likes
            </h2>
            <div *ngFor="let user of newLikes" class="mb-3">
                <app-like-item
                    (refreshList)="getLikedByUsers()"
                    (limitReached)="displayLimitReached = true"
                    [user]="user"
                    [preselected]="
                        preselectedUserId
                            ? user._id === preselectedUserId
                            : false
                    "
                ></app-like-item>
            </div>
        </div>
        <div *ngIf="seenLikes.length > 0">
            <h2 class="pt-6 mb-4 px-4 heading-2 text-brand-magenta">Gesehen</h2>
            <div *ngFor="let user of seenLikes" class="mb-3">
                <app-like-item
                    (refreshList)="getLikedByUsers()"
                    (limitReached)="displayLimitReached = true"
                    [user]="user"
                    [preselected]="
                        preselectedUserId
                            ? user._id === preselectedUserId
                            : false
                    "
                ></app-like-item>
            </div>
        </div>
        <h2
            class="pt-6 mb-4 px-4 heading-2 text-brand-magenta"
            *ngIf="newLikes.length === 0 && seenLikes.length === 0"
        >
            Keine Likes
        </h2>
    </div>
</div>
<div class="pt-[90px] md:pt-0">
    <app-discover-limit
        @simpleFadeAnimation
        *ngIf="displayLimitReached"
        (manuallyClosed)="displayLimitReached = false"
        (boughtNewLikes)="displayLimitReached = false"
    >
    </app-discover-limit>
</div>
