import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "app-mobile-wrapper-layout",
    templateUrl: "./mobile-wrapper-layout.component.html",
})
export class MobileWrapperLayoutComponent implements OnInit {
    @Input() type = "";
    public bgImage = "url(assets/images/dekstop_hearts_background.png), ";
    public selectedGradient = "";
    @Input() height = "650"; // Add an input property for the height
    @Input() containerHeight: string; // Add an input property for the height

    ngOnInit(): void {
        this.selectedGradient = this.setBackground(this.type);
        this.containerHeight = this.height;
    }

    /**
     * Set the gradient property according to type
     * @param type
     * @returns The selected typ as string
     */
    setBackground(type: string): string {
        switch (type) {
            case "instantMatchPassive":
                return "linear-gradient(205deg, #B34380 30%, #D7A9C5)";
            case "instantMatchActive":
                return "linear-gradient(205deg, #B34380 30%, #45A6C4)";
            case "loginBonus":
                return "linear-gradient(to right, rgba(235, 187, 211, 0.58), rgba(162, 214, 238, 0.73))";
            case "cyanToMagenta":
                return "linear-gradient(205deg, #45A6C4, #B34380)";
            case "cyan":
                return "linear-gradient(205deg, #45A6C4, #1784A5)";
            default:
                return "";
        }
    }
}
