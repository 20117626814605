<div class="text-[13px]">
    <div
        (click)="openContent = !openContent"
        class="flex items-center justify-between bg-brand-body/5 rounded-lg px-3 py-2 cursor-pointer"
    >
        <p class="!font-bold text-brand-title">{{ title }}</p>
        <img
            class="w-3"
            src="../../../../assets/images/{{
                openContent ? 'arrow_up' : 'arrow_down'
            }}.png"
            alt="arrow_down"
        />
    </div>
    <div *ngIf="openContent" class="px-3 my-3 leading-5 font-medium">
        <ng-content></ng-content>
    </div>
</div>

<!--
<div class="relative overflow-hidden">
    <input type="checkbox" class="peer absolute top-0 inset-x-0 h-12 opacity-0 z-10 cursor-pointer">
    <div (click)="toggleContent()"
        class="flex items-center justify-between p-2  text-brand-title  bg-brand-body/5 rounded-lg cursor-pointer border-brand-title">

        <h1 class="font-semibold px-2">
            {{ title }}
        </h1>
        <div class="text-white transition-transform duration-500 rotate-0 peer-checked:rotate-180">
            <img class="w-3" src="../../../../assets/images/arrow_up.png"
            alt="arrow_down">
        </div>
    </div>


    <div class="bg-white overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-screen">
        <div class="px-3 my-3 leading-4">
            <ng-content></ng-content>
        </div>

    </div>
</div> -->

<!-- <div class="relative overflow-hidden">
    <input type="checkbox" class="peer absolute top-0 inset-x-0 h-12 opacity-0 z-10 cursor-pointer">
    <div
        class="flex items-center justify-between p-2  text-brand-title  bg-brand-body/5 rounded-lg cursor-pointer border-brand-title">

        <h1 class="font-semibold px-2 py-1 text-sm pr-4">
            {{ title }}
        </h1>
    </div>
    <div class="absolute top-4 right-3 text-white transition-transform duration-500 rotate-0 peer-checked:rotate-180">
        <img class="h-2" src="../../../../assets/images/{{ openContent?'arrow_up' : 'arrow_down' }}.png">
    </div>
    <div class="bg-white overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-screen">
        <div class="px-3 my-3 leading-4 text-xs">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
            dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
            clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
            consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
            sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no
            sea takimata sanctus est Lorem ipsum dolor sit amet.
        </div>

    </div>
</div> -->
