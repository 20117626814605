import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "app-shrink-button",
    templateUrl: "./shrink-button.component.html",
})
export class ShrinkButtonComponent implements OnInit {
    @Input() theme!: string;
    @Input() path!: string;
    @Input() name!: string;
    @Input() classProps!: string;
    selectedTheme = "";

    ngOnInit(): void {
        this.setTheme();
    }

    setTheme() {
        const purpleTheme =
            "bg-brand-magenta  hover:bg-[#8e2f65] py-3 text-white text-lg font-medium";
        const pinkTheme = "bg-[#E94365] hover:bg-[#D73C54] text-white text-xl font-medium py-3";

        if (this.theme === "pink") {
            this.selectedTheme = pinkTheme;
        } else if (this.theme === "purple") {
            this.selectedTheme = purpleTheme;
        }
    }
}
