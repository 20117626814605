import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: "app-purchase-unsuccessful",
    templateUrl: "./purchase-unsuccessful.component.html",
})
export class PurchaseUnsuccessfulComponent implements OnInit {
    public product: any;
    constructor(private router: Router) {
        if (!this.router.getCurrentNavigation()?.extras.state) {
            this.router.navigate(["user/shop"]);
        }
    }

    ngOnInit(): void {
        if (sessionStorage.length > 0) {
            const productSS = sessionStorage.getItem("product");
            this.product = productSS ? JSON.parse(productSS) : null;
        }
    }
}
