import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "app-form-input",
    templateUrl: "./input.component.html",
})
export class FormInputComponent {
    @Input() id!: string;
    @Input() label!: string;
    @Input() class?: string;
    @Input() info?: string;
    @Input() placeholder?: string = "z.B. Mathias";
    @Input() maxlength?: number;
    @Output() getInputValueName: EventEmitter<string> = new EventEmitter();
    public value = "";
    @Input() oldValue = "";
    @Input() errorMsg!: string;

    public valueUpdated(event: any): void {
        this.value = event.target.value || "";
        this.getInputValueName.emit(this.value);
    }
}
