import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "app-notify",
    templateUrl: "./notify.component.html",
})
export class NotifyComponent {
    @Input() notification: any;
    @Output() closeNotify: EventEmitter<string> = new EventEmitter();

    onClose() {
        this.closeNotify.emit();
    }
}
