<div class="space-y-6 py-6 flex-col">
    <h2 class="heading-magenta">
        Allgemeine<br />Geschäftsbedingungen für amolino
    </h2>

    <p><strong>I. Allgemeines</strong></p>
    <p><strong>1. Anbieterin</strong></p>
    <p>
        Anbieterin der Dienste unter der Bezeichnung amolino ist die Multilabel
        GmbH, Hohe Bleichen 12, 20354 Hamburg (im Folgenden: „Anbieterin&quot;).
        Der Zugang zu dem Angebot kann unter der Domain
        <a href="http://amolino.de/">amolino.de</a> oder über eine
        Smartphone-App erfolgen. Über diese können angemeldete Kunden sowohl
        kostenfreie als auch kostenpflichtige Features nutzen und andere Kunden
        kennenlernen (im Folgenden zusammengefasst: „Dienst&quot; oder
        „Dienste&quot;). Die Anbieterin ermöglicht ihren Kunden (im Folgenden:
        „Nutzer&quot; – der Begriff schließt alle Personen (m/w/d) ein) während
        der Vertragslaufzeit den Zugriff auf die bereitgestellten Dienste nach
        Maßgabe der nachfolgenden Vertragsbedingungen. Der Dienst der Anbieterin
        versteht sich als ein soziales Netzwerk, das seinen Kunden die
        Möglichkeit bietet, sich mit mittels eines Profils zu präsentieren und
        mit anderen Nutzern zu interagieren.
    </p>
    <p><strong>2. Anwendungsbereich der AGB</strong></p>
    <p>
        Diese Geschäftsbedingungen gelten für die Geschäftsbeziehungen zwischen
        der Anbieterin und dem Nutzer, und zwar hinsichtlich der Nutzung der
        Dienste. Die Dienste und diese AGB richten sich ausschließlich an
        Verbraucher.
    </p>
    <p><strong>3.</strong> <strong>Vertragssprache</strong></p>
    <p>
        Die für den Vertragsschluss zur Verfügung stehende Sprache ist deutsch.
    </p>
    <p><strong>4. Aufruf und Speicherung der AGB</strong></p>
    <p>
        Die aktuellen AGB können jederzeit über den Link in der Fußzeile
        aufgerufen werden.
    </p>
    <p>
        Sie können vom Nutzer über die entsprechenden Funktionen seines Browsers
        zur Archivierung ausgedruckt oder lokal gespeichert werden. Bitte
        beachten Sie, dass wir unsere AGB regelmäßig aktualisieren können.
    </p>
    <p><strong>II. Vertragsinhalte und Vertragsschluss</strong></p>
    <p><strong>1. Nutzung der Dienste</strong></p>
    <p>
        Die Dienste können nach vorheriger Registrierung und Erstellung eines
        Nutzerkontos genutzt werden. Die Registrierung und die Nutzung der
        Grundfunktionen der Dienste sind kostenlos. Weitere, kostenpflichtige
        Funktionen können vom eingeloggten Nutzer innerhalb der App individuell
        freigeschaltet werden. Die Preise für das jeweilige Produkt sind am
        Produkt selbst angegeben.
    </p>
    <p><strong>2. Vertragsschluss</strong></p>
    <p>
        Die unter amolino.de dargestellten Angebote der Anbieterin stellen kein
        verbindliches Angebot im juristischen Sinne dar, sondern eine Einladung
        an den Nutzer, seinerseits durch Eröffnung eines Nutzerkontos ein
        Angebot auf Abschluss eines Nutzungsvertrages abzugeben bzw. bei
        bestehendem Nutzungsvertrag auf seinen Wunsch kostenpflichtige Dienste
        für sich freizuschalten (sog. „invitatio ad offerendum&quot;).
    </p>
    <p>
        Der Vertragsschluss erfolgt elektronisch über die Plattform amolino.de.
        Hierbei kann der Nutzer manuell ein Konto anlegen oder sich über seine
        Login-Daten aus einem bestehenden Konto bei Apple, Google oder Facebook
        anmelden.
    </p>
    <p>
        Vor der verbindlichen Absendung seiner Registrierung und/oder der
        Buchung von kostenpflichtigen Funktionen kann der Nutzer alle von ihm
        gemachten Eingaben laufend über die üblichen Tastatur- und
        Mausfunktionen korrigieren. Darüber hinaus werden alle Eingaben vor
        verbindlicher Absendung der Registrierung und/oder Buchung von
        kostenpflichtigen Funktionen noch einmal in einem Bestätigungsfenster
        angezeigt und können auch dort mittels der üblichen Tastatur- und
        Mausfunktionen korrigiert werden.
    </p>
    <p>Der Vertrag kommt im Einzelnen wie folgt zustande:</p>
    <p>
        <strong
            >2.1 Vertragsschluss „Kontoeröffnung&quot; (Kostenlose
            Basisdienste)</strong
        >
    </p>
    <p>
        2.1.1 Inhalt des Vertrages ist seitens des Nutzers die Eröffnung eines
        Kontos, das ihn berechtigt, nach Eingabe seiner Login-Daten die von der
        Anbieterin bereitgestellten kostenfreien Bestandteile des Dienstes
        amolino.de zu nutzen. Voraussetzung für eine Registrierung ist die
        Vollendung des 18. Lebensjahres.
    </p>
    <p>
        2.1.2 Für die Registrierung stehen dem Nutzer verschiedene Möglichkeiten
        zur Verfügung:
    </p>
    <p>a) Registrierung mit Email-Adresse und Passwortvergabe:</p>
    <p>
        Der Nutzer gibt in einem ersten Schritt eine gültige, auf ihn lautende
        Email-Adresse an und vergibt für seinen amolino-Account ein selbst
        gewähltes Passwort. Die Email-Adresse ist später in den Stammdaten
        hinterlegt und dient der Kontaktaufnahme durch die Anbieterin. Mit
        seinem Passwort und der angegebenen Email-Adresse kann der Nutzer sich
        einloggen, um die Dienste zu nutzen. Andere Nutzer können die
        Email-Adresse nicht sehen und erhalten durch die Anbieterin keinen
        Zugriff.
    </p>
    <p>b) Registrierung über Account bei anderen Diensten:</p>
    <p>
        Alternativ kann der Nutzer sich unter Verwendung seiner dortigen
        Login-Daten über Apple, Google oder Facebook bei der Anbieterin
        registrieren. In diesen Fällen bedarf es keiner Bestätigung der
        Email-Adresse duch den Nutzer, da der Dienst, mit dessen Account sich
        der Nutzer anmeldet (Apple, Google oder Facebook) die Identität des
        Nutzers verifiziert. Ein eigenes Passwort für die amolino-Dienste ist
        nicht erforderlich, da der Login mit den Accountdaten des vom Nutzer
        gewählten Drittanbieters erfolgt. Die dort hinterlegte Email-Adresse des
        Nutzers erhält die Anbieterin in diesen Fällen direkt von dem Dienst,
        über den der Nutzer sich anmeldet.
    </p>
    <p>
        Wählt der Nutzer eine Anmeldung über seinen Apple-, Google- oder
        Facebook-Account, erteilt er der Anbieterin die Berechtigung zum Zugriff
        auf und zur Verwendung von bestimmten Informationen seines dortigen
        Accounts, unter anderem seine dort hinterlegte Email-Adresse und auch
        sein öffentliches Profil, soweit vorhanden. Weitere Informationen zur
        Datenverarbeitung bei einer Anmeldung mit Ihrem Apple-, Google- oder
        Facebook-Login finden Sie in den
        <a class="link" [routerLink]="'/data-protection'"
            >Datenschutzhinweisen der Anbieterin</a
        >.
    </p>
    <p>2.1.3</p>
    <p>
        Bei allen vorgenannten Registrierungswegen gibt der Nutzer durch Klicken
        auf die Schaltfläche „Konto erstellen&quot; gegenüber der Anbieterin ein
        für den Nutzer verbindliches Angebot auf Abschluss eines kostenfreien
        Nutzungsvertrages der Dienste ab.Nach Eingang dieses Angebotes bei der
        Anbieterin sendet sie dem Nutzer an die angegebene Email-Adresse eine
        automatisierte Bestätigungsemail, die den Eingang der Registrierung bei
        der Anbieterin bestätigt. Im Falle einer Anmeldung nach Ziffer 2.1.2 a)
        enthält diese Email zudem einen Link, über den der Nutzer den
        Vertragsschluss bestätigen und zugleich zugleich die Verifikation der
        angegebenen Email-Adresse erklärt. Durch Anklicken dieses Links kommt
        der Vertrag zustande. Im Falle einer Anmeldung nach Ziffer 2.1.2 b)
        kommt der Vertrag mit Zugang der Bestätigungsmail zustande.
    </p>
    <p>
        <strong>2.2 Vertragsschluss „kostenpflichtige Funktionen&quot;</strong>
    </p>
    <p>
        Registrierte und eingeloggte Nutzer können innerhalb ihres
        amolino-Nutzungskontos zudem individuell kostenpflichtige Funktionen
        freischalten, indem sie diese über Schaltflächen in der App / auf der
        Webseite buchen. Die angebotenen Zahlungsmittel heißen innerhalb des
        Dienstes Credits oder Schlüssel und werden mit einem Kurs, der dort
        angegeben ist, in Euro umgerechnet. Die Bezahlung der Credits oder
        Schlüssel erfolgt in Euro über externe Zahlungsdienstleister (s.u.
        Ziffer III.). Die Vergütung ist fällig bei verbindlicher Bestellung wie
        folgt:
    </p>
    <p>
        Durch Klick auf den entsprechenden Button beim Produkt gelangt der
        Nutzer zur Bezahlseite. Nachdem er die gewünschte Zahlungsart ausgewählt
        und mit &quot;Weiter&quot; bestätigt hat, wird er aufgefordert, die
        Daten seiner Bankverbindung bzw. des gewählten Zahlungsdienstleisters
        einzugeben. Bestätigt er diese mit &quot;Weiter&quot;, gibt er die
        Bestellung verbindlich auf und die gebuchten Zahlungseinheiten (Credits
        oder Schlüssel) werden nach Eingang der geschuldeten Vergütung bei der
        Anbieterin seinem amolino-Nutzungskonto gutgeschrieben, von wo aus er
        sie abrufen und zur Nutzung der von ihm ausgewählten kostenpflichtigen
        Funktionen verwenden kann. Die Abwicklung der Zahlung erfolgt über den
        vom Nutzer ausgewählten Zahlungsdienstleister.
    </p>
    <p><strong>3. Speicherung der Vertragsinhalte</strong></p>
    <p>
        Der Inhalt des zwischen dem Nutzer und der Anbieterin geschlossenen
        Vertrages ergibt sich aus den Allgemeinen Geschäftsbedingungen und dem
        individuellen Teil des Vertragsinhalts, nämlich den vom Nutzer
        ausgewählten Diensten und den dazu jeweils von der Anbieterin
        angegebenen Preisen.
    </p>
    <p>
        Der Nutzer kann den Vertragsinhalt für seine Unterlagen dokumentieren,
        indem er die jeweiligen Seiten und die AGB mit Hilfe der Druckfunktion
        seines Browsers ausdruckt oder als PDF speichert. Die zum Zeitpunkt der
        Registrierung geltenden AGB und die Registrierungsangaben erhält der
        Nutzer von der Anbieterin zusammen mit der Bestätigungsmail gemäß Ziffer
        2.1.3 übermittelt.
    </p>
    <p><strong>III. Preise, Umsatzsteuer und Zahlung</strong></p>
    <p>
        1. Die Einrichtung eines Kontos und die Nutzung der Basisdienste von
        amolino.de ist gegenüber der Anbieterin kostenlos. Ggf. entstehen dem
        Nutzer Kosten für die von ihm verwendeten Kommunikationsmittel.
    </p>
    <p>2.</p>
    <p>
        Der Nutzer kann auf eigenen Wunsch zusätzlich kostenpflichtige Angebote
        des Dienstes in Anspruch nehmen. Die Kosten für das jeweilige Produkt
        sind am Produkt angegeben. Bei dem am jeweiligen Produkt angegebenen
        Preis handelt es sich um einen Endpreis, der alle Preisbestandteile
        einschließlich der gesetzlichen Umsatzsteuer enthält. Die Anbieterin ist
        nach den Vorschriften des deutschen Steuerrechts umsatzsteuerpflichtig,
        die gesetzliche Umsatzsteuer wird auf den Rechnungen ausgewiesen.
    </p>
    <p>
        2.1. Die Anbieterin bietet Zahlungsmöglichkeiten über die folgenden
        Zahlungswege bzw. -anbieter an:
    </p>
    <ul>
        <li>
            Banküberweisung (bei Verwendung dieser Zahlungsart erhält der Nutzer
            Nutzer Bonuseinheiten im Wert von 10% des gebuchten Betrags)
        </li>
        <li>
            PayPal (bei Verwendung dieser Zahlungsart erhält der Nutzer
            Bonuseinheiten im Wert von 5% des gebuchten Betrags).
        </li>
        <li>Stripe</li>
        <li>Paysafe.</li>
    </ul>
    <p>
        Die Abwicklung der Zahlungen erfolgt über den vom Nutzer gewählten
        Zahlungsdienstleister zu dessen Bedingungen. Bitte beachten Sie hierzu
        auch die Hinweise in unserer
        <a class="link" [routerLink]="'/data-protection'"
            >Datenschutzerklärung</a
        >.
    </p>
    <p>2.2</p>
    <p>
        Die Gutschrift der gebuchten Werteinheiten (Credits oder Schlüssel) im
        amolino-Nutzerkonto erfolgt, wenn die geschuldete Vergütung bei der
        Anbieterin eingegangen ist.
    </p>
    <p>
        2.3 Die Höhe der Vergütung für kostenpflichtige Dienste richtet sich
        nach den bei der Buchung gültigen Preisen. Diese sind am jeweiligen
        Produkt ausgewiesen. Bei ausgewiesenen Zahlungswegen erhält der Nutzer
        die unter Ziffer III.2.1 ausgewiesenen Bonus-Einheiten. Diese werden von
        der Anbieterin zusammen mit den gebuchten Einheiten automatisch
        gutgeschrieben.
    </p>
    <p><strong>IV. Nutzungsbedingungen der Dienste</strong></p>
    <p><strong>1. Nur private Nutzung</strong></p>
    <p>
        Die Dienste richten sich ausschließlich an privat handelnde Nutzer
        (Verbraucher:innen im Sinne des BGB). Es ist nicht erlaubt, die Dienste
        für gewerbliche, geschäftliche, berufliche oder sonstige kommerzielle
        Zwecke zu nutzen. Dies schließt das Verbot ein, die Plattform und die
        Dienste für das gewerbliche Posten oder Bereitstellen von Links zu
        kommerziellen Internetseiten zu nutzen.
    </p>
    <p><strong>2. Zugang</strong></p>
    <p>
        Der Nutzer erhält durch die Eröffnung seines Nutzerkontos
        („Account&quot;) gemäß Ziffer II. 2.1 die Nutzungsmöglichkeit für die
        Dienste. Jeder Account darf nur durch denjenigen Nutzer genutzt werden,
        der für den Account registriert ist. Bei Zuwiderhandlung behält sich die
        Anbieterin vor, den Zugang zu mit sofortiger Wirkung sperren.
    </p>
    <p>
        Die Anbieterin behält sich weiter vor, alle Daten eines Accounts, die
        älter sind als 180 Tage, zu löschen; davon ausgenommen sind die
        Stammdaten des Nutzers und die von ihm hochgeladenen Bilder sowie die
        erworbenen Guthaben.
    </p>
    <p><strong>3. Moderation</strong></p>
    <p>
        Die Anbieterin behält sich vor, über Moderationen die Aktivitäten auf
        amolino.de zu erhöhen. Dies dient dazu, den Nutzern eine:n
        Chatpartner:in zu vermitteln, auch wenn nicht genügend Nutzer online
        oder registriert sind. Die Moderator:innenen legen im Auftrag der
        Anbieterin Profile an, wobei sie sich auch als fiktive Personen ausgeben
        dürfen. Der Nutzer ist darüber informiert, dass es sich bei einem
        Chatpartner bzw. einer Chatpartnerin um eine:n solche:n Moderator:in
        handeln kann. Der Nutzer willigt in dieses Vorgehen ausdrücklich ein.
    </p>
    <p><strong>4. Verantwortlichkeit und Pflichten des Nutzers</strong></p>
    <p>4.1</p>
    <p>
        Der Nutzer trägt die alleinige Verantwortung für den Inhalt der von ihm
        gemachten Angaben und hochgeladenen Inhalte. Insbesondere sichert er
        gegenüber der Anbieterin zu, dass die von ihm angegebenen Daten und
        Informationen der Wahrheit entsprechen und ihn persönlich beschreiben.
        Die Anbieterin behält sich ausdrücklich vor, die möglichen zivil- und
        strafrechtlichen Schritte einzuleiten und das Konto bis zu einer
        Aufklärung für die Nutzung zu sperren, wenn sie den begründeten Verdacht
        hat, dass der Nutzer falsche Angaben gemacht hat.
    </p>
    <p>4.2</p>
    <p>
        Der Nutzer verpflichtet sich, die Zugangsdaten, die er zur Anmeldung bei
        amolino.de verwendet, vertraulich zu behandeln. Dies dient dem eigenen
        Schutz: Der Nutzer ist verantwortlich für sämtliche Aktivitäten, die
        unter seinen Zugangsdaten auftreten.
    </p>
    <p>
        Wenn Sie denken, dass sich jemand unberechtigt Zugriff auf Ihr Konto
        verschafft hat, kontaktieren Sie bitte unverzüglich die Anbieterin,
        damit wir ggf. Ihr Konto schnellstmöglich sperren können.
    </p>
    <p>4.3</p>
    <p>
        Der Nutzer verpflichtet sich gegenüber der Anbieterin, alle Nachrichten
        und Daten, die er von anderen Nutzern erhält, bzw. von denen er im
        Rahmen seiner Mitgliedschaft Kenntnis erlangt, vertraulich zu behandeln.
        Insbesondere verpflichtet er sich, den Inhalt der Nachrichten nicht ohne
        Einwilligung oder Zustimmung des jeweils anderen Nutzers Dritten
        zugänglich zu machen.
    </p>
    <p>4.4</p>
    <p>
        Der Nutzer verpflichtet sich, seinen Account, seine Daten und seine
        Nachrichten (gesendet und empfangen) in regelmäßigen Abständen
        abzurufen, zu überprüfen und auf eigenen Speichermedien zu archivieren.
        Die Anbieterin ist berechtigt, die in dem Account des Nutzers
        vorhandenen gesendeten und empfangenen Nachrichten nach Ablauf von 180
        Tagen nach Empfang, bzw. Versand aus dem Serverspace der Anbieterin zu
        löschen. Im Rahmen einer kostenlosen Mitgliedschaft können alle Daten
        nach Ablauf von 180 Tage nach letztmaliger Nutzung gelöscht werden,
        soweit keine gesetzlichen Speicherpflichten bestehen und soweit der
        Nutzer keine Guthaben mehr auf diesem Konto hat.
    </p>
    <p><strong>5. Verantwortlichkeit für rechtswidrige Inhalte</strong></p>
    <p>
        Die Anbieterin ist bemüht, ein Umfeld für einen respektvollen Umgang mit
        Personen und Inhalten innerhalb der Plattform zu schaffen. Hierzu dienen
        neben technischen Vorkehrungen der Anbieterin insbesondere die
        Verhaltensregeln, zu den sich alle Nutzer bei Vertragsbeginn
        verpflichten. Die Anbieterin gibt keine Garantie, dass sämtliche von
        Nutzern geteilten oder sonstwie bereitgestellten Inhalte mit diesem
        Vertrag im Einklang stehen. Die Berechtigung der Anbieterin, Inhalte,
        die gegen diesen Vertrag verstoßen, zu prüfen und zu entfernen, ändert
        nichts an der alleinigen Verantwortung der Nutzer für die von ihnen
        hochgeladenen oder bei Nutzung des Dienstes erstellten Inhalte.
    </p>
    <p>
        Der Nutzer ist für die von ihm eingestellten Inhalte einschließlich der
        Inhalte seiner Kommunikation mit anderen Nutzenden der Dienste der
        alleinige Verantwortliche im rechtlichen Sinne.
    </p>
    <p>
        Die Anbieterin weist darauf hin, dass sie nicht für das Verhalten von
        Nutzern innerhalb oder außerhalb des Dienstes verantwortlich ist. Sie
        weist weiter darauf hin, dass sie keine proaktive Prüfung der Inhalte
        durchführt, auch nicht im Hinblick auf die Rechtmäßigkeit der Inhalte.
        Sie verpflichtet die Nutzenden durch die geltenden AGB zu einem im
        Einklang mit dem geltenden Recht stehenden Nutzungsverhalten. Garantien
        gibt sie hierdurch nicht ab. Gegen gemeldete Verstöße wird sie Maßnahmen
        nach diesen AGB sowie nach den gesetzlichen Pflichten und Möglichkeiten
        einleiten.
    </p>
    <p>
        Bitte scheuen Sie nicht, uns zu informieren, wenn Sie Inhalte anderer
        Nutzer bemerken, die Ihrer Einschätzung nach gegen diese
        Vertragsbedingungen verstoßen.
    </p>
    <p><strong>6. Verfügbarkeit</strong></p>
    <p>
        Die Anbieterin stellt dem Nutzer den Zugang zur Plattform amolino.de an
        24 Stunden/Tag und 365 Tage/Jahr zu 99,00 % im Jahresmittel bereit,
        jedoch unter Ausschluss der Wartungsarbeiten. Soweit möglich wird die
        Anbieterin den Nutzer über geplante Wartungsarbeiten rechtzeitig
        informieren. Wartungsarbeiten finden insbesondere in der Zeit zwischen
        20:00 Uhr und 5:00 Uhr statt.
    </p>
    <p><strong>7. Leistungsänderungen</strong></p>
    <p>
        Die Anbieterin ist berechtigt, Inhalte und Funktionen jederzeit zu
        aktualisieren und zu erweitern, um deren hohen Qualitätsstandard laufend
        zu gewährleisten. Sie kann insbesondere Inhalte und Funktionen
        angemessen austauschen oder beschränken, wenn der Austausch und/oder die
        Beschränkung für die Anbieterin erforderlich und unter Berücksichtigung
        der Interessen des Nutzers zumutbar ist. Sollte der Nutzer nicht
        einverstanden sein, kann er von seiner jederzeit bestehenden Möglichkeit
        Gebrauch machen, sein amolino-Konto zu löschen.
    </p>
    <p><strong>8. Angebote Dritter</strong></p>
    <p>
        Auf der Plattform amolino.de finden sich Angebote Dritter. Diese sind
        als solche kenntlich gemacht. Dabei kann es sich um selbständige
        Applikationen oder Werbeeinblendungen oder –links handeln. Im Fall, dass
        der Nutzer die Angebote eines Dritten nutzt oder Vereinbarungen mit
        diesen eingeht, wird nicht die Anbieterin Vertragspartnerin dieser
        Angebote. Vertragspartner sind allein der jeweilige Nutzer und der
        Dritte.
    </p>
    <p><strong>9. Verhaltensregeln für die Nutzung</strong></p>
    <p>Den Nutzern ist es untersagt</p>
    <p>
        9.1. rechtswidrige Inhalte zu verbreiten, insbesondere beleidigende,
        verleumderische, pornographische, rassistische, volksverhetzende oder
        vergleichbare Inhalte, sowie Inhalte, die die Persönlichkeitsrechte,
        Urheberrechte, Markenrechte oder andere Rechte Dritter verletzen,
    </p>
    <p>
        9.2. einen Account im Namen und / oder mit Mailadressen von Dritten zu
        erstellen,
    </p>
    <p>
        9.3. Mechanismen, Software oder Skripte sowie ähnliche Mittel in
        Verbindung mit der Nutzung der Dienste zu verwenden, die die
        Funktionalität oder Erreichbarkeit der Dienste einschränken oder
        behindern oder in sonstiger Weise in die Dienste eingreifen.
    </p>
    <p>
        9.4. innerhalb des Profiltextes persönliche Kontaktdaten, insbesondere
        Telefonnummer(m), Email- oder postalische Adressen oder Kontodaten, zu
        veröffentlichen. Dies dient dem Schutz vor Spam und ungewollten
        Kontaktaufnahmen außerhalb der Plattform. Ebenfalls ist es nicht
        gestattet, die Profiltexte zur Kommunikation oder zu anderen Zwecken zu
        missbrauchen. Hierfür stellt der Dienst spezielle Funktionen bereit.
    </p>
    <p>
        9.5 innerhalb der Dienste finanzrelevante Informationen (z. B.
        Kreditkarten- oder Bankkontodaten) gegenüber anderen Nutzenden
        preiszugeben. Die von der Anbieterin angebotenen Zahlungsflüsse finden
        ausschließlich zwischen der Anbieterin und den Nutzern statt.
        Direktzahlungen zwischen Nutzenden sind nicht Teil der Dienste. Sollte
        ein Nutzer von einem anderen Nutzer Zahlungsaufforderungen erhalten,
        besteht der dringende Verdacht einer betrügerischen Absicht und eines
        Verstoßes gegen die Verhaltensregeln für die Nutzung des Dienstes. Bitte
        scheuen Sie nicht, uns in solchen Fällen zu informieren.
    </p>
    <p><strong>10**</strong>. Rechte Dritter / Nutzungsrechte**</p>
    <p>
        Der Nutzer sichert zu, dass die von ihm hochgeladenen Inhalte keine
        Rechte Dritter verletzt werden und er Inhaber aller für die Nutzung
        benötigten Rechte ist.
    </p>
    <p>
        Der Nutzer räumt der Anbieterin mit dem Einstellen von Inhalten zugleich
        unentgeltlich das räumlich und inhaltlich unbeschränkte
        nichtausschließliche Recht zur Nutzung und Verwertung dieser Inhalte
        während der Vertragslaufzeit zzgl. 180 Tagen ein, insbesondere das Recht
        zur Speicherung und zur Veröffentlichung und öffentlichen
        Zugänglichmachung sowie, soweit dies zur Bereitstellung der Dienste
        erforderlich ist: zur Vervielfältigung, zur Bearbeitung und zur
        Weiterleitung an andere Nutzer:innen des Dienstes. Dies gilt jeweils im
        Zusammenhang mit den Funktionen der Dienste und auf der Plattform.
    </p>
    <p>
        Auf die Datenschutzrechte in unseren
        <a class="link" [routerLink]="'/data-protection'"
            >Datenschutzhinweisen</a
        >
        wird ausdrücklich hingewiesen. Diese haben Vorrang, soweit sie zu diesen
        AGB im Widerspruch stehen und es sich um unverzichtbare Rechte handelt.
    </p>
    <p><strong>11. Maßnahmen der Anbieterin</strong></p>
    <p>
        Die Anbieterin kann folgende Maßnahmen ergreifen, wenn konkrete
        Anhaltspunkte dafür bestehen, dass ein Nutzer gesetzliche Vorschriften,
        Rechte Dritter oder diese Allgemeinen Geschäftsbedingungen verletzt,
        oder wenn die Anbieterin ein sonstiges berechtigtes Interesse hat,
        insbesondere zum Schutz der anderen Nutzer: Verwarnung von Nutzern,
        vorläufige, teilweise oder endgültige Sperrung des Nutzers.
    </p>
    <p><strong>V. Kündigung</strong></p>
    <ol>
        <li><strong>Löschung des kostenfreien Basiskontos</strong></li>
    </ol>
    <p>
        Nutzer können ihr Nutzerkonto jederzeit ohne Angabe von Gründen über die
        Schaltfläche „Konto löschen&quot; auf der Plattform amolino.de löschen
        und dadurch den Vertrag mit der Anbieterin beenden. Die Löschung wird
        180 Tage nach Bestätigung der Löschung vollständig ausgeführt und alle
        Daten endgültig gelöscht, soweit seitens der Anbieterin keine
        gesetzlichen Aufbewahrungspflichten darüber hinaus bestehen. Während des
        180-Tage-Zeitraums ist das Profil mit allen Daten (Stamm-, Nutzungsdaten
        und eingestellte Inhalte) weiterhin bei der Anbieterin gespeichert,
        jedoch für niemanden sichtbar. Der Nutzer hat während des
        180-Tage-Zeitraums die Möglichkeit, die Kündigung wieder rückgängig zu
        machen und das Profil wieder zu aktivieren und zur Nutzung
        freizuschalten.
    </p>
    <p>
        <strong
            >2. Auskehr erworbener und bis zur Kontolöschung nicht verbrauchter
            Guthaben</strong
        >
    </p>
    <p>
        Soweit der Nutzer zum Zeitpunkt der Vertragsbeendigung in seinem Account
        erworbene und nicht verbrauchte Guthaben (Credits oder Schlüssel)
        vorhält, wird deren Gegenwert in EURO an den Nutzer ausgekehrt. Dies
        gilt nicht für Gratiscredits und/oder -schlüssel, die dem Nutzer von der
        Anbieterin unentgeltlich überlassen wurden.
    </p>
    <p>
        Maßgeblich für die Umrechnung in EURO ist die zum Zeitpunkt der Auskehr
        geltende Preisliste. Die Auskehr des Guthabens in EURO erfolgt durch die
        Anbieterin per Überweisung an eine vom Nutzer zu diesem Zweck angegebene
        Kontoverbindung. Der Name des Kontoinhabers bzw. der Kontonhaberin muss
        mit dem Namen des Nutzers übereinstimmen. Dies dient dem Schutz vor
        Missbrauch. Weiter muss es sich um ein Konto in einem EU-Mitgliedsstaat
        oder der Schweiz handeln. Ggf. anfallende Transaktionskosten bei einem
        Auslandskonto trägt der Nutzer.
    </p>
    <p>
        <strong
            >Hat der Nutzer der Anbieterin innerhalb der der 180-tägigen
            Speicherfrist (siehe unter Ziffer V.1) keine Kontoverbindung
            mitgeteilt, kann eine Auskehr nicht mehr erfolgen, wenn nicht im
            Einzelfall zwischen der Anbieterin und dem Nutzer etwas anderes
            vereinbart ist.</strong
        >
    </p>
    <p><strong>3. Außerordentliche Kündigung</strong></p>
    <p>
        Das Recht zur außerordentlichen Kündigung aus wichtigem Grund nach
        Maßgabe der gesetzlichen Bestimmungen bleibt unberührt. Die Parteien
        sind sich insbesondere darüber einig, dass die Anbieterin einen Nutzer
        endgültig von der aktiven Benutzung der Dienste auch dann ausschließen
        kann, wenn dieser bei der Anmeldung falsche Kontaktdaten angegeben hat,
        insbesondere eine falsche oder ungültige E-Mail-Adresse, wenn er andere
        Nutzer oder die Anbieterin in erheblichem Maße schädigt oder wenn ein
        anderer wichtiger Grund vorliegt.
    </p>
    <p>
        Sobald ein Nutzer vorläufig oder endgültig gesperrt wurde, darf er die
        Dienste für die Dauer der Sperre auch mit anderen Nutzerzugängen nicht
        mehr nutzen und sich nicht erneut registrieren.
    </p>
    <p>Die Löschfristen gemäß Ziffer V.1. gelten entsprechend.</p>
    <p><strong>VI. Haftung</strong></p>
    <p>
        1. Die Anbieterin haftet unbeschränkt für vorsätzliches oder grob
        fahrlässiges Verhalten. Sie haftet auch für die leicht fahrlässige
        Verletzung von wesentlichen Pflichten (Pflichten, deren Verletzung die
        Erreichung des Vertragszwecks gefährdet) sowie für die Verletzung von
        Kardinalpflichten (Pflichten, deren Erfüllung die ordnungsgemäße
        Durchführung des Vertrages überhaupt erst ermöglicht und auf deren
        Einhaltung der Nutzer regelmäßig vertraut), jedoch jeweils nur für den
        vorhersehbaren, vertragstypischen Schaden. Für die leicht fahrlässige
        Verletzung anderer als der vorstehenden Pflichten haftet die Anbieterin
        nicht.
    </p>
    <p>
        2. Die Haftungsbeschränkungen des vorstehenden Absatzes gelten nicht bei
        der Verletzung von Leben, Körper und Gesundheit, Im Falle der Übernahme
        von Garantien und bei arglistig verschwiegenen Mängeln. Die Haftung nach
        dem Produkthaftungsgesetz bleibt unberührt.
    </p>
    <p>
        3. Ist die Haftung der Anbieterin ausgeschlossen oder beschränkt, so
        gilt dies ebenfalls für die persönliche Haftung ihrer Angestellten,
        Vertreter und Erfüllungsgehilfen.
    </p>
    <p>
        <strong>VII. Änderungen dieser Allgemeinen Geschäftsbedingungen</strong>
    </p>
    <p>
        Die Anbieterin behält sich vor, ihre Allgemeinen Geschäftsbedingungen
        bei Bedarf mit Wirkung für die Zukunft zu verändern, solange dadurch das
        vertragliche Gleichgewicht zwischen ihr und dem Nutzer nicht gestört
        wird und Änderungen bzw. Abweichungen unter Berücksichtigung der
        Interessen der Anbieterin für den Nutzer zumutbar sind.
    </p>
    <p>
        Die geänderten Nutzungsbedingungen werden dem Nutzer jeweils per E-Mail
        mitgeteilt. Sollte der Nutzer nicht einverstanden sein, kann er von
        seiner jederzeit bestehenden Möglichkeit Gebrauch machen, sein
        amolino-Konto zu löschen.
    </p>
    <p><strong>VIII. Anwendbares Recht / Gerichtsstand</strong></p>
    <p>
        Alle Streitigkeiten aus diesem Rechtsverhältnis unterliegen dem Recht
        der Bundesrepublik Deutschland. Die Geltung von UN-Kaufrecht ist
        ausgeschlossen.
    </p>
    <p>
        Die ladungsfähige Anschrift und Geschäftsadresse der Anbieterin kann dem
        Impressum entnommen werden.
    </p>
    <p>Stand der AGB: 07/2023</p>
</div>
