<app-main-layout [showProfile]="true" [leftMenuText]="'Kontoeinstellungen'">
    <div
        *ngIf="!showDeleteAccount && me"
        class="text-brand-body px-4 pt-16 sm:pt-6 pb-8 space-y-8 flex flex-col justify-between"
    >
        <div class="leading-4">
            <h2 class="text-brand-magenta mb-8 heading-2">Anmeldung</h2>
            <div class="space-y-6 pb-12 border-b border-brand-body/10">
                <!-- Change Username -->
                <div class="flex justify-between items-end">
                    <div>
                        <label class="block mb-1">Nickname</label>
                        <div class="text-brand-title font-bold">
                            {{ me.username ?? "-" }}
                        </div>
                    </div>
                    <a
                        *ngIf='pendingData.includes(pending.changeUsername)'
                        routerLink="/change-username"
                        class="text-brand-cyan font-semibold"
                        >Ändern</a
                    >
                </div>

                <!-- Change Email -->
                <div class="flex justify-between items-end">
                    <div>
                        <label class="block mb-1">E-Mail Adresse</label>
                        <div class="text-brand-title font-bold">
                            {{ me.email ?? "-" }}
                        </div>
                    </div>
                    <a
                        routerLink="/change-email"
                        class="text-brand-cyan font-semibold"
                        >Ändern</a
                    >
                </div>

                <!-- Change Password -->
                <div class="flex justify-between items-end">
                    <div>
                        <label class="block">Passwort</label>
                        <div class="text-brand-title text-2xl font-bold">
                            &#9679;&#9679;&#9679;&#9679;&#9679;&#9679;
                        </div>
                    </div>
                    <a
                        *ngIf="me.authProvider === 'LOCAL'"
                        routerLink="/change-password"
                        class="text-brand-cyan font-semibold"
                        >Ändern</a
                    >
                    <a
                        *ngIf="me.authProvider !== 'LOCAL'"
                        class="text-brand-cyan font-semibold opacity-40"
                        >Ändern</a
                    >
                </div>

                <!-- Single Sign On -->
                <div
                    *ngIf="me && me.authProvider !== 'LOCAL'"
                    class="w-full flex justify-between items-end"
                >
                    <div>
                        <label class="block text-xs mb-2">Verbunden Mit</label>
                        <div class="text-brand-title font-bold">
                            {{
                                me.authProvider === "GOOGLE"
                                    ? "Google"
                                    : "Facebook"
                            }}
                        </div>
                    </div>
                    <a
                        [routerLink]="null"
                        class="text-brand-cyan font-semibold opacity-40"
                        >Ändern</a
                    >
                </div>
            </div>
        </div>

        <div>
            <h2 class="text-brand-magenta mb-8 heading-2">Sucheinstellungen</h2>
            <div class="space-y-6 pb-12 border-b border-brand-body/10">
                <!-- Range -->
                <div>
                    <label class="block mb-4 font-medium" for="umkreissuche"
                        >Umkreissuche</label
                    >
                    <div class="flex justify-evenly space-x-2">
                        <div
                            *ngFor="let range of ranges"
                            class="cursor-pointer border-[3px] text-center border-brand-cyan font-bold rounded-md px-4 mr-1 mb-2 {{
                                (me.lookingFor?.distance ?? 'NEAR') ===
                                range.type
                                    ? 'bg-brand-cyan text-white'
                                    : 'text-brand-cyan'
                            }}"
                            (click)="updateDistanceConstraints(range.type)"
                        >
                            {{ range.distance }} Km
                        </div>
                    </div>
                </div>

                <!-- Age -->
                <div>
                    <label class="block mb-4" for="age">Altersspanne</label>
                    <div #ageSlider>
                        <app-slider
                            (valueChanged)="updateAgeConstraints($event)"
                            [minValue]="me.lookingFor?.age?.min ?? 18"
                            [maxValue]="me.lookingFor?.age?.max ?? 27"
                        ></app-slider>
                    </div>
                </div>

                <!-- Sex -->
                <div class="grid space-y-1">
                    <label class="block" for="Matching"> Matching </label>
                    <app-slide-button
                        [title]="'Alle'"
                        (valueChanged)="updateMatching('ALL')"
                        [state]="matchingState === 'ALL'"
                        [onlyOn]="true"
                    ></app-slide-button>
                    <app-slide-button
                        [title]="'Männer'"
                        (valueChanged)="updateMatching('MALE')"
                        [state]="matchingState === 'MALE'"
                        [onlyOn]="true"
                    ></app-slide-button>
                    <app-slide-button
                        [title]="'Frauen'"
                        (valueChanged)="updateMatching('FEMALE')"
                        [state]="matchingState === 'FEMALE'"
                        [onlyOn]="true"
                    ></app-slide-button>
                </div>
            </div>
        </div>

        <div>
            <h2 class="text-brand-magenta mb-8 heading-2">
                Benachrichtigungen
            </h2>
            <div class="space-y-6 pb-12 border-b border-brand/10">
                <div class="grid space-y-4">
                    <label class="block" for="E-Mail Notifications">
                        E-Mail Benachrichtigung
                    </label>
                    <div class="space-y-2">
                        <app-slide-button
                            [title]="'E-Mail Notifications'"
                            (valueChanged)="updateNotificationSetting($event)"
                            [state]="me.emailNotification ?? false"
                        ></app-slide-button>
                        <app-slide-button
                            [title]="'E-Mail Newsletter'"
                            (valueChanged)="updateNewsletterSetting($event)"
                            [state]="me.emailNewsletter ?? false"
                        ></app-slide-button>
                    </div>

                    <div>
                        Es ist nicht möglich, System-E-Mails zu deaktivieren.
                        Diese E-Mails beinhalten wichtige Informationen wie
                        beispielsweise Anweisungen zum Zurücksetzen des
                        Passworts oder Löschen des Kontos.
                    </div>
                </div>
            </div>
        </div>
        <div class="grid space-y-4 pb-12 border-b border-brand-body/10">
            <app-as-footer-item
                [title]="'Impressum'"
                [linkPath]="'/imprint'"
            ></app-as-footer-item>
            <app-as-footer-item
                [title]="'Allgmeine Geschäftsbedingungen'"
                [linkPath]="'/terms'"
            >
            </app-as-footer-item>
            <app-as-footer-item
                [title]="'Datenschutz Richtlinie'"
                [linkPath]="'/data-protection'"
            ></app-as-footer-item>
            <app-as-footer-item
                [title]="'Widerrufsbelehrungen'"
                [linkPath]="'/right-of-withdrawal'"
            >
            </app-as-footer-item>
            <app-as-footer-item
                [title]="'Hilfe / FAQ'"
                [linkPath]="'/help'"
            ></app-as-footer-item>
            <footer>
                <a
                    href="javascript: Cookiebot.show();"
                    class="w-full flex justify-between items-end"
                >
                    Cookie-Einstellungen
                    <a class="text-brand-cyan font-semibold w-[9px]"
                        ><img
                            src="../../../../assets/images/blue_arrow.png"
                            alt="blue_arrow"
                    /></a>
                </a>
            </footer>
        </div>
        <a
            (click)="showDeleteAccount = !showDeleteAccount"
            class="underline cursor-pointer self-left !mt-2"
        >
            Konto löschen
        </a>
    </div>
    <app-modal
        @simpleFadeAnimation
        *ngIf="showDeleteAccount"
        (closeMatch)="showDeleteAccount = !showDeleteAccount"
        [showSeparationLine]="false"
        [classProps]="'p-6'"
        [customFooter]="true"
        [linkClass]="'text-brand-body font-semibold underline'"
        image="../../../../assets/images/alert2.png"
        h1b="bist du Sicher?"
        h1="{{ name }},"
        [footerLinkText]="'Nein, abbrechen und zurück'"
        (footerLink)="showDeleteAccount = !showDeleteAccount"
    >
        <div subtitle>
            <p class="font-bold">
                Alle Deine Daten werden entfernt, einschließlich Deiner Matches,
                Chats, Bilder und aller persönlichen Informationen.
            </p>
            <br />
            <p>
                Bitte beachte, dass die Löschung Deiner Daten erst
                <span class="font-semibold">(180 Tage nach Bestätigung)</span>
                des Löschvorgangs erfolgt. Falls Du diesen Vorgang abbrechen
                möchtest, kannst Du Dich einfach wieder "einloggen".
            </p>
            <br />
            <p class="underline font-semibold">Hinweis:</p>
            <p>
                Während Dein Konto in Löschung steht, ist Dein Profil für
                niemanden sichtbar.
            </p>
        </div>
        <div content>
            <div class="w-full">
                <app-link-button
                    (userClicked)="deleteAccount()"
                    [isDisabled]="isClickInProgress"
                    [theme]="'pink'"
                    >Ja, Konto löschen</app-link-button
                >
            </div>
        </div>
    </app-modal>
</app-main-layout>
