import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Product } from "src/app/services/shop.service";

@Component({
    selector: "app-purchase-successful",
    templateUrl: "./purchase-successful.component.html",
})
export class PurchaseSuccessfulComponent implements OnInit {
    public product?: Product;
    public transactionNumber?: string;

    constructor(private router: Router) {
        if (this.router.getCurrentNavigation()?.extras.state) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const state = this.router.getCurrentNavigation()?.extras.state as any;
            if (state && Object.prototype.hasOwnProperty.call(state, "description")) {
                this.transactionNumber = state.description;
            }
            if (state && Object.prototype.hasOwnProperty.call(state, "product")) {
                this.product = state.product;
            }
        }
    }

    ngOnInit(): void {
        if (sessionStorage.length > 0 && !this.product) {
            const productSS = sessionStorage.getItem("product");
            this.product = productSS ? JSON.parse(productSS) : undefined;
        }
    }
}
