import { Component, Input } from "@angular/core";

@Component({
    selector: "app-faq-item",
    templateUrl: "./faq-item.component.html",
})
export class FaqItemComponent {
    @Input() public title!: string;
    public openContent = false;

    public toggleContent(): void {
        this.openContent = !this.openContent;
    }
}
