<div class="bg-white h-full" [@bounce]="loading ? 'hide' : 'show'">
    <div>
        <app-overlay
            *ngIf="!loading"
            [classProps]="'p-6'"
            [customFooter]="false"
            [h1]="'Login Bonus'"
            [imageProps]="'w-24 h-24'"
            [image]="'../../../assets/images/auth/loginbonus_modal.png'"
        >
            <div subtitle>
                Du erhältst kostenlose Schlüssel und Credits, indem Du Dich
                täglich einloggst und amolino nutzt. Mit jedem weiteren
                täglichen Login erhöht sich dein Bonus.
            </div>
            <div content>
                <div class="bg-blue-50 w-full shadow-inner px-6 py-4">
                    <div class="flex space-x-4 text-sm">
                        <div id="Icons" class="mt-1.5 space-y-5">
                            <div *ngFor="let icon of icons">
                                <img
                                    *ngIf="!icon.lastIcon"
                                    class="w-2 h-2"
                                    src="../../../assets/images/checkmark_magenta.png"
                                />
                                <img
                                    *ngIf="icon.lastIcon"
                                    class="w-2 h-2"
                                    src="../../../assets/images/forward_blue.png"
                                />
                            </div>
                        </div>
                        <div class="flex-grow space-y-2">
                            <div
                                class="flex justify-between"
                                *ngFor="
                                    let bonus of bonuses;
                                    let indexOfBonus = index
                                "
                            >
                                <div
                                    class="{{
                                        indexOfBonus + 1 === bonusLevel
                                            ? 'font-bold text-black opacity-100'
                                            : 'font-semibold opacity-40'
                                    }}"
                                >
                                    Bonus Stufe {{ bonus.step }}
                                </div>
                                <div
                                    class="{{
                                        indexOfBonus + 1 === bonusLevel
                                            ? 'text-right font-bold text-lime-500 opacity-100'
                                            : 'text-right font-semibold opacity-40'
                                    }}"
                                >
                                    {{
                                        bonus.coins > 0
                                            ? bonus.coins + " Credits + "
                                            : ""
                                    }}
                                    {{ bonus.keys }} Schlüssel
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="w-full pt-6">
                    <app-link-button
                        [theme]="'purple'"
                        (userClicked)="claimBonus()"
                        >Weiter</app-link-button
                    >
                </div>
            </div>
        </app-overlay>
    </div>
</div>
