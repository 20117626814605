<div
    class="bg-gray-100 h-full flex flex-col space-y-14 pt-14 items-center text-brand-body text-[13px]"
>
    <div class="text-brand-title">
        <h1 class="text-4xl text-center font-bold">Huch!</h1>
        <h2 class="text-3xl font-semibold">Der Stapel ist leer</h2>
    </div>
    <img
        class="h-16 w-16"
        src="../../../../assets/images/layers_empty_screen.png"
        alt="layers_empty_screen"
    />
    <p class="text-center leading-6 max-w-[250px]">
        Dein Kontingent für heute ist leider aufgebraucht. Schau gerne morgen
        wieder vorbei, um weitere aufregende Personen zu entdecken.
    </p>
</div>
