import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "customizeDistance",
})
export class CustomizeDistancePipe implements PipeTransform {
    transform(value: string): string {
        switch (value) {
            case "CLOSE":
                return "In deiner Nähe";
            case "NEAR":
                return "Weniger als 15km";
            case "FAR":
                return "Mehr als 50km";
        }
        return "Mehr als 50km";
    }
}
