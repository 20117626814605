import { animate, state, style, transition, trigger } from "@angular/animations";
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { LookingForGender } from "src/app/enums/gender";
import { Distance, OwnProfileData } from "src/app/interfaces/ProfileData";
import Pending from "src/app/enums/pending";
import { ApiService } from "src/app/services/api.service";
import { LogoutService } from "src/app/services/logout.service";
import { MembersMeStoreService } from "src/app/services/members-me-store.service";
@Component({
    selector: "app-account-settings",
    templateUrl: "./account-settings.component.html",
    animations: [
        trigger("simpleFadeAnimation", [
            state("in", style({ opacity: 1 })),
            transition(":enter", [style({ opacity: 0 }), animate(300)]),
            transition(":leave", animate(300, style({ opacity: 0 }))),
        ]),
    ],
})
export class AccountSettingsComponent implements OnInit, AfterViewInit, OnDestroy {
    public me?: OwnProfileData;
    public pendingData:string[] = []
    showDeleteAccount = false;
    public name: string;
    public pending = Pending
    public isClickInProgress = false; // initialize with false

    @ViewChild("ageSlider") private ageSlider: ElementRef;

    toggleDeleteAccount() {
        this.showDeleteAccount = !this.showDeleteAccount;
    }

    public ranges = [
        { type: "CLOSE", distance: 100 },
        { type: "NEAR", distance: 250 },
        { type: "FAR", distance: 500 },
    ];

    constructor(
        private api: ApiService,
        private router: Router,
        private membersMeStore: MembersMeStoreService,
        private logoutService: LogoutService
    ) {}

    ngOnInit(): void {
        this.membersMeStore.getMembersMeFromStore().subscribe((response) => {
            this.me = response;
            if (!response.name) {
                console.warn("No name found in response", response);
                return;
            }
            this.name = response.username ? response.username : '-';
        });

        this.pendingData = this.getInitialPendingArray();
    }

    ngAfterViewInit(): void {
        this.ageSlider.nativeElement.addEventListener("touchmove", this.ageSliderTouchmove, {
            passive: false,
        });
    }

    ngOnDestroy(): void {
        this.ageSlider.nativeElement.removeEventListener("touchmove", this.ageSliderTouchmove);
    }

    ageSliderTouchmove(event) {
        event.preventDefault();
    }

    goBack() {
        history.back();
    }

    public get matchingState(): string {
        return this.me?.lookingFor?.sex ?? "UNDEFINED";
    }

    public updateDistanceConstraints(distanceArg: string): void {
        const distance =
            distanceArg === "NEAR"
                ? Distance.NEAR
                : distanceArg === "CLOSE"
                ? Distance.CLOSE
                : distanceArg === "FAR"
                ? Distance.FAR
                : Distance.NEAR;
        this.api
            .me()
            .update({ distance })
            .subscribe(() => {
                this.membersMeStore.updateMembersMeInStore("lookingFor.distance", distance);
            });
    }

    public updateAgeConstraints({ min, max }): void {
        this.api
            .me()
            .update({ minAge: min, maxAge: max })
            .subscribe(() => {
                if (!this.me || !this.me.lookingFor) return;

                if (this.me.lookingFor.age.min !== min) {
                    this.membersMeStore.updateMembersMeInStore("lookingFor.age.min", min);
                }
                if (this.me.lookingFor.age.max !== max) {
                    this.membersMeStore.updateMembersMeInStore("lookingFor.age.max", max);
                }
            });
    }

    public updateMatching(genderArg: string) {
        const gender: LookingForGender =
            genderArg === "FEMALE"
                ? LookingForGender.FEMALE
                : genderArg === "MALE"
                ? LookingForGender.MALE
                : LookingForGender.ALL;
        this.api
            .me()
            .update({ sex: gender })
            .subscribe(() => this.membersMeStore.updateMembersMeInStore("lookingFor.sex", gender));
    }

    public updateNotificationSetting(state: boolean): void {
        this.api
            .me()
            .update({ emailNotification: state })
            .subscribe(() =>
                this.membersMeStore.updateMembersMeInStore("emailNotification", state)
            );
    }

    public updateNewsletterSetting(state: boolean): void {
        this.api
            .me()
            .update({ emailNewsletter: state })
            .subscribe(() => this.membersMeStore.updateMembersMeInStore("emailNewsletter", state));
    }

    public deleteAccount(): void {
        this.isClickInProgress = true; // set isClickInProgress to true
        this.api
            .me()
            .delete()
            .subscribe((response) => {
                localStorage.setItem("accountJustDeleted", JSON.stringify(true));
                this.logoutService.logout();
                this.router.navigate(["/"], {
                    state: {
                        date: response.deleteBy,
                    },
                });
                this.isClickInProgress = false; // reset isClickInProgress to false once the operation completes
            });
    }

    private getInitialPendingArray(): string[] {
        const initialPending = localStorage.getItem("initialPending");
        if (!initialPending) {
            console.warn("Failed to read localStorage initialPending. User will be logged out.");
            this.logoutService.logout();
            return [];
        }
        const pending: string[] = JSON.parse(initialPending);
        return pending;
    }
}
