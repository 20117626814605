<div
    [ngClass]="[sentByMe ? 'flex' : 'flex flex-row-reverse']"
    class="justify-between px-2 md:px-5"
>
    <div
        class="text-brand-body font-semibold text-xs text-center text-[9px] shrink-0 basis-1/6"
    >
        {{ createdAtTime }}
    </div>
    <div
        (click)="
            sentByMe
                ? (this.showRequestReadConfirmation =
                      !this.showRequestReadConfirmation)
                : ''
        "
        class="basis-5/6 break-words shrink-0 w-4 rounded-md py-2 px-4 text-[0.9rem] leading-1 font-semibold"
        style="filter: drop-shadow(1px 2px 1px rgba(0, 0, 0, 0.3))"
        [ngClass]="[
            sentByMe
                ? 'bg-brand-magenta cursor-pointer text-white'
                : 'bg-white text-brand-body'
        ]"
    >
        {{ message.body }}
    </div>
</div>
<div
    class="justify-between py-2 px-3 md:px-5 flex text-brand-body font-medium text-[11px]"
>
    <div class="shrink-0 basis-1/6"></div>

    <div *ngIf="message.readReceipt" class="basis-5/6 shrink-0">
        <span>
            <img
                class="inline-flex w-2 h-2 mr-1"
                src="../../../../assets/images/checkmark_green.png"
                alt="checkmark_green"
            />
            <span class="font-semibold">Lesebestätigung:</span>
            <span *ngIf="message.readAt; else notReadYet">{{
                readReceipt
            }}</span>
            <ng-template #notReadYet>
                <span> Diese Nachricht wurde noch nicht gelesen.</span>
            </ng-template>
        </span>
    </div>
</div>
<div
    *ngIf="showRequestReadConfirmation"
    @simpleFadeAnimation
    class="z-50 absolute inset-0 top-[43px] md:top-0"
>
    <app-modal
        (closeMatch)="
            showRequestReadConfirmation = !showRequestReadConfirmation
        "
        [topPadding]="'6'"
        [disclaimer]="' '"
        [image]="'../../../../assets/images/lesebestaetigung_modal.png'"
        [keys]="requestReadReceiptPrice"
        [h1]="'Lesebestätigung'"
    >
        <div subtitle>
            <p class="flow-text-logged-in text-brand-body">
                Du möchtest wissen ob und wann ein Kontakt deine Nachricht
                gelesen hat? Kein Problem, fordere jetzt die Lesebestätigung an.
            </p>
        </div>
        <div content>
            <div *ngIf="keys < 2" class="text-center">
                <div class="flex flex-col py-4">
                    <p class="text-brand-danger my-2">
                        Dein Guthaben reicht für diese Aktion nicht aus!
                    </p>
                    <a class="text-brand-cyan underline" routerLink="/user/shop"
                        >Zum Shop</a
                    >
                </div>
            </div>
            <div class="w-full">
                <app-link-button
                    *ngIf="keys < 2"
                    class="w-full"
                    [theme]="'disable'"
                    [isDisabled]="isClickInProgress"
                >
                    Lesebestätigung anfordern
                </app-link-button>
                <app-link-button
                    *ngIf="keys >= 2"
                    [theme]="'purple'"
                    [isDisabled]="isClickInProgress"
                    (click)="keys >= 2 ? requestReadConfirmation() : ''"
                >
                    Lesebestätigung anfordern</app-link-button
                >
            </div>
        </div>
    </app-modal>
</div>
