import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "app-insufficient-balance-for-unlock-profile",
    templateUrl: "./insufficient-balance-for-unlock-profile.component.html",
})
export class InsufficientBalanceForUnlockProfileComponent {
    @Input() member!: any;
    @Output() closeView: EventEmitter<string> = new EventEmitter();

    public close(): void {
        this.closeView.emit();
    }
}
