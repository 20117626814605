<div
    class="flex justify-between items-center px-4"
    *ngIf="!showUnlockProfileModal && !insufficientCreditsForProfileUnlock"
>
    <a
        class="flex space-x-4 md:space-x-2 items-center cursor-pointer"
        (click)="clickOnProfile()"
    >
        <div class="grid relative">
            <div
                class="w-14 h-14 md:h-[50px] md:w-[50px] rounded-full overflow-hidden"
            >
                <img
                    class="{{
                        user.locked ? 'blur-[3px]' : 'blur-none'
                    }} object-cover h-14 w-16  md:h-[50px] md:w-[50px]"
                    src="{{ user.image | addImageBaseUrl }}"
                    alt="user_photo"
                    style="border-radius: 50%"
                />
            </div>
            <!-- <div class="h-[50px] w-[50px] bg-gray-400 rounded-full opacity-80 "></div> -->
            <p
                *ngIf="user.locked"
                class="absolute right-0 bg-brand-magenta rounded-full p-1 border-[3px] border-white"
            ></p>
        </div>
        <div class="leading-normal">
            <h2 class="text-brand-title font-bold">
                {{ user.username ?? '-' }}, {{ user.dob | dateToAge }}
            </h2>
            <p class="!profile-subtitle">
                {{ user.likedAt | dateToLikedAtTime }}
            </p>
        </div>
    </a>

    <div class="flex space-x-2 items-center pr-2">
        <div>
            <img
                (click)="postInteract(user._id, 'LIKE', user.locked)"
                class="{{
                    user.locked
                        ? ''
                        : 'opacity-100 transition-scale duration-500 ease-out scale-100 hover:scale-125 cursor-pointer'
                }}"
                src="../../../../assets/images/like_page_heart_{{
                    user.locked ? 'inactive' : 'active'
                }}.png"
                alt="heart icon"
                width="25px"
                height="25px"
            />
        </div>
        <div
            (click)="toggleUnlockProfileModal(user.locked, $event)"
            class="relative w-[25px] h-[25px] group {{
                !user.locked ? 'cursor-pointer' : ''
            }}"
        >
            <img
                class="absolute {{
                    !user.locked
                        ? 'opacity-50'
                        : 'transition duration-500 ease-out group-hover:scale-50 group-hover:opacity-0'
                }}"
                src="../../../../assets/images/like_page_keyhole.png"
                alt="keyhole icon"
                width="25px"
                height="25px"
            />
            <img
                *ngIf="user.locked"
                class="absolute transition duration-500 ease-out scale-50 group-hover:scale-100"
                src="../../../../assets/images/like_page_keyhole_unlock.png"
                alt="keyhole icon"
                width="25px"
                height="25px"
            />
        </div>
        <div>
            <img
                class="transition-scale duration-500 ease-out scale-100 hover:scale-125 cursor-pointer"
                (click)="deleteLike(user._id)"
                (mouseover)="delete = 'delete_like'"
                (mouseleave)="delete = 'delete_like'"
                src="../../../../assets/images/{{ delete }}.png"
                alt="close icon"
                width="25px"
                height="25px"
            />
        </div>
    </div>
</div>

<div
    *ngIf="showUnlockProfileModal && !insufficientCreditsForProfileUnlock"
    class="bg-white z-20 text-brand-body fixed md:absolute top-0 md:pt-0 h-full w-full"
>
    <app-modal
        [keys]="fees?.profileAccess"
        [topPadding]="'6'"
        smallIcon="../../../../assets/images/lock_shadow.png"
        image="{{ user.image | addImageBaseUrl }}"
        [imageWrapperProps]="'h-40 w-40 rounded-full overflow-hidden'"
        [imageProps]="'h-40 w-40 rounded-full object-cover blur-sm'"
        (closeMatch)="toggleUnlockProfileModal(true, $event)"
        [h1]="'Unentdecktes Profil'"
    >
        <div subtitle>
            Du möchtest wissen, wer an Dir interessiert ist? Schalte jetzt das
            Profil frei und entscheide selbst, ob Du das Interesse erwidern
            möchtest.
        </div>
        <div content>
            <app-link-button
                [isDisabled]="isClickInProgress"
                (click)="profileUnlock(user._id, 'BUY_ACCESS')"
                class="w-full"
                [theme]="'purple'"
            >
                Profil freischalten
            </app-link-button>
        </div>
    </app-modal>
</div>
<div
    @simpleFadeAnimation
    *ngIf="insufficientCreditsForProfileUnlock"
    class="bg-white z-20 text-brand-body fixed md:absolute top-0 md:pt-0 h-full w-full"
>
    <app-insufficient-credits
        (closeForm)="
            insufficientCreditsForProfileUnlock =
                !insufficientCreditsForProfileUnlock
        "
    ></app-insufficient-credits>
</div>
