import { AfterViewInit, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import PaymentProvider from "src/app/enums/payment-provider";
import {
    PaypalTransactionResponse,
    StripeTransactionResponse,
} from "src/app/interfaces/api-responses";
import { ApiService } from "src/app/services/api.service";
import { LogoutService } from "src/app/services/logout.service";
import { Product } from "src/app/services/shop.service";
import { TokenHandlingService } from "src/app/services/token-handling.service";
import { WebToAppService } from "src/app/services/web-to-app.service";
import { environment } from "src/environments/environment";
import { PaypalTransactionDetails } from "../checkout-paypal/checkout-paypal.component";

@Component({
    selector: "app-checkout",
    templateUrl: "./checkout.component.html",
})
export class CheckoutComponent implements OnInit, AfterViewInit {
    public product: Product;
    public stripe: any;
    public secret: string;
    public error = false;
    public success = false;
    public elements: any;
    public paymentIntent: string | null = null;
    public paymentIntentObj: any | null = null;
    public paymentIntentCanceled = false;
    public applePayRequest: any;
    public googlePayRequest: any;
    userAgent: string;
    public applePayAvailable = false;
    public googlePayAvailable = false;
    public showGooglePay = false;
    public showApplePay = false;
    constructor(
        private api: ApiService,
        private router: Router,
        private logoutService: LogoutService,
        private webToAppService: WebToAppService,
        private tokenHandlingService: TokenHandlingService
    ) {
        if (this.router.getCurrentNavigation()?.extras.state) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const state = this.router.getCurrentNavigation()?.extras.state as any;
            if (state && Object.prototype.hasOwnProperty.call(state, "product")) {
                this.product = state.product;
            }
        }

        //If there is not product value go back to shop pages
        if (!this.product) this.router.navigate(["/user/shop"]);

        // @ts-ignore
        this.stripe = Stripe(environment.stripe.publicKey);
        this.elements = this.stripe.elements();

        this.googlePayRequest = this.stripe.paymentRequest({
            country: "DE",
            currency: "eur",
            total: {
                label: "MultiLabel GmbH",
                amount: this.product.cost,
            },
        });

        // Initialize paymentRequest for Apple Pay
        this.applePayRequest = this.stripe.paymentRequest({
            country: "DE",
            currency: "eur",
            total: {
                label: "MultiLabel GmbH",
                amount: this.product.cost,
            },
            // additional Apple Pay specific options
            //applePay: true,
        });

        this.googlePayRequest.on("cancel", () => this.cancelPaymentIntent());
        this.applePayRequest.on("cancel", () => this.cancelPaymentIntent());

        // Determine if Google Pay is supported
        this.googlePayRequest.canMakePayment().then((result) => {
            this.showGooglePay = result?.googlePay ?? false;
        });

        // Determine if Apple Pay is supported
        this.applePayRequest.canMakePayment().then((result) => {
            this.showApplePay = result?.applePay ?? false;
        });
    }

    navigatedBack() {
        this.cancelPaymentIntent();
    }

    cancelPaymentIntent() {
        if (!this.paymentIntentCanceled && this.paymentIntentObj) {
            this.api.shop().cancelStripe(this.paymentIntentObj.paymentIntent.id).subscribe();
            this.paymentIntentCanceled = true;
        }
    }

    ngOnInit(): void {
        if (!this.product) {
            this.router.navigate(["/user/shop"]);
        }
    }

    private async setPaymentIntent() {
        this.paymentIntentObj = await this.stripe.retrievePaymentIntent(this.secret);
    }

    ngAfterViewInit(): void {
        // Listen for a payment success event
        this.googlePayRequest.on("paymentmethod", async () => {
            const { error, paymentMethod } = await this.stripe.createPaymentMethod({
                type: "card",
                card: this.elements.getElement("card"),
            });

            if (error) {
                // Handle error her
            } else {
                // If payment is successful, navigate to 'purchase-successful' route
                this.router.navigate(["/purchase-successful"], {
                    state: {
                        description: paymentMethod.description,
                        product: this.product,
                    },
                });
            }
        });
    }

    public continueWithTransfer(event: MouseEvent): void {
        event.preventDefault();

        this.api
            .shop()
            .buy(this.product._id, PaymentProvider.TRANSFER)
            .subscribe((response: StripeTransactionResponse) => {
                sessionStorage.setItem("product", JSON.stringify(this.product));

                this.router.navigate(["/prepayment-order"], {
                    state: {
                        reference: response.reference,
                        product: this.product,
                    },
                });
            });
    }

    public continueWithStripe(event: MouseEvent, method: string): void {
        event.preventDefault();

        // cancel old payment intent if one was created
        this.cancelPaymentIntent();

        this.api
            .shop()
            .buy(this.product._id, PaymentProvider.STRIPE, method)
            .subscribe((response: StripeTransactionResponse) => {
                sessionStorage.setItem("product", JSON.stringify(this.product));
                this.router.navigate(["/checkout-stripe"], {
                    state: {
                        secret: response.reference,
                        product: this.product,
                    },
                });
            });
    }

    public makePayment(paymentRequest: any): void {
        this.api
            .shop()
            .buy(this.product._id, PaymentProvider.STRIPE, "card")
            .subscribe((response: StripeTransactionResponse) => {
                this.secret = response.reference;
                this.setPaymentIntent();
                this.paymentIntentCanceled = false;
            });
        paymentRequest.show();
        paymentRequest.canMakePayment().then(() => {
            paymentRequest.on("paymentmethod", async (ev) => {
                const { paymentIntent, error } = await this.stripe.confirmCardPayment(
                    this.secret.toString(),
                    {
                        payment_method: ev.paymentMethod.id,
                    }
                );

                if (error) {
                    this.error = true;
                    console.error("Failed to make payment:", error.message);
                    ev.complete("fail");
                } else {
                    if (paymentIntent.status === "succeeded") {
                        this.success = true;
                        ev.complete("success");
                        sessionStorage.setItem("product", JSON.stringify(this.product));

                        this.router.navigate(["/purchase-successful"], {
                            state: {
                                description: paymentIntent.description,
                                product: this.product,
                            },
                        });
                    } else {
                        this.error = true;
                        console.error("Failed to make payment. Reasons are unknown.");
                        ev.complete("fail");
                    }
                }
            });
        });
    }

    public continueWithPayPal(event: MouseEvent): void {
        event.preventDefault();

        this.api
            .shop()
            .buy(this.product._id, PaymentProvider.PAYPAL)
            .subscribe((response: PaypalTransactionResponse) => {
                sessionStorage.setItem("product", JSON.stringify(this.product));

                const paypalTransactionDetails: PaypalTransactionDetails = {
                    clientSecret: response.clientSecret,
                    product: this.product,
                };

                if (this.webToAppService.isUsingApp()) {
                    this.tokenHandlingService.getValidAuthenticationToken().subscribe({
                        next: async (response) => {
                            const accessToken = response.accessToken;
                            if (!accessToken) {
                                console.error(
                                    "Failed to get accessToken, therefore paying with Paypal is not possible."
                                );
                                this.router.navigate(["/purchase-unsuccessful"]);
                                return;
                            }

                            const customTabPaypalResponse =
                                await this.webToAppService.payWithPaypalUsingCustomTab(
                                    paypalTransactionDetails,
                                    accessToken
                                );
                            if (
                                customTabPaypalResponse.purchaseSuccessful &&
                                customTabPaypalResponse.description
                            ) {
                                this.router.navigate(["/purchase-successful"], {
                                    state: {
                                        description: customTabPaypalResponse.description,
                                        product: this.product,
                                    },
                                });
                            } else {
                                this.router.navigate(["/purchase-unsuccessful"]);
                            }
                        },
                        error: (error) => {
                            console.warn(
                                "Failed to get valid authentication token. User wil be logged out.",
                                error
                            );
                            // logout user
                            this.logoutService.logout();
                            this.router.navigate(["/"]);
                        },
                    });
                } else {
                    this.router.navigate(["/checkout-paypal"], {
                        state: {
                            paypalTransactionDetails,
                            product: this.product,
                        },
                    });
                }
            });
    }
}
