<div class="px-6 pb-8 pt-28 sm:pt-8 absolute flex flex-col h-full w-full">
    <h2 class="text-brand-magenta heading-2">Suche</h2>
    <p class="flow-text-logged-in">
        Gib den Nicknamen ein oder Suche nach einem bestimmten Ort.
    </p>

    <form #f="ngForm" (ngSubmit)="onSubmit(f)" novalidate>
        <input name="username" ngModel required #username="ngModel" />
        <button type="submit">Suchen</button>
    </form>

    <h2 class="text-brand-magenta heading-2">Ausgabe</h2>

    <ul>
        <li *ngFor="let member of members">{{ member.username }}</li>
    </ul>
</div>