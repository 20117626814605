import { Location } from "@angular/common";
import { Component } from "@angular/core";

@Component({
    selector: "app-location-info",
    templateUrl: "./location-info.component.html",
})
export class LocationInfoComponent {
    public buttonEnabled = false;

    constructor(private location: Location) {
        setTimeout(() => {
            this.buttonEnabled = true;
        }, 1500);
    }

    navigateBack() {
        this.location.back();
    }
}
