import { Component, Input } from "@angular/core";

@Component({
    selector: "app-image",
    templateUrl: "./image.component.html",
})
export class ImageComponent {
    @Input() image!: any;
    @Input() imageItem!: any;
    @Input() lockIcon = true;
}
