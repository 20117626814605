<div class="{{ classList }}">
    <label class="text-sm leading-6 font-bold text-brand-title">{{
        label
    }}</label>
    <div
        class="flex space-x-2 border-b-2 {{
            !error ? 'border-brand-green' : 'border-black'
        }} text-sm font-medium mt-4 pb-4"
    >
        <ng-content></ng-content>
        <div (click)="clickIcon()" class="shrink-0 w-4">
            <img src="../../../../assets/images/{{ icon }}" />
        </div>
    </div>
    <div class="text-red-500">
        <ng-content select="[error]"></ng-content>
    </div>
</div>
