import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ApiService } from "src/app/services/api.service";
import { MembersMeStoreService } from "src/app/services/members-me-store.service";

import { Router } from "@angular/router";

@Component({
  selector: 'app-change-username',
  templateUrl: './change-username.component.html',
  styleUrls: ['./change-username.component.css'],
  animations: [
    trigger("simpleFadeAnimation", [
        state("in", style({ opacity: 1 })),
        transition(":enter", [style({ opacity: 0 }), animate(300)]),
        transition(":leave", animate(300, style({ opacity: 0 }))),
    ]),
],
})
export class ChangeUsernameComponent implements OnInit {

  form: UntypedFormGroup = new UntypedFormGroup({
    username: new UntypedFormControl(null, [Validators.required, Validators.max(32), Validators.min(4), Validators.pattern(/^[a-zA-Z0-9_.+-]+$/i)]),
  });

  public requestError: string | null = null;
  public requestSuccess = false;
  public isDisabled = false;
  public loading = false;


  constructor(private api: ApiService, private membersMeStore: MembersMeStoreService, private router: Router) {}

  ngOnInit(): void {
    this.membersMeStore.getMembersMeFromStore().subscribe((response) => {
      this.username.setValue(response.username);
    });
  }

  get username(): UntypedFormControl {
    return this.form.get("username") as UntypedFormControl;
  }

  public changeUsername(): void {
    if (this.username.invalid) {
        return;
    }
    this.loading = true;
    this.api
        .me()
        .update({username: this.username.value})
        .subscribe({
            next: (res) => {
                this.form.clearValidators();
                this.requestError = null;
                this.requestSuccess = true;
                this.loading = false;
                this.membersMeStore.updateMembersMeInStore("username", this.username.value);

                setTimeout(() => this.router.navigate(['account-settings']))
            },
            error: (response) => {
                console.log(response)
                this.requestError = response.message;
                if(response.error.errorCode == 3114) {
                  this.requestError = 'Dieser Username exisitiert bereits. Wähle einen anderen.'
                }
                
                this.loading = false;
            },
        });
  } 

}
