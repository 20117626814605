<div [class]="class">
    <ng-content></ng-content>

    <!-- Bottom Border -->
    <div
        class="border-b-2 {{
            checked && !error ? 'border-brand-green' : ' '
        }} {{ error ? 'border-brand-danger' : ' ' }}"
    ></div>

    <!-- Validation Errors -->
    <div class="text-brand-danger">
        {{ error }}
    </div>

    <!-- Input Information -->
    <div *ngIf="info" class="flex space-x-2 mt-4 text-xs">
        <div
            class="bg-white rounded-full w-4 h-4 flex items-center justify-center text-xs font-bold text-brand-magenta shrink-0"
        >
            ?
        </div>
        <span class="text-white">{{ info }}</span>
    </div>
</div>
