import { Observable } from "rxjs";
import { BuyReadReceiptResponse } from "../interfaces/api-responses";
import { ApiService } from "./api.service";

export class MessageService {
    constructor(private api: ApiService) {}

    /**
     * Buys read receipt for message
     * @param messageId The message id
     */

    public message(messageId: string): Observable<BuyReadReceiptResponse> {
        return this.api.post(`messages/${messageId}`);
    }
}
