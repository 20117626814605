<div *ngIf="isAuthenticated !== null">
    <app-main-layout
        *ngIf="isAuthenticated"
        [showProfile]="true"
        [leftMenuText]="'AGB'"
    >
        <div class="px-4 pt-11 md:pt-0">
            <app-terms-content></app-terms-content>
        </div>
    </app-main-layout>
    <app-public-wrapper-layout *ngIf="!isAuthenticated" [type]="'cyan'">
        <div class="md:py-8 flex flex-col md:px-12 space-y-4">
            <a class="link pb-4" href="/">Zurück</a>

            <app-terms-content></app-terms-content>
        </div>
    </app-public-wrapper-layout>
</div>
