import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class ImageService {
    private subject = new Subject<{ imageId: string }>();

    unlockImage(imageId: string) {
        this.subject.next({ imageId });
    }

    onImageUnlocked(): Observable<{ imageId: string }> {
        return this.subject.asObservable();
    }
}
