import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, firstValueFrom, tap } from "rxjs";
import { MeData } from "../interfaces/MeData";
import { ApiService } from "../services/api.service";
import { CacheResolverService } from "../services/cache-resolver.service";
import { AppState } from "../store/app.state";
import { getMeData, updateMeData } from "../store/me-data-store";

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
    constructor(
        private cacheResolver: CacheResolverService,
        private store: Store<AppState>,
        private apiService: ApiService
    ) {}

    /**
     * Intercepts a request and caches the response if required
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //
        // If the request is not a GET, then continue as usual
        if (request.method !== "GET") {
            return this.sendRequest(request, next);
        }

        this.cacheResolver.get(request.url);

        return this.sendRequest(request, next);
    }

    private shouldUpdateMeData = (currentMeData: MeData, newMeData: MeData): boolean => {
        const currentUpdatedBy = new Date(currentMeData.progressiveBonusKeys.updatedBy);
        const newUpdatedBy = new Date(newMeData.progressiveBonusKeys.updatedBy);

        return newUpdatedBy >= currentUpdatedBy;
    };

    /**
     * Sends the request to the next chained middleware
     */
    sendRequest(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(async (event) => {
                // If this is the login request, wait to set the me data in the store. Otherwise the me data update
                // triggers requests, that need the auth token, before the auth token got set. Since this HttpInterceptor
                // runs before the login request finishes
                if (event instanceof HttpResponse && event.body != null) {
                    if (!this.apiService.isAuthenticated()) {
                        setTimeout(() => {
                            this.store.dispatch(updateMeData(event.body.me));
                        });
                    } else {
                        const currentMeData = await firstValueFrom(this.store.select(getMeData));
                        if(event.body.pending) {
                            localStorage.setItem("initialPending", JSON.stringify(event.body.pending));
                        }

                        if (
                            !currentMeData ||
                            this.shouldUpdateMeData(currentMeData, event.body.me)
                        ) {
                            this.store.dispatch(updateMeData(event.body.me));
                        }
                    }
                }
            })
        );
    }
}
