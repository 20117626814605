import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ApiService } from "src/app/services/api.service";
import { FeesService } from "src/app/services/fees.service";
import { MatchDataService } from "src/app/services/match-data.service";
import { UpdateMemberStackService } from "src/app/services/update-member-stack.service";

@Component({
    selector: "app-instant-match-form-modal",
    templateUrl: "./instant-match-form-modal.component.html",
    animations: [
        trigger("fadeIn", [
            state(
                "void",
                style({
                    transform: "translateY(100%)",
                    opacity: 0,
                })
            ),
            state(
                "show",
                style({
                    opacity: 1,
                    transform: "translateY(0)",
                })
            ),
            transition("* <=> *", animate(500)),
        ]),
        trigger("popOverState", [
            state(
                "hide",
                style({
                    transform: "translateY(-100%)",
                })
            ),
            state(
                "show",
                style({
                    transform: "translateY(0)",
                })
            ),
            transition("void => show", animate("600ms ease-out")),
            transition("show => hide", animate("600ms ease-out")),
            transition("hide => show", animate("1000ms ease-in")),
        ]),
    ],
})
export class InstantMatchFormModalComponent implements OnInit {
    @Input() member!: any;
    public isClickInProgress = false;

    @Output() submitInstantMatchSuccessful: EventEmitter<string> = new EventEmitter();

    @Output() closeForm: EventEmitter<string> = new EventEmitter();

    public state = "void";
    @Output() insufficientCredits = new EventEmitter<boolean>();

    public show = true;

    //fees
    public fees: any;

    /**
     * Error occurs when credits are not sufficient
     */
    public insufficientCreditsError = false;

    /**
     * MatchInfo is info data need for displaying matches screen
     */
    public matchInfo = {
        myImage: "",
        partnerImage: "",
        partnerId: "",
        matchId: "",
        type: "",
    };

    /**
     * Message is form data of Instant match form
     */
    form: FormGroup = new FormGroup({
        message: new FormControl("", [Validators.required, Validators.maxLength(160)]),
    });

    constructor(
        private api: ApiService,
        private feesService: FeesService,
        private updateMemberStack: UpdateMemberStackService,
        private matchDataService: MatchDataService
    ) {}

    ngOnInit(): void {
        this.show = false;
        this.feesService.getFees().subscribe((response) => (this.fees = response));
    }

    /**
     * Get input message from instant match form
     */
    public get message(): FormControl {
        return this.form.get("message") as FormControl;
    }

    /**
     * Send Message for instant match
     * @param memberId Member Id
     * @param interaction Interactiont type is INSTANTMATCH
     */
    public submitInstantMatchMessage(memberId: string, interaction: string): void {
        this.isClickInProgress = true;

        this.api
            .members()
            .interact(memberId, interaction, this.message.value)
            .subscribe({
                next: (response) => {
                    this.updateMemberStack.interactWithMember();
                    this.matchInfo.myImage = response.me.image;
                    if (this.member.image[0].image !== undefined) {
                        this.matchInfo.partnerImage = this.member.image[0]?.image;
                    } else {
                        this.matchInfo.partnerImage = this.member.image;
                    }

                    this.matchInfo.partnerId = this.member._id;
                    this.matchInfo.matchId = response.matchId ?? "";

                    //
                    // Submit the event so the start page can react to it (and initiate the animation)
                    this.submitInstantMatchSuccessful.emit(response.matchId);

                    const matchData = this.matchInfo;
                    matchData["type"] = "instantMatchActive";
                    this.matchDataService.changeMatchData(matchData);
                    this.hideModal();
                },
                error: (response) => {
                    if (response.status === 402) {
                        this.insufficientCredits.emit(this.insufficientCreditsError); // emit the value
                    }
                },
            });
        this.isClickInProgress = false;
    }

    /**
     * Get input message from instant match form
     */
    public hideModal() {
        this.state = "void";
        this.closeForm.emit();
    }

    public InsufficientCredits() {
        this.insufficientCredits.emit();
    }
}
