import { Component } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ApiService } from "src/app/services/api.service";

@Component({
    selector: "app-change-password",
    templateUrl: "./change-password.component.html",
})
export class ChangePasswordComponent {
    form: UntypedFormGroup = new UntypedFormGroup({
        password: new UntypedFormControl(null, [
            Validators.required,
            Validators.pattern(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[#$@!%&*?]).{8,}$/),
        ]),
        repeatPassword: new UntypedFormControl(null, [
            Validators.required,
            Validators.pattern(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[#$@!%&*?]).{8,}$/),
        ]),
    });

    public showPassword = false;
    public success = false;
    public showRepeatPassword = false;
    public requestError: string | null = null;
    public requestSuccess: string | null = null;
    public loading = false;

    constructor(private api: ApiService) {}

    passwordInputType = "password";
    togglePasswordVisibility() {
        this.passwordInputType = this.passwordInputType === "password" ? "text" : "password";
    }

    repeatPasswordInputType = "password";
    toggleRepeatPasswordVisibility() {
        this.repeatPasswordInputType =
            this.repeatPasswordInputType === "password" ? "text" : "password";
    }

    public changePassword(): void {
        this.success = false;
        this.loading = true;

        if (this.password.invalid || this.repeatPassword.invalid) {
            return;
            this.loading = false;
        }

        this.api
            .me()
            .resetPassword(this.password.value, this.repeatPassword.value)
            .subscribe({
                next: () => {
                    this.success = true;
                    this.loading = false;

                    this.form.clearValidators();
                    this.requestError = null;
                    this.requestSuccess = "Das Passwort wurde erfolgreich geändert";
                },
                error: (response) => {
                    this.requestError = response.message;
                    this.requestSuccess = null;
                    this.loading = false;
                },
            });
    }

    get password(): UntypedFormControl {
        return this.form.get("password") as UntypedFormControl;
    }

    get repeatPassword(): UntypedFormControl {
        return this.form.get("repeatPassword") as UntypedFormControl;
    }
}
