import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { GalleryImage } from "src/app/interfaces/ProfileData";
import { ApiService } from "src/app/services/api.service";
import { MembersMeStoreService } from "src/app/services/members-me-store.service";
import { NavBarOptions, NavBarService } from "src/app/services/navbar.service";
import { EditPhotoComponent } from "../edit-photo/edit-photo.component";

export interface GalleryImageData extends GalleryImage {
    profileImage: boolean;
}
export interface GalleryImageEditData {
    /**
     * represents image data
     */
    url: string;
    /**
     * TODO: rename
     */
    href: string;
    id: string;
    type: string; // TODO: should be enum
    profileImage: boolean;
}
@Component({
    selector: "app-my-photos",
    templateUrl: "./my-photos.component.html",
    animations: [
        trigger("fadeIn", [
            state(
                "void",
                style({
                    transform: "translateY(100%)",
                })
            ),
            state(
                "show",
                style({
                    transform: "translateY(0)",
                })
            ),
            transition("* <=> *", [style({ position: "absolute" }), animate(500)]),
        ]),
    ],
})
export class MyPhotosComponent implements OnInit {
    public navbarOptions: NavBarOptions = {
        ...NavBarService.StandardNavBarOptions,
        showProfile: true,
        leftMenuText: "Deine Fotos",
        rightMenuIcon: "photo_icon_gallery.png",
        separationLine: true,
    };

    gallery: GalleryImageData[];
    noOfFreeSlots: number;
    public numberUploadedPhotos = 0;
    public isClickInProgress = false;

    displayUploadForm = false;
    hasButton = false;
    @Input() public capture!: string;
    public selectedfile!: File;
    public imgURL: string;
    public rightMenuText: string;
    public height = 720;
    public aspectRatio: number;

    public toBeCropped?: string;
    public loading = false;
    /* TODO: should be refactored later */
    public currentEditPhoto?: GalleryImageEditData;

    /**
     * When the user selects a new profile image and then deselects it, this image will be used as the new profile image
     */
    public fallbackProfileImage?: GalleryImage;

    public imageElement!: ElementRef;
    galleryArr: GalleryImageData[] = [];
    public imageSource!: string;
    constructor(
        public router: Router,
        private membersMeStore: MembersMeStoreService,
        private api: ApiService,
        private navbarService: NavBarService
    ) {}

    getAspectRatio(targetWidthOrHeight, actualWidthOrHeight) {
        return targetWidthOrHeight / actualWidthOrHeight;
    }

    getNewHeightOrWidth(aspectRatio, heightOrWidth) {
        return aspectRatio * heightOrWidth;
    }

    editPhoto(image: GalleryImageEditData) {
        this.currentEditPhoto = image;
        this.navbarService.navbarOptionsChanged.emit(EditPhotoComponent.NavbarOptions);

        this.navbarService.customBackFunction = () => {
            this.currentEditPhoto = undefined;
            this.navbarService.customBackFunction = undefined;

            this.navbarService.navbarOptionsChanged.emit(this.navbarOptions);
            this.setRightMenuText();
        };
    }

    onCroppedImage(file: File) {
        this.toBeCropped = undefined;
        this.loading = true;

        const imageFormData = new FormData();

        imageFormData.append("image", file);
        imageFormData.append("profileImage", "false");
        imageFormData.append("type", "PUBLIC");

        this.api.images(imageFormData).subscribe({
            next: () => {
                this.toBeCropped = undefined;
                this.membersMeStore.removeMembersMeFromStore(); // reload the user data
                this.loading = false;
            },
            error: () => {
                // TODO: show user error message
                this.loading = false;
            },
        });
    }

    compressImage(event: Event) {
        const input = event.target as HTMLInputElement;

        if (!input.files) return;

        const file = input.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (event) => {
            const image_url = event.target ? event.target.result : null;
            const image = document.createElement("img");
            image.src = image_url as string;
            this.imgURL = reader.result as string;

            image.onload = (e) => {
                const canvas = document.createElement("canvas");
                canvas.height = this.height;
                const aspectratio = this.getAspectRatio(
                    this.height,
                    (e.target as HTMLImageElement).height
                );
                canvas.width = this.getNewHeightOrWidth(
                    aspectratio,
                    (e.target as HTMLImageElement).width
                );
                const context = canvas.getContext("2d");
                if (!context) return;
                context.drawImage(image, 0, 0, canvas.width, canvas.height);
                const new_image_url = context.canvas.toDataURL("image/jpeg", 90);
                this.toBeCropped = new_image_url;
            };
        };
    }

    calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
        const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

        return { width: srcWidth * ratio, height: srcHeight * ratio };
    }

    getPhotos() {
        this.membersMeStore.getMembersMeFromStore().subscribe((response) => {
            if (!response.gallery) return;

            const profileImage: GalleryImageData = response.gallery
                .filter((image) => image.image === response.image.image)
                .map((image) => ({ ...image, profileImage: true }))[0];
            const otherImages: GalleryImageData[] = response.gallery
                .filter((image) => image.image !== response.image.image)
                .map((image) => ({ ...image, profileImage: false }));

            this.gallery = [profileImage, ...otherImages];
            this.galleryArr = [profileImage, ...otherImages];

            if (!this.fallbackProfileImage) this.fallbackProfileImage = profileImage;

            this.noOfFreeSlots = 11 - Object.keys(this.gallery).length;
        });
    }

    setRightMenuText() {
        this.navbarService.navbarOptionsChanged.emit({
            rightMenuText: this.galleryArr.length + "/11",
        });
    }

    ngOnInit(): void {
        this.getPhotos();
        // TODO: refactor
        setTimeout(() => {
            this.setRightMenuText();
        });
    }
}
