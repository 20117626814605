<div class="w-full rounded-full">
    <div class="w-full"></div>
    <div>
        <div
            class="w-full h-14 flex justify-center items-center cursor-pointer px-4 py-3 bg-white hover:bg-[#E6E6E6] rounded-full border-[#E6E6E6] border-2"
        >
            <div class="flex items-center justify-center w-1/5">
                <img
                    class="w-8"
                    src="../../../assets/images/auth/login_{{ image }}.png"
                />
            </div>
            <div
                class="flex items-center w-4/5 font-bold text-center text-brand-title justify-left"
            >
                Einloggen mit {{ loginOptionName }}
            </div>
        </div>
    </div>
</div>
