import { Component, Input } from "@angular/core";

@Component({
    selector: "app-close",
    templateUrl: "./close.component.html",
})
export class CloseComponent {
    @Input() theme!: string;
    @Input() public closeColor!: string;
}
