<div
    class="absolute overflow-y-scroll top-0 bottom-0 left-0 z-30 w-full h-full md:flex md:items-center md:justify-center md:bg-gray-400/80"
>
    <app-modal
        [startPageModal]="true"
        *ngIf="!loginIssues && !emailSent"
        (footerLink)="toggleLoginIssues()"
        (closeMatch)="close()"
        [desktopHeight]="'800'"
        [customFooter]="true"
        [desktopWidth]="'400'"
        [topPadding]="'6'"
        [footerLinkText]="
            loginStep === 1
                ? 'Probleme beim Einloggen?'
                : 'Passwort zurücksetzen'
        "
        (clickFooter)="(toggleLoginIssues)"
        [image]="'../../../../assets/images/auth/einloggen_icon.png'"
        [h1]="'Einloggen'"
    >
        <div subtitle>
            Du bist neu bei uns?<br />
            Hier geht es zur
            <span
                ><app-link (clickedLink)="goToRegister()"
                    >Kontoerstellung</app-link
                ></span
            >
        </div>

        <div content>
            <div class="flex flex-col items-center h-full px-4 space-between">
                <!-- Third Party Buttons -->
                <div
                    class="flex flex-col items-center w-full h-full space-y-2 space-between"
                    *ngIf="loginStep === 1"
                >
                    <div
                        (click)="googleLoginClicked()"
                        class="w-full border-[#E6E6E6] border-2 rounded-full"
                    >
                        <div
                            id="signInWithGoogle"
                            class="w-full {{
                                googleButtonLoaded
                                    ? ''
                                    : 'cursor-not-allowed bg-gray-50'
                            }}"
                        >
                            <button
                                role="button"
                                disabled
                                class="w-full h-14 flex justify-center items-center pointer-events-none !px-4 py-3 bg-white hover:bg-[#E6E6E6] rounded-full border-[#E6E6E6]"
                            >
                                <div
                                    class="flex items-center justify-center w-1/5"
                                >
                                    <img
                                        class="w-8"
                                        src="../../../assets/images/auth/login_google.png"
                                    />
                                </div>
                                <div
                                    class="flex items-center w-4/5 font-bold text-center text-brand-title justify-left"
                                >
                                    Einloggen mit Google
                                </div>
                            </button>
                        </div>
                        <template id="signInWithGoogleButton">
                            <div
                                class="w-full h-14 flex justify-center items-center cursor-pointer !px-4 py-3 bg-white hover:bg-[#E6E6E6] rounded-full border-[#E6E6E6] border-2"
                            >
                                <div
                                    class="flex items-center justify-center w-1/5"
                                >
                                    <img
                                        class="w-8"
                                        src="../../../assets/images/auth/login_google.png"
                                    />
                                </div>
                                <div
                                    class="flex items-center w-4/5 font-bold text-center text-brand-title justify-left"
                                >
                                    Einloggen mit Google
                                </div>
                            </div>
                        </template>
                    </div>
                    <app-login-option
                        (click)="signinWithFacebook()"
                        image="facebook"
                        [loginOptionName]="'Facebook'"
                        class="w-full"
                    ></app-login-option>

                    <app-login-option
                        (click)="signInWithApple()"
                        image="apple"
                        [loginOptionName]="'Apple-ID'"
                        class="w-full"
                    ></app-login-option>

                    <div class="pt-4 text-sm text-brand-body">
                        oder Einloggen mit
                    </div>
                    <app-login-option
                        (click)="loginStep = loginStep + 1"
                        image="email"
                        [loginOptionName]="'E-Mail'"
                        class="w-full"
                    ></app-login-option>
                </div>

                <!-- Form -->
                <form
                    *ngIf="loginStep === 2"
                    [formGroup]="form"
                    (ngSubmit)="login()"
                    class="w-full"
                >
                    <app-input-box
                        [label]="'E-Mail'"
                        [icon]="'auth/at-symbol.png'"
                        class="mb-3"
                        [error]="errorEmail"
                    >
                        <input
                            style="font-family: inherit"
                            class="w-full focus:outline-none input text-brand-title placeholder:input-placeholder"
                            placeholder="Wie lautet deine Email"
                            formControlName="email"
                        />
                    </app-input-box>

                    <app-input-box
                        [label]="'Passwort'"
                        [inputValue]="passwordInput"
                        class="w-full"
                        (eyeClicked)="togglePasswordVisibility()"
                        [error]="password.hasError('required')"
                    >
                        <input
                            type="{{ passwordInputType }}"
                            (input)="getInputValue($event)"
                            class="w-full focus:outline-none input text-brand-title placeholder:input-placeholder appearance-none !display-none"
                            placeholder="Wie lautet dein Passwort"
                            formControlName="password"
                        />
                    </app-input-box>
                    <p
                        *ngIf="errorMessage"
                        class="mt-2 text-center text-brand-danger"
                    >
                        {{ errorMessage }}
                    </p>
                    <div class="py-2 mt-8">
                        <app-link-button
                            [loggedIn]="false"
                            [isDisabled]="isClickInProgress"
                            *ngIf="form.valid"
                            (userClicked)="login()"
                            [theme]="'purple'"
                            >Jetzt Einloggen</app-link-button
                        >
                        <app-link-button
                            [loggedIn]="false"
                            *ngIf="!form.valid"
                            [theme]="'disable'"
                            >Jetzt Einloggen</app-link-button
                        >
                    </div>
                    <input type="submit" hidden />
                </form>
            </div>
        </div>
    </app-modal>
    <app-modal
        [startPageModal]="true"
        *ngIf="emailSent"
        (closeMatch)="closeEmailSentScreen()"
        [desktopHeight]="'800'"
        [supportFooter]="true"
        [topPadding]="'6'"
        [image]="'../../../../assets/images/checkmark-circle.png'"
        [desktopWidth]="'400'"
        [h1]="'E-Mail gesendet'"
    >
        <div subtitle class="text-center">
            Eine Bestätigungsmail wurde an Deine E-Mail-Adresse gesendet. Bitte
            überprüfe auch Deinen Posteingang sowie Deinen Spam- bzw.
            Junk-Ordner. Es kann bis zu 5 Minuten dauern, bis die E-Mail bei Dir
            eintrifft.
        </div>
    </app-modal>
    <app-password-reset
        @simpleFadeAnimation
        *ngIf="loginIssues"
        (hidePopup)="closePopup()"
    ></app-password-reset>
</div>
