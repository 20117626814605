import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { OwnProfileData } from "src/app/interfaces/ProfileData";
import { LogoutService } from "src/app/services/logout.service";
import { MembersMeStoreService } from "src/app/services/members-me-store.service";
import { NavBarService } from "src/app/services/navbar.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-my-profile",
    templateUrl: "./my-profile.component.html",
})
export class MyProfileComponent implements OnInit {
    me?: OwnProfileData;
    image: string;
    public selectedfile!: File;
    public imageUploaded: any;
    @Input() public capture!: string;
    public imgURL: string;
    public height = 720;
    public aspectRatio: number;
    constructor(
        private router: Router,
        private membersMeStore: MembersMeStoreService,
        private logoutService: LogoutService,
        private navbarService: NavBarService
    ) {}

    ngOnInit(): void {
        this.membersMeStore.getMembersMe().subscribe((response) => {
            this.me = response;
            this.image = environment.url.image + "/" + this.me.image.image;
        });
        this.navbarService.clickedRightMenu = () => this.logOut();
    }

    getAspectRatio(targetWidthOrHeight, actualWidthOrHeight) {
        return targetWidthOrHeight / actualWidthOrHeight;
    }

    getNewHeightOrWidth(aspectRatio, heightOrWidth) {
        return aspectRatio * heightOrWidth;
    }

    compressImage(event: Event) {
        const target = event.target as HTMLInputElement;

        if (!target.files) return;

        const file = target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (event) => {
            const image_url = event.target ? event.target.result : null;
            const image = document.createElement("img");
            image.src = image_url as string;
            this.imgURL = reader.result as string;

            image.onload = (e) => {
                const canvas = document.createElement("canvas");
                canvas.height = this.height;
                const aspectratio = this.getAspectRatio(
                    this.height,
                    (e.target as HTMLImageElement).height
                );
                canvas.width = this.getNewHeightOrWidth(
                    aspectratio,
                    (e.target as HTMLImageElement).width
                );
                const context = canvas.getContext("2d");
                if (!context) return;
                context.drawImage(image, 0, 0, canvas.width, canvas.height);
                const new_image_url = context.canvas.toDataURL("image/jpeg", 90);
                const imageData = { url: new_image_url as string };
                localStorage.setItem("image", JSON.stringify(imageData));
                document.location.href = "/crop-photo";

                // let new_image = document.createElement("img")
                // new_image.src = new_image_url
                // new_image.className= 'hidden'
                // this.document.getElementById("wrapper").appendChild(image)

                // localStorage.setItem('image', new_image_url);
            };
        };
    }

    calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
        const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

        return { width: srcWidth * ratio, height: srcHeight * ratio };
    }

    logOut() {
        this.logoutService.logout();
        this.router.navigate([""]);
    }
}
