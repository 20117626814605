<!-- css for bg image is in style.css with id -->
<div
    class="h-full overflow-y-scroll flex flex-col bg-no-repeat bg-center bg-cover"
    [ngStyle]="{ 'background-image': bgImage + selectedGradient }"
>
    <!-- header -->
    <div class="hidden md:block pt-8 px-20 lg:px-32">
        <img
            class="w-[90px] md:w-[150px]"
            src="../../../assets/images/logo/1203x405.png"
            alt="amolino logo"
        />
    </div>

    <div class="flex h-full w-full justify-center md:-mt-4 md:h-[700px]">
        <div
            class="h-full w-full relative bg-white md:w-[350px] md:rounded-2xl md:ring-8 md:ring-slate-200 md:ring-opacity-40 md:overflow-scroll no-scrollbar"
        >
            <ng-content></ng-content>
        </div>
    </div>
</div>
