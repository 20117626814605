import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ApiService } from "src/app/services/api.service";

@Component({
    selector: "app-interests",
    templateUrl: "./interests.component.html",
})
export class InterestsComponent implements OnInit {
    interestsArray: any[] = [];
    list: string[] = [];
    disabled = false;
    @Output() getList: EventEmitter<string[]> = new EventEmitter();
    constructor(private api: ApiService) {}
    @Input() me: any;

    ngOnInit(): void {
        this.api.tags().subscribe((response) => {
            this.interestsArray = response.availableTags;
        });

        this.list = this.me?.tags ?? [];
        if (this.list.length === 5) this.disabled = true;
    }

    /**
     * Add tag id in a list and send list to parent set profile component
     * @param item tag id
     */
    public addList(item: string): void {
        if (this.list.includes(item)) {
            this.list = this.list.filter((e) => e !== item);
            this.disabled = false;
        } else {
            if (!this.disabled) {
                this.list.push(item);
                if (this.list.length == 5) this.disabled = true;
            }
        }

        this.getList.emit(this.list);
    }

    public isPrefill(id: string): boolean {
        return this.list.includes(id);
    }
}
