import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { OtherProfileData } from "src/app/interfaces/ProfileData";
import { MatchResponse } from "src/app/interfaces/api-responses";
import { ApiService } from "src/app/services/api.service";
import { NavBarService } from "src/app/services/navbar.service";

@Component({
    selector: "app-profile-chat",
    templateUrl: "./profile-chat.component.html",
})
export class ProfileChatComponent implements OnInit {
    public showProfile: boolean;
    public member: OtherProfileData;
    public matchesResponse: MatchResponse;
    public matchId: string;

    public memberId?: string;
    public visitedFrom: string;

    private currentPath?: string;

    public readonly heartLineHalfFilled: string = "heart-line_half_filled.png";
    public readonly heartLine: string = "heart-line.png";
    public readonly heartLineFilled: string = "heart-line_filled.png";

    constructor(
        private activatedRoute: ActivatedRoute,
        private api: ApiService,
        private location: Location,
        private navbarService: NavBarService
    ) {}

    public showUser() {
        this.showProfile = true;
        this.location.replaceState(`/profile/${this.visitedFrom}/${this.memberId}`);
    }

    public showChat() {
        this.showProfile = false;
        this.location.replaceState(`/chat/${this.matchId}`);
    }

    public fetchProfile() {
        if (!this.memberId) {
            console.error("Failed to fetch profile because member id was not set.");
            return;
        }

        this.api
            .members()
            .find(this.memberId)
            .subscribe((response) => {
                this.member = response.member;
            });
    }

    ngOnInit(): void {
        this.navbarService.clickedAvatar = () => {
            this.showUser();
        };
        this.navbarService.clickedRightMenu = () => {
            this.showChat();
        };

        this.currentPath = this.location.path();

        if (this.currentPath.includes("profile")) {
            this.activatedRoute.params.subscribe((params) => {
                this.memberId = params["id"];
                this.visitedFrom = params["from"];
            });
            if (!this.memberId) {
                console.error("Failed to get member id from route.");
                return;
            }
            this.api
                .members()
                .find(this.memberId)
                .subscribe((response) => {
                    this.member = response.member;
                    const match = response.me.matches.filter(
                        (m: any) => m.partnerId === this.memberId
                    )[0];
                    if(match) this.matchId = match.matchId;
                });

            this.showProfile = true;
        } else {
            this.activatedRoute.params.subscribe((params) => {
                this.matchId = params["id"];
                this.visitedFrom = "chat";
            });
            this.showProfile = false;
            this.api
                .matches()
                .find(this.matchId)
                .subscribe((response) => {
                    this.matchesResponse = response;

                    this.memberId = response.match.partner._id;
                    this.api
                        .members()
                        .find(this.memberId)
                        .subscribe((response) => {
                            this.member = response.member;
                            const match = response.me.matches.filter(
                                (m: any) => m.partnerId === this.memberId
                            )[0];
                            if (match) this.matchId = match.matchId;
                        });
                });
        }
    }
}
