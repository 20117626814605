<!-- TODO: remove dynamic functions from app-main-layout -->
<app-main-layout
    (navigatedBack)="navigatedBack()"
    [showProfile]="true"
    leftMenuText="Zahlung"
    [bottomPadding]="'14'"
>
    <div class="px-4">
        <div
            class="py-4 bg-white flex justify-between text-base items-center sticky top-0 border-b-2 border-opacity-10 border-brand-body pt-20 md:pt-4"
        >
            <div class="font-medium leading-5 flex flex-col space-y-2">
                <p
                    class="text-brand-magenta font-bold text-[0.9rem] text-right"
                >
                    Deine Bestellung:
                </p>
                <div class="">
                    <img
                        class="inline-flex h-[10px]"
                        src="../../../../assets/images/checkmark_green.png"
                    />
                    <span
                        class="text-brand-title ml-1 font-bold text-[0.9rem]"
                        >{{
                            product.qtyCoins
                                ? product.qtyCoins + " Credits"
                                : product.qtyKeys + " Schlüssel"
                        }}</span
                    >
                </div>
            </div>
            <div class="flex flex-col items-end leading-5 pt-2">
                <p class="text-brand-body text-[11px]">Inkl. MwSt.</p>
                <h1 class="font-bold text-[0.9rem] text-right">
                    {{ product.cost / 100 | floatToEuro }}
                </h1>
            </div>
        </div>
    </div>
    <div
        class="bg-white bg-cover bg-no-repeat h-full overflow-y-scroll no-scrollbar text-sm pb-8 sm:pt-0"
    >
        <div class="p-4">
            <form id="payment-form">
                <div id="payment-element">
                    <!--Stripe.js injects the Payment Element-->
                </div>
                <!-- <button id="submit" class="px-5 py-2 text-lg bg-white text-black rounded-md drop-shadow-sm font-bold w-full mt-5">
                    <div class="spinner hidden" id="spinner"></div>
                    <span id="button-text">Kaufen</span>
                </button> -->
                <div class="pt-8">
                    <app-link-button
                        [error]="error"
                        [errorMessage]="'Kauf fehlgeschlagen'"
                        [loading]="loading"
                        [saveText]="'Kaufen'"
                        [savedText]="'Gekauft'"
                        [saveButton]="true"
                        (userClicked)="confirmPayment()"
                        [theme]="'purple'"
                    ></app-link-button>
                </div>
                <div id="payment-message" class="hidden text-white mt-2"></div>
            </form>
            <div class="flex items-center justify-center pt-8">
                <button (click)="cancelOrder()" class="link !text-brand-body">
                    Kauf Abbrechen
                </button>
            </div>
        </div>
    </div>
</app-main-layout>
