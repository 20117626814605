import { LookingForGender, OwnGender } from "../enums/gender";
import Pending from "../enums/pending";
import { AuthProvider } from "../services/api.service";
import { LocationData } from "./MeData";
import Tag from "./Tag";

// TODO: use this everywhere
export enum GalleryImageType {
    PUBLIC = "PUBLIC",
    PRIVATE = "PRIVATE",
    EXCLUSIVE = "EXCLUSIVE",
    INTIMATE = "INTIMATE",
}
export interface GalleryImage {
    id: string;
    image: string; // TODO: find out what this means
    type: GalleryImageType;
}

interface BaseProfileData {
    _id: string;
    /**
     * date of birth
     */
    dob: string | Date;
    gallery?: GalleryImage[]; // TODO: define gallery
    gender: OwnGender;
    image: any; // TODO: define image
    name?: string; // TODO: find out when name is set and when not
    username?: string;
    profileText?: string;
    tags: Tag[];
}

export enum Distance {
    CLOSE = "CLOSE",
    NEAR = "NEAR",
    FAR = "FAR",
}
export interface AgeRange {
    min: number;
    max: number;
}
export interface LookingFor {
    age: AgeRange;
    sex: LookingForGender;
    distance: Distance;
}
export interface OwnProfileData extends BaseProfileData {
    authProvider: AuthProvider;
    thirdPartyId?: string;
    username?:string;
    lookingFor?: LookingFor;
    pending?: Pending[]; // TODO: define pending further
    location: LocationData; // TODO: define location
    coins: number;
    keys: number;
    likes?: number;
    matches?: any[]; // TODO: define matches
    likedBy?: any[]; // TOOD: define likedBy
    emailNotification?: boolean;
    emailNewsletter?: boolean;
    email?: string; // TODO: find out when email is set and when not,
}
export interface OtherProfileData extends BaseProfileData {
    distance: Distance;
}
// TODO: add more fields
export interface PartialProfileData {
    _id: string;
}

// TODO: needs to be merged with other types
export interface MemberStackProfileData {
    _id: string;
    name: string;
    username: string;
    image: any; // TODO: define image
    locked: boolean;
    profileText: string;
    dob: string;
    distance: Distance;
    shop: any; // TODO: is this even used? seen in start-page.component.html
}

// TODO: should probably be merged with the other types
export interface LikedByProfile {
    _id: string;
    dob: string | Date;
    image: any; // TODO: define image
    likedAt: string;
    locked: boolean;
    name: string;
    username: string;
}
