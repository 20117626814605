import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { Router } from "@angular/router";
import { NgModule, APP_INITIALIZER, ErrorHandler } from "@angular/core";
import { BrowserModule, Meta } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PickerModule } from "@ctrl/ngx-emoji-mart";
import { EmojiModule } from "@ctrl/ngx-emoji-mart/ngx-emoji";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import {
    FacebookLoginProvider,
    SocialAuthServiceConfig,
    SocialLoginModule,
} from "angularx-social-login";

import { environment } from "src/environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CachingInterceptor } from "./interceptors/caching.interceptor";
import { LayoutModule } from "./layout/layout.module";
import { PublicModule } from "./public/public.module";
import { metaReducers, reducers } from "./store/app.reducer";

import * as Sentry from "@sentry/angular";


@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        PublicModule,
        HttpClientModule,
        BrowserAnimationsModule,
        PickerModule,
        EmojiModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        StoreDevtoolsModule.instrument(),
        SocialLoginModule,
        LayoutModule,

    ],
    providers: [
        Meta,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CachingInterceptor,
            multi: true,
        },
        {
            provide: "SocialAuthServiceConfig",
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider(environment.facebook.auth.clientId),
                    },
                ],
                onError: (err) => {
                    console.error(err);
                },
            } as SocialAuthServiceConfig,
        },
        {provide: 'googleTagManagerId',  useValue: 'G-4R1T10J1PV'},
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
              showDialog: true,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        }
    ],

    bootstrap: [AppComponent],
})
export class AppModule {}
