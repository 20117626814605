import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: "app-slide-button",
    templateUrl: "./slide-button.component.html",
})
export class SlideButtonComponent implements OnInit {
    @Input() public title!: string;
    @Input() public large: boolean;
    @Input() public height!: string;
    @Input() public activatedColor = "cyan";
    @Input() public inactiveColor = "cyan";

    @Input() public state = false;

    @Input() public onlyOn = false;
    toggleBtn: any;

    @Output() public valueChanged: EventEmitter<boolean> = new EventEmitter();

    @Output() public toggle: EventEmitter<void> = new EventEmitter();

    ngOnInit(): void {
        this.toggleBtn = document.getElementById("toggle-button");
    }

    public toogleButton(): void {
        this.toggle.emit();

        if (this.state && this.onlyOn) {
            return;
        }

        this.state = !this.state;
        this.valueChanged.emit(this.state);
    }
}
