import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from "@angular/core";
import GraphemeSplitter from "grapheme-splitter";
import { Observable, first } from "rxjs";
import Pending from "src/app/enums/pending";
import { MatchResponse } from "src/app/interfaces/api-responses";
import { ApiService } from "src/app/services/api.service";
import { NotificationService } from "src/app/services/notification.service";
import { OneSignalService } from "src/app/services/onesignal.service";

import { animate, query, style, transition, trigger } from "@angular/animations";
import { Fees } from "src/app/interfaces/Fees";
import { MeData } from "src/app/interfaces/MeData";
import { Message } from "src/app/interfaces/chat";
import { Match } from "src/app/interfaces/matches";
import { DeviceInfoService } from "src/app/services/device-info.service";

interface ModifiedMessage extends Message {
    lastMessageBySameSender: boolean;
}

@Component({
    selector: "app-chat",
    templateUrl: "./chat.component.html",
    animations: [
        trigger("slideLeftEnterSelf", [
            transition(":enter", [
                query(":self", [style({ opacity: 0, transform: "translateX(100%)" })], {
                    optional: true,
                }),
                query(
                    ":self",
                    [
                        animate(
                            "0.1s",
                            style({
                                transform: "translateX(70%)",
                                opacity: 0.5,
                            })
                        ),
                    ],
                    { optional: true }
                ),
                query(
                    ":self",
                    [animate("0.2s", style({ transform: "translateX(0)", opacity: 1 }))],
                    { optional: true }
                ),
            ]),
        ]),
    ],
})
export class ChatComponent implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy {
    @Input() matchId?: string;

    @ViewChild("scrollMe") private myScrollContainer: ElementRef;
    @ViewChild("inputField") private inputField: ElementRef;
    @ViewChild("main") private mainElement: ElementRef;
    el = document.getElementById("contenteditableChatInput");
    //fees
    public fees?: Fees;
    public me: MeData;
    multiLines = false;
    send = false;
    emptyField = true;
    requiredCoins = 0;
    charactersLeft = 160;
    coins = 6;
    lowCredits = false;
    showTutorial = false;
    infoCredits = false;
    displayCreditNotification = false;
    insufficentCredits = false;
    instantMatch = false;
    showEmojiPicker = false;
    showRequiredCoins = false;
    private scrolledDown = true;
    public partnerId: string;
    @Input() matchesResponse?: MatchResponse;

    public match?: Match;
    //messages are all response messages collected with this variable
    public loadedMessages: Array<Message | ModifiedMessage> = [];
    //messageArray is sorted array with date and messages array values. This array will be rendered in html
    public messageArray: Array<{ date: string; messages: ModifiedMessage[] }> = [];

    // Track loaded message IDs
    private loadedMessageIds: Array<string> = [];

    public lastMessageBySameSender: boolean;
    public lastMessageSender?: string;
    public unsentMessages: Array<any> = [];
    public unsentMessage: any;
    public readAtPurchased = false;
    public time: Date;
    public date: Date;
    private isAllMessagesLoaded = false;
    private scrollHeightBeforeLoad: number;
    private scrollHeightAfterLoad: number;
    public isLoading = false;

    private messagesContainerMutationObserver?: MutationObserver;
    private messagesRerenderedObserver?: Observable<void>;
    private onTouchScroll?: (event: TouchEvent) => void;
    private onResize?: (event: Event) => void;
    private onTouchEnd?: (event: Event) => void;

    private isSending = false

    tutorials: {
        heading: string;
        description: string;
        image: number;
        buttonText: string;
    }[] = [
        {
            heading: "Lesebestätigung",
            description:
                "Du möchtest wissen, ob Deine Nachricht von Deinem Chat-Partner oder Deiner Chat-Partnerin gelesen wurde? In diesem Fall kannst Du eine Lesebestätigung anfordern. Dazu musst Du einfach nur auf Deine letzte Nachricht klicken und schauen, ob und wann sie gelesen wurde.",
            image: 6,
            buttonText: "Weiter zum Chat",
        },
    ];

    constructor(
        private api: ApiService,
        private notificationService: NotificationService,
        private oneSignalService: OneSignalService,
        private deviceInfoService: DeviceInfoService
    ) {
        this.notificationService.newMessage.subscribe((message: string) => {
            const notification = JSON.parse(message);

            if (notification.match === this.matchId) {
                const asyncMessage = this.convertNotificationToMessageObject(notification);
                this.loadedMessages.push(asyncMessage);
                this.loadedMessageIds.push(asyncMessage._id);
                this.scrolledDown = false;
                this.sortMessagesWithDate(this.loadedMessages);
            }
        });
    }

    ngOnInit(): void {
        // checks for unsent messages in local storage and either retrieves unsent messages or creates new array accordingly
        const unsentMessages = localStorage.getItem("unsentMessages");
        if (unsentMessages !== null) {
            this.unsentMessages = JSON.parse(unsentMessages);
        } else {
            this.unsentMessages = [];
        }

        this.unsentMessage = this.getUnsentMessage(this.matchId);
        this.setComponentInitialValues();

        if (this.matchesResponse) {
            // Remove the "chatTutorial" flag from the pending array
            const initialPending = localStorage.getItem("initialPending");
            if (initialPending) {
                let pending: string[] = JSON.parse(initialPending);
                this.showTutorial =
                    pending.includes("chatTutorial") ||
                    this.matchesResponse.pending.includes(Pending.chatTutorial);
                pending = pending.filter((item) => item !== "chatTutorial");
                localStorage.setItem("initialPending", JSON.stringify(pending));
            }
        }
    }

    ngAfterViewInit() {
        this.messagesRerenderedObserver = new Observable((observer) => {
            this.messagesContainerMutationObserver = new MutationObserver(() => {
                observer.next();
            });
            this.messagesContainerMutationObserver.observe(this.myScrollContainer.nativeElement, {
                attributes: true,
                childList: true,
                subtree: true,
            });
        });

        this.inputField.nativeElement.innerText = "";

        let keyboardOpened = false;

        this.inputField.nativeElement.addEventListener("focus", () => {
            // keyboard opened

            if (this.deviceInfoService.isUsingMobileSafari()) {
                if (this.deviceInfoService.hasHomeButton()) {
                    this.mainElement.nativeElement.style.height = "calc(100% - 225px)"; // 225px is the height of the keyboard on iPhone 6
                } else {
                    this.mainElement.nativeElement.style.height = "calc(100% - 270px)"; // 270 is the height of the keyboard on iPhone 15 Pro Max
                }

                const endOfChat = document.getElementById("endOfChat");
                if (!endOfChat) return;

                endOfChat.scrollIntoView({
                    behavior: "smooth",

                    block: "end",
                });
            }

            keyboardOpened = true;
        });
        this.inputField.nativeElement.addEventListener("blur", () => {
            // keyboard closed

            this.mainElement.nativeElement.style.height = "calc(100%)";

            keyboardOpened = false;
        });

        this.onTouchScroll = (e) => {
            if (this.myScrollContainer.nativeElement) {
                const contains = this.myScrollContainer.nativeElement.contains(e.target);

                if (!contains) {
                    e.preventDefault();
                }
            }
        };

        this.onTouchEnd = () => {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        };
        window.addEventListener("touchend", this.onTouchEnd, {
            passive: false,
        });
        window.addEventListener("touchmove", this.onTouchScroll, {
            passive: false,
        });
        if (window.visualViewport) {
            // when the visualViewport resizes, this usually means that the keyboard is shown
            this.onResize = () => {
                if (!this.deviceInfoService.isUsingMobileSafari()) {
                    this.customScrollToBottom(500);
                } else {
                    if (keyboardOpened) window.scrollTo(0, 0);
                }
            };
            window.visualViewport.addEventListener("resize", this.onResize);
        }
    }

    ngAfterViewChecked() {
        // TODO: investigate this, should probably be moved elsewhere
        if (!this.scrolledDown) {
            this.scrollToBottom(true);
        }
    }

    ngOnDestroy(): void {
        if (this.messagesContainerMutationObserver)
            this.messagesContainerMutationObserver.disconnect();
        if (this.onTouchScroll) window.removeEventListener("touchmove", this.onTouchScroll);
        if (this.onTouchEnd) window.removeEventListener("touchend", this.onTouchEnd);
        if (window.visualViewport && this.onResize)
            window.visualViewport.removeEventListener("resize", this.onResize);
    }

    /**
     * This is used to prevent the keyboard from closing when the user clicks on the submit button (mobile)
     */
    public submitButtonContainerTouchEnd(e: Event) {
        e.preventDefault();
        this.submitMessage();
    }

    toggleEmojiPicker() {
        this.showEmojiPicker = !this.showEmojiPicker;
    }

    public chatField = "";
    public addEmoji(event) {
        this.changedInput;
        const el = document.getElementById("contenteditableChatInput");
        if (el) {
            const text = el?.innerText;

            if (text.length < 160) {
                el.innerText = el.innerText + event.emoji.native;
            }
        }
    }

    parseDate(str_date: string) {
        const today = new Date();
        this.date = new Date(Date.parse(str_date));
        if (this.date.toDateString() == today.toDateString()) {
            return "Heute";
        }
        return (
            this.padTo2Digits(this.date.getDate()) +
            "." +
            this.padTo2Digits(this.date.getMonth() + 1) +
            "." +
            this.padTo2Digits(this.date.getFullYear())
        );
    }

    padTo2Digits(num) {
        return num.toString().padStart(2, "0");
    }

    getUnsentMessage(matchId) {
        //unsent Message in this chat
        const unsentMessageInMatch = this.unsentMessages.filter((object) => {
            return object.matchId === matchId;
        });
        return unsentMessageInMatch[0];
    }

    deleteUnsentMessage(matchId) {
        this.unsentMessage = this.unsentMessages.filter(
            (unsentMessage) => unsentMessage.matchId !== matchId
        );
        this.unsentMessages.splice(this.unsentMessages.indexOf(matchId), 1);
        this.unsentMessage = null;
        localStorage.setItem("unsentMessages", JSON.stringify(this.unsentMessages));
    }

    convertNotificationToMessageObject(notificationObj) {
        const convertedObj = {
            _id: notificationObj.message._id,
            body: notificationObj.message.body,
            createdAt: notificationObj.message.createdAt,
            lastMessageBySameSender: true,
            readAt: notificationObj.message.readAt,
            readReceipt: notificationObj.message.readReceipt,
            recipient: notificationObj.message.recipient,
            sender: notificationObj.message.sender.id ?? notificationObj.message.sender,
        };

        return convertedObj;
    }

    public submitMessage(): void {
        if (this.isSending) return

        const chatInput = document.getElementById("contenteditableChatInput");
        const chatMessage = (chatInput ? chatInput.innerText : "").trim();
        if (chatMessage.length <= 0) {
            return;
        }

        //
        // Hide required coins bubble after sending the message
        this.showRequiredCoins = false;

        if (!this.matchId) return;

        //
        // Send the message
        this.isSending = true
        this.api
            .matches()
            .messages(this.matchId, chatMessage)
            .subscribe({
                next: (response) => {
                    if (response.pending.includes("lowCoins")) {
                        this.displayCreditNotification = true;
                    }
                    if (chatInput) chatInput.innerText = "";
                    this.multiLines = false;
                    this.scrolledDown = false;
                    this.loadedMessages.push({
                        _id: response.message,
                        body: chatMessage,
                        createdAt: new Date().toISOString(),
                        lastMessageBySameSender: this.lastMessageBySameSender,
                        readAt: undefined,
                        readReceipt: undefined,
                        recipient: this.partnerId,
                        sender: this.me._id,
                    });
                    this.loadedMessageIds.push(response.message);

                    // Sort messages array after adding new messages
                    this.sortMessagesWithDate(this.loadedMessages);
                    this.scrollToBottom();
                    this.isSending = false
                },
                error: () => {
                    this.insufficentCredits = true;
                    if (this.unsentMessage) {
                        this.deleteUnsentMessage(this.matchId);
                    }

                    //
                    // Adds unsentMessage to unsent messages array
                    this.scrolledDown = false;
                    this.unsentMessages.push({
                        matchId: this.matchId,
                        messageBody: chatMessage,
                        requiredCoins: this.requiredCoins,
                    });

                    //
                    // Updates localStorage
                    localStorage.setItem("unsentMessages", JSON.stringify(this.unsentMessages));
                    this.unsentMessage = this.getUnsentMessage(this.matchId);

                    if (chatInput) chatInput.innerText = "";
                    this.multiLines = false;

                    this.changedInput();
                    this.scrollToBottom();
                    this.isSending = false
                },
            });
    }

    parseTime(date: Date) {
        return this.padTo2Digits(date.getHours()) + ":" + this.padTo2Digits(date.getMinutes() + 1);
    }

    async closeChatTutorial() {
        this.showTutorial = false;

        // request to send notifications
        await this.oneSignalService.requestPermissions();

        // add user id as external id to onesignal
        this.api
            .me()
            .get()
            .subscribe(async (response) => {
                const user_id = response.me._id;
                this.oneSignalService.setExternalUserId(user_id);
            });
    }

    private setComponentInitialValues() {
        if (!this.matchesResponse) return;

        this.me = this.matchesResponse.me;
        this.match = this.matchesResponse.match;
        this.instantMatch = this.match.instantMatch;
        this.partnerId = this.match.partner._id;
        //Load initial messages
        this.fetchMessagesWithPageIndex();
    }

    public async fetchMessagesWithPageIndex() {
        if (!this.isAllMessagesLoaded && !this.isLoading) {
            // Calculate the page number based on the number of messages already loaded
            const page = Math.floor(this.loadedMessages.length / 30);

            //Save scroll position
            if (page !== 0) {
                this.scrollHeightBeforeLoad = this.myScrollContainer.nativeElement.scrollHeight;
                this.isLoading = true;
            }
            if (!this.matchId) return;
            this.api
                .matches()
                .getMessages(this.matchId, page)
                .subscribe({
                    next: (response) => {
                        // Filter out duplicate messages and append unique new messages
                        const uniqueNewMessages = response.messages.filter(
                            (message) => !this.loadedMessageIds.includes(message._id)
                        );

                        // Append unique new messages to this.messages
                        this.loadedMessages = this.loadedMessages.concat(uniqueNewMessages);

                        // Update loadedMessageIds with the IDs of newly loaded messages
                        uniqueNewMessages.forEach((message: ModifiedMessage) => {
                            this.loadedMessageIds.push(message._id);
                        });

                        // Sort and update the this.messageArray
                        this.sortMessagesWithDate(this.loadedMessages);

                        //check if all messages from response in messages array saved

                        if (this.loadedMessages.length === response.count)
                            this.isAllMessagesLoaded = true;
                    },
                    error: (error) => {
                        console.warn("Failed to get messages:", error);
                    },
                    complete: () => {
                        if (page === 0) {
                            // wait for message elements to be rerendered and then scroll down
                            if (this.messagesRerenderedObserver)
                                this.messagesRerenderedObserver.pipe(first()).subscribe(() => {
                                    this.myScrollContainer.nativeElement.scrollTo(
                                        0,
                                        this.myScrollContainer.nativeElement.scrollHeight
                                    );
                                });
                        } else {
                            setTimeout(() => {
                                this.isLoading = false;
                                this.scrollHeightAfterLoad =
                                    this.myScrollContainer.nativeElement.scrollHeight;
                                this.myScrollContainer.nativeElement.scrollTo(
                                    0,
                                    this.scrollHeightAfterLoad - this.scrollHeightBeforeLoad
                                );
                            }, 50);
                        }
                    },
                });
        } else {
            //Just make sure that the above part will execute even all messages were fetched it can be new messages there
            this.isAllMessagesLoaded = false;
        }
    }

    public onScrolledUp() {
        this.fetchMessagesWithPageIndex();
    }

    /**
     * Sort messages according to date
     * @param messages Messages that should be sorted before rendering in html
     * @returns Sorted Array with date as keys and messages array as values
     */
    private sortMessagesWithDate(messages: Message[] = []) {
        // Sort the messages by their createdAt property
        messages.sort((a, b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
            return dateA.getTime() - dateB.getTime();
        });

        // Assign the sorted messages to messageArray
        this.messageArray = [];

        for (const singleMessage of messages) {
            const date = this.parseDate(singleMessage.createdAt);
            const message: ModifiedMessage = {
                ...singleMessage,
                lastMessageBySameSender: singleMessage.sender === this.lastMessageSender,
            };
            this.lastMessageSender = singleMessage.sender;
            // Find the entry in messageArray for the current date, or create a new one
            const dateEntry = this.messageArray.find((entry) => entry.date === date);

            if (dateEntry) {
                // Add the message to the existing date entry
                dateEntry.messages.push(message);
            } else {
                // Create a new date entry and add the message
                this.messageArray.push({ date, messages: [message] });
            }
        }
    }

    customScrollToBottom = (duration) => {
        let start;

        const animationStep = (timestamp) => {
            if (!start) {
                start = timestamp;
            }
            const time = timestamp - start;
            const percent = Math.min(time / duration, 1);
            const to =
                this.myScrollContainer.nativeElement.scrollHeight -
                this.myScrollContainer.nativeElement.clientHeight;
            const currentScrollPos = this.myScrollContainer.nativeElement.scrollTop;

            if (Math.abs(to - currentScrollPos) < 2) {
                this.myScrollContainer.nativeElement.scrollTo(0, to);
                return;
            }

            const newPos = currentScrollPos + (to - currentScrollPos) * percent;

            this.myScrollContainer.nativeElement.scrollTo(0, newPos);

            if (time < duration) {
                window.requestAnimationFrame(animationStep);
            }
        };
        window.requestAnimationFrame(animationStep);
    };

    /**
     * Scrolls the chat to the bottom so every added message is visible
     */
    scrollToBottom(instant = false): void {
        const endOfChat = document.getElementById("endOfChat");
        if (!endOfChat) return;

        try {
            setTimeout(() => {
                endOfChat.scrollIntoView({
                    behavior: instant ? undefined : "smooth",

                    block: "end",
                    inline: "start",
                });
            }, 100);

            this.scrolledDown = true;
        } catch (err) {
            console.error("Error scrolling to bottom", err);
        }
    }

    changedInput() {
        const el = document.getElementById("contenteditableChatInput");
        const text = el?.innerText ?? "";
        const splitter = new GraphemeSplitter();
        if (el) el.innerText.length > 0 ? (this.emptyField = false) : (this.emptyField = true);
        const characterCount = splitter.countGraphemes(text.replace(/\n/g, ""));
        characterCount > 0 ? (this.showRequiredCoins = true) : (this.showRequiredCoins = false);
        this.requiredCoins = 2 * Math.ceil(characterCount / 160);
        if (text) {
            if (text && text.length > 47) {
                this.multiLines = true;
            } else {
                this.multiLines = false;
            }
        }
    }
    // private setFees() {
    //     this.feesService.getFees().subscribe((response) => {
    //         this.fees = response;
    //         this.requiredCoins = response.message;
    //     });
    // }
}
