<!-- TODO: this is not a optimal solution -->
<div class="absolute bottom-0 w-full">
    <div
        class="p-4 pb-8 -mb-5 bg-cover"
        style="
            background-image: url(&quot;../../../../assets/images/entdecken_foto_fade.png&quot;);
        "
    >
        <h1 class="text-2xl text-white font-semibold">
            {{ member?.username ? member.username : '-' }}, {{ member?.dob | dateToAge }}
        </h1>
        <div>
            <img
                class="inline-flex mr-2 h-5"
                src="../../../../assets/images/geopin.png"
                alt="location-icon"
            />
            <span class="">{{ member?.distance | customizeDistance }}</span>
        </div>
        <p
            class="text-white drop-shadow-2xl text-sm whitespace-nowrap overflow-hidden overflow-ellipsis"
        >
            {{ member?.profileText ?? "-" }}
        </p>
    </div>
    <div
        class="bg-white w-full flex flex-col items-center px-4 rounded-t-2xl py-4 md:rounded-b-2xl text-brand-body text-[13px]"
    >
        <div (click)="close()" class="self-end cursor-pointer">
            <app-close [closeColor]="'gray'"></app-close>
        </div>
        <img
            class="h-10 w-10 my-2"
            src="../../../../assets/images/profile_unlock_modal.png"
            alt="profile_unlock_modal"
        />
        <h1 class="text-3xl text-brand-title font-semibold my-2">
            Profil freischalten
        </h1>
        <p class="text-center my-2 leading-6">
            Du möchtest alle Fotos von
            <strong>{{ member?.username ? member.username : '-' }}</strong> sehen? Dann schalte jetzt ihr
            vollstöndiges Profil frei.
        </p>
        <p class="text-brand-danger my-2">
            Dein Guthaben reicht für diese Aktion nicht aus!
        </p>
        <a class="text-brand-cyan underline" routerLink="/user/shop"
            >Zum Shop</a
        >
        <div class="w-full">
            <app-link-button [theme]="'disable'">
                Freischalten
            </app-link-button>
            <p class="content text-center">Diese Funktion kostet:</p>
            <div class="text-xs flex items-center space-x-2 mt-2">
                <!-- TODO: change icoon with right one later -->
                <img
                    class="h-4 w-4"
                    src="../../../../assets/images/key_circle.png"
                    alt="sale_big"
                />
                <p class="font-bold text-brand-title">2x Schlüssel</p>
            </div>
        </div>
    </div>
</div>
