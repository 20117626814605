import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: "root",
})
export class ContactEmailService {
    public id?: string;

    constructor(private api: ApiService, private authService: AuthService) {
        if (this.authService.isAuthenticated) {
            // get the user id
            this.api
                .me()
                .get()
                .subscribe((response) => {
                    this.id = response.me._id;
                });
        }
    }

    getContactEmailHref(topic: string): string {
        if (this.id) {
            return "mailto:support@amolino.de?subject=Meine ID " + this.id + " - " + topic;
        } else {
            return "mailto:support@amolino.de?subject=" + topic;
        }
    }
}
