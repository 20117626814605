<div *ngIf="showAlternativeKeyShop" @simpleFadeAnimation>
    <app-insufficient-credits
        (closeForm)="showAlternativeKeyShop = !showAlternativeKeyShop"
    ></app-insufficient-credits>
</div>
<div class="h-full rounded-none md:rounded-lg flex flex-col bg-white p-6">
    <div>
        <app-close [closeColor]="'gray'" (click)="close()"></app-close>
    </div>
    <div class="h-full flex flex-col justify-center">
        <div class="space-y-8">
            <div class="flex justify-center">
                <img
                    class="w-12"
                    src="'../../../../../../assets/images/no_slides_left.png"
                />
            </div>
            <h1 class="heading-1 text-center">Das wars für heute</h1>
            <div class="flex justify-center text-center flow-text-logged-in">
                Die täglichen Aktionen für Liken und Ablehnen sind verbraucht.
                Du kannst 24 Stunden warten oder das Tageslimit zurücksetzen.
            </div>

            <div class="w-full">
                <app-link-button
                    [isDisabled]="false"
                    (click)="buyMore()"
                    [theme]="'purple'"
                >
                    Tageslimit zurücksetzen
                </app-link-button>
            </div>
            <div class="text-[13px]">
                <p class="content text-center">Diese Funktion kostet:</p>
                <div class="flex items-center justify-center space-x-2 mt-2">
                    <img
                        class="h-4 w-4"
                        src="../../../../assets/images/key_circle.png"
                        alt="sale_green_outline"
                    />
                    <p class="bold text-xs text-brand-title">
                        {{ fee }}x Schlüssel
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
