<div class="relative">
    <a
        [routerLink]="link"
        class="font-semibold {{ activePath ? '!opacity-100' : 'opacity-60' }}"
    >
        <ng-content></ng-content>
    </a>
    <img
        *ngIf="displayNotification"
        class="h-2 absolute top-0 -right-4"
        src="../../../../assets/images/magenta_dot.png"
    />
    <div
        *ngIf="activePath"
        class="border-y-2 sm:border border-white absolute -bottom-[7px] sm:-bottom-1 w-full rounded-full"
    ></div>
</div>
