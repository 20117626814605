<!-- <div class="flex items-center justify-between border-b-2 border-brand-title text-brand-title space-x-2 cursor-pointer">

    <h1 class="text-[15px] font-semibold">{{ title }}</h1>
    <span class="rotate-90 text-2xl">&gt;</span>
</div> -->

<div class="relative overflow-hidden">
    <input
        type="checkbox"
        class="peer absolute top-0 inset-x-0 h-12 opacity-0 z-10 cursor-pointer"
    />
    <div
        class="h-12 items-center justify-between text-brand-title space-x-2 cursor-pointer border-b-2 border-brand-title"
    >
        <h1 class="font-bold w-1/2">
            {{ title }}
        </h1>
    </div>
    <div
        class="absolute top-3 right-3 text-white transition-transform duration-500 rotate-0 peer-checked:rotate-180"
    >
        <img src="../../../../assets/images/arrow_down.png" />
    </div>
    <div
        class="bg-white overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-screen"
    >
        <div class="">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
            et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
            Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
            sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
            et dolore magna aliquyam erat, sed diam voluptua. At vero eos et
            accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
            no sea takimata sanctus est Lorem ipsum dolor sit amet.
        </div>
    </div>
</div>
