<app-main-layout [showProfile]="true" [leftMenuText]="'Passwort ändern'">
    <div class="flex flex-col space-y-8">
        <form [formGroup]="form" class="pt-11 sm:pt-0">
            <div class="absolute -top-7 right-4 font-medium text-white text-sm">
                <button
                    [disabled]="!form.valid"
                    class="flex space-x-2 items-center {{
                        !form.valid ? 'opacity-40' : ' '
                    }}"
                >
                    <p class="underline">Speichern</p>
                    <img
                        class="h-3"
                        src="../../../../assets/images/save_icon.png"
                        alt="save_icon"
                    />
                </button>
            </div>
            <div class="px-4 text-brand-body text-[13px]">
                <h2 class="my-10 text-brand-magenta heading-2">
                    Neues Passwort setzen
                </h2>
                <p class="leading-6 mb-10">
                    Das Passwort muss aus mindestens acht Zeichen bestehen und
                    folgende Kriterien erfüllen: mindestens ein Großbuchstabe,
                    eine Zahl und ein Sonderzeichen.
                </p>
                <app-input-box
                    [label]="'Passwort'"
                    (eyeClicked)="togglePasswordVisibility()"
                >
                    <input
                        class="w-full focus:outline-none text-base placeholder:opacity-50"
                        placeholder="Neues Passwort eingeben"
                        type="{{ passwordInputType }}"
                        formControlName="password"
                    />
                    <p
                        error
                        *ngIf="
                            password.touched && password.hasError('required')
                        "
                    >
                        Bitte füllen Sie dieses Feld aus.
                    </p>
                    <p
                        error
                        *ngIf="password.touched && password.hasError('pattern')"
                    >
                        Das Passwort muss mindestens 8 Zeichen, einen
                        Großbuchstaben, eine Zahl und ein Sonderzeichen
                        enthalten.
                    </p>
                </app-input-box>
                <app-input-box
                    [label]="'Passwort wiederholen'"
                    (eyeClicked)="toggleRepeatPasswordVisibility()"
                >
                    <input
                        class="w-full focus:outline-none text-base placeholder:opacity-50"
                        placeholder="Neues Passwort wiederholen"
                        type="{{ repeatPasswordInputType }}"
                        formControlName="repeatPassword"
                    />
                    <p
                        error
                        *ngIf="
                            repeatPassword.touched &&
                            repeatPassword.hasError('required')
                        "
                    >
                        Bitte füllen Sie dieses Feld aus.
                    </p>
                    <p
                        error
                        *ngIf="
                            repeatPassword.touched &&
                            repeatPassword.hasError('pattern')
                        "
                    >
                        Das Passwort muss mindestens 8 Zeichen, einen
                        Großbuchstaben, eine Zahl und ein Sonderzeichen
                        enthalten.
                    </p>
                </app-input-box>

                <!-- <app-input-box [label]="'Passwort wiederholen'" [icon]="'account-settings/view.png'" (eyeClicked)="showRepeatPassword =! showRepeatPassword" [error]="!repeatPassword.valid">
                <input class="w-full focus:outline-none text-base placeholder:opacity-50" type="{{ showRepeatPassword? 'text' : 'password' }}" placeholder="Neues Passwort wiederholen" formControlName="repeatPassword" />
                <p error *ngIf="repeatPassword.touched && repeatPassword.value !== password.value">
                    Das gewählte Passwort stimmt nicht überein.
                </p>
            </app-input-box> -->
                <p class="text-brand-danger" *ngIf="requestError !== null">
                    {{ requestError }}
                </p>
                <p class="text-brand-green" *ngIf="requestSuccess !== null">
                    {{ requestSuccess }}
                </p>
            </div>
        </form>
        <div class="px-4">
            <app-link-button
                [isDisabled]="password.invalid || repeatPassword.invalid"
                [loading]="loading"
                [success]="success"
                [saveButton]="true"
                (userClicked)="changePassword()"
                [theme]="'purple'"
                >Speichern</app-link-button
            >
        </div>
    </div>
</app-main-layout>
