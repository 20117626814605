<div
    [ngClass]="['bg-' + bgColor]"
    class="md:bg-dashboard bg-center bg-no-repeat bg-cover {{
        hScreen ? 'h-screen' : ''
    }} md:bg-fixed main-navbar h-full w-full md:overflow-y md:overflow-y-scroll flex flex-col"
>
    <!-- Header -->
    <div
        [ngClass]="[navbarRelative ? 'relative' : 'fixed']"
        class="text-white w-full top-0 md:static bg-brand-cyan md:bg-transparent pt-0 md:pt-4 text-sm md:px-20 z-10 drop-shadow-md"
    >
        <div
            class="items-center justify-between px-4 md:-mb-6 lg:-mb-12 flex"
            [ngClass]="[
                showProfile || showLoginBonus
                    ? 'pt-0 md:pt-4 mb-0'
                    : 'pt-4 mb-4',
                leftMenuText ? '' : ''
            ]"
        >
            <div
                class="{{
                    showProfile || showLoginBonus ? 'hidden md:block' : ''
                }}"
            >
                <a href="/"
                    ><img
                        class="w-[85px] md:w-40"
                        src="../../../assets/images/logo/1203x405.png"
                        alt="amolino logo"
                /></a>
            </div>

            <!-- User -->
            <div
                class="{{
                    showProfile || showLoginBonus ? 'hidden md:block' : ''
                }}"
            >
                <div class="flex space-x-1 lg:space-x-4 items-center">
                    <!-- Coins and Keys -->
                    <div
                        class="flex border pl-1 pr-2 space-x-2 items-center border-white rounded-full bg-white/20 text-sm font-semibold"
                    >
                        <div class="flex items-center">
                            <img
                                src="../../../../../assets/images/navbar/credits.png"
                                width="20px"
                                height="20px"
                            />
                            <div *ngIf="coins">
                                {{ coins.toString().padStart(3, "0") }}
                            </div>
                        </div>
                        <div class="flex items-center space-x-0.5">
                            <img
                                src="../../../../../assets/images/navbar/keys.png"
                                width="18px"
                                height="18px"
                            />
                            <div *ngIf="keys">
                                {{ keys.toString().padStart(3, "0") }}
                            </div>
                        </div>
                    </div>

                    <!-- Profile Image -->
                    <div class="flex space-x-3 items-center">
                        <p class="hidden lg:blocknt-semibold">
                            {{ me?.username ?? "-" }},
                            {{ me?.dob?.toString() ?? "-" | dateToAge }}
                        </p>
                        <a routerLink="/my-profile">
                            <img
                                src="{{ me ? imagePath + '/' + me.image : '' }}"
                                class="w-8 h-8 object-cover rounded-full ring-4 ring-white/20"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!-- Home Navbar -->
        <div
            *ngIf="!(showProfile || showLoginBonus)"
            class="mt-2 lg:mt-0 cursor-pointer md:md-width mx-auto"
        >
            <div
                class="flex justify-around md:bg-white/[.22] md:p-3 md:rounded-full pb-[6px] font-medium [&>a]:text-white [&>a]:opacity-70 hover:[&>a]:opacity-100 [&>a]:whitespace-nowrap"
            >
                <app-menu-item
                    [activePath]="activePath === '/user/discover'"
                    [link]="'/user/discover'"
                    [displayNotification]="displayDiscoverNotification"
                >
                    Entdecken
                </app-menu-item>
                <app-menu-item
                    [activePath]="activePath === '/user/chats'"
                    [link]="'/user/chats'"
                    [displayNotification]="displayChatNotification"
                >
                    Chats
                </app-menu-item>
                <app-menu-item
                    [activePath]="activePath === '/user/like-you'"
                    [displayNotification]="displayLikeYouNotification"
                    [link]="'/user/like-you'"
                >
                    Likes
                </app-menu-item>
                <!-- <app-menu-item
                    [activePath]="activePath === '/user/search'"
                    [link]="'/user/search'"
                >
                    Suchen
                </app-menu-item> -->
                <app-menu-item
                    [activePath]="
                        activePath === '/user/shop' ||
                        activePath === '/purchase-unsuccessful' ||
                        activePath === '/purchase-successful'
                    "
                    [link]="'/user/shop'"
                >
                    Shop
                </app-menu-item>
            </div>
        </div>

        <!-- mobile notification main pages-->
        <div class="relative w-screen block md:hidden">
            <app-notify
                (closeNotify)="displayNotification = !displayNotification"
                *ngIf="displayNotification && !(showProfile || showLoginBonus)"
                class="w-full z-50 top-0 absolute"
                [notification]="notification"
            ></app-notify>
        </div>

        <!-- Submenu Navbar -->
        <div
            *ngIf="showProfile || showLoginBonus"
            class="flex items-center justify-between md:bg-white/[.22] md:rounded-full bg-brand-cyan md:mb-4 md:md-width mx-auto"
        >
            <!-- Left Item -->
            <div
                *ngIf="!showLoginBonus"
                class="flex items-center justify-center"
            >
                <a
                    *ngIf="leftMenuText"
                    class="cursor-pointer p-4"
                    (click)="
                        leftMenuIcon === 'backarrow'
                            ? goBack()
                            : emitLeftMenuClicked()
                    "
                    [style.pointer-events]="
                        isLeftMenuClickDisabled ? 'none' : 'auto'
                    "
                >
                    <img
                        src="../../../../assets/images/{{ leftMenuIcon }}.png"
                        alt="{{ leftMenuIcon }}"
                        class="{{
                            leftMenuIcon === 'backarrow' ? 'h-[0.8rem]' : 'h-4'
                        }}"
                    />
                </a>
                <div *ngIf="separationLine && !showLoginBonus" class="pr-2">
                    <div
                        class="h-5 border-l-2 border-opacity-30 border-white"
                    ></div>
                </div>
                <div (click)="emitAvatarClicked()">
                    <a
                        class="flex space-x-2 items-center cursor-pointer {{
                            leftTextLinkPath ? ' ' : 'pointer-events-none'
                        }}"
                        [routerLink]="leftTextLinkPath"
                    >
                        <img
                            *ngIf="userAvatar"
                            class="rounded-full ring-4 sm:ring-0 ring-[#f5f5f521] object-cover h-[1.7rem] w-[1.7rem]"
                            src="{{ imagePath + '/' + userAvatar }}"
                        />
                        <div class="relative w-fit">
                            <div class="flex flex-col">
                                <p class="font-bold">{{ leftMenuText }}</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <!-- Right Item -->
            <div
                *ngIf="!showLoginBonus"
                class="flex space-x-2 items-center pr-4 justify-center cursor-pointer group"
                (click)="
                    !isRightMenuClickDisabled ? emitRightMenuClicked() : ''
                "
            >
                <div class="relative">
                    <div class="flex flex-col">
                        <a
                            [class.group-hover:underline]="
                                !isRightMenuClickDisabled
                            "
                            class="font-bold opacity-{{ rightMenuOpacity }} {{
                                isRightMenuClickDisabled ? 'opacity-30' : ''
                            }} {{
                                !rightMenuLinkPath ? 'pointer-events-none' : ''
                            }}"
                            [routerLink]="rightMenuLinkPath"
                            [style.pointer-events]="
                                isRightMenuClickDisabled ? 'none' : 'auto'
                            "
                        >
                            {{ rightMenuText }}
                        </a>
                    </div>
                </div>
                <img
                    *ngIf="rightMenuIcon"
                    class="{{ userAvatar ? 'h-8' : 'h-4' }}"
                    [ngClass]="{
                        'h-5 ':
                            rightMenuIcon === 'logout.png' ||
                            rightMenuIcon === 'save_icon.png' ||
                            rightMenuIcon === 'send_email.png',
                        'h-[1.3rem]':
                            rightMenuIcon === 'heart-line.png' ||
                            rightMenuIcon === 'heart-line_half_filled.png' ||
                            rightMenuIcon === 'heart-line_filled.png'
                    }"
                    src="../../../../assets/images/{{ rightMenuIcon }}"
                />
            </div>
        </div>

        <div
            *ngIf="menuBar && !showLoginBonus"
            [@slide]="menuPositionLeft ? 'left' : 'right'"
            (click)="menuPositionLeft = !menuPositionLeft"
            class="absolute w-1/2 sm:hidden"
        >
            <div
                class="{{
                    menuPositionLeft ? 'ml-12 mr-4' : 'ml-20 mr-4'
                }} bg-white rounded-md h-1"
            ></div>
        </div>
    </div>
    <!-- Container -->
    <div
        class="md:flex-grow flex h-full lg:space-x-8 justify-between md:px-20 flow-text"
        [ngClass]="[
            showProfile || showLoginBonus ? 'md:mt-2' : 'md:mt-6',
            navbarRelative ? 'overflow-hidden md:overflow-visible' : ''
        ]"
    >
        <div class="hidden md:block lg:flex-1">
            <div
                class="hidden lg:flex flex-col justify-center space-y-40 items-start w-full"
            >
                <div class="space-y-4">
                    <div class="flex space-x-4 items-center">
                        <img
                            src="../../../../assets/images/support-thumb.png"
                            class="w-11 h-11 object-cover rounded-full ring-4 ring-white ring-opacity-30"
                        />
                        <div class="text-[#ad4b86] font-bold leading-none">
                            <p>Support Team</p>
                            <p class="text-sm">von Amolino</p>
                        </div>
                    </div>
                    <div
                        id="speech-bubble"
                        class="p-5 max-w-[250px] drop-shadow-[1px_5px_4px_rgba(0,0,0,0.25)] text-brand-body text-sm relative w-full bg-white rounded-xl before:absolute before:left-4 before:w-0 before:h-0 before:bottom-full before:border-8 before:border-transparent before:border-solid before:border-b-white"
                    >
                        <p class="">
                            Hey <strong>{{ name ?? '-' }},</strong> Amolino wurde
                            speziell für Dein Smartphone optimiert. Für das
                            beste Erlebnis empfehlen wir Dir die mobile Nutzung.
                            <br />
                            <br />
                            Besuche uns einfach über Deinen Handybrowser auf
                            <i class="not-italic text-brand-cyan font-semibold"
                                >www.amolino.de</i
                            >
                            😊
                        </p>
                    </div>
                </div>
                <div class="space-y-4">
                    <div
                        class="bg-brand-cyan w-44 text-center py-1 px-6 rounded-full leading-5 font-bold text-white"
                    >
                        Jetzt verfügbar
                    </div>
                    <div class="flex space-x-2">
                        <a
                            href="https://play.google.com/store/apps/details?id=de.amolino.app31664"
                        >
                            <img
                                class="w-32"
                                src="../../../assets/images/google_play_badge.png"
                            />
                        </a>
                        <img
                            class="w-32"
                            src="../../../assets/images/apple-appstore-badge.png"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div
            class="w-full h-full flex-none relative md:bg-white md:md-width md:md-height md:rounded-2xl md:ring-8 md:ring-white md:ring-opacity-[22%] overflow-scroll no-scrollbar"
        >
            <!-- desktop notification -->
            <div class="relative z-50">
                <div class="absolute top-0 w-full">
                    <div>
                        <app-notify
                            (closeNotify)="
                                displayNotification = !displayNotification
                            "
                            *ngIf="displayNotification"
                            class="md:block hidden"
                            [notification]="notification"
                        ></app-notify>
                    </div>
                </div>
            </div>
            <div *ngIf="showNormalMatch" class="z-50">
                <app-match
                    [position]="'absolute'"
                    *ngIf="showNormalMatch"
                    (closeMatch)="closeMatch()"
                    [show]="showNormalMatch"
                    [matchData]="matchInfo"
                    color="cyan"
                    [src]="'../../../../assets/images/match/match_modal.png'"
                    [h1]="'Glückwunsch!'"
                    [h2]="'Du hast ein Match'"
                    [info]="
                        'Ergreife die Initiative und schreibe ihr oder ihm als Erstes eine Nachricht'
                    "
                    style="
                        background-image: url(assets/images/hearts_matching_screens.png),
                            linear-gradient(45deg, #45a6c4 0%, #b34380 100%);
                    "
                >
                    <app-link-button (click)="goToChat()" [theme]="'purple'"
                        >Zum Chat
                    </app-link-button>
                </app-match>
            </div>
            <div *ngIf="showInstantMatchPassive" class="z-50">
                <app-match
                    [position]="'absolute'"
                    *ngIf="showInstantMatchPassive"
                    [show]="showInstantMatchPassive"
                    (closeMatch)="closeMatch()"
                    color="instantMatchPassive"
                    [matchData]="matchInfo"
                    [src]="
                        '../../../../assets/images/match/instantmatch_modal.png'
                    "
                    [h1]="'Du hast ein'"
                    [h2]="'Sofort-Match erhalten'"
                    [info]="
                        'Jemand zeigt Interesse an Dir! Du hast +1 neue Nachricht in Deinem Posteingang'
                    "
                    style="
                        background-image: url(assets/images/hearts_matching_screens.png),
                            linear-gradient(45deg, #45a6c4 0%, #b34380 100%);
                    "
                >
                    <app-link-button (click)="goToChat()" [theme]="'purple'"
                        >Jetzt lesen
                    </app-link-button>
                </app-match>
            </div>
            <div *ngIf="showInstantMatchActive" class="z-50">
                <app-match
                    [position]="'absolute'"
                    (closeMatch)="closeMatch()"
                    (closeAnimationEnd)="showInstantMatchActive = false"
                    [matchData]="matchInfo"
                    [show]="showInstantMatchActive"
                    color="instantMatchActive"
                    [src]="
                        '../../../../assets/images/match/instantmatch_modal.png'
                    "
                    [h1]="'Sofort-Match'"
                    [h2]="'wurde hergestellt'"
                    [info]="
                        'Jetzt heißt es abwarten! Wir drücken Dir die Daumen, dass Du eine Antwort erhältst!'
                    "
                    style="
                        background-image: url(assets/images/hearts_matching_screens.png),
                            linear-gradient(205deg, #b34380 0%, #d7a9c5 100%);
                    "
                >
                    <app-link-button (click)="goToChat()" [theme]="'cyan'"
                        >Zum Chat
                    </app-link-button>
                </app-match>
            </div>
            <!-- mobile notification subpages -->
            <app-notify
                (closeNotify)="displayNotification = !displayNotification"
                *ngIf="displayNotification && (showProfile || showLoginBonus)"
                class="w-full z-50 block pt-11 md:hidden absolute top-0"
                [notification]="notification"
            ></app-notify>

            <ng-content
                *ngIf="!showLoginBonus; else showLoginBonusBlock"
            ></ng-content>
            <ng-template #showLoginBonusBlock>
                <app-login-bonus-page
                    (closeEvent)="showLoginBonus = false"
                ></app-login-bonus-page>
            </ng-template>
        </div>

        <div class="hidden md:block lg:flex-1">
            <div class="hidden lg:block">
                <ng-content select="[rightbar]"></ng-content>
            </div>
        </div>
    </div>
    <footer
        class="hidden md:pt-6 md:flex flex-grow justify-center text-white h-full"
    >
        <a routerLink="/imprint" class="mx-2">Impressum</a>
        <div class="h-4 w-px bg-gray-400">|</div>
        <a routerLink="/terms" class="mx-2">AGB</a>
        <div class="h-4 w-px bg-gray-400">|</div>
        <a routerLink="/data-protection" class="mx-2">Datenschutz</a>
        <div class="h-4 w-px bg-gray-400">|</div>
        <a routerLink="/right-of-withdrawal" class="mx-2"
            >Widerrufsbelehrung
        </a>
    </footer>
    <ng-content select="[save-btn]"></ng-content>

    <div *ngIf="loading" class="z-[999] fixed top-0 left-0 w-full h-full">
        <app-loader [theme]="'transparent'" [h1]="'Speichert'"></app-loader>
    </div>
</div>
