import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { NotificationService } from "src/app/services/notification.service";

@Component({
    selector: "app-chat-list",
    templateUrl: "./chat-list.component.html",
})
export class ChatListComponent implements OnInit {
    public matches: Array<any> = [];
    public myId: string;
    public noOfUnreadMessages = 0;
    public noOfNewMatches = 0;
    public notification: any;

    constructor(private api: ApiService, private notificationService: NotificationService) {
        this.notificationService.newMessage.subscribe((message: string) => {
            this.notification = JSON.parse(message);
            if (this.notification.type === "NewMessageTemplate") {
                this.getMatches();
            }
        });
    }

    ngOnInit(): void {
        this.getMatches();
    }

    private setNoOfUnreadMeassageAndMatch(matches: any) {
        this.noOfUnreadMessages = 0;
        this.noOfNewMatches = 0;
        matches.map((value: any) => {
            if (value.new) {
                this.noOfNewMatches += 1;
            }
            if (
                value.lastMessage &&
                value.lastMessage.read === false &&
                value.lastMessage.from !== this.myId
            ) {
                this.noOfUnreadMessages += 1;
            }
        });
    }

    getMatches() {
        this.api
            .me()
            .matches()
            .subscribe((response) => {
                this.matches = response.matches;
                this.myId = response.me._id;
                this.matches.sort((a, b) => {
                    const dt1 = Date.parse(a.lastMessage ? a.lastMessage.createdAt : a.createdAt);
                    const dt2 = Date.parse(b.lastMessage ? b.lastMessage.createdAt : b.createdAt);

                    if (dt1 < dt2) return 1;
                    if (dt1 > dt2) return -1;
                    return 0;
                });
                this.setNoOfUnreadMeassageAndMatch(this.matches);
            });
    }
}
