import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ApiService } from "src/app/services/api.service";

@Component({
    selector: "app-photo-type-item",
    templateUrl: "./photo-type-item.component.html",
})
export class PhotoTypeItemComponent implements OnInit {
    @Input() public type!: string;
    @Input() public info!: string;
    @Input() public price!: string;
    @Input() public icon!: string;
    @Input() public dataForUnlock: any;
    @Output() closePhotoModal: EventEmitter<string> = new EventEmitter();
    @Output() unlockCompleted: EventEmitter<string> = new EventEmitter();
    @Output() insufficientCredits: EventEmitter<string> = new EventEmitter();

    public error = false;
    public isClickInProgress = false;
    public background: string;
    constructor(private api: ApiService) {}

    ngOnInit(): void {
        this.selectBackground();
    }

    private selectBackground() {
        switch (this.type) {
            case "intimate":
                this.background = "from-brand-magenta to-brand-title";
                break;
            case "private":
                this.background = "from-cyan-900 to-brand-cyan";
                break;
            case "exclusive":
                this.background = "from-brand-cyan to-brand-magenta";
                break;
            case "special":
                this.background = "from-brand-magenta to-[#812A59]";
                break;
        }
    }

    public unlockPhoto() {
        this.isClickInProgress = true;
        this.api
            .members()
            .image(this.dataForUnlock.memberId, this.dataForUnlock.imageId, this.dataForUnlock.type)
            .subscribe({
                next: () => {
                    this.unlockCompleted.emit(this.dataForUnlock.imageId);
                },
                error: (response) => {
                    if (response.status === 402) {
                        this.error = true;

                        this.insufficientCredits.emit();
                    }
                },
            });
        this.isClickInProgress = false;
    }

    public closeModal() {
        this.closePhotoModal.emit();
    }
}
