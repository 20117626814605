import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ApiService } from "src/app/services/api.service";
import { MatchDataService } from "src/app/services/match-data.service";
import { UpdateMemberStackService } from "src/app/services/update-member-stack.service";

@Component({
    selector: "app-others-profile-header",
    templateUrl: "./others-profile-header.component.html",
})
export class OthersProfileHeaderComponent {
    @Input() member: any;
    @Input() showButton: any;
    @Input() commonTagsText: string;

    /**
     * Declarations for modal
     */
    @Output() displayStatusMatchForm: EventEmitter<string> = new EventEmitter();
    @Output() displayMatchModal: EventEmitter<any> = new EventEmitter();
    @Output() likeMember: EventEmitter<string> = new EventEmitter();
    @Output() limitReached: EventEmitter<string> = new EventEmitter();
    @Output() insufficientCredits: EventEmitter<any> = new EventEmitter<boolean>();

    @Output() performedInstantMatch: EventEmitter<any> = new EventEmitter();
    @Output() profileImageClicked: EventEmitter<any> = new EventEmitter<any>();

    public matchInfo = {
        myImage: "",
        partnerImage: "",
        partnerId: "",
        matchId: "",
        type: "",
    };

    public showInstantMatchFormModal = false;

    form: FormGroup = new FormGroup({
        message: new FormControl("", [Validators.required, Validators.maxLength(160)]),
    });

    constructor(
        private api: ApiService,
        private updateMemberStack: UpdateMemberStackService,
        private matchDataService: MatchDataService
    ) {}

    outPutInsufficientCredits() {
        this.insufficientCredits.emit();
        this.showInstantMatchFormModal = false;
    }

    /**
     * Interact with the other member
     * @param memberId The member id
     * @param interaction Interaction type
     */
    postInteract(memberId: string, interaction: string) {
        this.api
            .members()
            .interact(memberId, interaction)
            .subscribe({
                next: (response) => {
                    this.updateMemberStack.interactWithMember();
                    if (interaction === "LIKE" && response.matchId) {
                        this.matchInfo.myImage = response.me.image;
                        this.matchInfo.partnerImage = this.member.image;
                        this.matchInfo.partnerId = this.member._id;
                        this.matchInfo.matchId = response.matchId;
                        this.displayMatchModal.emit({
                            type: "normalmatch",
                            match: this.matchInfo,
                        });
                        const matchData = this.matchInfo;
                        matchData["type"] = "normal";
                        this.matchDataService.changeMatchData(matchData);
                    } else {
                        this.likeMember.emit();
                    }
                },
                error: (response) => {
                    if (response.status === 402) {
                        this.limitReached.emit();
                    }
                },
            });
    }

    instantMatchSuccessful(matchInfo: any) {
        this.performedInstantMatch.emit(matchInfo);

        this.displayMatchModal.emit(
            this.displayMatchModal.emit({
                type: "normalmatch",
                match: matchInfo,
            })
        );
    }

    /**
     * open match form
     */
    public openMatchForm() {
        this.showInstantMatchFormModal = true;
        this.displayStatusMatchForm.emit("opened");
    }

    /**
     * close match form
     */
    public closeMatchForm() {
        this.showInstantMatchFormModal = false;
        this.form.reset(this.form.get("message"));
        this.displayStatusMatchForm.emit("closed");
    }

    onProfileImageClicked() {
        this.profileImageClicked.emit();
    }
}
