import { Component, Input } from "@angular/core";

@Component({
    selector: "app-question",
    templateUrl: "./question.component.html",
})
export class QuestionComponent {
    @Input() public class!: string;
    @Input() public title!: string;
    @Input() public centered = false;
}
