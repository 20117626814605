<div class="w-full h-full flex flex-col overflow-y-auto">
    <div class="p-4 flex flex-col items-center space-y-2 min-h-0">
        <img
            class="min-h-0"
            src="../../../../assets/images/tutorial/tutorial_slide_{{
                tutorials[index].image
            }}.jpg"
        />
        <div class="space-y-2">
            <h1 class="text-center heading-1">
                {{ tutorials[index].heading }}
            </h1>
            <p class="text-center body">{{ tutorials[index].description }}</p>
        </div>
    </div>
    <div class="grow"></div>
    <div class="flex flex-col items-center w-fit h-fit mx-auto space-y-2 pb-8">
        <button class="link !no-underline !text-lg" (click)="nextTutorial()">
            {{ continueText }}
        </button>
        <div class="flex items-center space-x-2">
            <div *ngFor="let tutorial of tutorials">
                <div
                    *ngIf="tutorials[index] !== tutorial"
                    class="w-4 h-4 rounded-full bg-brand-body"
                ></div>
                <div
                    *ngIf="tutorials[index] === tutorial"
                    class="w-4 h-4 rounded-full bg-brand-magenta"
                ></div>
            </div>
        </div>
    </div>
</div>
