import { Component, Input } from "@angular/core";

@Component({
    selector: "app-heart-divider",
    templateUrl: "./heart-divider.component.html",
})
export class HeartDividerComponent {
    @Input() bgColor!: string;
    @Input() position!: string;
    @Input() liftUp = false;
    @Input() rotate = "";
    @Input() icon!: string;
}
