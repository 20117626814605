import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ChatListComponent } from "./chats/chat-list/chat-list.component";
import { StartMainComponent } from "./discover/start-page/start-page.component";
import { LikesListComponent } from "./like-you/likes-list/likes-list.component";
import { MainPageLayoutComponent } from "./main-page-layout/main-page-layout.component";
import { ShopComponent } from "./shops/shop/shop.component";
import { SearchComponent } from "./search/search.component";

const routes: Routes = [
    {
        path: "",
        component: MainPageLayoutComponent,
        children: [
            { path: "discover", component: StartMainComponent },
            { path: "chats", component: ChatListComponent },
            { path: "like-you", component: LikesListComponent },
            { path: "search", component: SearchComponent },
            { path: "shop", component: ShopComponent },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PublicRoutingModule {}
