import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class MemberStackService {
    /**
     * Set data in local storage
     * @param data The member stack data from api
     */
    public set(data: any): void {
        const dataWithTimestamp = {
            data: data,
            timestamp: new Date().toISOString(),
        };
        localStorage.setItem("memberStack", JSON.stringify(dataWithTimestamp));
    }

    /**
     * Get memberStack data from local storage
     * @returns Data as JSON
     */
    public get(): any {
        const storedData = localStorage.getItem("memberStack");

        if (storedData) {
            try {
                const parsedData = JSON.parse(storedData);
                return parsedData.data || null;
            } catch (error) {
                //JSON parsing failed
                return null;
            }
        }
        return null;
    }

    /**
     * Check if the memberStack data in local storage is inactive
     * @returns true if inactive (timestamp is 6 hours or more in the past), false otherwise
     */
    public isInactive(): boolean {
        const storedData = localStorage.getItem("memberStack");
        if (storedData) {
            try {
                const storedTimestamp = new Date(JSON.parse(storedData).timestamp);
                const currentTimestamp = new Date();
                const timeDifferenceInHours =
                    Math.abs(currentTimestamp.getTime() - storedTimestamp.getTime()) / 36e5;
                return timeDifferenceInHours >= 12;
            } catch (error) {
                //JSON parsing failed
                //as there is no memberStack it is equivalent to inactive thats why it returns true
                return true;
            }
        } else {
            //as there is no memberStack it is equivalent to inactive thats why it returns true
            return true;
        }
    }

    public setPreviousMeId(id: string) {
        localStorage.setItem("previous_me_id", id);
    }

    public getPreviousMeId(): string | null {
        return localStorage.getItem("previous_me_id");
    }
}
