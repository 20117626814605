<div
    class="h-[100vh] w-full bg-no-repeat bg-center bg-cover"
    [ngStyle]="{ 'background-image': bgImage + selectedGradient }"
>
    <div
        class="h-full bg-white md:bg-transparent  px-{{
            verticalPadding
        }} md:px-4  lg:24 md:space-x-4 overflow-y-scroll flex flex-col md:flex-row"
    >
        <!-- header -->
        <div
            class="md:flex-1 mt-{{
                horizontalPadding
            }} md:mt-8 after:hidden md:block"
        >
            <a [routerLink]="['/']">
                <img
                    class="hidden md:block min-w-[90px] w-[90px] md:w-[150px]"
                    routerLinkActive="router-link-active"
                    src="../../../assets/images/logo/1203x405.png"
                    alt="amolino logo"
                />
            </a>
        </div>
        <div
            class="bg-white h-full overflow-y-scroll no-scrollbar w-full md:w-[640px]"
        >
            <ng-content></ng-content>
        </div>
        <div class="hidden md:flex-1 md:block"></div>
    </div>
</div>
