import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-public-profile-content",
    templateUrl: "./public-profile-content.component.html",
})
export class PublicProfileContentComponent implements OnInit {
    public profileInfos: any = null;
    private shareId = "";

    constructor(private activatedRouter: ActivatedRoute) {}

    ngOnInit(): void {
        this.activatedRouter.params.subscribe((params) => {
            this.shareId = params["id"];
            fetch(`${environment.url.api}/share/${this.shareId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((data) => (this.profileInfos = data))
                .catch((error) => console.error("PublicProfileContentComponent:", error));
        });
    }
}
