import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ApiService } from "src/app/services/api.service";
import { MembersMeStoreService } from "src/app/services/members-me-store.service";

@Component({
    selector: "app-about-you",
    templateUrl: "./about-you.component.html",
})
export class AboutYouComponent implements OnInit {
    public success = false;
    public loading = false;
    public length = 0;
    @Input() user!: any;
    @Output() closeMatchForm: EventEmitter<string> = new EventEmitter();
    public updateSuccessful = false;
    public isRightMenuClickInProgress = false;
    form: UntypedFormGroup = new UntypedFormGroup({
        description: new UntypedFormControl("", [Validators.required, Validators.maxLength(480)]),
    });

    constructor(private api: ApiService, private membersMeStore: MembersMeStoreService) {}

    ngOnInit(): void {
        this.membersMeStore.getMembersMeFromStore().subscribe((response) => {
            this.description.setValue(response.profileText);
            this.length = this.description.value.length;
        });
    }

    public get description(): UntypedFormControl {
        return this.form.get("description") as UntypedFormControl;
    }

    public submitForm(): void {
        this.loading = true;
        this.success = false;

        if (this.description.invalid) {
            return;
        }
        this.api
            .me()
            .update({ profileText: this.description.value })
            .subscribe(() => {
                this.success = true;
                this.loading = false;

                this.updateSuccessful = true;
                this.membersMeStore.updateMembersMeInStore("profileText", this.description.value);
            });
    }
}
