import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "app-my-interests-item",
    templateUrl: "./my-interests-item.component.html",
})
export class MyInterestsItemComponent {
    @Output() selectItem: EventEmitter<string> = new EventEmitter<string>();
    @Input() public selected!: boolean;
    @Input() public title!: string;
    @Input() selectDisabled!: boolean;

    clickedItem(): void {
        this.selectItem.emit();
        this.selected = !this.selected;
    }
}
