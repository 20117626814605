<div
    *ngIf="!showInsufficientCreditsError"
    class="pt-[88px] sm:pt-0 h-full w-full"
    #mainContent
>
    <app-discover-limit
        @simpleFadeAnimation
        [fee]="fees.refreshLikes"
        (manuallyClosed)="discoverLimitReached = false"
        (boughtNewLikes)="discoverLimitReached = false"
        *ngIf="discoverLimitReached && fees"
    >
    </app-discover-limit>
    <app-discover-empty-stack *ngIf="emptyStack"></app-discover-empty-stack>
    <div
        *ngIf="!discoverLimitReached && !emptyStack && member"
        class="h-full w-full bg-cover bg-no-repeat overflow-y-scroll no-scrollbar"
        [ngStyle]="{
            'background-image':
                'url(' + imageBaseUrl + '/' + nextMember?.image + ')'
        }"
    >
        <div
            [@popOverState]="profileStateName"
            (touchstart)="touchStartListener($event)"
            (touchmove)="touchMoveListener($event)"
            (touchend)="touchEndListener($event)"
            *ngIf="!discoverLimitReached && !emptyStack && member"
            class="grid h-full w-full text-white bg-cover bg-no-repeat overflow-y-scroll no-scrollbar"
            [ngClass]="[
                !showUnlockProfileModal ? '' : '-translate-y-48',
                !showInstantMatchFormModal ? '' : '-translate-y-48',
                swipeRightAnimation ? 'fade-out-right-animation' : '',
                swipeLeftAnimation ? 'fade-out-left-animation' : ''
            ]"
            [style.background-image]="
                'url(' + imageBaseUrl + '/' + member.image + ')'
            "
            #currentImageElement
        >
            <div
                class="pt-[88px] sm:pt-0 absolute z-50 md:top-0 top-8 right-4 px-4 rounded-full"
            ></div>
            <div *ngIf="showSaleTag" class="absolute top-4 right-0">
                <img
                    class="w-12"
                    src="../../../../assets/images/sale_shape.png"
                />
                <img
                    class="w-5 z-10 absolute top-[12px] right-[12px]"
                    src="../../../../assets/images/sale_big.png"
                />
            </div>

            <!-- TODO : shop doesnot exist in member objekt -->
            <div
                *ngIf="
                    showNotifications &&
                    displayShopNotification &&
                    (member?.shop ?? false)
                "
                class="absolute w-full top-0 left-0"
            >
                {{ member }}
                <div class="absolute w-full top-0 left-0">
                    <div
                        class="flex items-center justify-between mb-4 md:text-xs w-full md:w-[350px] text-white bg-gradient-to-r from-brand-magenta to-brand-danger h-[70px] md:h-[60px] px-4 py-6"
                    >
                        <div class="flex items-center space-x-2">
                            <div class="overflow-hidden rounded-full">
                                <img
                                    class="w-4 h-4"
                                    src="../../../../assets/images/sale_big.png"
                                />
                            </div>

                            <div class="w-[240px] md:w-[200px]">
                                <p class="font-semibold text-xs md:text-[12px]">
                                    Es gibt ein neues Angebot für Dich:
                                </p>
                            </div>
                        </div>
                        <div
                            class="flex justify-between items-center space-x-4"
                        >
                            <a
                                href="user/shop"
                                class="underline cursor-pointer font-semibold text-white"
                                >Zum Shop</a
                            >
                            <span
                                (click)="
                                    displayShopNotification =
                                        !displayShopNotification
                                "
                            >
                                <img
                                    class="h-3 w-3 self-end cursor-pointer"
                                    src="../../../../assets/images/close_white.png"
                                    alt="close_white"
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="member-profile"
                *ngIf="!showUnlockProfileModal && !showInstantMatchFormModal"
                id="wrapper"
                class="flex flex-col justify-end leading-normal"
            >
                <div
                    class="bg-cover space-y-6 px-6 pt-11 pb-10"
                    style="
                        background-image: url('../../../../assets/images/entdecken_foto_fade.png');
                    "
                >
                    <div class="space-y-2">
                        <div class="flex justify-between">
                            <div>
                                <h1
                                    class="text-2xl text-white font-bold drop-shadow-md"
                                >
                                    {{ member.username ? member.username : '-' }},
                                    {{ member.dob | dateToAge }}
                                </h1>
                                <img
                                    class="inline-flex mr-2 h-5"
                                    src="../../../../assets/images/geopin.png"
                                    alt="location-icon"
                                />
                                <span class="font-medium">{{
                                    member.distance | customizeDistance
                                }}</span>
                            </div>
                            <div
                                *ngIf="
                                    !showUnlockProfileModal &&
                                    !showInstantMatchFormModal &&
                                    member?.locked
                                "
                                class="group"
                            >
                                <img
                                    (click)="clickUnlock()"
                                    class="cursor-pointer transition transition-scale duration-300 group-hover:scale-105 h-14 w-14"
                                    src="../../../../assets/images/{{
                                        unlockProfile
                                    }}.png"
                                    alt="unlock_profile"
                                    (mouseover)="unlockProfile = 'unlock_hover'"
                                    (mouseleave)="
                                        unlockProfile = 'unlock_default'
                                    "
                                />
                            </div>
                            <a
                                [routerLink]="[
                                    '/profile',
                                    'discover',
                                    member._id
                                ]"
                                *ngIf="
                                    !showUnlockProfileModal &&
                                    !showInstantMatchFormModal &&
                                    !member?.locked
                                "
                                class="group"
                            >
                                <img
                                    class="cursor-pointer transition transition-scale duration-300 group-hover:scale-105 w-14 h-14"
                                    src="../../../../assets/images/{{
                                        unlockProfile
                                    }}.png"
                                    alt="unlock_profile"
                                    (mouseover)="unlockProfile = 'unlock_hover'"
                                    (mouseleave)="
                                        unlockProfile = 'unlock_default'
                                    "
                                />
                            </a>
                        </div>
                        <p
                            class="text-white opacity-70 whitespace-nowrap overflow-hidden overflow-ellipsis drop-shadow-lg text-base max-w-[300px]"
                        >
                            {{ member.profileText }}
                        </p>
                    </div>
                    <p
                        class="text-white absolute md:top-0 top-4 bg-brand-magenta font-bold px-4 rounded-full"
                    >
                        NEU!
                    </p>
                    <p
                        *ngIf="showInteraction"
                        class="text-white absolute top-4 !text-[5rem] font-bold px-4 right-4 rounded-full"
                        [ngClass]="[
                            interactionEmoji == '🥱'
                                ? 'top-4'
                                : 'top-[100px] left-4'
                        ]"
                    >
                        {{ interactionEmoji }}
                    </p>
                    <div
                        class="flex items-center justify-center space-x-6 md:space-x-4 pb-2"
                    >
                        <app-reject-button
                            class="w-14"
                            (click)="postInteract(member._id, 'DISLIKE')"
                        ></app-reject-button>
                        <app-like-button
                            class="w-20"
                            (click)="postInteract(member._id, 'LIKE')"
                        ></app-like-button>
                        <app-instant-match-button
                            class="w-14"
                            (click)="showNotifications = false"
                            (click)="toggleInstantMatchFormModal()"
                        >
                        </app-instant-match-button>
                    </div>
                </div>
            </div>

            <!-- <app-insufficient-balance-for-unlock-profile [@fadeIn] *ngIf="insufficientCreditsForProfileUnlock" (closeView)="insufficientCreditsForProfileUnlock = false" [member]="member">
            </app-insufficient-balance-for-unlock-profile> -->
        </div>
        <div
            *ngIf="member"
            class="absolute bottom-2 w-full flex items-center justify-center"
        >
            <a
                class="link !text-white !font-semibold"
                href="mailto:support@amolino.de?subject=Profil #{{
                    member._id
                }} &body=Hallo,%0D%0A ich möchte den/die User:in aus folgenden Gründen melden:"
                >Foto Melden</a
            >
        </div>
    </div>
</div>

<app-instant-match-form-modal
    *ngIf="showInstantMatchFormModal"
    (submitInstantMatchSuccessful)="handleMemberInteraction('LIKE')"
    (insufficientCredits)="
        showInsufficientCreditsError = !showInsufficientCreditsError;
        showInstantMatchFormModal = !showInstantMatchFormModal
    "
    [member]="member"
    (closeForm)="showInstantMatchFormModal = !showInstantMatchFormModal"
></app-instant-match-form-modal>

<div
    [@fadeIn]
    *ngIf="showUnlockProfileModal && !showInsufficientCreditsError"
    class="absolute w-full bottom-0 z-10"
>
    <div
        class="p-4 pb-8 -mb-5 bg-cover leading-none"
        style="
            background-image: url('../../../../assets/images/entdecken_foto_fade.png');
        "
    >
        <h1 class="text-2xl text-white font-bold drop-shadow-md">
            {{ member?.username ?? "-" }}, {{ member?.dob ?? "-" | dateToAge }}
        </h1>
        <img
            class="inline-flex text-white mr-2 h-5"
            src="../../../../assets/images/geopin.png"
            alt="location-icon"
        />
        <span class="text-white font-medium">{{
            member?.distance ?? "" | customizeDistance
        }}</span>
        <p
            class="text-white mt-2 drop-shadow-lg text-base whitespace-nowrap overflow-hidden overflow-ellipsis"
        >
            {{ member?.profileText ?? "-" }}
        </p>
    </div>

    <app-modal
        classProps="rounded-t-2xl"
        [sectionTopPaddingLarge]="'8'"
        [sectionTopPaddingLarge]="'4'"
        [desktopHeight]="'200'"
        [topPadding]="'6'"
        [image]="'../../../../assets/images/profile_unlock_modal.png'"
        [keys]="fees ? fees.profileAccess : 1000"
        (closeMatch)="toggleUnlockProfileModal()"
        [h1]="'Profil Gesperrt'"
    >
        <div subtitle>
            Um mehr Fotos und Informationen über
            <strong>{{ member?.username ?? "-" }}</strong> zu erhalten, kannst Du
            jetzt das vollständige Profil freischalten.
        </div>
        <div content>
            <div class="w-full">
                <app-link-button
                    [isDisabled]="isClickInProgress"
                    (click)="
                        profileUnlock(member ? member._id : '', 'BUY_ACCESS')
                    "
                    class="my-5"
                    [theme]="'purple'"
                >
                    Profil freischalten
                </app-link-button>
            </div>
        </div>
    </app-modal>
</div>

<div @simpleFadeAnimation *ngIf="showInsufficientCreditsError">
    <app-insufficient-credits
        (closeForm)="
            showInsufficientCreditsError = !showInsufficientCreditsError
        "
    ></app-insufficient-credits>
</div>
