<app-main-layout [showProfile]="true" [leftMenuText]="'Deine Interessen'">
    <div class="pt-11 sm:pt-0 pb-8">
        <div class="px-4 mt-6" *ngIf="updateSuccessful">
            <!-- <app-saved-successfully-text></app-saved-successfully-text> -->
        </div>
        <div class="text-brand-body text-sm">
            <div class="flex justify-between items-center px-4">
                <div>
                    <h2 class="my-10 text-brand-magenta heading-2">
                        Interessen wählen
                    </h2>
                    <p class="my-5 font-semibold">
                        Du hast die Möglichkeit, bis zu 10 Interessen in Deinem
                        Profil anzugeben. Allerdings müssen mindestens 5
                        Interessen ausgewählt sein.
                    </p>
                </div>
                <p class="text-brand-title font-bold f text-xs">
                    {{ selectedTags.length }}/10
                </p>
            </div>

            <!-- Divider -->
            <div
                class="border-b-2 border-brand-body opacity-10 mb-6 mx-4"
            ></div>
            <div *ngFor="let tag of availableTags">
                <app-my-interests-item
                    title="{{ tag.de }}"
                    [selected]="selectedTags.includes(tag._id)"
                    (selectItem)="addToList(tag)"
                    [selectDisabled]="disabled"
                ></app-my-interests-item>
            </div>
        </div>
    </div>
    <div
        class="sticky h-[100px] bottom-0 bg-white shadow-[-1px_-12px_5px_0px_rgba(0,0,0,0.1)]"
        save-btn
    >
        <div class="py-6 px-4">
            <app-link-button
                [isDisabled]="disabled"
                [success]="success"
                [saveButton]="true"
                (userClicked)="saveChanges()"
                [theme]="'purple'"
                [loading]="loading"
            ></app-link-button>
        </div>
    </div>
</app-main-layout>
