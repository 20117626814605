<div class="mb-4">
    <label *ngIf="label" class="text-sm mb-4">{{ label }}</label>
    <div [ngClass]="'flex flex-row space-x-4 mb-3 pt-2 text-sm' + classprop">
        <ng-content></ng-content>
    </div>
    <div
        [ngClass]="!error ? 'border-b-brand-green' : 'border-b-black'"
        class="border-b-2"
    ></div>
    <div class="text-brand-danger">
        <ng-content select="[error]"></ng-content>
    </div>
</div>
