<div class="h-full grid justify-items-center items-center w-full text-white bg-brand-title px-6">
    <div class="grid justify-items-center gap-y-8 md:w-[420px]">
        <img class="h-16 w-16" src="../../../assets/images/unlock_photo_intimate.png" alt="view_eyes">
        <p class="text-3xl font-semibold text-center">Ups, da hat etwas leider nicht geklappt.</p>
        <p class="text-center text-sm font-medium">
Es tut uns leid, aber der von Dir gewünschte Inhalt ist leider nicht (mehr) verfügbar oder es liegt ein anderes Problem
vor.
            <br>
            <br>
            Sollte dieser Fehler öfter vorkommen, kontaktiere uns bitte: <a class="font-bold"
                href="{{ contactEmailService.getContactEmailHref('Allgemeiner Support') }}">support@amolino.de</a>
        </p>
        <div class="w-full">
        <app-link-button [theme]="'cyan'" [path]="'/'">Zur Startseite</app-link-button>
    </div>
    </div>

</div>
