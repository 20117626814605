import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: "app-interest-item",
    templateUrl: "./interest-item.component.html",
})
export class InterestItemComponent implements OnInit {
    @Input() selectDisabled!: boolean;
    @Input() prefill!: boolean;
    @Output() addItem: EventEmitter<string> = new EventEmitter();
    selected = false;

    ngOnInit(): void {
        if (this.prefill) this.selected = true;
    }

    public checkAllowSelection(): void {
        this.addItem.emit();
        if (!this.selectDisabled) this.selected = !this.selected;
        else this.selected = false;
    }
}
