import { Component } from "@angular/core";
import { ContactEmailService } from "src/app/services/contact-email.service";
// import { ContactEmailService } from 'src/app/services/contact-email.service';

@Component({
    selector: "app-not-found-page",
    templateUrl: "./not-found-page.component.html",
})
export class NotFoundPageComponent {
    constructor(public contactEmailService: ContactEmailService) {}
}
