<app-main-layout
    [showProfile]="true"
    [leftMenuText]="'Deine Fotos'"
    [rightMenuText]="rightMenuText"
    [rightMenuIcon]="'photo_icon_gallery.png'"
    [separationLine]="true"
>
    <div *ngIf="currentEditPhoto; else standardBlock">
        <app-edit-photo
            [fallbackProfileImage]="
                fallbackProfileImage &&
                currentEditPhoto.id != fallbackProfileImage.id
                    ? fallbackProfileImage
                    : undefined
            "
            [image]="currentEditPhoto"
        ></app-edit-photo>
    </div>
    <ng-template #standardBlock>
        <div *ngIf="toBeCropped">
            <app-photo-cropper
                [imageToBeCropped]="toBeCropped"
                (croppedImage)="onCroppedImage($event)"
            ></app-photo-cropper>
        </div>
        <div class="flex items-center justify-center pt-11 pb-8 sm:pt-0">
            <div
                class="px-4 pt-6 text-brand-body text-[13px] space-y-8 leading-6"
            >
                <div id="wrapper" class="">
                    <ng-content></ng-content>
                </div>

                <div class="grid grid-cols-3 gap-2">
                    <div *ngIf="galleryArr.length !== 11" class="relative">
                        <input
                            type="file"
                            (change)="compressImage($event)"
                            accept="image/jpeg,image/jpg,image/heic,image/heif"
                            style="display: none"
                            #filePicker
                        />
                        <div
                            class="!bg-[#96CDDE] bg-opacity-30 pt-4 flex flex-col h-full w-full space-y-4 items-center justify-center bg-[brand-body]/10 font-medium"
                            (click)="filePicker.click()"
                        >
                            <img
                                class="cursor-pointer w-14"
                                src="../../../../assets/images/cam_white.png"
                            />
                            <p
                                class="mx-4 text-xs text-center text-white leading-[0.8rem]"
                            >
                                Hochladen
                            </p>
                        </div>
                    </div>

                    <div *ngFor="let image of galleryArr">
                        <app-photo-container
                            [image]="image"
                            (selectedImage)="editPhoto($event)"
                        ></app-photo-container>
                    </div>
                    <div
                        *ngFor="
                            let dummy of ' '.repeat(noOfFreeSlots).split('');
                            let x = index
                        "
                    >
                        <div
                            class="relative h-52 bg-[#f9f9f9]"
                            id="border-dash"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</app-main-layout>
