<div
    (click)="toggle()"
    class="z-40 h-full w-full bg-center bg-cover"
    [style.backgroundImage]="
        'url(https://m.faz.net/media1/ppmedia/aktuell/wissen/leben-gene/4258389069/1.7900893/mmobject-still_full/dinosaurier-der-gattung-spinosaurus-jagt-onchopristis.jpg)'
    "
>
    <div
        [@slideInOut]
        *ngIf="show"
        class="z-50 h-full w-full bg-center bg-cover"
        [style.backgroundImage]="
            'url(https://encrypted-tbn2.gstatic.com/licensed-image?q=tbn:ANd9GcT_GNCGDJ8y7cD1YlOOfdej_6pfw9zmbQdT-0WCSvjplZjb7_ojPf76lOVVBYD_H_3kweKS2ulaaL3zl14)'
        "
    ></div>
</div>
