import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "../store/app.state";
import { deleteMeData } from "../store/me-data-store";
import { deleteMembersMe } from "../store/members-me.store";
import { CacheResolverService } from "./cache-resolver.service";
import { NotificationService } from "./notification.service";

@Injectable({
    providedIn: "root",
})
export class LogoutService {
    constructor(
        private cache: CacheResolverService,
        private store: Store<AppState>,
        private notificationService: NotificationService
    ) {}

    /**
     * Log out the user, clears all localStorage except for accountJustDeleted
     */
    public logout(): void {
        // preserve uc settings
        const ucGcm = localStorage.getItem("uc_gcm");
        const ucSettings = localStorage.getItem("uc_settings");
        const ucUiVersion = localStorage.getItem("uc_ui_version");
        const ucUserInteraction = localStorage.getItem("uc_user_interaction");

        const accountJustDeleted = localStorage.getItem("accountJustDeleted"); // Store the value in a variable
        const memberStack = localStorage.getItem("memberStack");
        const previousMeId = localStorage.getItem("previous_me_id");

        localStorage.clear(); // Clear the entire localStorage
        this.cache.clearCache(); // Clear the entire cache

        // Clear data from ngrx store
        this.store.dispatch(deleteMeData());
        this.store.dispatch(deleteMembersMe());

        if (memberStack) {
            localStorage.setItem("memberStack", memberStack);
        }
        if (previousMeId) {
            localStorage.setItem("previous_me_id", previousMeId);
        }

        if (accountJustDeleted) {
            localStorage.setItem("accountJustDeleted", accountJustDeleted); // Set back the value of accountJustDeleted
        }
        if (ucGcm) localStorage.setItem("uc_gcm", ucGcm);
        if (ucSettings) localStorage.setItem("uc_settings", ucSettings);
        if (ucUiVersion) localStorage.setItem("uc_ui_version", ucUiVersion);
        if (ucUserInteraction) localStorage.setItem("uc_user_interaction", ucUserInteraction);

        // Close the websocket connection
        this.notificationService.closeConnection();
    }
}
