<app-main-layout
    id="ml"
    [showProfile]="true"
    [leftMenuText]="'Bildausschnitt wählen'"
    [separationLine]="true"
>
    <div
        class="m-4 text-white space-y-6 flex flex-col justify-between m-4 pt-11 sm:pt-0"
    >
        <div id="wrapper" class="!space-y-6">
            <div class="image-cropper">
                <div id="img-container" class="w-full !h-full">
                    <!-- <img class="absolute bottom-0 z-30" src='../../../../assets/images/entdecken_foto_fade.png'> -->
                    <div class="absolute w-full z-40"></div>
                </div>
            </div>

            <div class="flow-text">
                Wähle den gewünschten Bildausschnitt aus. Nutze dazu das
                Werkzeug zum Verschieben und Zoomen, um das Bild perfekt
                auszurichten.
            </div>
            <div>
                <app-link-button
                    [loading]="loading"
                    [success]="success"
                    [saveButton]="true"
                    (userClicked)="saveEditedImage()"
                    [theme]="'cyan'"
                    >Speichern</app-link-button
                >
            </div>
        </div>
    </div>
    <div *ngIf="loading" class="absolute top-0 w-full h-full">
        <app-loader [theme]="''" [h1]="'Speichert'"></app-loader>
    </div>
</app-main-layout>
