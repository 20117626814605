<a
    href="{{ linkPath }}"
    class="w-full flex justify-between items-end"
    [attr.target]="isTargetBlank ? '_blank' : null"
>
    <div>
        <div>{{ title }}</div>
    </div>
    <a class="text-brand-cyan font-semibold w-[9px]"
        ><img
            src="../../../../assets/images/{{ arrowColor }}_arrow.png"
            alt="blue_arrow"
    /></a>
</a>
