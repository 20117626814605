import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { ApiService } from "./api.service";
import { WebToAppService } from "./web-to-app.service";

declare const OneSignal;
declare const OneSignalDeferred;

@Injectable({
    providedIn: "root",
})
export class OneSignalService {
    constructor(private webToAppService: WebToAppService, private apiService: ApiService) {}

    loadScript = () => {
        if (this.webToAppService.isUsingApp()) return;

        const oneSignalScript = document.createElement("script");
        oneSignalScript.setAttribute(
            "src",
            `https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js`
        );
        oneSignalScript.setAttribute("defer", "defer");
        document.head.appendChild(oneSignalScript);
    };

    setExternalUserId(userId: string): Promise<void> {
        return new Promise((resolve) => {
            if (!this.webToAppService.isUsingApp()) {
                OneSignalDeferred.push(function () {
                    OneSignal.login(userId);
                    resolve();
                });
            } else {
                this.webToAppService.oneSinalLogin(userId);
            }
        });
    }

    async setExternalUserIdToUserId(): Promise<void> {
        try {
            const response = await firstValueFrom(this.apiService.me().get());
            await this.setExternalUserId(response.me._id);
        } catch (e) {
            console.warn(
                "Couldn't set external user id. This may be because the user is not logged in. More info:",
                e
            );
        }
    }

    async requestPermissions() {
        if (this.webToAppService.isUsingApp()) {
            await this.webToAppService.requestNotificationPermissions();
        } else {
            await OneSignal.Notifications.requestPermission();
        }
    }
}
