import { Component } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent {

  constructor(private api: ApiService) { }

  members:any[] = []


  onSubmit(form:any) {
    if (form.value.username != '') {
      this.api.members().searchByUsername(form.value.username).subscribe(res => this.members = res.members)
    }
  }
}
