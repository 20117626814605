<div class="px-4">
    <div
        class="py-4 bg-white flex justify-between text-base items-center sticky top-0 border-b-2 border-opacity-10 border-brand-body pt-20 md:pt-4"
    >
        <div class="font-medium leading-5 flex flex-col space-y-2">
            <p class="text-brand-magenta font-bold text-[0.9rem] text-right">
                Deine Bestellung:
            </p>
            <div class="">
                <img
                    class="inline-flex h-[10px]"
                    src="../../../../assets/images/checkmark_green.png"
                />
                <span class="text-brand-title ml-1 font-bold text-[0.9rem]">{{
                    paypalTransactionDetails.product.qtyCoins
                        ? paypalTransactionDetails.product.qtyCoins + " Credits"
                        : paypalTransactionDetails.product.qtyKeys +
                          " Schlüssel"
                }}</span>
            </div>
        </div>
        <div class="flex flex-col items-end leading-5 pt-2">
            <p class="text-brand-body text-[11px]">Inkl. MwSt.</p>
            <h1 class="font-bold text-[0.9rem] text-right">
                {{ paypalTransactionDetails.product.cost / 100 | floatToEuro }}
            </h1>
        </div>
    </div>
    <h2 class="heading-2 text-brand-title mt-8 mb-4 text-center">
        Jetzt bezahlen mit Paypal
    </h2>
    <div class="flex justify-center">
        <!--paypal sdk injects the Paypal standards buttons-->
        <div #paypalRef class="w-full max-w-lg"></div>
    </div>
    <div class="flex items-center justify-center pt-8">
        <a
            (click)="cancelPaymentProcess($event)"
            class="link cursor-pointer !text-brand-body"
            >Kauf Abbrechen</a
        >
    </div>
</div>
