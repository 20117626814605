<div
    (click)="navigateToCheckout($event)"
    class="p-4 pb-8 flex border-b-2 border-opacity-50 justify-between bg-white font-medium ring-4 ring-white ring-opacity-30 cursor-pointer"
>
    <div>
        <!-- Title -->
        <h1 class="text-xl font-bold">{{ title }}</h1>
        <div class="flex items-center space-x-1 text-xs md:text-[10px]">
            <span class="text-brand-body font-semibold"
                >für nur
                {{ product.cost / 100 | floatToEuro }}
            </span>
        </div>
    </div>
    <div class="text-xs md:text-[10px] grid justify-items-end gap-y-2">
        <img class="h-4" src="../../../../assets/images/arrow-shop-grey.png" />
    </div>
</div>
