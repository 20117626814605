import { Component, Input } from "@angular/core";

@Component({
    selector: "app-register-with-item",
    templateUrl: "./register-with-item.component.html",
})
export class RegisterWithItemComponent {
    @Input() title!: string;
    @Input() icon!: string;
}
