<app-modal
    [overlay]="true"
    (closeMatch)="closeEmailSentScreen()"
    [desktopHeight]="'full'"
    [supportFooter]="true"
    [topPadding]="'6'"
    [image]="'../../../../assets/images/checkmark-circle.png'"
    [desktopWidth]="'full'"
    [h1]="'E-Mail gesendet'"
>
    <div subtitle class="text-center">
        Eine Bestätigungsmail wurde an Deine E-Mail-Adresse gesendet. Bitte
        überprüfe auch Deinen Posteingang sowie Deinen Spam- bzw. Junk-Ordner.
        Es kann bis zu 5 Minuten dauern, bis die E-Mail bei Dir eintrifft.
    </div>
    <div content>
        <p *ngIf="errorMessage" class="text-brand-danger text-center mt-2">
            {{ errorMessage }}
        </p>
    </div>
</app-modal>
