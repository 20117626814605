import { EventEmitter, Injectable } from "@angular/core";

export interface NavBarOptions {
    isRightMenuClickDisabled?: boolean;
    isLeftMenuClickDisabled?: boolean;
    showProfile?: boolean;
    separationLine?: boolean;
    rightMenuText?: string;
    hScreen?: boolean;
    rightMenuIcon?: string;
    rightMenuOpacity?: string;
    leftMenuIcon?: string;
    leftMenuText?: string;
    rightMenuLinkPath?: string;
    userAvatar?: string;
    leftTextLinkPath?: string;
    bottomPadding?: string;
    rightMenuDisable?: boolean;
    menuPositionLeft?: boolean;
    menuBar?: boolean;
    navbarRelative?: boolean;
    bgColor?: string;
}

// TODO: rename to main-layout.service.ts
@Injectable({
    providedIn: "root",
})
export class NavBarService {
    public navbarOptionsChanged: EventEmitter<NavBarOptions> = new EventEmitter();

    public clickedRightMenu?: () => void;
    public clickedLeftMenu?: () => void;
    public clickedAvatar?: () => void;
    /**
     * Don't forget to set the customBackFunction back to null.
     */
    public customBackFunction?: () => void;

    public static StandardNavBarOptions: NavBarOptions = {
        isRightMenuClickDisabled: false,
        isLeftMenuClickDisabled: false,
        showProfile: false,
        separationLine: true,
        hScreen: true,
        rightMenuOpacity: "100",
        leftMenuIcon: "backarrow",
        bottomPadding: "",
        rightMenuText: "",
        rightMenuIcon: "",
        leftMenuText: "",
        navbarRelative: false,
        bgColor: "white",
    };
}
