import { Component, Input } from "@angular/core";
import { ContactEmailService } from "src/app/services/contact-email.service";

@Component({
    selector: "app-imprint-content",
    templateUrl: "./imprint-content.component.html",
})
export class ImprintContentComponent {
    @Input() public id?: string;

    constructor(public contactEmailService: ContactEmailService) {}
}
