import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { MatchData } from "../interfaces/matches";

@Injectable({
    providedIn: "root",
})
export class MatchDataService {
    private matchDataSource = new BehaviorSubject<MatchData | undefined>(undefined);
    currentMatchData = this.matchDataSource.asObservable();
    private showMatchSource = new BehaviorSubject(false);
    currentShowMatch = this.showMatchSource.asObservable();

    changeMatchData(matchData: MatchData) {
        this.matchDataSource.next(matchData);
        this.showMatchData(true);
    }

    showMatchData(showMatch: boolean) {
        this.showMatchSource.next(showMatch);
    }
}
