<div class="flex flex-col space-y-8 items-center lg:items-start pt-10">
    <span
        class="bg-brand-cyan rounded-full px-4 py-1 text-white font-semibold shadow-md"
    >
        Jetzt verfügbar
    </span>
    <div class="flex flex-row space-x-4">
        <a
            href="https://play.google.com/store/apps/details?id=de.amolino.app31664"
            ><img
                src="../../../assets/images/google_play_badge.png"
                class="w-40 lg:w-52"
        /></a>
        <a class="cursor-pointer" href="/"
            ><img
                src="../../../assets/images/apple-appstore-badge.png"
                class="w-40 lg:w-52"
        /></a>
    </div>
</div>
