<div
    *ngIf="showDeleteModal"
    @simpleFadeAnimation
    class="absolute top-0 h-full w-full z-40"
>
    <app-modal
        [topPadding]="'5'"
        (closeMatch)="showDeleteModal = !showDeleteModal"
        [h1]="'Foto entfernen'"
        [imageWrapperProps]="'h-40 w-40 rounded-full overflow-hidden'"
        [imageProps]="'h-40 w-40 rounded-full object-cover'"
        [disclaimer]="'Diese Aktion kann nicht rückgängig gemacht werden.'"
        smallIcon="../../../../assets/images/delete-photo.png"
        [image]="imgSrc"
    >
        <div class="text-base" subtitle>
            Bist Du sicher, dass Du dieses Foto dauerhaft aus deiner Galerie
            entfernen möchtest?
        </div>
        <div class="mt-9" content>
            <app-link-button
                *ngIf="!imageProfileImage"
                (click)="deleteImage()"
                [text]="'Foto löschen'"
                [saveText]="'Löschen'"
                [savedText]="'Gelöscht'"
                [success]="successfullyDeleted"
                [saveButton]="true"
                [classProps]="'bg-brand-danger'"
                [theme]="'pink'"
            >
            </app-link-button>
        </div>
    </app-modal>
</div>

<app-photo-cropper
    *ngIf="toBeCropped"
    [imageToBeCropped]="toBeCropped"
    (croppedImage)="gotCroppedImage($event)"
></app-photo-cropper>

<div
    *ngIf="!showDeleteModal"
    class="text-white space-y-6 flex flex-col justify-between pt-11 sm:pt-0"
>
    <div id="wrapper">
        <div
            (click)="goToCrop()"
            class="relative"
            [style]="'background-image: url(' + imgSrc + '}});'"
        >
            <img
                class="absolute bottom-0 z-20"
                src="../../../../assets/images/entdecken_foto_fade.png"
            />
            <div class="w-full flex items-center justify-center">
                <img class="w-1/2" src="{{ imgSrc }}" />
            </div>
            <div class="absolute bottom-0 z-30 w-full">
                <div
                    class="flex items-center justify-center space-x-2 flow-text !text-white"
                >
                    <img
                        src="../../../../assets/images/crop.png"
                        class="w-4 inline-block h-4 !drop-shadow-sm"
                    />
                    <div>Klicken um Bildausschnitt zu ändern</div>
                </div>
            </div>
        </div>
        <div>
            <div class="w-full flex justify-between items-center px-4 pt-4">
                <div class="text-brand-title text-xs font-bold">Profilfoto</div>

                <app-slide-button
                    [activatedColor]="imageProfileImage ? 'body/30' : 'cyan'"
                    [onlyOn]="imageProfileImage ? true : false"
                    [large]="true"
                    (toggle)="toggleProfileImage()"
                    [state]="imageProfileImage"
                ></app-slide-button>
            </div>

            <div *ngIf="!imageProfileImage" class="flex flex-col space-y-4 p-4">
                <div
                    class="flex items-center justify-between pt-4 border-t-2 border-brand-body/20 text-brand-title text-xs font-bold"
                >
                    <div>Fototyp</div>
                    <div
                        *ngIf="!public"
                        class="flex items-center justify-center space-x-2"
                    >
                        <img
                            class="inline-block h-3"
                            src="../../../../assets/images/close_gray.png"
                        />
                        <div>Nicht öffentlich</div>
                    </div>
                    <div
                        *ngIf="public"
                        class="flex items-center justify-center space-x-2"
                    >
                        <img
                            class="inline-block h-3"
                            src="../../../../assets/images/checkmark_green.png"
                        />
                        <div class="text-brand-magenta">Öffentlich</div>
                    </div>
                </div>
                <div
                    class="grid grid-cols-2 gap-1 w-full items-center text-center"
                >
                    <app-image-type-select
                        [activeColor]="'cyan'"
                        [type]="'private'"
                        [active]="activeComponent === 'private'"
                        (click)="setActiveComponent('private')"
                        >private</app-image-type-select
                    >
                    <app-image-type-select
                        [activeColor]="'cyan'"
                        [type]="'special'"
                        [active]="activeComponent === 'special'"
                        (click)="setActiveComponent('special')"
                        >special</app-image-type-select
                    >
                    <app-image-type-select
                        [activeColor]="'magenta'"
                        [type]="'exclusive'"
                        [active]="activeComponent === 'exclusive'"
                        (click)="setActiveComponent('exclusive')"
                        >exclusive</app-image-type-select
                    >
                    <app-image-type-select
                        [activeColor]="'magenta'"
                        [type]="'intimate'"
                        [active]="activeComponent === 'intimate'"
                        (click)="setActiveComponent('intimate')"
                        >intimate</app-image-type-select
                    >
                </div>

                <p
                    class="pt-8 text-brand-body font-medium text-[13px] leading-6"
                >
                    Für jedes Foto kann nur ein Fototyp ausgewählt werden. Wenn
                    Du Fotos hochlädst, die intime Inhalte zeigen, müssen diese
                    mindestens als "Privat" markiert werden. Beachte bitte, dass
                    Profilbilder nur öffentlich einstellbar sind und keine
                    Inhalte enthalten dürfen, die für Personen unter 18 Jahren
                    ungeeignet sind.
                </p>

                <div class="border-t-2 border-brand-body/20 p-4">
                    <app-link-button
                        *ngIf="!imageProfileImage"
                        (click)="showDeleteModal = !showDeleteModal"
                        [text]="'Foto löschen'"
                        [saveText]="'Löschen'"
                        [savedText]="'Gelöscht'"
                        [success]="successfullyDeleted"
                        [saveButton]="true"
                        class="my-9"
                        [classProps]="'!bg-brand-danger'"
                        [theme]="'pink'"
                    >
                    </app-link-button>
                </div>
            </div>
        </div>
    </div>
</div>
