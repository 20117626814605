<app-mobile-wrapper-layout [type]="'cyan'">
    <app-hearts-bg *ngIf="!error && !showSentEmailScreen" type="set">
        <div
            *ngIf="!loadingWithoutTitle"
            class="text-white px-8 py-8 h-full w-full flex flex-col items-center"
            #mainContent
        >
            <!-- Pages -->
            <div class="w-full flex-grow">
                <!-- E-Mail verified -->
                <div
                    *ngIf="preStep"
                    class="flex flex-col items-center justify-start text-white space-y-12 pt-16"
                >
                    <h1 class="text-[1.7rem] font-bold text-white">
                        E-Mail bestätigt
                    </h1>
                    <img
                        class="h-24 w-24"
                        src="../../../assets/images/auth/bestaetigt.png"
                        alt="login-icon"
                    />
                    <p class="flow-text-logged-in text-center">
                        Danke, Du hast Deine E-Mail erfolgreich bestätigt. Jetzt
                        bist Du nur noch wenige Schritte davon entfernt, Dein
                        Konto vollständig nutzen zu können und neue Menschen zu
                        entdecken.
                    </p>
                    <div class="w-full">
                        <app-link-button
                            (click)="preStep = false"
                            [theme]="'purple'"
                            >Weiter
                        </app-link-button>
                    </div>
                </div>

                <!-- Question 2: Date of birth -->
                <app-question
                    [class]="step === 1 && !preStep ? 'block h-full' : 'hidden'"
                    title="Wann bist du geboren?"
                >
                    <div>
                        <div class="flex space-x-1">
                            <app-date-field
                                [label]="'Tag'"
                                [error]="validationDob.day"
                            >
                                <input
                                    type="number"
                                    pattern="[0-9]*"
                                    inputmode="numeric"
                                    placeholder="TT"
                                    maxlength="2"
                                    [(ngModel)]="day"
                                    (ngModelChange)="setDob()"
                                    class="focus:outline-none bg-transparent input placeholder:input-placeholder w-full text-center"
                                />
                            </app-date-field>
                            <app-date-field
                                [label]="'Monat'"
                                [error]="validationDob.month"
                            >
                                <input
                                    type="number"
                                    pattern="[0-9]*"
                                    inputmode="numeric"
                                    placeholder="MM"
                                    maxlength="2"
                                    [(ngModel)]="month"
                                    (ngModelChange)="setDob()"
                                    class="focus:outline-none bg-transparent input placeholder:input-placeholder w-full text-center"
                                />
                            </app-date-field>
                            <app-date-field
                                [label]="'Jahr'"
                                [error]="validationDob.year"
                            >
                                <input
                                    inputmode="numeric"
                                    placeholder="JJJJ"
                                    maxlength="4"
                                    [(ngModel)]="year"
                                    (ngModelChange)="setDob()"
                                    class="focus:outline-none bg-transparent input placeholder:input-placeholder w-full text-center"
                                />
                            </app-date-field>
                        </div>
                        <p
                            class="text-brand-danger font-semibold text-sm text-center"
                        >
                            {{ errorMessage }}
                        </p>
                    </div>
                    <div class="flex space-x-2 mt-4 text-xs">
                        <div
                            class="bg-white rounded-full w-4 h-4 flex items-center justify-center text-xs font-bold text-brand-magenta shrink-0"
                        >
                            ?
                        </div>
                        <span class="text-white"
                            >Dein Alter wird anderen Nutzern auf amolino
                            angezeigt, jedoch nicht das genaue Datum</span
                        >
                    </div>
                </app-question>

                <!-- Question 3: Gender -->
                <app-question
                    [class]="step === 2 ? 'block' : 'hidden'"
                    title="Welchem Geschlecht gehörst Du an?"
                >
                    <app-form-radio-button
                        label="Ich bin ein Mann"
                        name="gender"
                        (getInputGender)="setGender($event)"
                        [id]="'genderMale'"
                        [checked]="gender === 'MALE'"
                        value="MALE"
                        class="mb-4"
                    >
                    </app-form-radio-button>
                    <app-form-radio-button
                        label="Ich bin eine Frau"
                        name="gender"
                        (getInputGender)="setGender($event)"
                        [id]="'genderFemale'"
                        [checked]="gender === 'FEMALE'"
                        value="FEMALE"
                        class="mb-4"
                    >
                    </app-form-radio-button>
                    <app-form-radio-button
                        label="Ich ordne mich Divers zu"
                        name="gender"
                        (getInputGender)="setGender($event)"
                        [id]="'genderDivers'"
                        [checked]="gender === 'DIVERS'"
                        value="DIVERS"
                        info="Dein Geschlecht wird nicht öffentlich in Deinem Profil auf amolino angezeigt"
                    >
                    </app-form-radio-button>
                </app-question>

                <!-- Question 4: Looking for -->
                <app-question
                    [class]="step === 3 ? 'block' : 'hidden'"
                    title="Welche Geschlechter möchtest Du entdecken?"
                >
                    <app-form-radio-button
                        label="Ich möchte Frauen finden"
                        name="lookingFor"
                        [checked]="lookingFor === 'FEMALE'"
                        (getInputGender)="setLookingFor($event)"
                        [id]="'lookingMale'"
                        value="FEMALE"
                        class="mb-4"
                    >
                    </app-form-radio-button>
                    <app-form-radio-button
                        label="Ich möchte Männer finden"
                        name="lookingFor"
                        [checked]="lookingFor === 'MALE'"
                        (getInputGender)="setLookingFor($event)"
                        [id]="'lookingFemale'"
                        value="MALE"
                        class="mb-4"
                    >
                    </app-form-radio-button>
                    <app-form-radio-button
                        label="Mir egal, zeigt mir alle"
                        name="lookingFor"
                        [checked]="lookingFor === 'ALL'"
                        (getInputGender)="setLookingFor($event)"
                        [id]="'lookingAll'"
                        value="ALL"
                        info="Diese Information ist für andere Nutzer auf amolino nicht sichtbar"
                    ></app-form-radio-button>
                </app-question>

                <!-- Question 5: Location -->
                <app-question
                    [class]="
                        step === 4 && !manuallySelectLocation
                            ? 'block'
                            : 'hidden'
                    "
                    title="Bitte erlaube uns, Deinen Standort zu verwenden"
                    [centered]="true"
                >
                    <p
                        class="text-brand-danger font-semibold text-sm text-center"
                    >
                        {{ errorMessage }}
                    </p>
                    <div class="flex flex-col items-center">
                        <p class="flow-text-logged-in text-center mb-4">
                            Um passende Partner zu entdecken und für Deine
                            eigene Profilsichtbarkeit benötigen wir Deine
                            Zustimmung zur Standortnutzung. Ohne diese ist
                            amolino nicht nutzbar.
                        </p>
                        <img
                            src="../../../assets/images/geolocation_icon.png"
                            class="rounded-full h-[180px] w-[180px] md:h-[160px] md:w-[160px]"
                        />
                        <div
                            class="{{
                                errorMessage ? 'mt-8 md:mt-7' : 'mt-2 md:mt-2'
                            }} w-full"
                        >
                            <app-link-button
                                [theme]="'green'"
                                (click)="retrieveLocation()"
                                >Standort freigeben
                            </app-link-button>
                        </div>
                        <a
                            routerLink="/register/location-info"
                            class="pt-4 link !text-white text-center flow-text-logged-in cursor-pointer"
                            >Warum muss ich den Standort freigeben?</a
                        >
                    </div>
                </app-question>
                <app-question
                    [class]="
                        step === 4 && manuallySelectLocation
                            ? 'block'
                            : 'hidden'
                    "
                    title="Bitte wähle deinen Standort aus"
                    [centered]="true"
                >
                    <p
                        class="text-brand-danger font-semibold text-sm text-center"
                    >
                        {{ errorMessage }}
                    </p>
                    <div class="flex flex-col items-center">
                        <p class="flow-text-logged-in text-center mb-4">
                            Wir konnten leider deinen Standort nicht automatisch
                            ermitteln. Wir bitten dich daher deinen Standort
                            manuell einzugeben. Um passende Partner zu entdecken
                            und für Deine eigene Profilsichtbarkeit benötigen
                            wir Deine Zustimmung zur Standortnutzung. Ohne diese
                            ist amolino nicht nutzbar.
                        </p>
                        <div
                            class="{{
                                errorMessage ? 'mt-8 md:mt-7' : 'mt-12 md:mt-9'
                            }} w-full"
                        >
                            <app-form-input
                                [id]="'autocomplete-input'"
                                [errorMsg]="locationError"
                                placeholder="Musterstadt"
                                label="Standort"
                                info="Hier kannst du deine Stadt eingeben."
                            ></app-form-input>
                        </div>
                    </div>
                </app-question>
            </div>

            <!-- Stepper -->
            <div
                *ngIf="!preStep"
                class="flex flex-col w-full mt-4 sticky bottom-8"
            >
                <span class="text-center text-xs font-bold"
                    >Schritt {{ step }} von 4</span
                >
                <div class="flex space-x-6 items-center">
                    <img
                        class="h-[50px] w-[50px] {{
                            step <= 1 ? 'opacity-0 cursor-default' : ''
                        }}"
                        (click)="previousStep()"
                        src="../../../assets/images/back_button.png"
                        alt="back_button"
                    />
                    <div class="bg-white rounded-full h-2 p-px grow">
                        <div
                            [style]="
                                'width: ' + this.currentStepProgress() + '%'
                            "
                            class="bg-brand-magenta rounded-full h-full"
                        ></div>
                    </div>
                    <button
                        (click)="nextStep($event)"
                        type="submit"
                        [ngClass]="[
                            photoUploading ? 'pointer-events-none' : ''
                        ]"
                    >
                        <img
                            class="h-[50px] w-[50px] {{
                                step === 5 && !manuallySelectLocation
                                    ? 'opacity-0 cursor-default'
                                    : ''
                            }}"
                            src="../../../assets/images/forward_button_{{
                                !currentInputValid ? 'inactive' : 'active'
                            }}.png"
                            alt="forward_button_active"
                        />
                    </button>
                </div>
            </div>
            <p
                class="link !text-white !text-xs cursor-pointer"
                (click)="showLegaLinksModal = true"
            >
                Rechtliche Links
            </p>
        </div>
    </app-hearts-bg>
    <!-- This Modal is visible when showLegalLinksModal is true -->
    <div>
        <app-legal-links
            [isVisible]="showLegaLinksModal"
            (closeModal)="showLegaLinksModal = false"
        ></app-legal-links>
    </div>

    <app-overlay
        *ngIf="error && !loading"
        [theme]="'dark'"
        [classProps]="'p-6'"
        [customFooter]="true"
        [h1]="'Kontoerstellung abgebrochen'"
        [imageProps]="'w-16 h-16'"
        [image]="'../../../assets/images/alert_white.png'"
        [footerLinkText]="'Zurück zur Startseite'"
        [customFooter]="true"
        (clickFooter)="goToHome()"
    >
        <div subtitle>
            Leider konnte das gewünschte Konto nicht erstellt werden. Dies kann
            verschiedene Gründen haben. Bitte wende Dich an unseren
            Kundenservice unter
            <a href="mailto:support@amolino.de">support@amolino.de</a>
            für weitere Informationen.
        </div>
        <div content>
            <div class="w-full pt-6">
                <app-link-button
                    [theme]="'cyan'"
                    (userClicked)="toggleSupport()"
                    >Support Kontaktieren</app-link-button
                >
            </div>
        </div>
    </app-overlay>

    <div
        class="absolute top-0 w-full h-full"
        *ngIf="loading || loadingWithoutTitle"
    >
        <app-loader
            [h1]="loadingWithoutTitle ? '' : 'Konto wird erstellt'"
            [theme]="'transparent'"
        >
        </app-loader>
    </div>
    <app-email-sent
        *ngIf="showSentEmailScreen"
        [errorMessage]="errorEmailVerification"
    ></app-email-sent>
</app-mobile-wrapper-layout>
