<div
    class="h-full w-full absolute top-0 left-0 z-30 md:flex md:items-center md:justify-center md:bg-gray-400/80"
>
    <app-modal
        [startPageModal]="true"
        *ngIf="!emailSent"
        (closeMatch)="closePopup()"
        [desktopHeight]="'800'"
        [desktopWidth]="'400'"
        [supportFooter]="true"
        [topPadding]="'6'"
        [image]="'../../../../assets/images/auth/zuruecksetzen_icon.png'"
        [h1]="'Passwort zurücksetzen'"
    >
        <!-- <app-body></app-body> -->
        <div subtitle class="px-4">
            Gib bitte Deine E-Mail-Adresse ein. Wir werden Dir anschließend eine
            E-Mail senden, mit der Du Dein Passwort zurücksetzen kannst.
        </div>
        <div content class="grid justify-items-center px-4">
            <form
                [formGroup]="form"
                (ngSubmit)="requestEmail()"
                class="mb-6 w-full"
            >
                <app-input-box
                    [label]="'E-Mail'"
                    [icon]="'auth/at-symbol.png'"
                    [error]="!email.valid"
                >
                    <input
                        class="w-full focus:outline-none input text-brand-title placeholder:input-placeholder"
                        placeholder="Wie lautet Deine E-Mail"
                        formControlName="email"
                    />
                    <p
                        error
                        *ngIf="email.touched && email.hasError('required')"
                    >
                        Bitte füllen Sie dieses Feld aus.
                    </p>
                    <p error *ngIf="email.touched && email.hasError('email')">
                        Bitte geben Sie eine gültige E-Mail-Adresse ein.
                    </p>
                </app-input-box>
                <div class="mt-8 py-2 w-full">
                    <app-link-button
                        [loggedIn]="false"
                        [isDisabled]="isClickInProgress"
                        *ngIf="form.valid"
                        (userClicked)="requestEmail()"
                        [theme]="'purple'"
                        >E-Mail anfordern</app-link-button
                    >
                    <app-link-button
                        [loggedIn]="false"
                        *ngIf="!form.valid"
                        [theme]="'disable'"
                        >E-Mail anfordern</app-link-button
                    >
                </div>
                <input type="submit" hidden />
            </form>
        </div>
    </app-modal>
    <app-modal
        [startPageModal]="true"
        *ngIf="emailSent"
        (closeMatch)="emailSent = !emailSent"
        [desktopHeight]="'800'"
        [supportFooter]="true"
        [desktopWidth]="'400'"
        [topPadding]="'6'"
        [image]="'../../../../assets/images/checkmark-circle.png'"
        [h1]="'E-Mail gesendet'"
    >
        <div subtitle>
            Bitte überprüfe sowohl Deinen Posteingang als auch Deinen
            Spam-/Junk-Ordner auf den Eingang unserer E-Mail. Bitte beachte,
            dass es bis zu 5 Minuten dauern kann, bis die E-Mail bei Dir
            eintrifft.
        </div>
        <div content class="flex flex-col items-center justify-center">
            <div class="text-brand-title text-base font-semibold">
                Keine E-Mail erhalten?
            </div>
            <a class="modal-link" routerLink="../help">E-Mail erneut senden</a>
        </div>
    </app-modal>
</div>
