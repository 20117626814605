import { Component, Input } from "@angular/core";

@Component({
    selector: "app-form-generic-input",
    templateUrl: "./generic-input.component.html",
})
export class FormGenericInputComponent {
    @Input() id!: string;
    @Input() class?: string;
    @Input() info?: string;
    @Input() checked?: boolean;
    @Input() error = "";
}
