import { Component, Input } from "@angular/core";

@Component({
    selector: "app-date-field",
    templateUrl: "./date-field.component.html",
})
export class DateFieldComponent {
    @Input() label!: string;
    @Input() error!: boolean;
    @Input() classprop!: string;
}
