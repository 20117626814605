<div [ngClass]="liftUp ? '-mt-16' : ' '">
    <div [ngClass]="bgColor" class="h-10 translate-y-20"></div>
    <div class="relative">
        <div
            [ngClass]="position + ' ' + bgColor"
            class="text-3xl mix-blend-normal p-2 absolute z-10 w-16 h-16 -translate-y-8 md:-translate-y-9 rounded-full md:w-20 md:h-20 hidden md:flex flex-col items-center justify-center"
        >
            <img
                class="mix-blend-normal h-full w-full"
                src="../../../../assets/images/{{ icon }}"
            />
        </div>
        <div
            [ngClass]="bgColor + ' ' + rotate"
            class="h-16 shadow-[1px_-13px_22px_-18px_rgba(0,0,0,0.1)]"
        ></div>
    </div>
</div>
