import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "app-overlay",
    templateUrl: "./overlay.component.html",
})
export class OverlayComponent {
    @Input() theme = "white";
    @Input() topPadding = "16";
    @Input() borderRadius = "none";
    @Input() public closeColor = "gray";
    @Output() closeMatch: EventEmitter<string> = new EventEmitter();
    @Output() clickFooter: EventEmitter<string> = new EventEmitter();
    @Output() footerLink: EventEmitter<string> = new EventEmitter();
    @Input() supportFooter = false;
    @Input() customFooter = false;
    @Input() event: string;
    @Input() sectionTopPaddingLarge = "8";
    @Input() sectionTopPaddingSmall = "4";
    @Input() desktopHeight = "600";
    @Input() desktopWidth = "300";
    @Input() show = true;
    @Input() overlay = false;
    @Input() startPageModal = false;
    @Input() h1: string;
    @Input() padding = "6";
    @Input() image: string;
    @Input() imageWrapperProps: string;
    @Input() imageProps: string;
    @Input() footerLinkRouterLink: string;
    @Input() disclaimer: string;
    @Input() smallIcon: string;
    @Input() body: string;
    @Input() footer: string;
    @Input() footerLinkText: string;
    @Input() link: string;
    @Input() subtitle: string;
    @Input() keys: number;
    @Input() fontSize = "xs";
    @Input() classProps!: string;

    toggle() {
        this.show = !this.show;
    }

    public emitFooterClicked(): void {
        this.clickFooter.emit();
    }

    public emitCloseClicked(): void {
        this.closeMatch.emit();
    }

    public emitFooterLinkClicked(): void {
        this.footerLink.emit();
    }
}
