import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "app-legal-links",
    templateUrl: "./legal-links.component.html",
})
export class LegalLinksComponent {
    @Input() isVisible!: boolean;
    @Output() closeModal: EventEmitter<string> = new EventEmitter();

    closeWindow() {
        this.closeModal.emit();
    }
}
