import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { ApiService } from "../services/api.service";
import { WebToAppOperation, WebToAppService } from "../services/web-to-app.service";

@Injectable({
    providedIn: "root",
})
export class IsNotAuthenticatedGuard implements CanActivate {
    constructor(
        private router: Router,
        private api: ApiService,
        private webToAppService: WebToAppService
    ) {}

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.api.isAuthenticated() && localStorage.getItem("register_completed") === "TRUE") {
            const openedInCustomTab = this.webToAppService.openedInCustomTab();
            const customTabData = this.webToAppService.getCustomTabData();
            if (openedInCustomTab && customTabData) {
                if (customTabData.operation === WebToAppOperation.AUTH) {
                    if (this.webToAppService.openedInCustomTab())
                        this.webToAppService.flagAsCustomTab();
                    return true;
                }
            }

            this.router.navigate(["user/discover"]);
            return false;
        } else {
            if (this.webToAppService.openedInCustomTab()) this.webToAppService.flagAsCustomTab();

            return true;
        }
    }
}
