import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, Input } from "@angular/core";

@Component({
    selector: "app-simple-fade-in-out-modal",
    templateUrl: "./simple-fade-in-out-modal.component.html",
    animations: [
        trigger("fadeInOut", [
            state("void", style({ opacity: 0 })),
            state("*", style({ opacity: 1 })),
            transition(":enter, :leave", animate("300ms ease-in-out")),
        ]),
    ],
})
export class SimpleFadeInOutModalComponent {
    @Input() showModal!: boolean;
}
