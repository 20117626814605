import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, catchError, concat, from, map, of, switchMap, tap } from "rxjs";
import { environment } from "src/environments/environment";
import { RegisterStep } from "../interfaces/RegisterSteps";
import {
    AuthResponse,
    FeesResponse,
    ImageUploadResponse,
    RegisterStepResponse,
    TagsResponse,
} from "../interfaces/api-responses";
import { CacheResolverService } from "./cache-resolver.service";
import { LogoutService } from "./logout.service";
import { MatchesService } from "./matches.service";
import { MemberMeService } from "./member-me.service";
import { MembersService } from "./members.service";
import { MessageService } from "./message.service";
import { ShopService } from "./shop.service";
import { TokenHandlingService } from "./token-handling.service";
import { WebToAppService } from "./web-to-app.service";

export type AuthProvider = "LOCAL" | "FACEBOOK" | "GOOGLE" | "APPLE";
interface AuthDataCommon {
    authProvider: AuthProvider;
    register?: boolean;
    tt_ref?: string;
}
export interface LocalAuthData extends AuthDataCommon {
    email: string;
    password: string;
}
export interface ThirdPartyAuthData extends AuthDataCommon {
    token: string;
}
export type AuthData = LocalAuthData | ThirdPartyAuthData;

@Injectable({
    providedIn: "root",
})
export class ApiService {
    private readonly endpointsToCache: string[] = [
        "members/me/matches",
        "matches/",
        "members/me/likes",
    ];

    constructor(
        private http: HttpClient,
        private cache: CacheResolverService,
        private tokenHandling: TokenHandlingService,
        private logoutService: LogoutService,
        private router: Router,
        private webToAppService: WebToAppService
    ) {}

    /**
     * Sends a get request
     * @param endpoint The endpoint
     * @param authenticated Whether to send an authenticated request or not
     * @returns The observable
     */
    get(endpoint: string, authenticated = true): Observable<any> {
        const cachedResponse = this.cache.get(endpoint);
        if (cachedResponse) {
            //Returning cached response for endpoint: ${endpoint}
            const cachedResponseObservable = of(cachedResponse.body);
            const apiResponseObservable = this.getFromApi(endpoint, authenticated).pipe(
                catchError((error: HttpErrorResponse) => {
                    console.error(`API error for endpoint: ${endpoint}`, error);
                    this.webToAppService.logHTTPErrorResponse(error);

                    throw error;
                }),
                map((apiResponse: any) => {
                    //Updating cached response for endpoint: ${endpoint}
                    this.cache.set(endpoint, apiResponse);
                    return apiResponse.body;
                })
            );
            //make api response available in the view
            return concat(cachedResponseObservable, apiResponseObservable);
        } else {
            //Fetching from API: ${endpoint}
            return this.getFromApi(endpoint, authenticated).pipe(
                map((apiResponse: any) => {
                    //Caching response for endpoint: ${endpoint} only for endpoints that are in the Cache list
                    if (this.shouldCacheEndpoint(endpoint)) {
                        this.cache.set(endpoint, apiResponse);
                    }
                    return apiResponse.body;
                })
            );
        }
    }

    private getFromApi(endpoint: string, authenticated: boolean): Observable<any> {
        const token$ = from(this.tokenHandling.getValidAuthenticationToken());

        // Use switchMap to wait for the token before making the HTTP request
        return token$.pipe(
            catchError((error: HttpErrorResponse) => {
                // error while fetching auth token
                console.warn("Couldn't get accessToken. The user will be logged out.", error);
                this.webToAppService.logHTTPErrorResponse(error);

                this.logoutService.logout();
                this.router.navigate(["/"]);

                throw error;
            }),
            switchMap((token) => {
                const headers: { [header: string]: string | string[] } =
                    authenticated && token.accessToken ? { Authorization: token.accessToken } : {};

                return this.http
                    .get(`${environment.url.api}/${endpoint}`, {
                        headers,
                        observe: "response",
                    })
                    .pipe(
                        catchError((error: HttpErrorResponse) => {
                            if (error.status === 0) {
                                console.error(
                                    `Client-Side API error for endpoint: ${endpoint}`,
                                    error
                                );
                            } else {
                                console.error(
                                    `Server-Side API error for endpoint: ${endpoint}`,
                                    error
                                );
                            }
                            this.webToAppService.logHTTPErrorResponse(error);

                            throw error;
                        })
                    );
            })
        );
    }

    private shouldCacheEndpoint(endpoint: string): boolean {
        // Check if endpoint is in the endpointsToCache array
        if (this.endpointsToCache.includes(endpoint)) {
            return true;
        } else {
            if (endpoint.startsWith("members/")) {
                const segments = endpoint.split("/");
                const hasDynamicId = segments.length === 2 && segments[1].length > 5;
                return hasDynamicId; // Only cache endpoint with dynamic ID
            } else if (endpoint.startsWith("matches/")) {
                const segments = endpoint.split("/");
                const hasDynamicId = segments.length === 2 && segments[1].length > 5;
                return hasDynamicId; // Only cache endpoint with dynamic ID
            } else {
                return false;
            }
        }
    }

    /**
     * Sends a post request
     * @param endpoint The endpoint
     * @param authenticated Whether to send an authenticated request or not
     * @returns The observable
     */
    public post(endpoint: string, body: any | null = null, authenticated = true): Observable<any> {
        // Retrieve authentication token
        const token$ = from(this.tokenHandling.getValidAuthenticationToken());

        // Use switchMap to wait for the token before making the HTTP request
        return token$.pipe(
            catchError((error) => {
                // error while fetching auth token
                console.warn("Couldn't get accessToken. The user will be logged out.", error);
                this.webToAppService.logHTTPErrorResponse(error);

                this.logoutService.logout();
                this.router.navigate(["/"]);

                throw error;
            }),
            switchMap((token) => {
                const headers: { [header: string]: string | string[] } =
                    authenticated && token.accessToken ? { Authorization: token.accessToken } : {};

                return this.http.post(`${environment.url.api}/${endpoint}`, body ?? {}, {
                    headers,
                });
            })
        );
    }

    /**
     * Sends a delete request
     * @param endpoint The endpoint
     * @param authenticated Whether to send an authenticated request or not
     * @returns The observable
     */
    public delete(endpoint: string, authenticated = true): Observable<any> {
        // Retrieve authentication token
        const token$ = from(this.tokenHandling.getValidAuthenticationToken());

        // Use switchMap to wait for the token before making the HTTP request
        return token$.pipe(
            catchError((error) => {
                // error while fetching auth token
                console.warn("Couldn't get accessToken. The user will be logged out.", error);
                this.webToAppService.logHTTPErrorResponse(error);

                this.logoutService.logout();
                this.router.navigate(["/"]);

                throw error;
            }),
            switchMap((token) => {
                const headers: { [header: string]: string | string[] } =
                    authenticated && token.accessToken ? { Authorization: token.accessToken } : {};

                return this.http.delete(`${environment.url.api}/${endpoint}`, {
                    headers,
                });
            })
        );
    }

    /**
     * Checks whether the user is authenticated
     * @returns Whether the user is authenticated
     */
    public isAuthenticated(): boolean {
        return this.tokenHandling.getAuthenticationToken() !== null;
    }

    /**
     * Authenticates the user
     * @param data The authentication data
     * @returns The observable
     */
    public auth(data: LocalAuthData | ThirdPartyAuthData): Observable<AuthResponse> {
        const request = this.http.post(`${environment.url.auth}/auth`, data, { withCredentials: true }).pipe(
            tap((response: AuthResponse) => {
                if (response && response.accessToken) {
                    this.tokenHandling.setAuthenticationToken(response.accessToken);
                }
            })
        );

        return request;
    }

    /**
     * Pushes profile data while updating the account
     * @param data The data to push
     * @returns The observable
     */
    public registerStep(data: RegisterStep): Observable<RegisterStepResponse> {
        return this.post("register/step", data);
    }

    /**
     * Upload Image
     * @param data The Image data to push
     * @returns The observable
     */
    public images(data: FormData): Observable<ImageUploadResponse> {
        return this.post("members/me/images", data);
    }

    /**
     * Get all tags
     * @returns The observable
     */
    public tags(): Observable<TagsResponse> {
        return this.get("tags");
    }

    public fees(): Observable<FeesResponse> {
        return this.get("settings/fees");
    }

    /**
     * Access to the message service
     * @returns The message service
     */
    public message(): MessageService {
        return new MessageService(this);
    }

    /**
     * Access to the member-me service
     * @returns The member-me service
     */
    public me(): MemberMeService {
        return new MemberMeService(this);
    }

    /**
     * Access to the members service
     * @returns The members service
     */
    public members(): MembersService {
        return new MembersService(this);
    }

    public matches(): MatchesService {
        return new MatchesService(this);
    }

    /**
     * Access to the shop service
     * @returns The shop service
     */
    public shop(): ShopService {
        return new ShopService(this);
    }

    /**
     * Sets up the third party buttons
     */
    public setupThirdPartyButtons(handler: (token: string) => void, register: boolean): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const gid = (window as any).google.accounts.id;
        gid.initialize({
            client_id: environment.google.auth.clientId,
            callback: (response) => {
                handler(response.credential as string);
            },
        });

        const googleButton = document.getElementById(
            register ? "signInWithGoogleReg" : "signInWithGoogle"
        ) as HTMLDivElement;

        gid.renderButton(googleButton, {
            theme: "outline",
            size: "small",
        });

        if (register) {
            this.transformRegisterButton(googleButton);
        } else {
            this.transformLoginButton(googleButton);
        }
    }

    /**
     * Transforms the button on the login page
     * @param googleButton The google button
     */
    private transformLoginButton(googleButton: HTMLDivElement) {
        const actualButton = document.getElementById(
            "signInWithGoogleButton"
        ) as HTMLTemplateElement;
        const iframe = googleButton.querySelector("iframe");

        if (
            !actualButton ||
            !iframe ||
            !googleButton.querySelector('[role="button"]') ||
            !actualButton.content.firstElementChild
        ) {
            console.warn("Couldn't transform google login button.");
            return;
        }

        iframe.remove();
        const wrapper = googleButton.querySelector('[role="button"]') as HTMLDivElement;
        wrapper.replaceChildren(actualButton.content.firstElementChild);
        wrapper.className = "";
    }

    /**
     * Transforms the button on the register page
     * @param googleButton The google button
     */
    private transformRegisterButton(googleButton: HTMLDivElement) {
        const actualButton = document.getElementById(
            "signInWithGoogleButton"
        ) as HTMLTemplateElement;

        const iframe = googleButton.querySelector("iframe");

        if (
            !actualButton ||
            !iframe ||
            !googleButton.querySelector('[role="button"]') ||
            !actualButton.content.firstElementChild
        ) {
            console.warn("Couldn't transform google register button.");
            return;
        }

        iframe.remove();
        (googleButton.firstElementChild as HTMLDivElement).className = "";

        const wrapper = googleButton.querySelector('[role="button"]') as HTMLDivElement;
        wrapper.className = "";

        wrapper.replaceChildren(actualButton.content.firstElementChild);
    }

    /* TODO: Seemingly not used at the moment: */

    public share(shareId: string): Observable<any> {
        return this.get(`share/${shareId}`, false);
    }

    /**
     * Verify email
     * @param data The verification token to verify
     * @returns The observable
     */
    // TODO: check if this is even needed
    public verifyEmail(data: any): Observable<any> {
        return this.post("verify", data);
    }

    /**
     * Signs up a new user
     * @param data The sign uo data
     * @returns The observable
     */
    // TODO: check if this is even needed
    public register(data: any): Observable<any> {
        return this.post("register", data, false);
    }
}
