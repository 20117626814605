import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component } from "@angular/core";

@Component({
    selector: "app-card",
    templateUrl: "./card.component.html",
    animations: [
        trigger("slideInOut", [
            state("in", style({ transform: "translateX(0)" })),
            transition(":leave", [
                animate("600ms ease-in-out", style({ transform: "translateX(-100%)" })),
            ]),
        ]),
    ],
})
export class CardComponent {
    counter = 0;
    show = true;

    toggle() {
        this.show = !this.show;
    }
}
