import { DOCUMENT } from "@angular/common";
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
} from "@angular/core";

import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { NavigationService } from "src/app/services/navigation.service";
import { NotificationService } from "src/app/services/notification.service";
import { getMeData } from "src/app/store/me-data-store";
import { environment } from "src/environments/environment";

import { animate, state, style, transition, trigger } from "@angular/animations";
import { Router } from "@angular/router";
import { MeData } from "src/app/interfaces/MeData";
import { ApiService } from "src/app/services/api.service";
import { LogoutService } from "src/app/services/logout.service";
import { MatchDataService } from "src/app/services/match-data.service";
import { NavBarService } from "src/app/services/navbar.service";
import { StatusService } from "src/app/services/status.service";

@Component({
    selector: "app-main-layout",
    templateUrl: "./main-layout.component.html",
    animations: [
        trigger("slide", [
            state("left", style({ transform: "translateX(0%)" })),
            state("right", style({ transform: "translateX(100%)" })),
            transition("left => right", animate("500ms ease-out")),
            transition("right => left", animate("500ms ease-in")),
        ]),
    ],
})
export class MainLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() isRightMenuClickDisabled = false;
    @Input() isLeftMenuClickDisabled = false;
    @Input() showProfile = false;
    @Input() separationLine = true;
    @Input() public rightMenuText!: string;
    @Input() hScreen = true;
    @Input() public rightMenuIcon!: string;
    @Input() public rightMenuOpacity = "100";
    @Input() public leftMenuIcon = "backarrow";
    @Input() public leftMenuText!: string;
    @Input() public rightMenuLinkPath!: string;
    @Input() public userAvatar!: string;
    @Input() public leftTextLinkPath!: string;
    @Input() public bottomPadding = "";
    @Input() public rightMenuDisable!: boolean;
    @Input() public menuPositionLeft: boolean;
    @Input() public menuBar: boolean;
    @Input() public bgColor = "white";
    /**
     * Whether 100% of the content includes the blue navbar (relative) or not (fixed).
     */
    @Input() public navbarRelative = false;

    public me?: MeData;
    public name: any;
    public unsentMessages: Array<any> = [];
    public activePath!: string;
    public imagePath = environment.url.image;
    public balanceUpdated = false;
    public currentPath: string;
    public match: any;
    partner: any;
    public pending: string[] = [];

    protected loading = false;
    public showLoginBonus = false;

    public users = [];
    public product: any;
    public newLikes = [];
    public seenLikes = [];
    public notification: any;
    public matchInfo = {
        myImage: "",
        partnerImage: "",
        partnerId: "",
        matchId: "",
        type: "",
    };

    showNormalMatch = false;
    showInstantMatchPassive = false;
    showInstantMatchActive = false;
    displayCreditNotification = false;
    infoCredits = false;
    displayShopNotification = false;

    private notificationServiceSubscription?: Subscription;

    matchData: any;
    matchId: string;

    /**
     * True: display new like notification on top
     */
    displayLikeYouNotification = false;
    displayDiscoverNotification = false;

    /**
     * True: display new chat notification on top
     */
    displayChatNotification = false;
    displayNotification = false;
    addtoKeys = 0;
    addtoCoins = 0;
    keys = 0;
    coins = 0;
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private router: Router,
        private notificationService: NotificationService,
        private navigation: NavigationService,
        private store: Store<any>,
        private matchDataService: MatchDataService,
        private api: ApiService,
        private cdr: ChangeDetectorRef,
        private logoutService: LogoutService,
        private navbarService: NavBarService,
        private statusService: StatusService
    ) {
        this.navbarService.navbarOptionsChanged.subscribe((options) => {
            this.showProfile = options.showProfile ?? this.showProfile;
            this.rightMenuText = options.rightMenuText ?? this.rightMenuText;
            this.rightMenuIcon = options.rightMenuIcon ?? this.rightMenuIcon;
            this.rightMenuOpacity = options.rightMenuOpacity ?? this.rightMenuOpacity;
            this.leftMenuIcon = options.leftMenuIcon ?? this.leftMenuIcon;
            this.leftMenuText = options.leftMenuText ?? this.leftMenuText;
            this.rightMenuLinkPath = options.rightMenuLinkPath ?? this.rightMenuLinkPath;
            this.userAvatar = options.userAvatar ?? this.userAvatar;
            this.leftTextLinkPath = options.leftTextLinkPath ?? this.leftTextLinkPath;
            this.bottomPadding = options.bottomPadding ?? this.bottomPadding;
            this.rightMenuDisable = options.rightMenuDisable ?? this.rightMenuDisable;
            this.menuPositionLeft = options.menuPositionLeft ?? this.menuPositionLeft;
            this.menuBar = options.menuBar ?? this.menuBar;
            this.navbarRelative = options.navbarRelative ?? this.navbarRelative;
            this.bgColor = options.bgColor ?? this.bgColor;
        });
        this.statusService.isLoading.subscribe((isLoading) => {
            this.loading = isLoading;
        });
    }

    closeMatch() {
        localStorage.setItem(this.matchInfo.matchId, "true");
        this.matchDataService.showMatchData(false);
    }

    goToChat() {
        this.closeMatch();
        this.matchDataService.showMatchData(false);
        this.matchDataService.currentShowMatch.subscribe();
        this.router.navigate(["/chat/" + this.matchInfo.matchId]);
    }

    isOlderThan24h(date1: Date, date2: Date): boolean {
        const timeDifference = Math.abs(date1.getTime() - date2.getTime());
        const hoursDifference = timeDifference / (1000 * 60 * 60);
        const isOlderThan24h = hoursDifference > 24;

        return isOlderThan24h;
    }

    goBack() {
        if (this.navbarService.customBackFunction) {
            this.navbarService.customBackFunction();
        } else {
            this.navigation.back();
        }
    }

    deleteUnsentMessage(matchId) {
        this.unsentMessages = this.unsentMessages.filter(
            (unsentMessage) => unsentMessage.matchId !== matchId
        );

        localStorage.setItem("unsentMessages", JSON.stringify(this.unsentMessages));
    }

    showLoginBonusPage() {
        this.showLoginBonus = true;
    }

    ngOnDestroy(): void {
        if (this.notificationServiceSubscription)
            this.notificationServiceSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.name = "name";
        this.cdr.markForCheck();
        this.cdr.detectChanges();

        this.store.select(getMeData).subscribe((response) => {
            if (!response) return;

            this.me = response;
            this.keys = response.keys;
            this.coins = response.coins;

            if (this.coins < 10 && window.location.pathname.includes("/chat/")) {
                this.displayCreditNotification = true;
            }

            // Check if a new login bonus should be applied
            const updatedBy = this.me.progressiveBonusKeys.updatedBy;
            const today = new Date();
            const givenDate = new Date(updatedBy);
            if (givenDate && this.isOlderThan24h(today, givenDate)) {
                this.showLoginBonusPage();
            }

            this.displayLikeYouNotification = this.me.likedBy.length > 0;

            if (this.me && this.me.matches && this.me.matches.length > 0) {
                this.matchId = this.me.matches[0].matchId;
            }

            this.pending = this.getInitialPendingArray();

            // Set displayChatNotification to true if there is a match where new == true
            for (const match of this.me.matches) {
                if (match.new) {
                    this.displayChatNotification = true;
                    break;
                }
            }

            this.notificationService.init(this.me._id);
            if (this.matchId && this.pending && this.pending.includes("initMatch")) {
                this.api
                    .matches()
                    .find(this.matchId)
                    .subscribe((response) => {
                        this.match = response.match;
                        this.partner = this.match.partner;

                        if (this.me) this.matchInfo.myImage = this.me.image;
                        this.matchInfo.partnerImage = this.partner.image.image;
                        this.matchInfo.partnerId = this.partner._id;
                        this.matchInfo.matchId = this.match._id;
                        const matchData = this.matchInfo;
                        matchData["type"] = "normal";
                        this.matchDataService.changeMatchData(matchData);

                        //
                        // Remove the "initMatch" flag from the pending array

                        let pending: string[] = this.getInitialPendingArray();
                        pending = pending.filter((item) => item !== "initMatch");
                        localStorage.setItem("initialPending", JSON.stringify(pending));
                        this.pending = pending;
                    });
            }
        });

        this.currentPath = window.location.pathname;

        this.notificationServiceSubscription = this.notificationService.newMessage.subscribe(
            (message: string) => {
                this.notification = JSON.parse(message);
                if (this.notification.type === "NewMessageTemplate") {
                    if (
                        !window.location.href.includes("chats") &&
                        !window.location.href.includes("chat/" + this.notification.match)
                    ) {
                        this.displayNotification = true;
                    }
                    const notificationTone = new Audio("assets/sounds/new_message.wav");
                    this.displayChatNotification = true;
                    notificationTone.play();
                }

                if (this.notification.type === "NewLikeTemplate") {
                    if (!window.location.href.includes("like-you")) {
                        this.displayNotification = true;
                    }
                    const notificationTone = new Audio("assets/sounds/new_like.wav");
                    this.displayLikeYouNotification = true;

                    notificationTone.play();
                }
                if (
                    this.notification.type === "NewInstantmatchTemplate" ||
                    this.notification.type === "NewMatchTemplate"
                ) {
                    if (this.me) this.matchInfo.myImage = this.me.image;
                    this.matchInfo.partnerImage = this.notification.partner_image;
                    this.matchInfo.matchId = this.notification.match;

                    const notificationTone = new Audio("assets/sounds/match_final.wav");
                    if (this.isChatPage()) {
                        this.displayNotification = true;
                        this.displayChatNotification = true;
                    } else {
                        if (this.notification.type === "NewInstantmatchTemplate") {
                            this.showInstantMatchPassive = true;
                            this.showNormalMatch = false;
                        }
                        if (this.notification.type === "NewMatchTemplate") {
                            this.showInstantMatchPassive = false;
                            this.showNormalMatch = true;
                        }
                    }
                    notificationTone.play();
                }
            }
        );
        this.activePath = this.document.location.pathname;
        const unsentMessagesLS = localStorage.getItem("unsentMessages");
        this.unsentMessages = unsentMessagesLS ? JSON.parse(unsentMessagesLS) : [];

        if (this.unsentMessages) {
            this.unsentMessages.forEach((unsentMessage) =>
                this.api
                    .matches()
                    .messages(unsentMessage.matchId, unsentMessage.messageBody)
                    .subscribe({
                        next: () => {
                            this.deleteUnsentMessage(unsentMessage.matchId);
                        },
                        error: () => {
                            console.error("Failed to send unsent message", {
                                matchId: unsentMessage.matchId,
                                message: unsentMessage.messageBody,
                            });
                        },
                    })
            );
        }
        this.matchDataService.currentShowMatch.subscribe((showMatch) => {
            if (showMatch) {
                this.matchDataService.currentMatchData.subscribe((matchData) => {
                    if (!matchData) return;

                    this.matchInfo = matchData;
                    if (this.matchInfo.type === "instantMatchActive") {
                        this.showInstantMatchActive = true;
                    }
                    if (this.matchInfo.type === "instantMatchPassive") {
                        this.showInstantMatchPassive = true;
                    }
                    if (this.matchInfo.type === "normal") {
                        this.showNormalMatch = true;
                    }
                });
            }
        });

        // to watch the path change
        this.router.events.subscribe(() => {
            this.activePath = this.document.location.pathname;
        });

        this.name = this.me ? this.me.username : "-";
    }

    ngAfterViewInit(): void {
        //remove the hidden class from cookie banner. hidden class will be added in chat page
        // const element = document.getElementById("usercentrics-root");
        // if (element && element.classList.contains("hidden")) {
        //     element.classList.remove("hidden");
        // }
    }

    emitRightMenuClicked() {
        if (this.navbarService.clickedRightMenu) this.navbarService.clickedRightMenu();
    }

    emitLeftMenuClicked() {
        if (this.navbarService.clickedLeftMenu) this.navbarService.clickedLeftMenu();
    }

    emitAvatarClicked() {
        if (this.navbarService.clickedAvatar) this.navbarService.clickedAvatar();
    }

    private isChatPage(): boolean {
        const currentPath = this.router.url;
        return currentPath.startsWith("/chat/");
    }

    private getInitialPendingArray(): string[] {
        const initialPending = localStorage.getItem("initialPending");
        if (!initialPending) {
            console.warn("Failed to read localStorage initialPending. User will be logged out.");
            this.logoutService.logout();
            return [];
        }
        const pending: string[] = JSON.parse(initialPending);
        return pending;
    }
}
