import { Component } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";

@Component({
    selector: "app-register-success",
    templateUrl: "./register-success.component.html",
})
export class RegisterSuccessComponent {
    showTutorial = false;

    tutorials: {
        heading: string;
        description: string;
        image: number;
        buttonText: string;
    }[] = [
        {
            heading: "So funktioniert amolino.de",
            description:
                "Um auf Amolino mit anderen Nutzern chatten zu können, muss zuerst ein Match zustande kommen. Ein Match entsteht, wenn Du und ein anderer Nutzer Euch gegenseitig mit einem Like signalisiert habt, dass Ihr Interesse habt.",
            image: 1,
            buttonText: "Weiter",
        },
        {
            heading: "Personen ablehnen",
            description:
                "Mit dem X-Button hast Du die Möglichkeit, Personen abzulehnen, die Dir nicht gefallen. Keine Sorge, die betroffene Person wird darüber nicht informiert. So kannst Du ganz einfach und unkompliziert auswählen, mit wem Du in Kontakt treten möchtest und mit wem nicht",
            image: 2,
            buttonText: "Weiter",
        },
        {
            heading: "Personen liken",
            description:
                "Wenn Du auf Amolino Interesse an einer Person zeigen möchtest, kannst Du einfach auf das Herz-Symbol klicken. Dadurch wird die Person darüber benachrichtigt, dass Du an ihr oder ihm interessiert bist. Es ist jedoch nicht sofort ersichtlich, wer dieses Interesse signalisiert hat. Erst wenn die andere Person Dir ebenfalls ein Like sendet, entsteht ein Match und Ihr könnt miteinander chatten",
            image: 3,
            buttonText: "Weiter",
        },
        {
            heading: "Sofort-Match",
            description:
                "Wenn Du nicht lange warten möchtest, ob die Person, die Dir besonders gut gefällt, auch Interesse an Dir hat, kannst Du die Sofort-Match-Funktion nutzen. Klicke dazu einfach auf den Button mit dem Blitz-Symbol. So hast Du sofort ein Match mit Deinem Schwarm und kannst ihn oder sie direkt mit einer ersten Nachricht von Dir überzeugen",
            image: 4,
            buttonText: "Weiter",
        },
        {
            heading: "Profil freischalten",
            description:
                'Unter dem Menüpunkt "Entdecken" hast Du zusätzlich die Möglichkeit, das Profil einer Person freizuschalten. Klicke dafür einfach auf das kleine Schloss-Symbol am rechten Bildschirmrand. Diese Funktion ermöglicht es Dir, weitere Bilder und Informationen über die Person zu sehen, bevor Du ein Like vergibst oder ein Sofort-Match erstellst',
            image: 5,
            buttonText: "Jetzt loslegen",
        },
    ];

    constructor(private authService: AuthService) {}

    goToDiscover() {
        this.authService.navigateToDiscoverPageAfterLoginRegister();
    }
}
