import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { FooterComponent } from "../shared/components/footer/footer.component";
import { HeaderComponent } from "../shared/components/header/header.component";
import { SharedModule } from "../shared/shared.module";
import { UserAuthRoutingModule } from "../user-auth/user-auth-routing.module";
import { MainLayoutComponent } from "./main-layout/main-layout.component";
import { MenuItemComponent } from "./menu-item/menu-item.component";
import { MobileWrapperLayoutComponent } from "./mobile-wrapper-layout/mobile-wrapper-layout.component";
import { PublicWrapperLayoutComponent } from "./public-wrapper-layout/public-wrapper-layout.component";

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        MobileWrapperLayoutComponent,
        MainLayoutComponent,
        MenuItemComponent,
        PublicWrapperLayoutComponent,
    ],
    imports: [CommonModule, SharedModule, UserAuthRoutingModule],
    exports: [
        HeaderComponent,
        FooterComponent,
        MobileWrapperLayoutComponent,
        MainLayoutComponent,
        PublicWrapperLayoutComponent,
    ],
})
export class LayoutModule {}
