import { Component, Input } from "@angular/core";

@Component({
    selector: "app-payment-methods-item",
    templateUrl: "./payment-methods-item.component.html",
})
export class PaymentMethodsItemComponent {
    @Input() src!: string;
    color = "grey";

    @Input() title!: string;
    @Input() recommended!: boolean;
    @Input() bonus!: string;
}
