<app-simple-fade-in-out-modal [showModal]="isVisible">
    <app-hearts-bg type="set">
        <app-modal
            (closeMatch)="closeWindow()"
            [imageProps]="'h-20'"
            image="../../../assets/images/logo/1203x405.png"
            [closeColor]="'white'"
            [desktopHeight]="'h-full'"
            [desktopWidth]="'w-full'"
            [backgroundColor]="'transparent'"
            [subtitleProps]="'!text-base'"
            [topPadding]="'6'"
        >
            <div subtitle>
                <p>
                    amolino.de ist eine Marke der MultiLabel GmbH mit Sitz in
                    Hamburg: Hohe Bleichen 12, 20354 Hamburg
                </p>
            </div>
            <div content>
                <div
                    class="grid space-y-4 py-12 border-t-2 border-b-2 border-white"
                >
                    <app-as-footer-item
                        [isTargetBlank]="true"
                        [arrowColor]="'white'"
                        [title]="'Impressum'"
                        [linkPath]="'/imprint'"
                    ></app-as-footer-item>
                    <app-as-footer-item
                        [isTargetBlank]="true"
                        [arrowColor]="'white'"
                        [title]="'Allgmeine Geschäftsbedingungen'"
                        [linkPath]="'/terms'"
                    >
                    </app-as-footer-item>
                    <app-as-footer-item
                        [isTargetBlank]="true"
                        [arrowColor]="'white'"
                        [title]="'Datenschutz Richtlinie'"
                        [linkPath]="'/data-protection'"
                    ></app-as-footer-item>
                    <app-as-footer-item
                        [isTargetBlank]="true"
                        [arrowColor]="'white'"
                        [title]="'Widerrufsbelehrungen'"
                        [linkPath]="'/right-of-withdrawal'"
                    >
                    </app-as-footer-item>
                    <app-as-footer-item
                        [isTargetBlank]="true"
                        [arrowColor]="'white'"
                        [title]="'Hilfe / FAQ'"
                        [linkPath]="'/help'"
                    ></app-as-footer-item>
                    <footer>
                        <a
                            href="javascript: Cookiebot.show();"
                            class="w-full flex justify-between items-end"
                        >
                            Cookie-Einstellungen
                            <a class="text-brand-cyan font-semibold w-[9px]"
                                ><img
                                    src="../../../../assets/images/white_arrow.png"
                                    alt="blue_arrow"
                            /></a>
                        </a>
                    </footer>
                </div>
                <div class="grid my-8 py-8 justify-items-center w-full">
                    <div class="text-white text-base font-semibold">
                        Du brauchst Hilfe?
                    </div>
                    <a class="modal-link !text-white" routerLink="../../help"
                        >Support kontaktieren</a
                    >
                </div>
            </div>
        </app-modal>
    </app-hearts-bg>
</app-simple-fade-in-out-modal>
