<app-main-layout [showProfile]="true" [leftMenuText]="'Dein Profil'">
    <div class="px-4 pt-11 sm:pt-0 text-brand-body text-[13px]">
        <app-edit-profile-section title="Deine Interessen">
            <div class="flex justify-between space-x-5">
                <div class="font-medium">{{ this.getTags() }}</div>
                <a
                    routerLink="/edit-my-interests"
                    class="text-brand-cyan font-bold cursor-pointer"
                    >Ändern</a
                >
            </div>
        </app-edit-profile-section>
        <app-edit-profile-section title="Über dich">
            <div class="flex justify-between space-x-5">
                <div class="font-medium">{{ me?.profileText ?? "-" }}</div>
                <a
                    routerLink="/about-you"
                    class="text-brand-cyan font-bold cursor-pointer"
                    >Ändern</a
                >
            </div>
        </app-edit-profile-section>
        <app-edit-profile-section title="Deine Angaben">
            <div class="grid gap-y-2">
                <div class="flex justify-between">
                    <p class="font-medium">Name</p>
                    <p class="text-brand-title font-bold">
                        {{ me?.username ?? "-" }}
                    </p>
                </div>
                <div class="flex justify-between">
                    <p class="font-medium">Wohnort</p>
                    <p class="text-brand-title font-bold">
                        {{ me?.location.city ?? "-" }}
                    </p>
                </div>
                <div class="flex justify-between">
                    <p class="font-medium">Geschlecht</p>
                    <p class="text-brand-title font-bold">
                        {{ this.getGender() }}
                    </p>
                </div>
                <div class="flex justify-between">
                    <p class="font-medium">Suche</p>
                    <p class="text-brand-title font-bold">
                        {{ this.getLookingFor() }}
                    </p>
                </div>
                <div class="flex justify-between">
                    <p class="font-medium">Geburtsdatum</p>
                    <p class="text-brand-title font-bold">
                        {{ this.getDateOfBirth() }} ({{ this.getAge() }})
                    </p>
                </div>
            </div>
        </app-edit-profile-section>
        <div class="grid justify-items-center gap-y-9 mt-8">
            <p class="text-center font-medium">
                Wenn du Änderungen an Deinen Stammdaten vornehmen möchtest,
                stehen wir Dir gerne zur Verfügung. Kontaktiere einfach unseren
                Support und wir helfen Dir gerne weiter.
            </p>
            <div class="flex space-x-2 items-center mb-10">
                <img
                    class="h-4 w-4"
                    src="../../../../assets/images/headphones_blue.png"
                    alt="headphones_blue"
                />
                <!-- TODO: give the right path -->
                <a
                    routerLink="/help"
                    class="text-sm font-semibold text-gray-400 underline hover:no-underline"
                    >Support Kontaktieren</a
                >
            </div>
        </div>
    </div>
</app-main-layout>
