import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "app-hearts-bg",
    templateUrl: "./hearts-bg.component.html",
})
export class HeartsBgComponent implements OnInit {
    @Input() type?: string = "";
    setBackground(type) {
        2;
        switch (type) {
            case "instantMatchPassive":
                return "url(assets/images/hearts_matching_screens.png), linear-gradient(205deg, #B34380 30%, #45A6C4)";
            case "instantMatchActive":
                return "url(assets/images/hearts_matching_screens.png), linear-gradient(205deg, #B34380 30%, #D7A9C5)";
            case "shop":
                return "url(assets/images/hearts_matching_screens.png), linear-gradient(205deg, #112F38 30%, #45A6C4)";
            case "registerSuccessful":
                return "url(assets/images/hearts_register_and_register_finish.png), linear-gradient(205deg, #45A6C4, #B34380)";
            case "set":
                return "url(assets/images/hearts_register_and_register_finish.png), linear-gradient(205deg, #45A6C4, #1784A5)";
            case "cyan":
                return "url(assets/images/hearts_matching_screens.png), linear-gradient(205deg, #45A6C4, #1784A5)";
        }
        return "url(assets/images/hearts_matching_screens.png), linear-gradient(205deg, ##1784A5 30%, #45A6C4)";
    }

    ngOnInit(): void {
        this.setBackground(this.type);
    }
}
