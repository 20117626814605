<div class="z-50 w-full">
    <div
        class="w-full flex h-50 bg-white items-center justify-center space-x-2 md:space-x-4 p-2"
    >
        <div>
            <div class="h-10 overflow-hidden relative rounded-full w-10">
                <img
                    class="h-16 object-cover rounded-full w-16"
                    src="{{ notification.partner_image | addImageBaseUrl }}"
                />
            </div>
        </div>
        <div class="flex flex-col w-1/2 -space-y-1">
            <div
                *ngIf="notification.type === 'NewMessageTemplate'"
                class="truncate subheading-brand-title"
            >
                {{ notification.partner_name }}, {{ notification.partner_age }}
            </div>
            <div
                *ngIf="notification.type === 'NewMessageTemplate'"
                class="text-brand-body truncate flow-text !text-sm !md:text-base"
            >
                {{ notification.message.body }}
            </div>
            <div
                *ngIf="notification.type === 'NewLikeTemplate'"
                class="text-brand-title truncate flow-text !text-sm !md:text-base"
            >
                Jemand neues mag dich!
            </div>
            <div
                *ngIf="
                    notification.type === 'NewMatchTemplate' ||
                    notification.type === 'NewInstantmatchTemplate'
                "
                class="text-brand-title truncate flow-text !text-sm !md:text-base"
            >
                Wow! Ein neues Match
            </div>
        </div>
        <a
            *ngIf="notification.type === 'NewMessageTemplate'"
            class="link cursor-pointer font-semibold !text-sm !md:text-base"
            href="/chat/{{ notification.match }}"
            >Jetzt lesen</a
        >
        <a
            *ngIf="notification.type === 'NewLikeTemplate'"
            class="link cursor-pointer font-semibold !text-sm !md:text-base"
            href="/user/like-you"
            >Jetzt Ansehen</a
        >
        <a
            *ngIf="
                notification.type === 'NewMatchTemplate' ||
                notification.type === 'NewInstantmatchTemplate'
            "
            class="link cursor-pointer font-semibold !text-sm !md:text-base"
            href="/chat/{{ notification.match }}"
            >Zum Chat</a
        >
        <img
            class="w-2 h-2 md:h-3 md:w-3 cursor-pointer"
            src="../../../../assets/images/close_gray.png"
            alt="close"
            (click)="onClose()"
        />
    </div>
</div>
