import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class CacheResolverService {
    private cache = new Map<string, [Date | null, HttpResponse<any>]>();

    set(key: string, value: any, timeToLive: number | null = null) {
        if (timeToLive) {
            const expiresIn = new Date();
            expiresIn.setSeconds(expiresIn.getSeconds() + timeToLive);
            this.cache.set(key, [expiresIn, value]);
        } else {
            this.cache.set(key, [null, value]);
        }
    }

    get(key: string) {
        const tupel = this.cache.get(key);
        if (!tupel) {
            return null;
        }
        //Extract tuple
        const expiresIn = tupel[0];
        const httpCachedResponse = tupel[1];
        const now = new Date();
        if (expiresIn && expiresIn.getTime() < now.getTime()) {
            this.cache.delete(key);
            return null;
        }
        return httpCachedResponse;
    }

    delete(key: string) {
        this.cache.delete(key);
    }

    clearCache(): void {
        this.cache.clear();
    }
}
