import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";

@Component({
    selector: "app-image-type-select",
    templateUrl: "./image-type-select.component.html",
})
export class ImageTypeSelectComponent implements OnInit, OnChanges {
    @Input() type!: string;
    @Input() src!: string;
    @Input() activeColor!: string;
    @Input() active = true;

    ngOnInit(): void {
        this.src = this.type;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.active = changes["active"].currentValue;
    }
}
