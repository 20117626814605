<div
    class="group bg-white px-4 py-3 text-brand-body border-b-2 border-opacity-10 border-brand-body flex items-center cursor-pointer"
    (mouseover)="color = 'green'"
    (mouseleave)="color = 'grey'"
>
    <img
        class="w-16"
        src="../../../../assets/images/{{ src }}.png"
        alt="{{ src }}"
    />
    <div class="ml-4 mr-auto">
        <p class="font-semibold text-sm">
            {{ title }}
            <span
                *ngIf="recommended"
                class="bg-brand-green text-[11px] ml-1 text-white px-2 font-bold rounded-full"
                >Empfohlen
            </span>
        </p>
        <p class="text-xs leading-4 font-normal">
            <span *ngIf="bonus" class="underline">+ {{ bonus }} mehr </span>
            {{ bonus ? "Credits / Schlüssel" : "Kein Extra Bonus" }}
        </p>
    </div>
    <img
        class="h-4 transition-transform duration-300"
        src="../../../../assets/images/arrow-shop-{{ color }}.png"
        height="50px"
    />
</div>
