import { animate, state, style, transition, trigger } from "@angular/animations";
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Fees } from "src/app/interfaces/Fees";
import { MeData } from "src/app/interfaces/MeData";
import { MemberStackProfileData } from "src/app/interfaces/ProfileData";
import { MatchPreview } from "src/app/interfaces/matches";
import { ApiService } from "src/app/services/api.service";
import { FeesService } from "src/app/services/fees.service";
import { MemberStackService } from "src/app/services/member-stack.service";
import { UpdateMemberStackService } from "src/app/services/update-member-stack.service";
import { AppState } from "src/app/store/app.state";
import { getMeData } from "src/app/store/me-data-store";
import { environment } from "src/environments/environment";

export interface CardData {
    imageId: string;
    state: "default" | "flipped" | "matched";
}

@Component({
    selector: "app-start-page",
    templateUrl: "./start-page.component.html",
    animations: [
        trigger("fadeInOut2", [
            state("in", style({ opacity: 1 })),
            transition(":enter", [style({ opacity: 0 }), animate(600)]),
            transition(":leave", animate(600, style({ opacity: 0 }))),
        ]),
        trigger("swipeAnimation", [
            transition("* => *", [
                style({ transform: "translateX(0)", opacity: 1 }),
                animate("1s ease-in-out", style({ transform: "translateX(-100%)", opacity: 0 })),
            ]),
        ]),
        trigger("fadeIn", [
            state(
                "void",
                style({
                    transform: "translateY(100%)",
                    opacity: 0,
                })
            ),
            state(
                "show",
                style({
                    opacity: 1,
                    transform: "translateY(0)",
                })
            ),
            transition("* <=> *", animate(500)),
        ]),
        trigger("popOverState", [
            state(
                "hide",
                style({
                    transform: "translateY(-100%)",
                })
            ),
            state(
                "show",
                style({
                    transform: "translateY(0)",
                })
            ),
            transition("void => show", animate("600ms ease-out")),
            transition("show => hide", animate("600ms ease-out")),
            transition("hide => show", animate("1000ms ease-in")),
        ]),
        trigger("simpleFadeAnimation", [
            state("in", style({ opacity: 1 })),
            transition(":enter", [style({ opacity: 0 }), animate(300)]),
            transition(":leave", animate(300, style({ opacity: 0 }))),
        ]),
    ],
})
export class StartMainComponent implements OnInit, AfterViewInit, OnDestroy {
    public showProfile = true;
    get profileStateName() {
        return this.showProfile ? "show" : "hide";
    }

    //fees
    public fees?: Fees;
    public me: MeData;

    //DISPLAY NOTIFICATION

    /**
     * True: display sale tag on top
     */
    showSaleTag = false;
    showInteraction = false;
    showNotifications = true;
    public isClickInProgress = false;
    public matches?: MatchPreview[];

    /**
     * True: display shop notification on top
     */
    displayShopNotification = false;

    /**
     * True: show insufficient  credits while unlock profile view
     */
    insufficientCreditsForProfileUnlock = false;

    /**
     * True: open instantmatch form
     */
    showInstantMatchFormModal = false;

    /**
     * True: open profile unlock modal
     */
    showUnlockProfileModal = false;

    /**
     * True: show discover limit reached page
     */
    discoverLimitReached = false;

    /**
     * True: show match modal by like action
     */
    showNormalMatch = false;

    matchId?: string;
    /**
     * True: 402 payment required response
     */
    insufficientCreditsError = false;
    showInsufficientCreditsError = false;

    /**
     *  Photos names for hover properties
     */
    instantmatch = "sofortmatch_default";
    unlockProfile = "unlock_default";

    interactionEmoji: "😍" | "🥱" = "😍";

    public members: MemberStackProfileData[];
    public memberStack: MemberStackProfileData[];
    /**
     * Member that's currently displayed
     */
    public member?: MemberStackProfileData;
    public nextMember?: MemberStackProfileData;
    public imageBaseUrl: string;
    public matchInfo = {
        myImage: "",
        partnerImage: "",
        partnerId: "",
        matchId: "",
    };

    public passiveMatchInfo = {
        myImage: "",
        partnerImage: "",
        partnerId: "",
        matchId: "",
    };

    /**
     * If set, then execute the specificed postInteraction after HTML was rendered.
     */
    public doPostInteract?: {
        memberId: string;
        interaction: "LIKE" | "DISLIKE";
    };
    public doDisplayNextMember = false;
    /**
     * True: show empty stack page
     */
    emptyStack = false;
    private isLastMemberStack = false;

    // True is previous logged in user id and current logged in user id is equal
    private isSameUser = false;

    public swipeRightAnimation = false;
    public swipeLeftAnimation = false;

    private touchStartX?: number;
    private onTouchScroll?: (event) => void;

    @ViewChild("mainContent") private mainElement: ElementRef;
    @ViewChild("currentImageElement") private currentImageElement: ElementRef;

    constructor(
        private api: ApiService,
        private router: Router,
        private memberStackService: MemberStackService,
        private updateMemberStack: UpdateMemberStackService,
        private feesService: FeesService,
        private store: Store<AppState>,
        private cdr: ChangeDetectorRef
    ) {
        this.updateMemberStack = new UpdateMemberStackService();
        this.updateMemberStack.onInteractWithMember().subscribe(() => {
            this.updateMember();
        });

        if (this.router.getCurrentNavigation()?.extras.state) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const state = this.router.getCurrentNavigation()?.extras.state as any;

            if (state) {
                if (Object.prototype.hasOwnProperty.call(state, "doPostInteract")) {
                    this.doPostInteract = state.doPostInteract;
                }
                if (Object.prototype.hasOwnProperty.call(state, "doDisplayNextMember")) {
                    this.doDisplayNextMember = state.doDisplayNextMember;
                }
            }
        }
    }

    ngOnInit(): void {
        this.store.select(getMeData).subscribe((me) => {
            if (!me) return;

            // Check if previous logged in user id and current logged in user id is equal
            //we need it for collecting memberstack from local storage if user is same
            const previousMeId = localStorage.getItem("previous_me_id");
            if (previousMeId) {
                if (previousMeId === me._id) {
                    this.isSameUser = true;

                    localStorage.setItem("previous_me_id", me._id);
                }
            }

            this.me = me;
            this.matches = this.me ? this.me.matches : undefined;
            this.matchId =
                this.matches && this.matches.length ? this.matches[0].matchId : undefined;
        });

        this.feesService.getFees().subscribe((response) => {
            this.fees = response;
        });

        this.getMemberStack(() => {
            this.getMember();

            this.cdr.markForCheck();
            this.cdr.detectChanges();
        });

        this.imageBaseUrl = environment.url.image;
    }

    ngAfterViewInit(): void {
        if (this.doPostInteract) {
            this.postInteract(this.doPostInteract.memberId, this.doPostInteract.interaction);
            this.doPostInteract = undefined;
        }
        if (this.doDisplayNextMember) {
            this.handleMemberInteraction("LIKE");
            this.doDisplayNextMember = false;
        }

        // prevent scrolling on stack
        this.onTouchScroll = (e) => {
            if (this.mainElement.nativeElement) {
                const contains = this.mainElement.nativeElement.contains(e.target);
                if (contains) e.preventDefault();
            }
        };
        window.addEventListener("touchmove", this.onTouchScroll, {
            passive: false,
        });
    }

    ngOnDestroy(): void {
        if (this.onTouchScroll) window.removeEventListener("touchmove", this.onTouchScroll);
    }

    touchStartListener(e: TouchEvent): void {
        const touch = e.touches[0];
        this.touchStartX = touch.clientX;
    }
    touchMoveListener(e: TouchEvent): void {
        if (!this.touchStartX) return;

        const touch = e.touches[0];
        const moveX = touch.clientX - this.touchStartX;

        this.currentImageElement.nativeElement.style.transform = `translateX(${moveX}px)`;
    }
    touchEndListener(e: TouchEvent): void {
        if (!this.touchStartX || !this.member) return;

        const touch = e.changedTouches[0];
        const diffX = touch.clientX - this.touchStartX;
        const threshold = 15;

        if (diffX > threshold) {
            this.postInteract(this.member._id, "LIKE");
        } else if (diffX < -threshold) {
            this.postInteract(this.member._id, "DISLIKE");
        } else {
            this.currentImageElement.nativeElement.style.transform = `translateX(0px)`;
        }
    }

    clickUnlock() {
        this.showNotifications = false;

        if (!this.member) return;

        const lockedLocalStorageItem = localStorage.getItem(this.member._id + "_locked");
        if (lockedLocalStorageItem && JSON.parse(lockedLocalStorageItem) == false) {
            this.router.navigate(["/profile", "discover", this.member._id]);
        } else {
            this.showUnlockProfileModal = true;
        }
    }

    /**
     * Get Member Stack from either localStorage or from api call
     * and save the value in members variable
     */
    public get(): MemberStackProfileData[] | undefined {
        const storedData = localStorage.getItem("memberStack");

        if (storedData) {
            try {
                const parsedData = JSON.parse(storedData);
                return parsedData.data || undefined;
            } catch (error) {
                //JSON parsing failed
                return undefined;
            }
        }
        return undefined;
    }

    setPreviousMeId(id: string) {
        localStorage.setItem("previous_me_id", id);
    }

    set(data: MemberStackProfileData[]): void {
        const dataWithTimestamp = {
            data: data,
            timestamp: new Date().toISOString(),
        };
        localStorage.setItem("memberStack", JSON.stringify(dataWithTimestamp));
    }

    private getMemberStack(then: () => void) {
        // this.memberStack.set(JSON.parse(localStorage.getItem('memberStack')));
        /**
         * Remove isLastMemberstack attribute from localstorage.
         * New stack can also not be the last memberstack thats why
         * we first remove it from local storage and add it below after api call
         * if it is last memberstack
         */
        localStorage.removeItem("isLastMemberStack");

        const memberStack = this.get();

        /**
         * Check if user is Same,
         * Check if memberstack from local storage has members and
         * Check the memberstack is not inactive
         * if yes then we will take its value and meData value from localstorage
         * otherwise we call members api to fetch new stack
         */
        if (
            this.isSameUser &&
            memberStack &&
            memberStack.length > 1 &&
            !this.memberStackService.isInactive()
        ) {
            this.members = this.get() || [];
            this.getMember();
        } else {
            this.appendNewMemberStack(then);
        }
    }

    /**
     * Get first member of memberstack
     */
    public getMember() {
        if (this.isLastMemberStack) {
            if (this.members.length === 1) {
                this.showSaleTag = true;
            }
            if (this.members.length === 0) {
                this.showProfile = false;
                this.emptyStack = true;
            } else {
                this.member = this.members[0];
                this.nextMember = this.members[1];
            }
        } else {
            this.member = this.members[0];
            this.nextMember = this.members[1];
        }
    }

    /**
     * Remove the first member from members and get next member
     * if members has no memberleft then collect new stack
     */
    private updateMember() {
        this.members.shift();
        const dataWithTimestamp = {
            data: this.members,
            timestamp: new Date().toISOString(),
        };

        localStorage.setItem("memberStack", JSON.stringify(dataWithTimestamp));
        if (this.members.length <= 3) {
            this.appendNewMemberStack();
        }

        this.getMember();
    }

    private appendNewMemberStack(then?: () => void): void {
        this.api
            .members()
            .get()
            .subscribe((response) => {
                if (!response) return;

                this.setPreviousMeId(response.me._id);

                //
                // Create an array with the appended members
                const newMembers: MemberStackProfileData[] = [];
                (this.members || []).forEach((member) => newMembers.push(member));
                response.memberStack.forEach((member) => newMembers.push(member));

                //
                // Set the new array as the base array
                this.set(newMembers);
                this.members = newMembers;
                this.memberStack = this.members;

                if (response.memberStack.length < 10) {
                    this.isLastMemberStack = true;
                    localStorage.setItem("isLastMemberStack", "TRUE");
                    localStorage.setItem("memberStack", JSON.stringify(this.memberStack));
                }

                this.preloadMemberImages(this.members);

                if (then) then();
            });
    }

    /**
     * Pre-caches the images of the members before showing them to the user, to reduce loading times.
     */
    preloadMemberImages(members: MemberStackProfileData[]) {
        members.forEach((member) => {
            const url = this.imageBaseUrl + "/" + member.image;
            const img = new Image();
            img.src = url;
        });
    }

    /**
     * Interact with the other member
     * @param memberId The member id
     * @param interaction Interaction type
     */
    postInteract(memberId: string, interaction: "LIKE" | "DISLIKE") {
        this.handleMemberInteraction(interaction);

        this.api
            .members()
            .interact(memberId, interaction)
            .subscribe({
                next: (response) => {
                    if (!this.member) return;

                    if (interaction === "LIKE" && response.matchId) {
                        this.showNormalMatch = true;
                        this.matchInfo.myImage = response.me.image;
                        this.matchInfo.partnerImage = this.member.image[0]?.image;
                        this.matchInfo.partnerId = this.member._id;
                        this.matchInfo.matchId = response.matchId;
                    }
                },
                error: (response) => {
                    if (response.status === 402) {
                        this.discoverLimitReached = true;
                    }
                },
            });
    }

    /**
     * Triggers swipe animation.
     */
    public handleMemberInteraction(interaction: "LIKE" | "DISLIKE"): void {
        const eventListener = () => {
            this.updateMember();

            this.showInteraction = false;
            this.currentImageElement.nativeElement.style.transform = `translateX(0px)`;
            this.swipeRightAnimation = false;
            this.swipeLeftAnimation = false;
            window.removeEventListener("animationend", eventListener);
        };
        window.addEventListener("animationend", eventListener);
        this.showInteraction = true;
        this.interactionEmoji = interaction === "LIKE" ? "😍" : "🥱";

        if (interaction === "LIKE") {
            this.swipeRightAnimation = true;
        } else if (interaction === "DISLIKE") {
            this.swipeLeftAnimation = true;
        }
    }

    /**
     *
     * @param memberId Member id
     * @param interaction Ineraction type is BUY_ACCESS
     */
    public profileUnlock(memberId: string, interaction: string) {
        this.isClickInProgress = true;

        this.api
            .members()
            .interact(memberId, interaction)
            .subscribe({
                next: () => {
                    this.router.navigate(["/profile", "discover", memberId]);
                    localStorage.setItem(memberId + "_locked", JSON.stringify(false));
                },
                error: (error) => {
                    this.insufficientCreditsForProfileUnlock = true;
                    this.showInsufficientCreditsError = true;
                    this.showUnlockProfileModal = false;
                    console.warn("Failed to unlock profile:", error);
                },
            });

        this.isClickInProgress = false;
    }

    /**
     * Hide and show the instant match passive view
     */
    /**
     * Hide and show the instant match  view
     */
    toggleInstantMatchFormModal() {
        this.insufficientCreditsError = false;
        this.showInstantMatchFormModal = !this.showInstantMatchFormModal;
    }

    /**
     * Hide and show the unlockprofilemodal view
     */
    toggleUnlockProfileModal() {
        this.showUnlockProfileModal = !this.showUnlockProfileModal;
    }
}
