import { createAction, createReducer, createSelector, on, props } from "@ngrx/store";
import { OwnProfileData } from "../interfaces/ProfileData";
import { AppState } from "./app.state";

export const updateMembersMe = createAction(
    "[Members Me] updateMembersMe",
    props<{ path: string; value: any }>()
);
export const setMembersMe = createAction("[Members Me] setMembersMe", (data) => ({
    data,
}));
export const deleteMembersMe = createAction("[Members Me] deleteMembersMe");

export const initialMembersMe = null;

export const membersMeReducer = createReducer(
    initialMembersMe,
    on(setMembersMe, (state, { data }) => {
        // only update the store if the data has changed
        if (JSON.stringify(state) !== JSON.stringify(data)) {
            return data;
        }
        return state;
    }),
    on(deleteMembersMe, () => {
        return initialMembersMe;
    }),
    /**
    This implementation will take the path string and split it into an array of keys, representing the nested object structure. The last key is removed from the array and stored in lastKey. The keys.reduce function then iteratively retrieves the nested object using the keys in the array until it reaches the second-to-last key, at which point it updates the value of the last key to the value parameter passed in the action. Finally, it returns a new copy of the state object to trigger the change detection mechanism of the store.
     */
    on(updateMembersMe, (state, { path, value }) => {
        const keys = path.split(".");
        const lastKey = keys.pop();
        /* @ts-ignore */ // TODO: properly type this, so that we don't need to ignore this
        const newObj = { ...state };
        let nestedObj = newObj;
        for (const key of keys) {
            nestedObj[key] = { ...nestedObj[key] };
            nestedObj = nestedObj[key];
        }
        nestedObj[lastKey] = value;
        return newObj;
    })
);

/*
The first arrow function takes the entire state object as an argument and
selects the meData property from it. The selected value is then passed as an
argument to the second arrow function, which simply returns the value as is.
This second arrow function is called the "projection function" and its job is
to transform the input value into the desired output value.*/
export const getMembersMe = createSelector(
    (state: AppState) => state.membersMe,
    (membersMe: OwnProfileData) => membersMe
);
