<div class="flex flex-col space-y-8 items-center lg:items-start">
    <span
        class="bg-brand-title rounded-full px-12 py-1 text-white font-semibold shadow-md"
    >
        Faire Chance
    </span>
    <div
        class="flex flex-col space-y-4 lg:space-y-0 lg:flex-row justify-center items-center lg:space-x-6 text-3xl"
    >
        <img
            src="../../../assets/images/startseite_female_badge.png"
            class="h-20 w-20"
        />
        <div>
            <h1 class="font-medium">Frauenanteil</h1>
            <h1 class="text-brand-cyan text-center font-semibold lg:text-left">
                42%
            </h1>
        </div>
        <img
            src="../../../assets/images/startseite_male_badge.png"
            class="h-20 w-20"
        />
        <div>
            <h1 class="font-medium">Männeranteil</h1>
            <h1
                class="text-brand-magenta text-center font-semibold lg:text-left"
            >
                58%
            </h1>
        </div>
    </div>
</div>
