<app-form-generic-input
    [class]="class"
    [info]="info"
    [checked]="value || oldValue ? true : false"
    [error]="errorMsg"
>
    <label [for]="id" class="label leading-5 mb-4">{{ label }}</label>
    <div class="flex flex-row space-x-4 mb-3 pt-4 text-sm font-semibold">
        <input
            [id]="id"
            [value]="value ? value : oldValue"
            (input)="this.valueUpdated($event)"
            type="text"
            [placeholder]="placeholder"
            class="focus:outline-none bg-transparent input placeholder:input-placeholder w-full"
            [maxLength]="maxlength || 1000"
        />
        <div *ngIf="maxlength">
            <span
                >{{ value ? value.length : oldValue.length }}/{{
                    maxlength
                }}</span
            >
        </div>
    </div>
</app-form-generic-input>
