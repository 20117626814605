import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "./api.service";
import { OneSignalService } from "./onesignal.service";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    constructor(
        private router: Router,
        private oneSignalService: OneSignalService,
        private api: ApiService
    ) {}

    // TODO: use this method instead of LocalStorage and this.api.isAuthenticated(); in all other places
    get isAuthenticated(): boolean {
        // TODO: refactor this method to not use LocalStorage anymore
        return this.api.isAuthenticated();
    }

    navigateToDiscoverPageAfterLoginRegister() {
        this.oneSignalService.setExternalUserIdToUserId();
        this.router.navigate(["/user/discover"]).then(() => window.location.reload);
    }
}
