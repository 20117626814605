<div class="relative">
    <div class="fixed inset-0 bg-fixed bg-center h-screen">
        <img
            [src]="
                usingMobileDevice
                    ? '../../../assets/images/landing-page-background-mobile.png'
                    : '../../../assets/images/start_background.jpg'
            "
            class="h-full w-full object-cover"
        />
    </div>
    <div class="h-screen">
        <div
            class="flex fixed top-2 inset-x-0 items-center justify-between p-4 md:px-24 z-10 space-x-4"
        >
            <img
                class="mt-2 shrink-0"
                src="../../../assets/images/logo/340x115.png"
                alt="amolino logo"
                height="20px"
                width="150px"
            />
            <div class="grow flex text-white">
                <ul
                    class="text-lg font-bold drop-shadow-sm hidden lg:flex lg:flex-row ml-4 space-x-4"
                >
                    <li>
                        <a
                            class="cursor-pointer"
                            (click)="scrollToSection('matching')"
                            >Matching</a
                        >
                    </li>
                    <li>
                        <a
                            class="cursor-pointer"
                            (click)="scrollToSection('download-app')"
                            >App laden</a
                        >
                    </li>
                    <li>
                        <a
                            class="cursor-pointer"
                            (click)="scrollToSection('find-partner')"
                            >Partner Finden</a
                        >
                    </li>
                </ul>
                <ul class="flex flex-row items-center space-x-4 ml-auto">
                    <li
                        class="hidden md:flex items-center space-x-2 drop-shadow-sm"
                    >
                        <img
                            class="rounded-full"
                            src="../../../../assets/images/login_user_icon.png"
                            width="15px"
                        />
                        <div class="text-sm">Du hast ein Konto?</div>
                    </li>
                    <div
                        *ngIf="!usingMobileDevice"
                        (click)="clickLogin()"
                        class="font-bold px-4 py-1.5 bg-white rounded-full text-brand-cyan ring-4 ring-white ring-opacity-30 hover:ring-opacity-0 cursor-pointer"
                    >
                        Einloggen
                    </div>
                </ul>
            </div>
        </div>
        <div
            [ngClass]="{
                'items-end top-[calc(100vh-302px-60px)]': usingMobileDevice,
                'items-center h-screen': !usingMobileDevice
            }"
            class="fixed grid justify-center w-full"
        >
            <div class="flex flex-col items-center max-w-xs md:max-w-xl">
                <h1
                    [ngClass]="{
                        'text-3xl mb-10': usingMobileDevice,
                        'text-5xl mb-16': !usingMobileDevice
                    }"
                    class="md:text-6xl font-bold drop-shadow-lg text-center text-white"
                >
                    Hier findest du, was dein Herz sucht.
                </h1>
                <div class="w-[300px]">
                    <app-link-button
                        (click)="clickRegister()"
                        [theme]="'hero'"
                        [loggedIn]="false"
                        class=""
                    >
                        Konto erstellen
                    </app-link-button>
                </div>
                <div
                    [ngClass]="{ 'mt-1.5': usingMobileDevice }"
                    class="w-[300px]"
                    *ngIf="usingMobileDevice"
                >
                    <app-link-button
                        (click)="clickLogin()"
                        [theme]="'login'"
                        [loggedIn]="false"
                        class=""
                    >
                        Einloggen
                    </app-link-button>
                </div>
            </div>
        </div>
    </div>
</div>
