import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { map, Observable, of, switchMap, tap } from "rxjs";
import { OwnProfileData } from "../interfaces/ProfileData";
import { AppState } from "../store/app.state";
import {
    deleteMembersMe,
    getMembersMe,
    setMembersMe,
    updateMembersMe,
} from "../store/members-me.store";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: "root",
})
export class MembersMeStoreService {
    constructor(private api: ApiService, private store: Store<AppState>) {}

    removeMembersMeFromStore() {
        this.store.dispatch(deleteMembersMe());
    }

    updateMembersMeInStore(path: string, value: string | boolean | number | object) {
        this.store.dispatch(updateMembersMe({ path, value }));
    }

    // TODO: refactor other methods to also use this
    getMembersMeFromStore(): Observable<OwnProfileData> {
        return this.store.select(getMembersMe).pipe(
            switchMap((data) => {
                if (data) {
                    // If there is data in the store, return it as an observable
                    return of(data);
                } else {
                    // If there is no data in the store, fetch it from the API
                    return this.api
                        .me()
                        .get()
                        .pipe(
                            tap((response) => {
                                // Update the store with the new data
                                this.store.dispatch(setMembersMe(response.member));
                            }),
                            // Return the fetched data as an observable
                            map((response) => response.member)
                        );
                }
            })
        );
    }

    getMembersMe(): Observable<OwnProfileData> {
        return this.api
            .me()
            .get()
            .pipe(
                tap((response) => {
                    // Update the store with the new data
                    this.store.dispatch(setMembersMe(response.member));
                }),
                // Return the fetched data as an observable
                map((response) => response.member)
            );
    }
}
