import { animate, keyframes, state, style, transition, trigger } from "@angular/animations";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { ApiService } from "src/app/services/api.service";
import { DateStringMethodsService } from "src/app/services/date-string-methods.service";
import { FeesService } from "src/app/services/fees.service";
import { ImageService } from "src/app/services/image.service";
import { getMeData } from "src/app/store/me-data-store";

@Component({
    selector: "app-visit-profile",
    templateUrl: "./visit-profile.component.html",
    animations: [
        trigger("bounce", [
            state(
                "show",
                style({
                    transform: "translateY(0)",
                })
            ),
            state(
                "hide",
                style({
                    transform: "translateY(100%)",
                })
            ),
            transition("void => *", [
                // transition('hide => show', [
                animate(
                    "0.8s ease-in",
                    keyframes([
                        style({ transform: "translateY(400px)" }),
                        style({ transform: "translateY(0)" }),
                        style({ transform: "translateY(150px)" }),
                        style({ transform: "translateY(0)" }),
                        style({ transform: "translateY(50px)" }),
                        style({ transform: "translateY(0)" }),
                    ])
                ),
            ]),
            transition("* => void", [
                animate(
                    "0.8s ease-out",
                    keyframes([
                        style({ transform: "translateY(100%)" }),
                        style({ transform: "translateY(150px)" }),
                        style({ transform: "translateY(100%)" }),
                        style({ transform: "translateY(400px)" }),
                        style({ transform: "translateY(100%)" }),
                    ])
                ),
            ]),
            // transition('hide => show', animate('1000ms ease-in'))
        ]),
        trigger("simpleFadeAnimation", [
            state("in", style({ opacity: 1 })),
            transition(":enter", [style({ opacity: 0 }), animate(300)]),
            transition(":leave", animate(300, style({ opacity: 0 }))),
        ]),
        trigger("fadeIn", [
            state(
                "void",
                style({
                    transform: "translateY(100%)",
                })
            ),
            state(
                "show",
                style({
                    transform: "translateY(0)",
                })
            ),
            transition("* <=> *", [style({ position: "absolute" }), animate(500)]),
        ]),
    ],
})
export class VisitProfileComponent implements OnInit {
    //Need input values to initialize the component
    @Input() memberId: any = null;
    @Input() visitedFrom: any = null;

    @Output() refreshProfile: EventEmitter<void> = new EventEmitter();

    showProfile = true;
    get profileStateName() {
        return this.showProfile ? "show" : "hide";
    }

    showInsufficientCreditsError = false;

    /**
     * Variables for showing match and like button
     */
    public showInstantMatchButton = false;
    public showLikeButton: boolean;

    //to open and close image slider lightbox
    public openImageSlider = false;
    //start image index for image slider component
    public startImageIndexForSlider = 0;

    /**
     * variables responsible to open and close modals that present in this component
     */
    public interaktionLimitReached = false;
    public showSolveMatch = false;
    public private = false;
    public exclusive = false;
    public intimate = false;
    public special = false;

    public photoToUnlock: any;

    /**
     * Get member id  from url and request for other details from backend
     * From where this component is visited
     */
    public isClickInProgress = false; // initialize with false

    public opacity: string;
    public fromChatMatchId: string;
    @Input() member: any = null;
    public myMatches: any[] = [];
    public matchId: string;
    public rightMenudisabled = false;

    public commonTagsText = "";
    public commonTags: string[] = [];

    //fees
    public fees: any;

    /**
     * For controlling css property if match form is opened
     */
    public hideFullPage = false;

    /**
     * Declaration for match
     */
    public matchInfo: any;

    constructor(
        private api: ApiService,
        private router: Router,
        public dateToStringMethods: DateStringMethodsService,
        private imageService: ImageService,
        private feesService: FeesService,
        private store: Store<any>
    ) {}

    ngOnInit(): void {
        this.feesService.getFees().subscribe((response) => (this.fees = response));
        this.setInitialState(this.visitedFrom);
        this.getMember();
    }

    private getMember(redirect = true) {
        this.store.select(getMeData).subscribe((response) => {
            if (response === null) {
                console.warn("Failed to get me data. Response empty.");
                return;
            }

            const me = response;
            this.myMatches = me.matches;
            let hasMatched = false;
            const tags = response.tags as string[];
            this.commonTagsText = this.getCommonTagsStatus(tags, this.member.tags);
            for (const match of this.myMatches) {
                if (this.memberId === match.partnerId) {
                    hasMatched = true;

                    this.hideInteraktionButtons();
                    this.matchId = match.matchId;
                }
            }
            if (redirect && this.visitedFrom === "chat" && !hasMatched) {
                this.router.navigate(["/user/chats"]);
            }
        });
    }

    /**
     * Get the common tags value as string
     * @param myTagIds Tags of me object
     * @param partnerTags Tags of member object
     * @returns The string value for view
     */
    public getCommonTagsStatus(myTagIds: string[], partnerTags: any): string {
        const partnerTagIds = partnerTags.map((value) => value._id);
        let count = 0;
        myTagIds.filter((value) => {
            if (partnerTagIds.includes(value)) {
                this.commonTags.push(value);
                count++;
            }
        });
        return count === 0
            ? "Keine gemeinsamen Interessen"
            : count === 1
            ? "Ein gemeinsames Interesse"
            : count.toString() + " gemeinsamen Interessen";
    }

    /**
     * Set initial properties according to the type where it visitet from
     * @param from Value from where this component is visited ['chat','like','discover]
     */
    private setInitialState(from: string) {
        if (from === "discover") {
            this.showLikeButton = true;
            this.showInstantMatchButton = true;
        }
        if (from === "like") {
            this.showLikeButton = true;
            this.showInstantMatchButton = false;
        }
    }

    private hideInteraktionButtons() {
        this.showLikeButton = false;
        this.showInstantMatchButton = false;
    }

    /**
     * Change a boolean value according to status
     * @param status The current state of instant match form. state: opened or closed
     */
    public matchFormStatus(status: string) {
        if (status === "opened") {
            this.hideFullPage = true;
        } else {
            this.hideFullPage = false;
        }
    }

    /**
     * Show the match modal after successfully created
     * @param value Contains type of match and match infos
     */
    public likedMember() {
        /*we are showing profile with new profile-chat so it has to be implemented */
        //this.selectedHeartIcon = this.heartLineHalfFilled;
        this.hideInteraktionButtons();
        this.router.navigate(["user/discover"], {
            state: {
                doPostInteract: {
                    memberId: this.memberId,
                    interaction: "LIKE",
                },
            },
        });
    }

    public performedInstantMatch() {
        this.router.navigate(["user/discover"], {
            state: {
                doDisplayNextMember: true,
            },
        });
    }

    public deleteMatch(matchId: string) {
        this.isClickInProgress = true; // set isClickInProgress to true

        this.api
            .matches()
            .delete(matchId)
            .subscribe({
                next: () => {
                    this.router.navigate(["/user/chats"]);
                },
                error: (e) => {
                    console.error("Error while deleting match", e);
                },
            });
        this.isClickInProgress = false; // reset isClickInProgress to false once the operation completes
    }

    public showPhotoUnlockModal(value: any) {
        this.photoToUnlock = value;
        this.hideFullPage = true;
        if (value.type === "PRIVATE") this.private = true;
        if (value.type === "INTIMATE") this.intimate = true;
        if (value.type === "EXCLUSIVE") this.exclusive = true;
        if (value.type === "SPECIAL") this.special = true;
    }

    public photoUnlockCompleted(imageId: string) {
        this.private = false;
        this.intimate = false;
        this.exclusive = false;
        this.special = false;
        this.hideFullPage = false;
        this.getMember(false);

        // refresh the profile
        this.refreshProfile.emit();

        this.imageService.unlockImage(imageId);
    }

    closeInsufficientCreditsError() {
        this.showInsufficientCreditsError = false;
        this.hideFullPage = false;
    }

    openImageLightBox() {
        this.openImageSlider = true;
    }

    closeImageLightBox() {
        this.openImageSlider = false;
        this.startImageIndexForSlider = 0;
    }

    handleStartImageIndexValue(value: number) {
        this.startImageIndexForSlider = value;
        this.openImageSlider = true;
    }
}
