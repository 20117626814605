import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { ReactiveFormsModule } from "@angular/forms";
import { LayoutModule } from "../layout/layout.module";
import { SharedModule } from "../shared/shared.module";
import { AccountDeletedComponent } from "./account-deleted/account-deleted.component";
import { PasswordResetComponent } from "./login-issues/password-reset/password-reset.component";
import { LoginOptionComponent } from "./login-option/login-option.component";
import { LoginComponent } from "./login/login.component";
import { RegisterSuccessComponent } from "./register-success/register-success.component";
import { RegisterComponent } from "./register/register.component";
import { UserAuthRoutingModule } from "./user-auth-routing.module";
@NgModule({
    declarations: [
        LoginComponent,
        RegisterComponent,
        RegisterSuccessComponent,
        PasswordResetComponent,
        LoginOptionComponent,
        AccountDeletedComponent,
    ],
    imports: [CommonModule, UserAuthRoutingModule, SharedModule, ReactiveFormsModule, LayoutModule],
    exports: [LoginComponent, RegisterComponent, AccountDeletedComponent],
})
export class UserAuthModule {}
