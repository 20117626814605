import { animate, state, style, transition, trigger } from "@angular/animations";
import { DOCUMENT } from "@angular/common";
import { Component, Inject, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { GalleryImage, GalleryImageType } from "src/app/interfaces/ProfileData";
import { ApiService } from "src/app/services/api.service";
import { MembersMeStoreService } from "src/app/services/members-me-store.service";
import { NavBarOptions, NavBarService } from "src/app/services/navbar.service";
import { StatusService } from "src/app/services/status.service";
import { environment } from "src/environments/environment";
import { GalleryImageEditData } from "../my-photos/my-photos.component";
@Component({
    selector: "app-edit-photo",
    templateUrl: "./edit-photo.component.html",
    animations: [
        trigger("simpleFadeAnimation", [
            state("in", style({ opacity: 1 })),
            transition(":enter", [style({ opacity: 0 }), animate(300)]),
            transition(":leave", animate(300, style({ opacity: 0 }))),
        ]),
    ],
})
export class EditPhotoComponent implements OnInit {
    public static NavbarOptions: NavBarOptions = {
        ...NavBarService.StandardNavBarOptions,
        leftMenuText: "Deine Fotos",
        separationLine: true,
        showProfile: true,
    };

    @Input() image: GalleryImageEditData;
    @Input() fallbackProfileImage?: GalleryImage;

    public imgSrc = "";

    public imageProfileImage = false;
    public success = false;
    public successfullyDeleted = false;
    public public = false;
    showDeleteModal = false;

    public imageURL: string;
    public imagePath: string;
    public activeComponent: string;

    public toBeCropped?: string;

    constructor(
        private api: ApiService,
        private membersMeStore: MembersMeStoreService,
        public router: Router,
        private statusService: StatusService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    ngOnInit(): void {
        this.imgSrc = `${environment.url.image}/${this.image.href}`;

        this.activeComponent = this.image.type.toLowerCase();
        this.imageProfileImage = this.image.profileImage;
    }

    deleteImage() {
        this.successfullyDeleted = false;
        this.api
            .me()
            .deleteImage(this.image.id)
            .subscribe({
                next: () => {
                    this.document.location.href = "/my-photos";
                    this.membersMeStore.removeMembersMeFromStore();
                    localStorage.removeItem("image");
                    this.successfullyDeleted = true;
                },
                error: (e) => {
                    console.error("Error while deleting image", e);
                },
            });
    }

    setActiveComponent(type: string) {
        if (type === this.activeComponent) {
            this.activeComponent = "public";
        } else {
            this.activeComponent = type;
        }
        this.activeComponent === "public" ? (this.public = true) : (this.public = false);
        this.saveEditedImage();
    }

    toggleProfileImage() {
        if (!this.fallbackProfileImage) {
            return;
        }

        this.imageProfileImage = !this.imageProfileImage;

        if (this.imageProfileImage === false) {
            this.setFallbackImageAsProfileImage();
        } else {
            this.saveEditedImage();
        }
    }

    goToCrop() {
        this.toBeCropped = this.image.url;
    }

    gotCroppedImage(file: File) {
        // this.toBeCropped = undefined;

        this.statusService.isLoading.emit(true);

        const imageFormData = new FormData();

        imageFormData.append("image", file);
        imageFormData.append("profileImage", "false");
        imageFormData.append("type", GalleryImageType.PUBLIC);

        this.api
            .me()
            .image(this.image.id, imageFormData)
            .subscribe({
                next: () => {
                    this.membersMeStore.removeMembersMeFromStore();
                    // refresh image
                    this.imgSrc = this.imgSrc + "?a=" + Math.random();

                    this.toBeCropped = undefined;
                    this.statusService.isLoading.emit(false);
                },
                error: (e) => {
                    console.error("Failed to save cropped image!", e);
                },
            });
    }

    setFallbackImageAsProfileImage() {
        if (!this.fallbackProfileImage) return;

        const imageFormData = new FormData();

        imageFormData.append("type", this.fallbackProfileImage.type);
        imageFormData.append("imageId", this.fallbackProfileImage.id);
        imageFormData.append("profileImage", true.toString());

        this.api
            .me()
            .image(this.fallbackProfileImage.id, imageFormData)
            .subscribe({
                next: () => {
                    this.success = true;
                    this.membersMeStore.removeMembersMeFromStore();
                },
                error: (e) => {
                    console.error("Failed to save edited image", e);
                },
            });
    }

    saveEditedImage() {
        this.success = false;

        const imageFormData = new FormData();

        imageFormData.append("type", this.activeComponent.toUpperCase());
        imageFormData.append("imageId", this.image.id);
        imageFormData.append("profileImage", this.imageProfileImage.toString());

        this.api
            .me()
            .image(this.image.id, imageFormData)
            .subscribe({
                next: () => {
                    this.success = true;
                    this.membersMeStore.removeMembersMeFromStore();
                },
                error: (e) => {
                    console.error("Failed to save edited image", e);
                },
            });
    }
}
