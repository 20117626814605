<div class="absolute top-0 w-full z-50 pt-10 md:pt-0">
    <div
        class="w-full flex !h-16 bg-brand-title items-center justify-center space-x-2 md:space-x-4 p-2"
    >
        <div>
            <div class="overflow-hidden rounded-full">
                <img
                    class="w-4 h-4"
                    src="../../../../assets/images/alert_white_filled.png"
                />
            </div>
        </div>
        <div class="flex flex-col w-1/2 -space-y-1">
            <div
                class="text-brand-title truncate flow-text !text-sm !text-white"
            >
                Deine Credits sind fast leer
            </div>
        </div>
        <a
            class="link cursor-pointerl font-semibod !text-sm !md:text-base"
            href="/user/shop"
            >Jetzt aufladen</a
        >
        <img
            class="w-2 h-2 md:h-3 md:w-3 cursor-pointer"
            src="../../../../assets/images/close_white.png"
            alt="close"
            (click)="onClose()"
        />
    </div>
</div>
