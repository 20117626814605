<app-main-layout [showProfile]="true" [leftMenuText]="'Vorkasse Zahlung'">
    <div
        class="h-full w-full bg-gradient-to-b from-brand-title to-brand-cyan px-4 pt-11 pb-8 sm:pt-0"
    >
        <div
            class="h-full w-full flex flex-col py-9 space-y-9 items-center overflow-y-scroll no-scrollbar"
        >
            <div class="flex flex-col items-center justify-center">
                <img
                    class="h-14 w-14"
                    src="../../../../assets/images/payment_accepted.png"
                    alt="vorkasse_check_small"
                />
                <h2 class="heading-2 text-white pt-2">Zahlung vorgemerkt</h2>
            </div>
            <div
                class="bg-white rounded-sm w-full py-4 space-y-4 text-brand-body"
            >
                <p class="px-5 text-center flow-text-logged-in">
                    Bitte beachte, dass bei dieser Zahlungsmethode Credits /
                    Schlüssel erst verbucht werden, sobald wir den
                    Zahlungseingang verzeichnen können.
                </p>
                <div
                    class="bg-brand-body/5 border-b border-t border-brand-body/20 py-6 px-4"
                >
                    <p class="text-brand-magenta font-bold mb-6">
                        Bitte überweise den Betrag wie folgt:
                    </p>
                    <div class="text-brand-title space-y-2 select-text">
                        <div class="flex justify-between">
                            <p class="font-normal">Empfänger:</p>
                            <p class="font-bold">{{ info?.name ?? "-" }}</p>
                        </div>
                        <div class="flex justify-between">
                            <p class="font-normal">Zahlungsbetrag::</p>
                            <p class="font-bold">
                                {{
                                    product
                                        ? (product.cost / 100 | floatToEuro)
                                        : "-"
                                }}
                            </p>
                        </div>
                        <div class="flex justify-between">
                            <p class="font-normal">Verwendungszweck:</p>
                            <p class="font-bold">{{ reference }}</p>
                        </div>
                        <div>
                            <div class="flex justify-between">
                                <p class="font-normal">IBAN:</p>
                                <p class="font-bold">{{ info?.iban ?? "-" }}</p>
                            </div>
                            <div class="flex justify-between">
                                <p class="font-normal">BIC:</p>
                                <p class="font-bold">{{ info?.bic ?? "-" }}</p>
                            </div>
                        </div>
                        <div>
                            <div class="flex justify-between">
                                <p class="font-normal">Kontonummer:</p>
                                <p class="font-bold">
                                    {{ info?.accountNumber ?? "-" }}
                                </p>
                            </div>
                            <div class="flex justify-between">
                                <p class="font-normal">BLZ:</p>
                                <p class="font-bold">{{ info?.blz ?? "-" }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <p
                    class="leading-5 px-10 text-brand-green font-bold text-center"
                >
                    Bei Zahlung per Vorkasse erhältst du immer on top
                    <span class="underline">
                        10% Credits / Schlüssel extra.</span
                    >
                </p>
            </div>
            <p class="leading-6 text-sm text-white/80 text-center px-6">
                Wir senden Dir alle Informationen zur Überweisung auch
                zeitgleich per E-Mail zu.
            </p>
            <a routerLink="/user/shop" class="underline font-bold text-white"
                >Zurück</a
            >
        </div>
    </div>
</app-main-layout>
