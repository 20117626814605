import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { Product } from "src/app/services/shop.service";

@Component({
    selector: "app-insufficient-credits",
    templateUrl: "./insufficient-credits.component.html",
})
export class InsufficientCreditsComponent implements OnInit {
    @Output() closeForm: EventEmitter<string> = new EventEmitter();
    public offers: Product[] = [];

    constructor(private api: ApiService) {}

    ngOnInit(): void {
        this.api
            .shop()
            .get()
            .subscribe((response: { products: Product[] }) => {
                // Get key offers only from shop api
                this.offers = response.products.filter((product) => product.qtyKeys > 0);
            });
    }

    public hideModal() {
        this.closeForm.emit();
    }

    public goToShop() {
        document.location.href = "/user/shop";
    }
}
