<!-- TODO: remove dynamic properties from app-main-layout -->
<app-main-layout
    *ngIf="member"
    [hScreen]="false"
    [rightMenuOpacity]="
        visitedFrom === 'like' || visitedFrom === 'discover'
            ? '50'
            : visitedFrom === 'chat'
            ? '100'
            : '0'
    "
    [rightMenuIcon]="
        visitedFrom === 'like'
            ? heartLineHalfFilled
            : visitedFrom === 'chat'
            ? heartLineFilled
            : visitedFrom === 'discover'
            ? heartLine
            : ''
    "
    [isRightMenuClickDisabled]="matchId ? false : true"
    [menuPositionLeft]="showProfile ? true : false"
    [rightMenuDisable]="matchId ? false : true"
    [leftMenuText]="member.username ? member.username : '-' + ', ' + (member.dob.toString() | dateToAge)"
    [userAvatar]="member.image"
    [rightMenuText]="'Chatten'"
    [showProfile]="true"
    [menuBar]="true"
    [separationLine]="true"
    [navbarRelative]="true"
    [bgColor]="showProfile ? 'white' : 'brand-cyan'"
>
    <div class="h-full w-full" *ngIf="showProfile">
        <app-visit-profile
            [member]="member"
            [memberId]="memberId"
            [visitedFrom]="visitedFrom"
            (refreshProfile)="fetchProfile()"
        ></app-visit-profile>
    </div>
    <app-chat
        *ngIf="!showProfile"
        [matchesResponse]="matchesResponse"
        [matchId]="matchId"
    ></app-chat>
</app-main-layout>

<!-- <div class="flex flex-col h-svh bg-red-500">
    <div class="bg-blue-500 h-full w-full"></div>
    <input class="" type="text" />
</div> -->
