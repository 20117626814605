import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

@Injectable({
    providedIn: "root",
})
export class NavigationService {
    private history: string[] = [];
    constructor(private router: Router, private location: Location) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.history.push(event.urlAfterRedirects);
            }
        });
    }

    // exceptions object
    private exceptions = {
        "/chat/": "/user/chats",
        "/crop-photo": "/my-photos",
        "/edit-photo": "/my-photos",
        "/profile/discover/": "/user/discover",
        "/profile/like/": "/user/like-you",
        "/profile": "/user/chats",
    };

    // check if current location is an exception
    private isException(): boolean {
        return Object.keys(this.exceptions).some((prevPage) =>
            window.location.pathname.includes(prevPage)
        );
    }

    // the updated back() method with exceptions
    back(): void {
        this.history.pop();
        if (this.isException()) {
            // handle exceptions
            const prevPage = Object.keys(this.exceptions).find((prevPage) =>
                window.location.pathname.includes(prevPage)
            );
            if (!prevPage) {
                console.warn("Failed to find exception (whatever that means) for back operation.");
                this.router.navigateByUrl("/");
                return;
            }
            const exception = this.exceptions[prevPage];
            this.router.navigateByUrl(exception);
        } else if (this.history.length > 0) {
            if (this.history[this.history.length - 1] === "/") {
                this.router.navigateByUrl("/home");
            } else {
                this.location.back();
            }
        } else {
            this.router.navigateByUrl("/");
        }
    }
}
