import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { GalleryImageEditData } from "../my-photos/my-photos.component";

@Component({
    selector: "app-photo-container",
    templateUrl: "./photo-container.component.html",
})
export class PhotoContainerComponent implements OnInit {
    @Input() image: any;
    @Input() newUpload = false;
    @Input() type!: string;

    @Output() selectedImage: EventEmitter<GalleryImageEditData> = new EventEmitter();

    public imgSrc: string;

    ngOnInit(): void {
        // needed to refresh image after editing // TODO: refactor
        this.imgSrc = this.image.image + "?" + Math.random();
    }

    UploadPhoto() {
        const image = document.getElementById("img-" + this.image.id) as HTMLImageElement;

        fetch(image.src)
            .then((res) => res.blob())
            .then((blob) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const imageData = {
                        url: reader.result as string,
                        href: this.image.image,
                        id: this.image.id,
                        type: this.image.type,
                        profileImage: this.image.profileImage,
                    };
                    this.selectedImage.emit(imageData);
                };
                reader.readAsDataURL(blob);
            });
    }
}
