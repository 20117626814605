import { Component, EventEmitter, Output } from "@angular/core";

@Component({
    selector: "app-link",
    templateUrl: "./link.component.html",
})
export class LinkComponent {
    @Output() clickedLink: EventEmitter<string> = new EventEmitter();

    emitLinkClicked() {
        this.clickedLink.emit();
    }
}
