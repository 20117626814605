<div class="space-y-6 py-6 flex-col">
    <h2 class="heading-magenta">IV. Widerrufsbelehrung,<br> Ausschluss des Widerrufsrechts</h2>
    <p class="font-semibold">Widerrufsrecht</p>
    <p>Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.
        <br>Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses.
        Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (Multilabel GmbH, Hohe Bleichen 12, 20354 Hamburg,
        support@amolino.de ) mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief oder eine
        E-Mail)
        über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte
        Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
        <br>Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor
        Ablauf der Widerrufsfrist absenden .</p>
    <p class="font-semibold">Folgen des Widerrufs</p>
    <p>Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben,
        einschließlich
        der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der
        Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens
        binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei
        uns
        eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen
        Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall
        werden Ihnen wegen dieser Rückzahlung Entgelte berechnet. </p>
    <p>Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen soll, so haben Sie uns einen
        angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des
        Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits erbrachten Dienstleistungen im Vergleich zum
        Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.</p>
    <ul>
        <li>- Ende der Widerrufsbelehrung -</li>
    </ul>


    <p class="font-semibold text-center">Muster-Widerrufsformular</p>
    <p>(Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.)</p>
    <p>An:</p>
    <p>Multilabel GmbH, Hohe Bleichen 12, 20354 Hamburg, support@amolino.de</p>
    <div class="pl-4">
        <p>Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über die Erbringung der
            folgenden
            Dienstleistung (*):</p>
        <br>
<p>Bestellt am (*)/erhalten am (*):
    <span><u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u></span>
</p>
        <br>

        <p>Name des/der Verbraucher(s):</p>
        <br>
        <br>

        <hr class="border-t-2 border-brand-body">
        <br>

        <p>Anschrift des/der Verbraucher(s):</p>
        <br>
        <br>

        <hr class="border-t-2 border-brand-body">
        <br>
        <br>

        <hr class="border-t-2 border-brand-body">

        <p>Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)</p>
        <br>

        <p>Datum:
    <span><u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u></span>

        </p>
    </div>
</div>
