import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "app-chat-item",
    templateUrl: "./chat-item.component.html",
})
export class ChatItemComponent implements OnInit {
    @Input() match!: any;
    @Input() myId: string;
    read = true;
    newInstantMatchNotFromMe = false;
    lastMessageFromRecipient = false;
    newInstantMatch = false;
    newMatch = false;
    lastMessageSentByMe = false;

    ngOnInit(): void {
        if (this.match.lastMessage?.from !== this.myId && this.match.new) {
            this.lastMessageFromRecipient = true;
            this.read = false;
        }
        if (
            this.match.instantMatch &&
            this.match.lastMessage?.from !== this.myId &&
            this.match.newInstantMatch
        ) {
            this.newInstantMatch = true;
        }
        if (!this.match.lastMessage) {
            this.newMatch = true;
        }
        if (this.match.lastMessage?.from === this.myId) {
            this.lastMessageSentByMe = true;
        }
    }

    public get lastMessage(): string {
        if (this.match.lastMessage) {
            return this.match.lastMessage.body;
        } else return "";
    }
}
