enum Pending {
    completeEmail = "completeEmail",
    chatTutorial = "chatTutorial",
    completeProfile = "completeProfile",
    completeName = "completeName",
    completeAge = "completeAge",
    completeLocation = "completeLocation",
    completeGender = "completeGender",
    completeLookingFor = "completeLookingFor",
    completeTags = "completeTags",
    completeImage = "completeImage",
    changeUsername = "changeUsername"
}

export default Pending;
