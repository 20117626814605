import { Observable } from "rxjs";
import PaymentProvider from "../enums/payment-provider";
import {
    PaypalTransactionResponse,
    ShopInfoResponse,
    ShopResponse,
    StandardApiResponse,
    StripeTransactionResponse,
} from "../interfaces/api-responses";
import { ApiService } from "./api.service";

export interface Product {
    _id: string;
    offer: boolean;
    description: string;
    cost: number;
    qtyCoins: number;
    qtyKeys: number;
    expiredAt?: string;
    bonusLevel: number;
    bonusText?: string;
}

export class ShopService {
    constructor(private api: ApiService) {}

    /**
     * Gets the items that the authenticated user can buy
     * @returns The observable
     */
    public get(): Observable<ShopResponse> {
        return this.api.get("shop");
    }

    /**
     * Buys a product
     * @param productId The product id of the product to buy
     * @param paymentProvider The payment provider (STRIPE or TRANSFER)
     * @param paymentOption The payment option (for stripe payments)
     * @returns The observable
     */
    public buy(
        productId: string,
        paymentProvider: PaymentProvider,
        paymentOption: string | null = null
    ): Observable<PaypalTransactionResponse | StripeTransactionResponse> {
        return this.api.post("shop", {
            productId,
            paymentProvider,
            paymentOption,
        });
    }

    /**
     * Gets the shop bank information as json
     * @returns The observable
     */
    public info(): Observable<ShopInfoResponse> {
        return this.api.get("shop/info");
    }

    /**
     * Cancel the buy process from stripe
     * @returns The observable
     */
    public cancelStripe(data: string): Observable<StandardApiResponse> {
        return this.api.post("shop/cancelStripe", { reference: data });
    }

    public verifyPaypal(paypalTransactionId: string): Observable<any> {
        return this.api.get("shop/verifyPaypal/" + paypalTransactionId);
    }
}
