import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "src/app/services/api.service";

@Component({
    selector: "app-prepayment-order",
    templateUrl: "./prepayment-order.component.html",
})
export class PrepaymentOrderComponent implements OnInit {
    public reference: string;

    public product: any;

    public info: any;

    constructor(private api: ApiService, private router: Router) {
        if (this.router.getCurrentNavigation()?.extras.state) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const state = this.router.getCurrentNavigation()?.extras.state as any;
            if (state && Object.prototype.hasOwnProperty.call(state, "product")) {
                this.reference = state.reference;
                this.product = state.product;
            }
        }
    }

    ngOnInit(): void {
        this.api
            .shop()
            .info()
            .subscribe((response) => (this.info = response.accountInfo));
    }
}
