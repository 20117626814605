import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "app-form-radio-button",
    templateUrl: "./radio-button.component.html",
})
export class FormRadioButtonComponent {
    @Input() id!: string;
    @Input() label!: string;
    @Input() class?: string;
    @Input() info?: string;
    @Input() name!: string;
    @Input() value!: string;
    @Input() checked!: boolean;
    @Output() getInputGender: EventEmitter<string> = new EventEmitter();
    checkedValue!: string;

    change(event: any) {
        this.checkedValue = event.target.value;

        this.getInputGender.emit(this.checkedValue);
    }
}
