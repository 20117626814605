import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import jwt_decode from "jwt-decode";
import { Observable, catchError, of, tap, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { WebToAppService } from "./web-to-app.service";

type AuthenticationToken = { accessToken: string | null };

@Injectable({
    providedIn: "root",
})
export class TokenHandlingService {
    constructor(private http: HttpClient, private webToAppService: WebToAppService) {}

    // private accessTokenSubject = new BehaviorSubject<string | null>(this.getAuthenticationToken());

    /**
     * Sets the current authentication token
     * @param accessToken The JWT
     */
    public setAuthenticationToken(accessToken: string): void {
        console.log(`Get new Access Token ${accessToken}`)
        localStorage.setItem("accessToken", accessToken);
        // this.accessTokenSubject.next(accessToken);
    }

    /**
     * Gets the current authentication token
     * @returns The access token or null
     */
    public getAuthenticationToken(): string | null {
        return localStorage.getItem("accessToken");
    }

    /**
     * Get the valid authentication token (accessToken). If possible from local storage, otherwise from the API
     */
    public getValidAuthenticationToken(): Observable<AuthenticationToken> {
        if (this.isAccessTokenValid()) {
            const accessToken = this.getAuthenticationToken();
            return of({ accessToken });
        }

        // Return an observable
        return this.fetchAuthToken();
    }

    /**
     * Request an auth token from the API using the refresh token (stored as an http-only cookie)
     */
    private fetchAuthToken(): Observable<AuthenticationToken> {
        return (
            this.http.get(environment.url.auth, {
                withCredentials: true,
            }) as Observable<AuthenticationToken>
        ).pipe(
            catchError((_error: HttpErrorResponse) => {
                console.warn(
                    "Failed to fetch auth token (accessToken). This might mean that the old refreshToken expired. More info:",
                    _error
                );
                this.webToAppService.logHTTPErrorResponse(_error);

                return throwError(() => _error);
            }),
            tap((result) => {
                if (result.accessToken) {
                    this.setAuthenticationToken(result.accessToken);
                }
            })
        );
    }

    /**
     * Checks if the access token is valid
     */
    private isAccessTokenValid(): boolean {
        const accessToken = this.getAuthenticationToken();
        if (accessToken === null) {
            console.warn("Access Token is null.");
            return false;
        }

        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const decoded: any = jwt_decode(accessToken);

            // Assume the token expiration time is stored in a variable called `expirationTime` (in seconds)
            const expirationTime = decoded.exp;

            // Convert the expiration time to milliseconds and create a new Date object
            const expirationDate = new Date(expirationTime * 1000);

            // Get the current time
            const now = new Date();

            // Check if the expiration time is greater than 1 minute from now if greater than true otherwise false
            const tokenExpired = expirationDate.getTime() - now.getTime() < 60000;

            if (tokenExpired) console.warn("Access Token is expired.");

            return !tokenExpired;
        } catch (error) {
            console.warn("Access Token couldn't be decoded.", error);
            return false;
        }
    }
}
