<div
    [ngClass]="
        active
            ? ['bg-brand-' + activeColor, 'text-white']
            : ['bg-[#f6f6f6]', 'text-brand-body']
    "
    class="cursor-pointer rounded-md text-center flex items-center justify-center py-6"
    style="display: flex; flex-wrap: wrap; align-items: center"
>
    <img
        class="h-8 pr-2"
        src="../../../../assets/images/{{
            active ? src + '_gallery_white.png' : src + '_gallery.png'
        }}"
    />
    <ng-content></ng-content>
</div>
