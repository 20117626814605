<!-- Heart -->
<div @simpleFadeAnimation class="">
    <div
        class="relative w-full touch-pan-y"
        [ngClass]="{
            'overflow-hidden':
                popupLogin || popupRegister || popupAccountDeleted
        }"
    >
        <app-header
            [usingMobileDevice]="usingMobileDevice"
            class="{{
                popupLogin || popupRegister || popupAccountDeleted
                    ? 'invisible md:visible'
                    : ''
            }}"
            (loginClicked)="loginButtonClicked()"
            (registerClicked)="registerButtonClicked()"
        ></app-header>

        <div
            class="{{
                popupLogin || popupRegister || popupAccountDeleted
                    ? 'fixed invisible md:visible'
                    : 'relative'
            }} z-20 bg-white text-lg md:text-xl w-full"
        >
            <!-- Schnelle Matches -->
            <section
                id="matching"
                style="
                    background-image: url('../../../../assets/images/dekstop_hearts_background.png');
                "
                class="shadow-[1px_-13px_22px_-18px_rgba(0,0,0,0.5)] px-4 pb-32 md:px-20 lg:px-40 w-full h-full text-white bg-no-repeat bg-cover"
            >
                <div class="flex flex-col items-center">
                    <!-- Heart -->
                    <div
                        *ngIf="!usingMobileDevice; else mobileView"
                        class="relative flex justify-center"
                    >
                        <div
                            class="absolute w-16 h-16 p-2 -translate-y-8 md:-translate-y-9 rounded-full md:w-20 md:h-20 flex flex-col items-center justify-center bg-white"
                        >
                            <img
                                class="h-full w-full"
                                src="../../../assets/images/herz_startseite_x4.png"
                                alt="heart-icon"
                            />
                        </div>
                    </div>
                    <ng-template #mobileView>
                        <div
                            class="absolute top-[50px] w-16 h-16 p-2 -translate-y-8 md:-translate-y-9 rounded-full md:w-20 md:h-20 flex flex-col items-center justify-center bg-white"
                        >
                            <img
                                class="h-full w-full"
                                src="../../../assets/images/herz_startseite_x4.png"
                                alt="heart-icon"
                            />
                        </div>
                    </ng-template>

                    <h1 class="landing-section-heading mb-10 mt-24 pt-4">
                        Schnelle Matches
                    </h1>
                    <p
                        class="max-w-xs md:max-w-3xl text-center flow-text mb-16 !text-[1.3rem] !leading-6"
                    >
                        Finde Dein perfektes Match in Sekundenschnelle mit nur
                        einem Klick. Entdecke neue Leute und zeige dein
                        Interesse mit einem Like oder chatte direkt mit der
                        "Sofort-Match" Funktion. Keine endlosen Fragebögen oder
                        psychologischen Tests - Einfach anmelden und mitmachen.
                    </p>
                </div>
                <div class="block mt-8">
                    <div
                        class="flex flex-col w-full items-center justify-center lg:flex-row lg:space-x-7"
                    >
                        <div class="w-80 lg:w-60">
                            <img
                                class="hidden lg:block"
                                src="../../../assets/images/landing/display_1.png"
                            />
                            <img
                                class="lg:hidden"
                                src="../../../assets/images/landing/display_1.png"
                            />
                        </div>
                        <div class="w-80 lg:w-60">
                            <img
                                class="hidden lg:block"
                                src="../../../assets/images/landing/display_2.png"
                            />
                            <img
                                class="lg:hidden"
                                src="../../../assets/images/landing/display_2.png"
                            />
                        </div>
                        <div class="w-80 lg:w-60">
                            <img
                                class="hidden lg:block"
                                src="../../../assets/images/landing/display_3.png"
                            />
                            <img
                                class="lg:hidden"
                                src="../../../assets/images/landing/display_3.png"
                            />
                        </div>
                        <div class="w-80 lg:w-60">
                            <img
                                class="hidden lg:block"
                                src="../../../assets/images/landing/display_4.png"
                            />
                            <img
                                class="lg:hidden"
                                src="../../../assets/images/landing/display_4.png"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <!-- Divider -->
            <app-heart-divider
                [bgColor]="'bg-brand-accent text-brand-title'"
                [position]="'left-1/4'"
                [rotate]="'rotate-1'"
                [icon]="'heart_section_2.png'"
            >
            </app-heart-divider>

            <!-- Section App -->
            <app-section-layout id="download-app" [bgColor]="'bg-brand-accent'">
                <div
                    class="grid gap-y-8 justify-items-center lg:justify-items-center lg:gap-y-0 lg:grid-cols-2 px-2 md:px-20"
                >
                    <div class="space-y-12 max-w-lg">
                        <h1
                            class="landing-section-heading text-center lg:text-left"
                        >
                            Dein Dating-Erlebnis für unterwegs
                        </h1>
                        <p
                            class="text-center md:text-left flow-text !text-[1.3rem] !leading-6"
                        >
                            Bei Amolino hast Du das Dating-Erlebnis immer in der
                            Tasche. Unsere Webseite wurde speziell für mobile
                            Endgeräte optimiert, damit Du jederzeit und überall
                            unkompliziert neue Leute kennenlernen kannst.
                        </p>
                        <p
                            class="text-center md:text-left flow-text !text-[1.3rem] !leading-6"
                        >
                            Und das Beste: Bald steht unsere App in den Stores
                            zum Download bereit, damit Du noch schneller und
                            einfacher Matches finden und chatten kannst. Sei
                            gespannt und verpasse nicht den Start unserer neuen
                            App!
                        </p>
                        <div>
                            <app-available-soon
                                class="hidden lg:block"
                            ></app-available-soon>
                        </div>
                    </div>
                    <img
                        class="w-[300px]"
                        src="../../../assets/images/phone.png"
                    />
                </div>
                <div>
                    <app-available-soon
                        class="block lg:hidden"
                    ></app-available-soon>
                </div>
            </app-section-layout>

            <!-- Divider -->
            <app-heart-divider
                [bgColor]="'bg-white text-brand-cyan'"
                [position]="'right-1/4'"
                [liftUp]="true"
                [rotate]="'rotate-1'"
                [icon]="'heart_section_4.png'"
            ></app-heart-divider>

            <!-- Section For Everyone -->
            <app-section-layout
                id="find-partner"
                [bgColor]="'bg-gradient-to-b from-white to-gray-200'"
            >
                <div
                    class="grid gap-y-8 justify-items-center lg:gap-y-0 lg:grid-cols-5 my-20"
                >
                    <img
                        class="rounded-xl lg:col-span-2 w-[275px] md:w-[350px] pr-4"
                        src="../../../assets/images/couple_section.png"
                    />
                    <div
                        class="space-y-12 order-first lg:order-last lg:col-span-3 px-2 md:px-16"
                    >
                        <h1
                            class="landing-section-heading text-center lg:text-left"
                        >
                            Bei amolino findet jeder sein passendes Match - auch
                            Du
                        </h1>
                        <p
                            class="text-center md:text-left flow-text pt-4 !text-[1.3rem] !leading-6"
                        >
                            Ja, meist tummeln sich mehr Männer als Frauen auf
                            deutschen Dating Portalen. Wir bei amolino versuchen
                            das Verhältnis zwischen Männern und Frauen jedoch
                            bewusst ausgewogen zuhalten, damit jeder die Chance
                            hat, den passenden Partner zu finden. Wir glauben
                            daran, dass es für jeden ein geeignetes Match gibt -
                            und wir helfen Dir gerne dabei, dieses zu finden.
                            Melde Dich jetzt bei amolino an und entdecke, wer
                            bereits auf Dich wartet!
                        </p>
                        <div class="pt-12">
                            <app-fair-chance
                                class="hidden lg:block"
                            ></app-fair-chance>
                        </div>
                    </div>
                </div>
                <app-fair-chance
                    class="grid lg:hidden justify-items-center"
                ></app-fair-chance>
            </app-section-layout>

            <!-- Divider -->
            <app-heart-divider
                [bgColor]="'bg-brand-cyan text-brand-accent'"
                [position]="'right-1/2'"
                [liftUp]="true"
                [rotate]="'-rotate-1'"
                [icon]="'heart_section_3.png'"
            ></app-heart-divider>

            <!-- Section Your Thing -->
            <app-section-layout-background [bgColor]="'bg-brand-cyan'">
                <div
                    class="grid justify-items-center content-center gap-y-8 py-6"
                >
                    <h1
                        class="text-[28pt] text-center max-w-xs md:text-5xl font-bold md:max-w-full text-white leading-9"
                    >
                        Entdecke dein Match bei amolino - jetzt kostenlos
                        registrieren
                    </h1>
                    <h2
                        class="max-w-2xl text-center flow-text mb-8 !text-white !text-[1.3rem] !leading-6"
                    >
                        Entdecke jetzt unzählige neue Leute. Egal, ob Du auf der
                        Suche nach einem lockeren Flirt oder einer ernsthaften
                        Beziehung bist - bei uns findest Du, was Du suchst.
                        Worauf wartest Du noch? Mach jetzt den ersten Schritt
                        und finde Dein Match bei amolino!
                    </h2>
                    <div class="w-[300px]">
                        <app-link-button
                            [loggedIn]="false"
                            (click)="popupRegister = true"
                            [theme]="'hero'"
                        >
                            Konto erstellen
                        </app-link-button>
                    </div>
                </div>
            </app-section-layout-background>

            <div class="px-4 lg:px-28 xl:px-44">
                <app-footer></app-footer>
            </div>
        </div>
        <div *ngIf="popupLogin" @simpleFadeAnimation>
            <app-login
                [loginData]="popupLoginData"
                (loading)="handleLoading($event)"
                (hidePopup)="popupLogin = false"
                (toRegister)="popupRegister = true; popupLogin = false"
            >
            </app-login>
        </div>
        <div *ngIf="popupRegister" @simpleFadeAnimation>
            <app-register
                [tiktokClickId]="tiktokClickId"
                [registerData]="popupRegisterData"
                (loading)="handleLoading($event)"
                (hidePopup)="popupRegister = false"
                (toLogin)="popupRegister = false; popupLogin = true"
            >
            </app-register>
        </div>
        <div *ngIf="popupAccountDeleted" @simpleFadeAnimation>
            <app-account-deleted
                [date]="deleteByDate"
                (hidePopup)="popupAccountDeleted = false"
            >
            </app-account-deleted>
        </div>
    </div>
</div>
<div
    @simpleFadeAnimation
    *ngIf="loader"
    class="h-full w-full z-50 absolute top-0 left-0"
>
    <app-loader
        theme="{{ loadingTheme }}"
        [h1]="loadingTheme === 'transparent' ? 'Einen Moment...' : ''"
    >
    </app-loader>
</div>
