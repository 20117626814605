<div class="h-full w-full flex flex-col">
    <div class="flex-1">
        <div
            class="h-full relative cursor-pointer"
            (click)="onProfileImageClicked()"
        >
            <img
                src="{{ member.image | addImageBaseUrl }}"
                class="absolute top-0 left-0 object-cover w-full h-full"
                alt="profile_images"
            />
            <img
                src="../../../../assets/images/entdecken_foto_fade.png"
                class="h-full absolute top-0 left-0"
            />
        </div>
    </div>
    <div
        class="relative grid items-center bg-brand-body bg-opacity-10"
        style="height: 25%"
    >
        <div class="space-y-1">
            <h1 class="font-bold text-[2rem] text-center">
                {{ member.username ?? '-' }}, {{ member.dob | dateToAge }}
            </h1>
            <p class="text-md leading-5 text-center px-4">
                {{ member?.distance ?? "-" | customizeDistance }} entfernt,
                {{ commonTagsText }}
            </p>
        </div>
        <div class="absolute w-full -top-6">
            <div class="flex space-x-1 w-fit mx-auto">
                <!-- <img *ngIf="!showButton.match || !showButton.like" src="../../../../assets/images/open_lightbox.png" (click)="onProfileImageClicked()" class="cursor-pointer absolute z-50 bottom-0 right-2"> -->
                <img
                    *ngIf="showButton.match || showButton.like"
                    src="../../../../assets/images/open_lightbox.png"
                    (click)="onProfileImageClicked()"
                    class="cursor-pointer absolute z-1 bottom-12 right-2"
                />

                <a
                    *ngIf="showButton.match"
                    (click)="openMatchForm()"
                    class="cursor-pointer"
                >
                    <img
                        class="h-[65px] w-[65px]"
                        src="../../../../assets/images/instantmatch_default.png"
                        alt="like-heart-icon"
                    />
                </a>
                <a
                    *ngIf="showButton.like"
                    (click)="postInteract(member._id, 'LIKE')"
                    class="cursor-pointer"
                >
                    <img
                        class="h-[65px] w-[65px]"
                        src="../../../../assets/images/like_default.png"
                        alt="like-heart-icon"
                    />
                </a>
            </div>
        </div>
    </div>
    <app-instant-match-form-modal
        *ngIf="showInstantMatchFormModal"
        (insufficientCredits)="outPutInsufficientCredits()"
        [member]="member"
        (closeForm)="closeMatchForm()"
        (submitInstantMatchSuccessful)="instantMatchSuccessful($event)"
    ></app-instant-match-form-modal>
</div>
