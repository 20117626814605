import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "floatToEuro",
})
export class FloatToEuroPipe implements PipeTransform {
    transform(value: number): string {
        const euroCurrency: string =
            "\u20AC " +
            value.toLocaleString("de-DE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        return euroCurrency;
    }
}
