<app-mobile-wrapper-layout [type]="'cyan'">
    <img
        src="/assets/images/alert2.png"
        class="w-9 h-9 absolute top-6 right-6"
    />
    <div class="p-8 h-full flex flex-col justify-between">
        <div class="body h-full pb-4 flex flex-col justify-between">
            <div>
                <h2 class="font-bold leading-1 text-2xl pr-[24px]">
                    Erlaube den Standortzugriff zum Fortfahren
                </h2>
                <p class="mt-4 flow-text-logged-in">
                    Ohne die Standortfreigabe können wir die Registrierung
                    leider nicht fortsetzen. Die Standortfreigabe ist zwingend
                    notwendig, da die Anwendung sonst technisch nicht
                    funktioniert. Sie ermöglicht es Dir, andere Nutzer in Deiner
                    Nähe zu finden und von ihnen gesehen zu werden. Bitte
                    erlaube den Zugri auf Deinen Standort, um die Registrierung
                    fortzusetzen."
                </p>
            </div>
            <div class="h-full flex flex-col justify-center">
                <p class="font-bold">Deine Standortdaten ...</p>
                <ul class="bullet-list mt-4 text-xm">
                    <li>werden keinem Nutzer exakt angezeigt.</li>
                    <li>werden von uns für keine anderen Zwecke genutzt.</li>
                    <li>werden sicher und verschlüsselt übertragen.</li>
                </ul>
            </div>
        </div>
        <app-link-button
            (click)="navigateBack()"
            [theme]="'danger'"
            [isDisabled]="!buttonEnabled"
            >Okay, verstanden</app-link-button
        >
    </div>
</app-mobile-wrapper-layout>
