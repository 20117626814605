<app-hearts-bg
    *ngIf="show"
    [type]="color"
    [@bounce]="stateName"
    (@bounce.done)="onAnimationEvent($event)"
    class="fixed md:absolute top-0 h-full w-full z-20"
>
    <div
        class="flex flex-col items-center justify-around h-full text-white text-center px-6 overflow-hidden"
    >
        <div (click)="closeView()" class="self-end">
            <app-close [closeColor]="'white'"></app-close>
        </div>
        <div>
            <h1 class="text-5xl text-inherit font-bold">{{ h1 }}</h1>
            <h2 class="text-3xl text-inherit font-bold">{{ h2 }}</h2>
        </div>
        <div>
            <div class="z-0 flex justify-center items-end -space-x-14">
                <div>
                    <img
                        class="rounded-full h-[168px] w-[168px] object-cover border-8 border-white border-opacity-20"
                        src="{{ imageBaseUrl + '/' + matchData.myImage }}"
                        alt="my image"
                    />
                </div>
                <div class="z-10 -mb-3">
                    <img
                        class="h-[65px] w-[65px] object-cover"
                        [src]="src"
                        alt="match_modal"
                    />
                </div>
                <div>
                    <img
                        class="rounded-full h-[168px] w-[168px] object-cover border-8 border-white border-opacity-20"
                        src="{{ imageBaseUrl + '/' + matchData.partnerImage }}"
                        alt="partner image"
                    />
                </div>
            </div>
        </div>
        <div class="font-medium">{{ info }}</div>
        <div class="button w-full">
            <ng-content></ng-content>
        </div>
        <div class="cursor-pointer font-semibold" (click)="closeView()">
            Später. Zurück bitte!
        </div>
    </div>
</app-hearts-bg>
