<div class="h-full flex flex-col">
    <div class="flex items-center justify-end space-x-2 my-4">
        <p>{{ list.length }}/5</p>
        <img
            class="h-3 w-3"
            src="../../../../../assets/images/auth/select_{{
                list.length === 5 ? 'active' : 'inactive'
            }}.png"
            alt="select_inactive"
        />
    </div>
    <div class="flex-grow">
        <div class="grid relative h-full overflow-scroll no-scrollbar">
            <div class="absolute flex flex-wrap justify-center">
                <div *ngFor="let item of interestsArray; let i = index">
                    <app-interest-item
                        (addItem)="addList(item._id)"
                        [selectDisabled]="disabled"
                        [prefill]="isPrefill(item._id)"
                    >
                        {{ item.de }}</app-interest-item
                    >
                </div>
            </div>
        </div>
    </div>
</div>
