<div class="w-full h-full" #main>
    <!-- Loading animation -->
    <div
        *ngIf="isLoading"
        class="absolute md:top-0 top-11 bg-white/40 h-full w-full grid justify-items-center z-[1]"
    >
        <img
            class="mt-4"
            src="../../../../assets/images/reload_icon.png"
            width="40px"
            height="40px"
        />
    </div>

    <app-credit-notify
        *ngIf="displayCreditNotification"
        (closeNotify)="displayCreditNotification = !displayCreditNotification"
    ></app-credit-notify>
    <div
        *ngIf="showTutorial"
        class="pt-[60px] bg-white absolute z-[1] top-0 md:pt-0 h-full w-full"
    >
        <app-tutorial
            (closeTutorial)="closeChatTutorial()"
            [tutorials]="tutorials"
            continueText="Weiter zum Chat"
        ></app-tutorial>
    </div>
    <div
        class="w-full h-full justify-between flex flex-col bg-cover bg-[url('assets/images/Chat_Pattern.png')]"
    >
        <!-- Messages -->
        <div
            #scrollMe
            infiniteScroll
            [infiniteScrollUpDistance]="0"
            [infiniteScrollThrottle]="100"
            (scrolledUp)="onScrolledUp()"
            [alwaysCallback]="true"
            [scrollWindow]="false"
            class="text-xs flex flex-col overflow-scroll no-scrollbar text-white z-0"
            [@slideLeftEnterSelf]="'open'"
        >
            <div class="relative flex items-center justify-center">
                <div class="absolute top-4">
                    <app-tag *ngIf="instantMatch" [theme]="'chatInstantMatch'"
                        >Sofort-Match</app-tag
                    >
                    <app-tag *ngIf="!instantMatch" [theme]="'chatNewMatch'"
                        >Neues Match</app-tag
                    >
                </div>
            </div>
            <div class="space-y-2">
                <div
                    *ngIf="messageArray.length === 0"
                    class="flex items-center justify-center text-[10px] py-4"
                >
                    <div class="flex items-center justify-center">
                        <app-tag [theme]="'chatDate'">Heute</app-tag>
                    </div>
                </div>
            </div>
            <div
                *ngFor="let date of messageArray; let lastMessageArray = last"
                class="pt-4"
            >
                <div class="flex items-center justify-center text-[10px] py-5">
                    <app-tag [theme]="'chatDate'">{{ date.date }}</app-tag>
                </div>

                <div
                    *ngFor="
                        let singlemessage of date.messages;
                        let first = first;
                        let last = last
                    "
                >
                    <!-- spacke between messages by different senders -->
                    <div
                        class="py-4"
                        *ngIf="!singlemessage.lastMessageBySameSender && !first"
                    ></div>

                    <!-- check if message is the last message of all messages -->
                    <div *ngIf="last && lastMessageArray; else notLastMessage">
                        <app-message-item
                            [requestReadReceiptPrice]="fees?.unlockReadReceipt"
                            class="w-full"
                            [me]="me"
                            [message]="singlemessage"
                        >
                        </app-message-item>
                    </div>
                    <ng-template #notLastMessage>
                        <app-message-item
                            [requestReadReceiptPrice]="fees?.unlockReadReceipt"
                            class="w-full"
                            [me]="me"
                            [message]="singlemessage"
                        >
                        </app-message-item>
                    </ng-template>
                </div>
            </div>
            <div *ngIf="unsentMessage" class="p-2">
                <div class="shrink-0">
                    <div class="flex">
                        <div
                            class="bg-brand-magenta opacity-60 cursor-pointer text-opacity-60 break-words w-full rounded-md py-2 px-4 text-[11px] font-medium basis-5/6"
                        >
                            {{ unsentMessage.messageBody }}
                        </div>

                        <div class="flex items-center justify-center ml-2">
                            <img
                                class="h-4 drop-shadow-md"
                                src="../../../../assets/images/send_message_failed.png"
                                alt="send_message_failed"
                            />
                        </div>
                    </div>
                </div>
                <div class="flex justify-end items-end pt-2 px-2">
                    <div
                        class="flex flex-col text-xs md:text-[11px] text-brand-body"
                    >
                        <span class=""
                            ><span class="font-semibold"
                                >Guthaben reicht nicht aus:
                            </span>
                            <span
                                >Diese Nachricht wird automatisch versendet,
                                sobald du Credits auflädt</span
                            >
                        </span>
                        <br />
                        <div>
                            <img
                                class="inline-flex h-2 w-2 mr-1"
                                src="../../../../assets/images/abort_red.png"
                                alt="abort_red"
                            />
                            <span
                                class="font-semibold underline cursor-pointer"
                                (click)="deleteUnsentMessage(matchId)"
                                >Diese Nachricht löschen</span
                            >
                            <a
                                class="link ml-4 font-semibold"
                                routerLink="/user/shop"
                                >Zum Shop</a
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div id="endOfChat"></div>
        </div>
        <!-- Textbox -->
        <div
            class="relative flex flex-row w-full gap-[8px] px-2 md:right-auto md:left-auto pt-3 pb-6"
        >
            <div
                class="grid w-full items-center px-4  py-2 min-h-[44px] bg-white shadow-md {{
                    multiLines ? 'rounded-[10px]' : 'rounded-[25px]'
                }}"
            >
                <div
                    #inputField
                    id="contenteditableChatInput"
                    [(ngModel)]="chatField"
                    ngDefaultControl
                    name="chatField"
                    contenteditable="true"
                    (input)="changedInput()"
                    [ngClass]="[
                        emptyField
                            ? 'w-full text-brand-body text-opacity-60'
                            : 'text-brand-title'
                    ]"
                    placeholder="Schreibe etwas"
                    class="!chatfield font-medium break-words max-h-20 text-[0.9rem] leading-4 focus:outline-none cursor-pointer overflow-scroll no-scrollbar"
                >
                    {{ chatField }}
                </div>
            </div>
            <div
                class="relative flex flex-col items-center justify-center"
                (touchend)="submitButtonContainerTouchEnd($event)"
            >
                <div
                    *ngIf="showRequiredCoins"
                    class="absolute z-50 bottom-[calc(8px+40px)] h-10 w-10 bg-brand-title border-2 rounded-full border-white flex items-center justify-center text-white"
                >
                    <img
                        class="h-4 w-4 mr-[2px]"
                        src="../../../../assets/images/credit_shadow.png"
                        alt="credit_shadow"
                    /><span class="font-bold">{{ requiredCoins }}</span>
                </div>
                <!-- TODO: should be converted to proper button for accessiblity reasons -->
                <img
                    class="cursor-pointer h-11"
                    (click)="submitMessage()"
                    src="../../../../assets/images/send_message.png"
                    style="object-fit: contain"
                />
            </div>
        </div>
    </div>
</div>
