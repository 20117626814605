import { Component, Input } from "@angular/core";

@Component({
    selector: "app-email-sent",
    templateUrl: "./email-sent.component.html",
})
export class EmailSentComponent {
    @Input() preventClose = false;
    @Input() errorMessage = "";

    closeEmailSentScreen() {
        if (this.preventClose) {
            //do nothing
        }
    }
}
