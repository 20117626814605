import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { MouseDrag } from "src/app/mouse-drag/mouse-drag";
import { ImageService } from "src/app/services/image.service";

@Component({
    selector: "app-image-slider",
    templateUrl: "./image-slider.component.html",
})
export class ImageSliderComponent implements OnInit, AfterViewInit {
    @Input() images: any;
    @Input() member: any;
    @Input() startIndex = 0;
    @ViewChild("imageSwiper", { static: false }) imageSwiper!: ElementRef;
    currentIndex = 0;
    imageSwiperWidth!: number;
    dragPosition?: number;
    mouseDownX!: number;
    public unlockIcon = "unlock_default";
    public noOfImages = 0;
    public isClickInProgress = false;

    /**
     * Declarations for modal
     */
    @Output() unlockPhotoModal: EventEmitter<any> = new EventEmitter();
    @Output() closeLightBox: EventEmitter<any> = new EventEmitter();

    public matchInfo = {
        myImage: "",
        partnerImage: "",
        partnerId: "",
        matchId: "",
    };

    constructor(private imageService: ImageService) {}

    ngOnInit(): void {
        this.noOfImages = this.images.length;
        this.imageService.onImageUnlocked().subscribe(({ imageId }) => {
            this.startIndex = this.images.findIndex((image: any) => image._id === imageId);
        });
        this.currentIndex = this.startIndex;
    }

    ngAfterViewInit(): void {
        this.setSwiperWidths();
        this.setTranslateX();
    }

    setSwiperWidths(currentIndex?: number) {
        if (this.images && this.images.length > 0) {
            this.imageSwiperWidth = this.imageSwiper.nativeElement.parentNode.clientWidth;

            this.imageSwiper.nativeElement.style.width = `${
                this.imageSwiperWidth * this.images.length
            }px`;
            this.removeTransitionClass();
            this.imageSwiper.nativeElement.style.transform = currentIndex
                ? `translate3d(${this.currentIndex * -this.imageSwiperWidth}px, 0, 0)`
                : `translate3d(0, 0, 0)`;

            // set the widths of each image based on the parent width
            this.imageSwiper.nativeElement.childNodes.forEach((node: any) => {
                if (node.className === "image") {
                    node.style.width = `${this.imageSwiperWidth}px`;
                }
            });
        }
    }

    goToImage({ direction, mouseUpX }: { direction: string; mouseUpX: any }) {
        this.addTransitionClass();
        this.dragPosition = undefined;
        const dragDistance = mouseUpX - this.mouseDownX;
        // next image
        if (direction === "next") {
            if ((mouseUpX && dragDistance < -70) || !mouseUpX) {
                this.currentIndex === this.images.length - 1
                    ? (this.currentIndex = this.images.length - 1)
                    : (this.currentIndex += 1);
            }
        }

        // previous image
        if (direction === "previous") {
            if ((mouseUpX && dragDistance > 70) || !mouseUpX) {
                this.currentIndex === 0 ? (this.currentIndex = 0) : (this.currentIndex -= 1);
            }
        }
        this.setTranslateX();
    }

    /**
     * calculate the translateX value depending on the currentIndex
     * and the imageSwiperWidth so we know which image to show
     */

    setTranslateX() {
        this.imageSwiper.nativeElement.style.transform = `translate3d(${
            this.currentIndex * -this.imageSwiperWidth
        }px, 0, 0)`;
    }

    removeTransitionClass() {
        this.imageSwiper.nativeElement.classList.remove("transform");
        this.imageSwiper.nativeElement.classList.remove("duration-1000");
        this.imageSwiper.nativeElement.classList.remove("ease-in-out");
    }

    addTransitionClass() {
        this.imageSwiper.nativeElement.classList.add("transform");
        this.imageSwiper.nativeElement.classList.add("duration-1000");
        this.imageSwiper.nativeElement.classList.add("ease-in-out");
    }

    mouseDrag({ mouseDownX, dragX }: MouseDrag) {
        this.mouseDownX = mouseDownX;
        this.removeTransitionClass();
        this.dragPosition = this.currentIndex * -this.imageSwiperWidth + dragX;

        if (this.dragPosition < 0 && this.currentIndex === 0) {
            this.imageSwiper.nativeElement.style.transform = `translate3d(${this.dragPosition}px, 0, 0)`;
        }
        if (this.dragPosition > 0 && this.currentIndex === this.images.length - 1) {
            this.imageSwiper.nativeElement.style.transform = `translate3d(${this.dragPosition}px, 0, 0)`;
        }
        if (this.images.length === 1) {
            this.imageSwiper.nativeElement.style.transform = `translate3d(0px, 0, 0)`;
        }
    }

    @HostListener("window:resize")
    windowSizeChange() {
        this.setSwiperWidths(this.currentIndex);
    }

    close() {
        this.closeLightBox.emit();
    }
}
