<div class="space-y-6 flex-col">
    <h2 class="heading-magenta">Impressum</h2>
    <p>
        amolino.de ist eine Marke der Multilabel GmbH mit Sitz in Hamburg.
    </p>
    <p>
        <strong class="font-semibold">Anschrift</strong>
        <br>
        Multilabel GmbH
        <br>
        Hohe Bleichen 12
        <br>
        20354 Hamburg
    </p>
    <p>
        <strong class="font-semibold">Geschäftsführer</strong>
        <br>
        Martin Heuer
        <br>
        HRB 176119 Amtsgericht Hamburg
        <br>
        USt-ID: DE354577477
        <br>
        HRB 31175
    </p>
    <p>
        <strong class="font-semibold">Nutzeranfragen</strong>
        <br>
        Du bist amolino-Nutzer und hast ein Anliegen? Hier helfen wir dir gern:
        <br>
        Support: <a href="{{ contactEmailService.getContactEmailHref('Allgemeiner Support') }}" class="underline text-brand-cyan">support@amolino.de</a>
        <br>
        Jugendschutz: <a href="{{ contactEmailService.getContactEmailHref('Jugendschutz') }}" class="underline text-brand-cyan">support@amolino.de</a>
        <br>
        Datenschutz: <a href="{{ contactEmailService.getContactEmailHref('Datenschutz') }}" class="underline text-brand-cyan">support@amolino.de</a>
        <br>
    </p>
    <p>
        <strong class="font-semibold">Behördenanfragen</strong>
        <br>
        Als Behörde verwenden Sie bitte diese E-Mail-Adresse: <a href="{{ contactEmailService.getContactEmailHref('Behoerden') }}" class="underline text-brand-cyan">support@amolino.de</a>
    </p>
    <p>
        <strong class="font-semibold">OS-Streitbeilegung</strong>
        <br>
        Die ODR Verordnung (Online Streitbeilegungs-Verordnung) der EU-Kommission sieht die Einrichtung einer
        Online-Streitbeilegungsplattform auf EU-Ebene vor. Diese sogenannte OS-Plattform soll Anlaufstelle für Verbraucher und
        Unternehmer sein, die aus Online-Rechtsgeschäften entstandene Streitigkeiten außergerichtlich beilegen möchten.
        <br>
        <br>
        Sie finden die Plattform Online-Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO sowie weitergehende Informationen zur
        OS-Streitbeilegung hier:
        <br>
        <br>
<a href="https://ec.europa.eu/consumers/odr/main/?event=main.home.howitworks" class="underline text-brand-cyan">https://ec.europa.eu/consumers/odr/main/?event=main.home.howitworks</a>
        <br>
        <br>

        <strong class="font-semibold">Streitbeilegung nach VSBG</strong>
        <br>
Gemäß § 36 Abs. 1 VSBG haben wir Sie darüber zu informieren, inwieweit wir bereit oder verpflichtet sind, an
Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen. Hierzu teilen wir mit:

        <br>
        <br>

<strong class="font-semibold">Die Multilabel GmbH ist nicht bereit und verpflichtet, an Streitbeilegungsverfahren vor einer
Verbraucherschlichtungsstelle teilzunehmen.</strong>
<br>
        <br>

Pflichtinformationen nach Entstehen einer Streitigkeit (§37 VSBG)
    </p>
    <p>Sollten wir eine Streitigkeit aus einem Verbrauchervertrag nicht durch Verhandlungen mit dem Kunden beilegen können,
    werden wir den Kunden nach Entstehen der Streitigkeit in Textform auf eine für ihn zuständige
    Verbraucherschlichtungsstelle hinweisen. Diese Information kann nicht vorsorglich erteilt werden - sie erfolgt gegenüber
    jedem Verbraucher, wenn eine Streitigkeit mit diesem nicht beigelegt werden kann.</p>
</div>
