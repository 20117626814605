<app-hearts-bg [type]="'shop'" *ngIf="!pageProcessing">
    <div class="px-4 pb-8 pt-28 sm:pt-8 space-y-6">
        <!-- Payment Message -->
        <div *ngIf="message !== null" class="text-white">{{ message }}</div>

        <!-- Offers -->
        <div *ngIf="offers.length" class="space-y-6">
            <div class="flex items-center justify-between px-2">
                <h1 class="text-white heading-1">Angebote</h1>
                <div class="flex items-center space-x-2">
                    <img
                        class="h-4 w-4"
                        src="../../../../assets/images/sale_green_outline.png"
                        alt="sale_green_outline"
                    />
                    <p class="text-sm">{{ offers.length }} verfügbar</p>
                </div>
            </div>

            <div *ngFor="let offer of offers" class="mb-4">
                <app-product-price-item
                    [product]="offer"
                ></app-product-price-item>
            </div>
        </div>

        <!-- Credits -->
        <div *ngIf="credits.length" class="space-y-6">
            <h1 class="text-white px-2 heading-1">Credits</h1>
            <p class="px-2 text-white flow-text-logged-in">
                Credits benötigst Du, um mit anderen Nutzern und Nutzerinnen in
                Kontakt treten zu können. Profitiere von unseren attraktiven
                Rabatten bei den höherpreisigen Paketen und chatte ohne
                Einschränkungen und Wartezeiten. Worauf wartest Du noch? Hol Dir
                jetzt Deine Credits und starte durch!
            </p>
            <div>
                <div *ngFor="let credit of credits" class="mb-4">
                    <app-product-price-item
                        [product]="credit"
                    ></app-product-price-item>
                </div>
            </div>
        </div>

        <!-- Keys -->
        <div *ngIf="keys.length" class="space-y-6">
            <h1 class="heading-1 text-white px-2">Schlüssel</h1>
            <p class="px-2 text-white flow-text-logged-in">
                Mit den Schlüsseln hast Du Zugang zu unseren exklusiven
                Features, wie zum Beispiel dem Freischalten von Matches,
                Profilen und Fotos. Erlebe amolino uneingeschränkt und genieße
                alle Vorteile durch den Kauf von Schlüsseln. Worauf wartest Du
                noch? Hol Dir jetzt Deine Schlüssel und erweitere Dein
                Dating-Erlebnis!
            </p>

            <div>
                <div *ngFor="let key of keys; let i = index" class="mb-4">
                    <app-product-price-item [index]="i" [product]="key">
                    </app-product-price-item>
                </div>
            </div>
        </div>
    </div>
</app-hearts-bg>
<p *ngIf="pageProcessing" class="px-6 pt-6 text-semibold">Processing...</p>
