import { BreakpointObserver } from "@angular/cdk/layout";
import { AfterViewInit, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";

import { animate, state, style, transition, trigger } from "@angular/animations";
import {
    AuthLoginDataCustomTabResponse,
    AuthRegisterDataCustomTabResponse,
    AuthType,
    WebToAppService,
} from "src/app/services/web-to-app.service";
@Component({
    selector: "app-landing",
    templateUrl: "./landing.component.html",
    animations: [
        trigger("simpleFadeAnimation", [
            state("in", style({ opacity: 1 })),
            transition(":enter", [style({ opacity: 0 }), animate(300)]),
            transition(":leave", animate(300, style({ opacity: 0 }))),
        ]),
    ],
})
export class LandingComponent implements OnInit, AfterViewInit {
    popupLogin = false;
    popupLoginData!: AuthLoginDataCustomTabResponse;

    popupRegister = false;
    popupRegisterData!: AuthRegisterDataCustomTabResponse;

    popupAccountDeleted = false;
    delayShowingPage = false;
    loader = true;
    loadingTheme: string;
    deleteByDate?: string;

    tiktokClickId?: string;

    usingMobileDevice = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private webToAppService: WebToAppService,
        private breakpointObserver: BreakpointObserver
    ) {
        if (this.router.getCurrentNavigation()?.extras.state) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const state = this.router.getCurrentNavigation()?.extras.state as any;
            if (state && Object.prototype.hasOwnProperty.call(state, "date")) {
                this.deleteByDate = state.date;
            }
        }
    }

    handleLoading(event: boolean) {
        this.loader = event; // set local variable to received value
    }

    ngOnInit(): void {
        this.loadingTheme = "cyan";
        const accountJustDeletedLS = localStorage.getItem("accountJustDeleted");
        const accountJustDeleted = accountJustDeletedLS
            ? JSON.parse(accountJustDeletedLS)
            : undefined;

        if (accountJustDeleted === true) {
            this.popupAccountDeleted = true;
            localStorage.removeItem("accountJustDeleted");
            // localStorage.removeItem('accountJustDeleted');
        }

        this.route.queryParams.subscribe((params) => {
            if (params["open"] === "register") {
                this.popupRegister = true;
            }
            if (params["open"] === "login") {
                this.popupLogin = true;
            }
            if (params[environment.ttQueryString]) {
                this.tiktokClickId = params[environment.ttQueryString];
            }
        });

        this.breakpointObserver.observe("(max-width: 768px)").subscribe((result) => {
            this.usingMobileDevice = result.matches;
        });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.loader = false;
            this.loadingTheme = "transparent";
        }, 2000); // 2000 milliseconds = 2 seconds
    }

    processCustomTabResponse(
        data: AuthRegisterDataCustomTabResponse | AuthLoginDataCustomTabResponse
    ) {
        if (data.authType === AuthType.LOGIN) {
            this.popupLoginData = data as AuthLoginDataCustomTabResponse;
            this.popupLogin = true;
        } else if (data.authType === AuthType.REGISTER) {
            this.popupRegisterData = data as AuthRegisterDataCustomTabResponse;
            this.popupRegister = true;
        }
    }

    async loginButtonClicked() {
        const isUsingApp = this.webToAppService.isUsingApp();
        if (isUsingApp) {
            this.loader = true;

            try {
                const responseData:
                    | AuthRegisterDataCustomTabResponse
                    | AuthLoginDataCustomTabResponse =
                    await this.webToAppService.loginUsingCustomTab();
                this.processCustomTabResponse(responseData);
            } catch (e) {
                console.warn("Couldn't login using Custom Tab:", e);
            }

            this.loader = false;
        } else {
            this.popupLogin = true;
        }
    }

    async registerButtonClicked() {
        const isUsingApp = this.webToAppService.isUsingApp();
        if (isUsingApp) {
            this.loader = true;

            try {
                const responseData:
                    | AuthRegisterDataCustomTabResponse
                    | AuthLoginDataCustomTabResponse =
                    await this.webToAppService.registerUsingCustomTab();
                this.processCustomTabResponse(responseData);
            } catch (e) {
                console.warn("Couldn't register using Custom Tab:", e);
            }

            this.loader = false;
        } else {
            this.popupRegister = true;
        }
    }
}
