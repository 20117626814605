import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { IsAuthenticatedGuard } from "./guards/is-authenticated.guard";
import { IsNotAuthenticatedGuard } from "./guards/is-not-authenticated.guard";
import { IsReadyForRegisterStepGuard } from "./guards/is-ready-for-register-step.guard";
import { AboutYouComponent } from "./public/about-you/about-you.component";
import { AccountSettingsComponent } from "./public/account-settings/account-settings/account-settings.component";
import { ChangeEmailComponent } from "./public/account-settings/change-email/change-email.component";
import { ChangePasswordComponent } from "./public/account-settings/change-password/change-password.component";
import { EditMyInterestsComponent } from "./public/account-settings/edit-my-interests/edit-my-interests.component";
import { AccountPausedComponent } from "./public/account/account-paused/account-paused.component";
import { AppleCallbackComponent } from "./public/apple-callback/apple-callback.component";
import { ProfileChatComponent } from "./public/chats/profile-chat/profile-chat.component";
import { CookiePolicyComponent } from "./public/cookie-policy/cookie-policy.component";
import { DataProtectionComponent } from "./public/data-protections/data-protection/data-protection.component";
import { ResetPasswordComponent } from "./public/forgot-password/reset-password/reset-password.component";
import { HelpHomeComponent } from "./public/help/help-home/help-home.component";
import { ImprintTestComponent } from "./public/imprints/imprint-test/imprint-test.component";
import { ImprintComponent } from "./public/imprints/imprint/imprint.component";
import { LandingComponent } from "./public/landing/landing.component";
import { NotFoundPageComponent } from "./public/not-found-page/not-found-page.component";
import { CheckoutPaypalComponent } from "./public/payments/checkout-paypal/checkout-paypal.component";
import { CheckoutStripeComponent } from "./public/payments/checkout-stripe/checkout-stripe.component";
import { CheckoutComponent } from "./public/payments/checkout/checkout.component";
import { PrepaymentOrderComponent } from "./public/payments/prepayment-order/prepayment-order.component";
import { PurchaseSuccessfulComponent } from "./public/payments/purchase-successful/purchase-successful.component";
import { PurchaseUnsuccessfulComponent } from "./public/payments/purchase-unsuccessful/purchase-unsuccessful.component";
import { CropPhotoComponent } from "./public/photos-settings/crop-photo/crop-photo.component";
import { DeletePhotoComponent } from "./public/photos-settings/delete-photo/delete-photo.component";
import { EditPhotoComponent } from "./public/photos-settings/edit-photo/edit-photo.component";
import { MyPhotosComponent } from "./public/photos-settings/my-photos/my-photos.component";
import { EditProfileComponent } from "./public/profile/edit-profile/edit-profile.component";
import { MyProfileComponent } from "./public/profile/my-profile/my-profile.component";
import { PublicProfileComponent } from "./public/public-profile/public-profile.component";
import { LocationInfoComponent } from "./public/register/location-info/location-info.component";
import { RightOfWithdrawalComponent } from "./public/rights/right-of-withdrawal/right-of-withdrawal.component";
import { SetProfileComponent } from "./public/set-profile/set-profile.component";
import { TermsAndConditionsComponent } from "./public/terms-and-conditions/terms-and-conditions.component";
import { PasswordResetComponent } from "./user-auth/login-issues/password-reset/password-reset.component";
import { RegisterSuccessComponent } from "./user-auth/register-success/register-success.component";
import { ChangeUsernameComponent } from "./public/account-settings/change-username/change-username.component";
const routes: Routes = [
    { path: "apple-callback", component: AppleCallbackComponent },

    {
        path: "imprint-test",
        component: ImprintTestComponent,
    },
    {
        path: "data-protection",
        component: DataProtectionComponent,
    },
    {
        path: "help",
        component: HelpHomeComponent,
    },
    {
        path: "imprint",
        component: ImprintComponent,
    },
    {
        path: "terms",
        component: TermsAndConditionsComponent,
    },
    {
        path: "right-of-withdrawal",
        component: RightOfWithdrawalComponent,
    },

    {
        path: "user",
        canActivate: [IsAuthenticatedGuard],
        loadChildren: () => import("./public/public.module").then((m) => m.PublicModule),
    },

    {
        path: "",
        canActivate: [IsNotAuthenticatedGuard],
        component: LandingComponent,
    },
    {
        path: "landing",
        canActivate: [IsNotAuthenticatedGuard],
        component: LandingComponent,
    },
    {
        path: "public-profile/:id",
        canActivate: [IsNotAuthenticatedGuard],
        component: PublicProfileComponent,
    },
    {
        path: "register/step",
        canActivate: [IsReadyForRegisterStepGuard],
        component: SetProfileComponent,
    },
    {
        path: "register/location-info",
        canActivate: [IsReadyForRegisterStepGuard],
        component: LocationInfoComponent,
    },
    {
        path: "reset-password",
        canActivate: [IsNotAuthenticatedGuard],
        component: ResetPasswordComponent,
    },
    {
        path: "checkout-paypal",
        component: CheckoutPaypalComponent,
    },
    {
        path: "",
        canActivate: [IsAuthenticatedGuard],
        children: [
            {
                path: "register-successfull",
                component: RegisterSuccessComponent,
            },
            {
                path: "chat/:id",
                component: ProfileChatComponent,
            },
            {
                path: "profile-chat/:id",
                component: ProfileChatComponent,
            },
            {
                path: "edit-photo/:id",
                component: EditPhotoComponent,
            },
            {
                path: "crop-photo",
                component: CropPhotoComponent,
            },
            {
                path: "edit-profile",
                component: EditProfileComponent,
            },
            {
                path: "change-password",
                component: ChangePasswordComponent,
            },
            {
                path: "my-profile",
                component: MyProfileComponent,
            },
            {
                path: "cookie-policy",
                component: CookiePolicyComponent,
            },
            {
                path: "change-email",
                component: ChangeEmailComponent,
            },
            {
                path: "change-username",
                component: ChangeUsernameComponent,
            },
            {
                path: "edit-my-interests",
                component: EditMyInterestsComponent,
            },
            {
                path: "profile/:from/:id",
                component: ProfileChatComponent,
            },
            {
                path: "account-paused",
                component: AccountPausedComponent,
            },
            {
                path: "password-reset",
                component: PasswordResetComponent,
            },
            {
                path: "checkout",
                component: CheckoutComponent,
            },
            {
                path: "checkout-stripe",
                component: CheckoutStripeComponent,
            },
            {
                path: "purchase-unsuccessful",
                component: PurchaseUnsuccessfulComponent,
            },
            {
                path: "purchase-successful",
                component: PurchaseSuccessfulComponent,
            },
            {
                path: "prepayment-order",
                component: PrepaymentOrderComponent,
            },
            {
                path: "account-settings",
                component: AccountSettingsComponent,
            },
            {
                path: "my-photos",
                component: MyPhotosComponent,
            },
            {
                path: "about-you",
                component: AboutYouComponent,
            },
            {
                path: "delete-photo",
                component: DeletePhotoComponent,
            },
        ],
    },
    { path: "**", component: NotFoundPageComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
