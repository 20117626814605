<app-main-layout [showProfile]="true" [leftMenuText]="'Email ändern'">
    <div class="pt-11 sm:pt-0">
        <form [formGroup]="form" *ngIf="!showEmailSent">
            <div class="absolute -top-7 right-4 font-medium text-white text-sm">
                <button
                    [disabled]="!form.valid"
                    class="flex space-x-2 items-center {{
                        !form.valid ? 'opacity-40' : ' '
                    }}"
                >
                    <p class="underline">Email-anfordern</p>
                    <!-- TODO: rplace the correct icon -->
                    <img
                        class="h-3"
                        src="../../../../assets/images/save_icon.png"
                        alt="save_icon"
                    />
                </button>
            </div>
            <div class="px-4 text-brand-body text-[13px]">
                <h2 class="my-10 text-brand-magenta heading-2">
                    Neue E-Mail Adresse festlegen
                </h2>
                <p class="leading-6 mb-10 mr-14">
                    Gib bitte Deine neue E-Mail-Adresse ein. Anschließend senden
                    wir Dir eine E-Mail, mit der Du dein Passwort zurücksetzen
                    kannst.
                </p>
                <app-input-box
                    [label]="'E-Mail'"
                    [icon]="'auth/at-symbol.png'"
                    class="mb-3"
                    [error]="!email.valid"
                >
                    <input
                        class="w-full focus:outline-none text-base placeholder:opacity-50"
                        placeholder="Wie lautet Deine neue E-Mail Adresse"
                        type="email"
                        formControlName="email"
                    />
                    <p
                        error
                        *ngIf="email.touched && email.hasError('required')"
                    >
                        Bitte geben Sie eine gültige E-Mail-Adresse ein.
                    </p>
                    <p error *ngIf="email.touched && email.hasError('email')">
                        Bitte geben Sie eine gültige E-Mail-Adresse ein.
                    </p>
                </app-input-box>
                <p class="text-brand-danger" *ngIf="requestError !== null">
                    {{ requestError }}
                </p>
                <app-link-button
                    (userClicked)="changeEmail()"
                    class="w-full"
                    [theme]="'purple'"
                >
                    E-Mail anfordern
                </app-link-button>
            </div>
        </form>
        <div
            *ngIf="showEmailSent"
            @simpleFadeAnimation
            class="z-50 absolute inset-0 top-[43px] md:top-0"
        >
            <app-modal
                [customFooter]="true"
                [footer]="'Keine E-Mail erhalten?'"
                [linkLink]="'/help'"
                [footerLinkText]="'E-Mail erneut senden'"
                [topPadding]="'6'"
                (closeMatch)="showEmailSent = !showEmailSent"
                [h1]="'E-Mail gesendet'"
                [image]="'../../../../assets/images/checkmark-circle.png'"
            >
                <div subtitle>
                    Eine Bestätigungsmail wurde an Deine E-Mail-Adresse
                    gesendet. Bitte überprüfe auch Deinen Posteingang sowie
                    Deinen Spam- bzw. Junk-Ordner. Es kann bis zu 5 Minuten
                    dauern, bis die E-Mail bei Dir eintrifft.
                </div>
                <div content></div>
            </app-modal>
        </div>
    </div>
</app-main-layout>
