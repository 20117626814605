<div class="w-full flex justify-between items-end text-brand-title">
    <div class="font-bold">{{ title }}</div>
    <label
        for="all-toggle"
        class="inline-flex relative items-center cursor-pointer"
    >
        <div
            (click)="toogleButton()"
            id="toggle-button"
            class="{{ large ? 'w-10 h-[22px]' : 'w-7' }}  bg-brand-{{
                !state ? activatedColor : inactiveColor
            }} {{
                state ? 'bg-opacity-100' : 'bg-opacity-30'
            }} rounded-full p-[2px]"
        >
            <p
                class="{{
                    large ? 'h-[18px] w-[18px]' : 'h-[14px] w-[14px]'
                }}  rounded-full bg-white {{
                    state && !large ? 'translate-x-[10px]' : ' '
                }} {{ state && large ? 'translate-x-[17px]' : ' ' }}"
            ></p>
        </div>
    </label>
</div>
