<button
    type="submit"
    [ngClass]="!isDisabled ? 'bg-brand-magenta' : 'bg-brand-body/30'"
    [disabled]="isDisabled"
    [class]="
        'py-2 text-center rounded-full text-white text-lg font-bold ' + addClass
    "
>
    <ng-content></ng-content>
</button>
