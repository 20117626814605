import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { ApiService } from "../services/api.service";

@Injectable({
    providedIn: "root",
})
export class IsReadyForRegisterStepGuard implements CanActivate {
    constructor(private router: Router, private api: ApiService) {}
    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.api.isAuthenticated()) {
            if (localStorage.getItem("register_completed") === "TRUE") {
                this.router.navigate(["user/discover"]);
                return false;
            } else {
                return true;
            }
        } else {
            this.router.navigate(["/"]);
            return false;
        }
    }
}
